<template>
    <div id="security-notice-container" class="overlay-content" v-if="displayNotice">
        <Panel header="Notice" style="max-width: 500px;">
            <p>
                By accessing use of our services at The Exemption Project, Inc (dba TrueRoll), 
                you agree to comply with federal and state laws, including the Driver's Privacy 
                Protection Act, Identity Theft Enforcement and Protection Action, Federal 
                Fair Credit Reporting Act, and Texas Motor Vehicle Records Disclosure Act. 
            </p>
            <p>
                Use of this service to prevent or investigate fraud related to homestead 
                transactions is permitted provided that (1) The service will only by used 
                by your investigation department for the duties of their office (2) Use by 
                any other department and external party, as well as distribution of confidential 
                information, is strictly prohibited (3) You have and will maintain appropriate 
                processes and procedures under Federal Information Processing Standard to 
                secure data from unauthorized access.
            </p>
            <Button severity="success" @click="handleAcceptClick">Accept and Continue</Button>
        </Panel>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import Button from "primevue/button"
import Panel from "primevue/panel"
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager"


const displayNotice = ref(false)
const ACCEPT_VALUE = "1"


const handleAcceptClick = () => {
	displayNotice.value = false;
	storageManager.setItem(StorageKeyEnum.SecurityNoticeAccepted, ACCEPT_VALUE)
}

onMounted(() => {
	if (storageManager.getItem(StorageKeyEnum.SecurityNoticeAccepted) !== ACCEPT_VALUE) {
		displayNotice.value = true
	}
})
</script>
