<template>
	<ApplicationSection title="Co-Applicant"
		v-if="formFields.applicant_add_spouse || formFields.applicant_add_coapplicant">
		<div class="element-container">
			<FileUploader :id="getId('id-file-2')" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label :for="getId('id-file-2')" class="form-label form-label-required">
						Upload: Driver License or state issued personal identification certificate
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus :containerID="getId('id-file-2')" />
		</div>
		<div class="element-container" v-for="field in coapplicantFields" :key="`coapplicant-${field.id}`">
			<label :for="`coapplicant-${field.id}`" class="form-label"
				:class="{ 'form-label-required': field.required }">
				{{ field.label }}
				<span class="fa fa-info-circle" v-if="field.tooltip" v-tooltip="field.tooltip"></span>
			</label>
			<component :id="`coapplicant-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required" @invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label class="form-label form-label-required">
				Are you a citizen of the United States?
			</label>
			<CustomRadioGroup required groupId="coapplicant-is-us-citizen" v-model="formFields.coapplicant_is_us_citizen"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'coapplicant-is-us-citizen-yes', name: 'coapplicant-is-us-citizen', label: 'Yes', value: true },
					{ id: 'coapplicant-is-us-citizen-no', name: 'coapplicant-is-us-citizen', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.coapplicant_is_us_citizen === true">
			<label for="coapplicant-sss-no" class="form-label form-label-required">
				Social Security #
				<span class="fa fa-info-circle"
					v-tooltip="'Disclosure of your social security number is mandatory. It is required by section 196.011(1)(b), Florida Statutes. The social security number will be used to verify taxpayer identity and homestead exemption information submitted to property appraisers.'"></span>
			</label>
			<InputMask id="coapplicant-sss-no" v-model="formFields.coapplicant_sss_no" mask="999-99-9999"
				@invalid.capture.prevent="elementRequiredHandler" :pt="requiredInputAttribute" />
		</div>

		<div class="element-container ms-2" v-if="formFields.coapplicant_is_us_citizen === false">
			<label for="coapplicant-imigration-no" class="form-label form-label-required">
				Immigration #
			</label>
			<InputText id="coapplicant-immigration-no" v-model="formFields.coapplicant_imigration_no"
				@invalid.capture.prevent="elementRequiredHandler" required />
		</div>

		<div class="element-container" v-for="field in coapplicantFields2" :key="`coapplicant2-${field.id}`">
			<label :for="`coapplicant2-${field.id}`" class="form-label"
				:class="{ 'form-label-required': field.required }">
				{{ field.label }}
				<span class="fa fa-info-circle" v-if="field.tooltip" v-tooltip="field.tooltip"></span>
			</label>
			<component :id="`coapplicant2-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required" @invalid.capture.prevent="elementRequiredHandler"
				:pt="field.pt" />
		</div>
		<div class="element-container">
			<label for="coapplicant-class-residency" class="form-label form-label-required">
				Does the co-applicant claim residency in another county or state?
			</label>
			<CustomRadioGroup groupId="coapplicant-claim-residency" v-model="formFields.coapplicant_claim_residency"
				required :elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'class-residency-yes', name: 'coapplicant-claim-residency', label: 'Yes', value: true },
					{ id: 'class-residency-no', name: 'coapplicant-claim-residency', label: 'No', value: false }
				]" />
		</div>
	</ApplicationSection>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import InputMask from "primevue/inputmask"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { computed, watch } from 'vue'
import { useApplicationForm } from '@/stores/applicationForm'
import type { ApplicationDataFL } from "@/helpers/interface/applicationForm"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import FileUploader from "@/components/Shared/FileUploader.vue"
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"
import InputNumber from "primevue/inputnumber"
import { requiredInputAttribute } from "@/helpers/common"

const {
	elementRequiredHandler,
	acceptFileTypes,
	uploadFiles
} = defineProps<{
	elementRequiredHandler: <T extends Event>(e: T) => void;
	acceptFileTypes: string;
	uploadFiles: (e: Event) => void;
}>()

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

interface CoapplicantField {
	id: string;
	label: string;
	component: any;
	model: keyof ApplicationDataFL;
	required: boolean;
	props?: Record<string, any>;
	pt?: {};
	tooltip?: string;
}

const getId = (text: string) => (`property-owner-${text}`)

const coapplicantFields: CoapplicantField[] = [
	{ id: 'first-name', label: 'First Name', component: InputText, model: 'coapplicant_first_name', required: true },
	{ id: 'middle-name', label: 'Middle Name', component: InputText, model: 'coapplicant_middle_name', required: false },
	{ id: 'last-name', label: 'Last Name', component: InputText, model: 'coapplicant_last_name', required: true },
	{ id: 'suffix', label: 'Name Suffix (e.g. Sr., IV, etc.)', component: InputText, model: 'coapplicant_suffix', required: false }
]

const coapplicantFields2: CoapplicantField[] = [
	{ id: 'dob', label: 'Date of Birth', component: DateComboBox, model: 'coapplicant_dob', required: true, props: { maxDate: new Date(), minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)) }, pt: requiredInputAttribute },
	{ id: 'percent-ownership', label: 'Percent ownership #', component: InputNumber, model: 'coapplicant_percent_ownership', required: true, props: { min: 0, max: 100 }, pt: requiredInputAttribute },
	{ id: 'date-permanent-residency', label: 'Date of permanent residency in Florida', component: DateComboBox, model: 'coapplicant_date_permanent_residency', required: true,  pt: requiredInputAttribute },
	{ id: 'email', label: 'Email Address', component: InputText, model: 'coapplicant_email', required: false, props: { type: 'email' }, tooltip: "To be used exclusively for communication about this application." },
	{ id: 'daytime-phone', label: 'Daytime Phone #', component: InputText, model: 'coapplicant_daytime_phone', required: false }
]

watch(() => formFields.value.coapplicant_is_us_citizen, (newVal) => {
	if (newVal === true) {
		formFields.value.coapplicant_imigration_no = ""
	}

	if (newVal === false) {
		formFields.value.coapplicant_sss_no = ""
	}
})

watch(() => formFields.value.applicant_add_spouse, (newVal) => {
	// If spouse as co-applicant is deselected, reset related co-applicant fields to null or empty
	if (!newVal) {
		formFields.value.coapplicant_first_name = ""
		formFields.value.coapplicant_middle_name = ""
		formFields.value.coapplicant_last_name = ""
		formFields.value.coapplicant_suffix = ""
		formFields.value.coapplicant_sss_no = ""
		formFields.value.coapplicant_imigration_no = ""
		formFields.value.coapplicant_dob = ""
		formFields.value.coapplicant_percent_ownership = null
		formFields.value.coapplicant_date_permanent_residency = ""
		formFields.value.coapplicant_email = ""
		formFields.value.coapplicant_daytime_phone = ""
		formFields.value.coapplicant_claim_residency = null
	}
})

watch(() => formFields.value.applicant_add_coapplicant, (newVal) => {
	// If co-applicant is deselected, reset related co-applicant fields to null or empty
	if (!newVal) {
		formFields.value.coapplicant_first_name = ""
		formFields.value.coapplicant_middle_name = ""
		formFields.value.coapplicant_last_name = ""
		formFields.value.coapplicant_suffix = ""
		formFields.value.coapplicant_sss_no = ""
		formFields.value.coapplicant_imigration_no = ""
		formFields.value.coapplicant_dob = ""
		formFields.value.coapplicant_percent_ownership = null
		formFields.value.coapplicant_date_permanent_residency = ""
		formFields.value.coapplicant_email = ""
		formFields.value.coapplicant_daytime_phone = ""
		formFields.value.coapplicant_claim_residency = null
	}
})

</script>
