<template>
    <div class="ir-details">
        <form @submit.prevent="updateIRDetails()">
            <table class="table table-striped table-sm">
                <tbody>
                    <tr>
                        <td class="ir-detail-key ir-detail-key-edit">{{ prettyNames['recommendation'] }}</td>
                        <td class="ir-detail-value ir-detail-value-edit text-wrap">
                            <ReportRecommendationDropdown v-model="editIR.recommendation" :customClass="'cbFormTextField cbFormTextField-xl'" />
                        </td>
                    </tr>
                    <tr>
                        <td class="ir-detail-key ir-detail-key-edit">{{ prettyNames['summary'] }}</td>
                        <td class="ir-detail-value ir-detail-value-edit text-wrap">
                            <RichTextEditor v-model:editorData="editIR.summary_par_1" :fieldID="'summaryPar1LimitExceed'"
                                @exceed-char-limit="handleCharLimitExceed" />
                        </td>
                    </tr>
                    <tr v-if="unqualifiedSelected">
                        <td class="ir-detail-key ir-detail-key-edit">{{ prettyNames['first_unqualified_year'] }}</td>
                        <td class="ir-detail-value ir-detail-value-edit text-wrap">
                            <select class="cbFormTextField cbFormTextField-xl" id="first_unqualified_year"
                                v-model="editIR.first_unqualified_year" required>
                                <option value="" selected>-- Any --</option>
                                <option v-for="f in first_unqualified_year_options" :value="f" :key="f">
                                    {{ f }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr v-if="unqualifiedSelected">
                        <td class="ir-detail-key ir-detail-key-edit">{{ prettyNames['primary_violation_reason'] }}</td>
                        <td class="ir-detail-value ir-detail-value-edit text-wrap">
                            <select class="cbFormTextField cbFormTextField-xl" id="reason" v-model="editIR.reason" required>
                                <option value="" selected>-- Any --</option>
                                <option v-for="pv in primaryViolationReasonOptions" :value="pv.value" :key="pv.value">
                                    {{ pv.text }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr v-if="unqualifiedSelected">
                        <td class="ir-detail-key ir-detail-key-edit">{{ prettyNames['owner_evidence'] }}</td>
                        <td class="ir-detail-value ir-detail-value-edit text-wrap">
                            <RichTextEditor v-model:editorData="editIR.summary_par_2" :fieldID="'summaryPar2LimitExceed'"
                                @exceed-char-limit="handleCharLimitExceed" />
                        </td>
                    </tr>
                    <tr v-if="unqualifiedSelected">
                        <td class="ir-detail-key ir-detail-key-edit">{{ prettyNames['situs_evidence'] }}</td>
                        <td class="ir-detail-value ir-detail-value-edit text-wrap">
                            <RichTextEditor v-model:editorData="editIR.summary_par_3" :fieldID="'summaryPar3LimitExceed'"
                                @exceed-char-limit="handleCharLimitExceed" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-block pt-2 text-end m-2">
                <div class="d-inline-flex">
                    <loading-icon v-if="formLoading" class="mx-2" />
                    <button class="searchButton" type="submit" :disabled="formLoading || exceedCharLimit">Update</button>
                    <button class="clearButton" type="button" @click="cancelEdit()" :disabled="formLoading">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import { ref, type PropType, computed } from 'vue';
import { getApiErrorMessage, QueueMap } from "@/helpers/common";
import LoadingIcon from '@/components/Shared/LoadingIcon.vue';
import RichTextEditor from "@/components/Shared/RichTextEditor.vue";
import { primaryViolationReasonOptions } from "@/helpers/dropdownOptions"
import type { InvestigationReportSummary, CandidateFormReport } from '@/helpers/interface/candidates';
import ReportRecommendationDropdown from "@/components/Shared/ReportRecommendationDropdown.vue";

const props = defineProps({
	data: {
		type: Object as PropType<Partial<InvestigationReportSummary>>,
		required: true,
		isReadonly: false,
	},
	prettyNames: {
		type: Object as PropType<Partial<InvestigationReportSummary>>,
		required: true
	}
})
const api = useAPI()
const formLoading = ref(false)
const summaryPar1LimitExceed = ref(false);
const summaryPar2LimitExceed = ref(false);
const summaryPar3LimitExceed = ref(false);

const exceedCharLimit = computed(() => (summaryPar1LimitExceed.value || summaryPar2LimitExceed.value || summaryPar3LimitExceed.value))
const unqualifiedSelected = computed(() => editIR.value.recommendation == QueueMap.Unqualified.id)

const first_unqualified_year_options: number[] = []
for (let index = 2009; index <= (new Date().getFullYear() + 1); index++) {
	first_unqualified_year_options.push(index)
}

const handleCharLimitExceed = (exceed: boolean, field: string) => {
	const fieldRefs: any = {
		summaryPar1LimitExceed,
		summaryPar2LimitExceed,
		summaryPar3LimitExceed,
	}

	if (fieldRefs[field] !== undefined) {
		fieldRefs[field].value = exceed;
	}
}

const editIRInitialState = {
	recommendation: props.data['recommendation'] || "",
	first_unqualified_year: props.data['first_unqualified_year'] || "",
	reason: props.data['primary_violation_reason'] || "",
	summary_par_1: props.data['summary'] || "", // summary of recommendation
	summary_par_2: props.data['owner_evidence'] || "", // owner whereabouts
	summary_par_3: props.data['situs_evidence'] || "" // situs resident whereabouts
}

const editIR = ref<CandidateFormReport>({
	...editIRInitialState
})

const emits = defineEmits(["cancelEdit", "formUpdated"])

let updateIRDetails = async () => {
	const tru_id = props.data["tru_id"] as string
	const ir_id = props.data["id"] as string

	formLoading.value = true

	const data = {
		"id": parseInt(ir_id),
		...editIR.value
	}

	if (!unqualifiedSelected.value) {
		data.first_unqualified_year = null
		data.reason = null
		data.summary_par_2 = ""
		data.summary_par_3 = ""
	}

	try {
		await api.patch(`/taxroll/investigation_report/${tru_id}`, data);
		toast.success(`Your update has been saved`)
		emits("formUpdated")
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
	}
	finally {
		formLoading.value = false;
	}

}

let cancelEdit = () => {
	emits("cancelEdit")
}
</script>

<style>
@import "@/assets/ir-page.css";
</style>