<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{name: 'data-requests'}">Onboarding Toolbox</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ newRequest ? "Create New": `${state.dataRequest?.title || "Untitled"} (${state.dataRequest?.status})` }}</li>
      </ol>
    </nav>

    <div>
      <h3>{{newRequest?'New':'Selected'}} Data Request</h3>
      <div v-if="state.dataRequestLoading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="completedUploads.length > 0">
        <h4>Completed Uploads</h4>
        <ul>
          <li v-for="upload in completedUploads" :key="upload.id">
            <a :href="upload.download_url!" target="_blank"><font-awesome-icon :icon="`fa-solid ${getIconForFilename(upload.file_name)}`"/> {{ upload.item_id }}/{{ upload.file_name }}</a>
          </li>
        </ul>
      </div>
      <!-- Form data -->
      <div class="mb-3" v-if="state.dataRequest">
        <label for="statusInput" class="form-label">Status</label>
        <select id="statusInput" class="form-control" v-model="state.formValues.status">
          <option>pending</option>
          <option>partially_filled</option>
          <option>cancelled</option>
          <option>completed</option>
          <option>deleted</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="recipientEmailInput" class="form-label">Recipient Email addresses (comma-separated list of emails to send the data request to)</label>
        <input type="text" class="form-control" id="recipientEmailInput" placeholder="name@example.com" v-model="state.formValues.recipient_emails">
      </div>
      <div class="mb-3">
        <label for="osmEmailInput" class="form-label">OSM Email addresses (comma-separated list of emails to send completion notification to)</label>
        <input type="text" class="form-control" id="osmEmailInput" placeholder="onboarding@trueroll.io" v-model="state.formValues.osm_emails">
      </div>
      <div v-if="!state.customers || state.customersLoading" class="spinner-border" role="status"><span class="visually-hidden">Loading customers list...</span></div>
      <div class="mb-3">
        <label for="customerInput" class="form-label">Customer <span class="text-danger">*</span></label>
        <input
            type="text"
            class="form-control"
            :class="{'is-invalid': state.validationErrors.customer_id}"
            id="customerInput"
            placeholder="Select Customer"
            list="customers"
            v-model="customerSelectedLabel"
            required
        >
        <div class="invalid-feedback" v-if="state.validationErrors.customer_id">
          {{ validationRules.customer_id.message }}
        </div>
        <datalist id="customers">
          <option
              v-for="customer in state.customers"
              :key="customer.customer_id"
              :value="`(${customer.customer_id}) ${customer.name} ${customer.fips_code} ${customer.schema_name}`"
          />
        </datalist>
      </div>
      <div class="mb-3">
        <label for="titleInput" class="form-label">Title <span class="text-danger">*</span></label>
        <input
            type="text"
            class="form-control"
            :class="{'is-invalid': state.validationErrors.title}"
            id="titleInput"
            placeholder="Title"
            v-model="state.formValues.title"
            required
        >
        <div class="invalid-feedback" v-if="state.validationErrors.title">
          {{ validationRules.title.message }}
        </div>
      </div>
      <div class="mb-3">
        <label for="requestTypeInput" class="form-label">Request Type</label>
        <select id="requestTypeInput" class="form-control" v-model="state.formValues.request_type">
          <option value="TaxRoll">TaxRoll</option>
          <option value="Paper Applications">Paper Applications</option>
          <option value="Digital Applications">Digital Applications</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="descriptionInput" class="form-label">Description</label>
        <markdown-editor v-model="state.formValues.description" />
      </div>
      <h4>Request Items</h4>
      <ul>
        <li v-for="(item, i) in state.formValues.items" :key="`item-${i}`">
          <div class="mb-3">
            <label for="itemIdInput" class="form-label">Item ID</label>
            <input type="text" class="form-control" id="itemIdInput" placeholder="Title" v-model="item.id">
          </div>
          <div class="mb-3">
            <label for="itemTitleInput" class="form-label">Item Title</label>
            <input type="text" class="form-control" id="itemTitleInput" placeholder="Title" v-model="item.title">
          </div>
          <div class="mb-3">
            <label for="itemDescriptionInput" class="form-label">Description</label>
            <markdown-editor v-model="item.description" />
          </div>
          <button type="button" class="btn btn-outline-secondary btn-sm m-1" @click="state.formValues.items?.splice(i, 1)">Remove</button>
        </li>
        <li>
          <button type="button" class="btn btn-outline-primary btn-sm" @click="state.formValues.items?.push({id: `item-${state.formValues.items?.length + 1}`, title: `Item ${state.formValues.items?.length+1}`})">Add Item</button>
        </li>
      </ul>
      <div v-if="state.dataRequest" class="mb-3">
        <label for="linkInout" class="form-label">Data Request Link</label>
        <input type="text" class="form-control" id="linkInout" placeholder="Link" :value="getDataRequestUrl(state.dataRequest)">
      </div>
      <div>
        <button :disabled="state.dataRequestLoading" type="button" class="btn btn-primary me-1" @click="submitForm()">Save Changes</button>
        <button :disabled="state.dataRequestLoading" type="button" class="btn btn-outline-primary me-1" @click="discardChanges()">Discard Changes</button>
        <button :disabled="state.dataRequestLoading" v-if="state.dataRequest" type="button" class="btn btn-outline-primary me-1" @click="cloneSelected()">Clone Data Request</button>
        <button :disabled="state.dataRequestLoading" v-if="state.dataRequest?.status === 'pending'" type="button" class="btn btn-outline-primary me-1" @click="cancelSelected()">Cancel Data Request</button>
        <button :disabled="state.dataRequestLoading" v-if="state.dataRequest && state.dataRequest?.status !== 'deleted'" type="button" class="btn btn-outline-primary me-1" @click="deleteSelected()">Delete Data Request</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import {computed, reactive, onMounted, watch} from "vue";
import type {
	DataRequest,
	NewDataRequestItem,
	UpdateDataRequest,
	NewDataRequest,
	Customer
} from "@/helpers/interface/trueload";
import {useRouter} from "vue-router";
import {useAPI} from "@/helpers/services/api";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { toast } from "@/helpers/toast";
import MarkdownEditor from "@/components/Shared/MarkdownEditor.vue";
import { hidePFGetHelpButton } from '@/helpers/common';

const router = useRouter()
const dataRequestId = computed(() => router.currentRoute.value.params.data_request_id as string)
const newRequest = computed(() => dataRequestId.value === 'new')
const state = reactive({
	dataRequest: null as DataRequest | null,
	customers: [] as Customer[],
	dataRequestLoading: false,
	customersLoading: false,
	validationErrors: {} as Record<string, boolean>,
	formValues: {
		status: '',
		customer_id: '',
		recipient_emails: '',
		title: '',
		description: '',
		request_type: 'TaxRoll',
		osm_emails: 'onboarding@trueroll.io',
		items: [] as Array<NewDataRequestItem>,
	} as UpdateDataRequest,
})
const completedUploads = computed(() => state.dataRequest?.uploads?.filter(u => u.status === 'completed').sort((a, b) => a.item_id > b.item_id ? 1 : -1) || [])
const customerSelectedLabel = computed({
	get() {
		return state.formValues.customer_id ? `(${state.formValues.customer_id}) ${state.customers.find(c => c.customer_id === state.formValues.customer_id)?.name}` : ''
	},
	set(value) {
		const match = value.match(/^\(([^)]+)\)/)
		if (match) {
			state.formValues.customer_id = match[1]
		}
	}
})

watch(() => ({...state.formValues}),
	(newValues) => {
		Object.keys(validationRules).forEach(field => {
			if (newValues[field as keyof typeof newValues] && state.validationErrors[field as keyof typeof state.validationErrors]) {
				state.validationErrors[field as keyof typeof state.validationErrors] = false
			}
		})
	},
	{ deep: true }
)

watch(() => state.customers, () => {
	if (state.formValues.customer_id && state.customers.find(c => c.customer_id === state.formValues.customer_id)) {
		state.formValues.customer_id = state.formValues.customer_id.valueOf()
	}
})

const getIconForFilename = (filename: string) => {
	if (filename.match(/\.(csv|tsv|tab)$/i)) {
		return 'fa-file-csv'
	} else if (filename.match(/\.(txt|log)$/i)) {
		return 'fa-file-lines'
	} else if (filename.match(/\.(pdf)$/i)) {
		return 'fa-file-pdf'
	} else if (filename.match(/\.(doc|docx)$/i)) {
		return 'fa-file-word'
	} else if (filename.match(/\.(xls|xlsx)$/i)) {
		return 'fa-file-excel'
	} else if (filename.match(/\.(zip|tar|gz|gzip|7z|rar)$/i)) {
		return 'fa-file-zipper'
	}
	return 'fa-file'
}
const api = useAPI()
const getDataRequestUrl = (dataRequest: DataRequest): string => {
	return `${window.location.protocol}//${window.location.host}/data-drop/${dataRequest.id}`
}

const loadDataRequest = async (id: string) => {
	if (!id || id === 'new') {
		return
	}
	state.dataRequestLoading = true
	const response = await api.get(`data-requests/${id}`)
	const dataRequest: DataRequest = state.dataRequest = response.data
	state.dataRequestLoading = false
	state.formValues = {
		status: dataRequest.status,
		title: dataRequest.title || '',
		customer_id: dataRequest.customer_id || '',
		description: dataRequest.description || '',
		recipient_emails: dataRequest.recipient_emails || '',
		request_type: dataRequest.request_type || '',
		osm_emails: dataRequest.osm_emails || '',
		items: dataRequest.items.map(item => ({
			id: item.id,
			title: item.title,
			description: item.description,
			format: item.format,
			fields: item.fields.map(field => ({
				name: field.name,
				description: field.description,
				type: field.type,
				required: field.required,
			}))
		}))
	}
}

const getCustomers = async () => {
	state.customersLoading = true;
	const response = await api.get(`customers/?include_prospects=true`);
	state.customers = response.data;
	state.customersLoading = false;
}

const createDataRequest = async (dataRequest: NewDataRequest): Promise<DataRequest> => {
	state.dataRequestLoading = true
	const resp = await api.post(`data-requests/`, dataRequest)
	state.dataRequestLoading = false
	return resp.data
}

const updateDataRequest = async (id: string, upd: UpdateDataRequest): Promise<DataRequest> => {
	state.dataRequestLoading = true
	const resp = await api.patch(`data-requests/${id}`, upd)
	await loadDataRequest(dataRequestId.value)
	state.dataRequestLoading = false
	return resp.data
}

const discardChanges = () => {
	if (state.dataRequest) {
		loadDataRequest(state.dataRequest.id!)
		toast.info('Changes discarded')
	} else {
		router.push({
			name: 'onboarding-toolbox',
		})
	}
}

const validationRules: Record<keyof Pick<UpdateDataRequest, 'customer_id' | 'title'>, {
  required: boolean
  message: string
  validator?: (value: any) => boolean
}> = {
	customer_id: {
		required: true,
		message: 'Customer is required!'
	},
	title: {
		required: true,
		message: 'Title is required'
	},
}

const validateForm = () => {
	Object.keys(validationRules).forEach(field => {
		state.validationErrors[field] = false
	})

	let hasErrors = false

	Object.entries(validationRules).forEach(([field, rules]) => {
		const value = state.formValues[field as keyof UpdateDataRequest]

		if (rules.required && !value) {
			state.validationErrors[field] = true
			hasErrors = true
		} else if (rules.validator && !rules.validator(value)) {
			state.validationErrors[field] = true
			hasErrors = true
		}
	})

	if (hasErrors) {
		toast.error('Please fix the validation errors')
	}

	return !hasErrors
}

const submitForm = async () => {
	if (!validateForm()) {
		return
	}

	if (newRequest.value) {
		const created = await createDataRequest(state.formValues as NewDataRequest)
		await router.push({
			name: 'dataRequest',
			params: {
				data_request_id: created.id!,
			}
		})
		await loadDataRequest(created.id!)
		toast.success('Created new data request')
	} else if (state.dataRequest) {
		await updateDataRequest(
        state.dataRequest.id!,
        state.formValues
		)
		toast.success('Updated data request')
	}
}

const cloneSelected = () => {
	router.push({
		name: 'dataRequest',
		params: {
			data_request_id: 'new',
		}
	})
	state.dataRequest = null
	toast.info('Data request cloned (changes not saved)')
}

const cancelSelected = async () => {
	if (state.dataRequest?.status === 'pending') {
		await updateDataRequest(state.dataRequest.id!, {status: 'cancelled'})
		await loadDataRequest(state.dataRequest.id!)
	}
	toast.success('Cancelled data request')
}

const deleteSelected = async () => {
	if (state.dataRequest) {
		await updateDataRequest(state.dataRequest.id!, {status: 'deleted'})
		await router.push({
			name: 'data-requests',
		})
		toast.warning(
			`Deleted data request "${state.dataRequest.title}" (undo?)`,
			{
				onClick: async () => {
					toast.warning("Update status and save changes to restore")
					await router.push({
						name: 'dataRequest',
						params: {
							data_request_id: state.dataRequest!.id!,
						}
					})
				},
				pauseOnHover: true,
			}
		)
	}
}

onMounted(async () => {
	await loadDataRequest(dataRequestId.value)
	await getCustomers()
	hidePFGetHelpButton()
});

</script>

<style scoped>

</style>