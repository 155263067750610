<template>
    <div id="config-new-label-container">
        <form @submit.prevent="submitLabel()">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3">
                            <label for="config-add-label" class="form-label">
                                Label Name <span class="required">*</span>
                            </label>
                            <input type="text" class="form-control cbFormTextField cbFormTextField-xl" maxlength="255"
                                required id="config-add-label" v-model="label.label_name" />
                        </div>

                        <p>Applies To</p>

                        <div class="row">
                            <div class="col-6 col-md-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="label.candidates"
                                        id="config-add-label-candidate">
                                    <label class="form-check-label" for="config-add-label-candidate">
                                        Candidates
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-md-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="label.applications"
                                        id="config-add-label-applications">
                                    <label class="form-check-label" for="config-add-label-applications">
                                        Applications
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12 mt-2 d-flex">
                    <button type="submit" class="searchButton" :disabled="formLoading">
                        <span class="fa fa-plus-circle" aria-hidden="true"></span>
                        Add
                    </button>
                    <loading-icon v-if="formLoading" />
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import type { AxiosError } from "axios"
import { getApiErrorMessage } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"

const api = useAPI()
const initialValue = {
	label_name: "",
	candidates: true,
	applications: true,
}
const label = ref({ ...initialValue })
const formLoading = ref(false)
const emits = defineEmits(["addNewLabel"])

const submitLabel = async () => {
	formLoading.value = true

	try {
		await api.post(`/labels/`, label.value)
		toast.success("Your submission was successful")
		label.value = { ...initialValue }
		emits("addNewLabel")
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}

	formLoading.value = false;
}

onMounted(() => {
	(document.querySelector("#config-add-label") as HTMLInputElement)?.focus()
})
</script>
