<template>
    <div>
        <TheHeader />

        <div v-if="isLoading" id="upload-list-loading-container" class="p-4">
            <LoadingIcon />
        </div>

        <div v-else id="upload-list-container" class="p-4">
            <div class="container">
                <div class="row">
                    <div class="column">
                        <div v-if="!!upload" class="mx-auto" style="max-width: 500px;">
                            <Card>
                                <template #title>
                                    <i class="fa fa-file-zipper"></i>
                                    {{ upload.file_name }}
                                </template>
                                <template #content>
                                    <p class="mb-3">
                                        {{ getFileSizeText(upload.file_size) }}
                                    </p>
                                    <p class="mb-3">
                                        Created on {{ (new Date(upload.created_at as string)).toLocaleDateString() }}
                                    </p>
                                    <Button icon="fa fa-download" @click="initiateDownload(upload?.download_url!)"
                                        label="Download" />
                                </template>
                            </Card>
                        </div>

                        <div v-else>
                            Download not found.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TheFooter />
    </div>
    <AppMonitor />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import type { AxiosError } from "axios"
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import Card from "primevue/card";
import Button from "primevue/button";
import { useRoute } from "vue-router";
import { useAPI } from "@/helpers/services/api";
import { toast } from "@/helpers/toast";
import type { Upload } from "@/helpers/interface/uploads";
import { getApiErrorMessage } from "@/helpers/common";
import { getFileSizeText } from "@/helpers/files";

const route = useRoute()
const api = useAPI()

const upload_id = route?.query?.id as string

const isLoading = ref(true)
const upload = ref(null as Upload | null)

const fetchUpload = async () => {
	isLoading.value = true
	upload.value = null

	try {
		const { data } = await api.get(`/uploads/${upload_id}/internal`)
		upload.value = data
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	isLoading.value = false
}

const initiateDownload = (url: string) => {
	window.location.href = url
}

onMounted(async () => {
	fetchUpload()
})
</script>
