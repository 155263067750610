import { computed } from 'vue'
import { useCandidateDetailsStore } from '@/stores/candidateDetails'
import { useTaxroll } from '@/stores/taxroll'
import type { PageView } from '@/helpers/interface/PageView'

/**
 * Provides a reactive reference to the last viewed pages of either the candidate or application type.
 *
 * @param {string} storeType - The type of store to retrieve the last viewed pages from. Defaults to 'candidate'.
 * @returns {object} An object containing a reactive reference to the last viewed pages.
 * @property {PageView[]} lastView - The last viewed pages.
 */
export function useLastView(storeType: 'candidate' | 'application' = 'candidate') {
	const storeCandidateDetails = useCandidateDetailsStore()
	const storeTaxroll = useTaxroll()

	const lastView = computed(() => {
		if (storeType === 'application') {
			return (storeTaxroll.lastView || [])
		}
		return storeCandidateDetails.lastView as PageView[]
	})

	return {
		lastView
	}
}