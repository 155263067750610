<template>
    <div>
        <label class="config-header">Unclaimed Exemption Data Request</label>
        <div class="row">
            <div class="col-12">
                <div v-if="showAlert" class="alert alert-success alert-dismissible fade show" role="alert">
                    Thank you, your request has been successfully submitted.
                    Please email <a href="mailto:csm@trueroll.io">csm@trueroll.io</a> with any questions or issues.
                    <button type="button" class="btn btn-close btn-sm" data-dismiss="alert" aria-label="Close"
                        @click="dismissAlert"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="d-flex">
                    <button class="searchButton cbFormTextField-sm mt-2" type="submit" id="request-data"
                        @click="requestUnclaimedExmptData" :disabled="requestLoading">
                        Request Data
                    </button>
                    <loading-icon v-if="requestLoading" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios"
import { useAPI } from "@/helpers/services/api";
import { getApiErrorMessage } from "@/helpers/common"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";

const api = useAPI()
const requestLoading = ref<boolean>(false)
const showAlert = ref<boolean>(false)

const requestUnclaimedExmptData = async () => {
	requestLoading.value = true

	try {
		await api.post("/triggers/unclaimed-exemption-data-request")
		toast.success("Request has been sent")
		showAlert.value = true
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}

	requestLoading.value = false;
}

const dismissAlert = () => {
	showAlert.value = false;
}
</script>
