import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createAuth0 } from '@auth0/auth0-vue';
import axios from "axios"
import "bootstrap"
import * as Sentry from "@sentry/vue";
import CKEditor from '@ckeditor/ckeditor5-vue';

/* CSS Imports */
import "bootstrap/dist/css/bootstrap.min.css"
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFileCsv, faFilePdf, faFileWord, faFileExcel, faTable, faFileLines, faFileZipper, faDownload, faFile } from '@fortawesome/free-solid-svg-icons'

/* MultiSelect */
import "@vueform/multiselect/themes/default.css"

/* trueroll.css should be last, allowing it to override dependencies with customizations */
import './assets/trueroll.css'

// PrimeVue
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import './assets/primevue-custom.css'
import Tooltip from 'primevue/tooltip';


import App from './App.vue'
import router from './router'
import ToastPlugin from 'vue-toast-notification';

axios.defaults.baseURL = import.meta.env.VITE_DEV_API_URL as string
/* add icons to the library */
library.add(faFileCsv, faFilePdf, faFileWord, faFileExcel, faTable, faFileLines, faFileZipper, faDownload, faFile)


const app = createApp(App)
app.use(PrimeVue, {
	theme: {
		preset: Aura,
		options: {
			cssLayer: { name: "primevue" },
			darkModeSelector: ".tr-darkmode"  // disables dark mode until this class is applied to document root element
		}
	}
});
app.directive('tooltip', Tooltip);



if (import.meta.env.VITE_SENTRY_DSN) {
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_SENTRY_MODE,
		tracePropagationTargets: [
			/^https:\/\/dev-api\.trueroll\.app\/api/,
			/^https:\/\/api\.trueroll\.app\/api/,
		],
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			// new Sentry.BrowserTracing({
			//   tracePropagationTargets: [
			//     /^https:\/\/dev-api\.trueroll\.app\/api/,
			//     /^https:\/\/api\.trueroll\.app\/api/,
			//   ],
			//   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			// }),
			Sentry.replayIntegration(),
			Sentry.extraErrorDataIntegration(),
			Sentry.captureConsoleIntegration({
				levels: ["error"],
			}),
			Sentry.httpClientIntegration({
				failedRequestStatusCodes: [[400, 499], [500, 599]]
			}),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}


app.use(createPinia())
app.use(router)
app.use(ToastPlugin)
app.use(CKEditor)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component("ckeditor", CKEditor.component)
app.use(
	createAuth0({
		domain: import.meta.env.VITE_AUTH0_DOMAIN,
		clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
		authorizationParams: {
			redirect_uri: window?.location?.origin + "/callback",
			audience: import.meta.env.VITE_AUTH0_AUDIENCE
		}
	})
);

app.mount('#app')