<template>
	<fieldset>
		<template v-for="option in options" :key="option.id">
			<input 
				type="radio" 
				class="custom-radio" 
				:name="groupId" 
				:id="option.id" 
				:value="option.value"
				v-model="selectedValue"
				:required="required"
				@invalid.capture.prevent="elementRequiredHandler"
			/>
			<label :for="option.id">{{ option.label }}</label>
			<br v-if="br">
		</template>
	</fieldset>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"

const props = defineProps<{
	groupId: string;
	required: boolean;
	options: { id: string; name: string; label: string; value: string | boolean | number; }[];
	modelValue: string | boolean | number | null | undefined;
	elementRequiredHandler: <T extends Event>(e: T) => void;
	br?: boolean;
}>()

const emit = defineEmits(['update:modelValue'])

// Local state to sync v-model with input
const selectedValue = ref(props.modelValue)

// Watch for changes and emit updates
watch(selectedValue, (newValue) => {
	emit('update:modelValue', newValue)
})
</script>