<template>
  <div class="" v-if="state.job_type === 'scorecard'">
    <div class="modal-dialog" v-if="!state.loading">
      <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <h5><b>Generate Scorecard:</b></h5>
            </div>
        </div>
        <hr>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="customerInput" class="form-label">Customer</label>
            <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
              list="jobs_customers" v-model="customerSelectedLabel">
            <datalist id="jobs_customers">
              <option v-for="customer in state.customers" :key="customer.customer_id"
                :label="`${customer.name} (${customer.customer_id})`" :value="customer.customer_id"/>
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label :for="`new_metadata`" class="form-label">New Metadata</label>
            <select
              :id="`new_metadata`"
              class="form-control"
              v-model="state.new_metadata"
            >
              <option value='true'>True</option>
              <option value='false'>False</option>
            </select>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label :for="`refresh`" class="form-label">Refresh</label>
            <select
              :id="`refresh`"
              class="form-control"
              v-model="state.refresh"
            >
              <option value='true'>True</option>
              <option value='false'>False</option>
            </select>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label :for="`alert`" class="form-label">Alert</label>
            <select
              :id="`alert`"
              class="form-control"
              v-model="state.alert"
            >
              <option value='true'>True</option>
              <option value='false'>False</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <label for="track_job">Track Job</label>
          <Checkbox v-model="state.track_job" :input-id="'track_job'" binary style="margin-right: 8px"/>
          <Button @click="run_job(state.track_job)" style="border: 1px solid #ccc" class="me-2"
          :disabled="!state.customer_id">Run Job</Button>
          <Button @click="closePopup" style="border: 1px solid #ccc" class="me-2" severity="secondary">Close</Button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
  <div class="" v-else-if="state.job_type === 'generate_extracts'">
    <div class="modal-dialog" v-if="!state.loading">
      <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <h5><b>Generate Extracts:</b></h5>
            </div>
        </div>
        <hr>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="customerInput" class="form-label">Customer</label>
            <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
              list="jobs_customers" v-model="customerSelectedLabel">
            <datalist id="jobs_customers">
              <option v-for="customer in state.customers" :key="customer.customer_id"
                :label="`${customer.name} (${customer.customer_id})`" :value="customer.customer_id"/>
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label :for="`extracts`" class="form-label">Extracts</label>
            <select
              :id="`extracts`"
              class="form-control"
              v-model="state.extracts"
            >
              <option :value="['all']">All</option>
              <option :value="['clear']">Clear</option>
              <option :value="['pcoa']">PCOA</option>
              <option :value="['scra']">SCRA</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <label for="track_job">Track Job</label>
          <Checkbox v-model="state.track_job" :input-id="'track_job'" binary style="margin-right: 8px"/>
          <Button @click="run_job(state.track_job)" style="border: 1px solid #ccc" class="me-2"
          :disabled="!state.customer_id">Run Job</Button>
          <Button @click="closePopup" style="border: 1px solid #ccc" class="me-2" severity="secondary">Close</Button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
  <div class="" v-else-if="state.job_type === 'generate_observations'">
    <div class="modal-dialog" v-if="!state.loading">
      <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <h5><b>Generate Observations:</b></h5>
            </div>
        </div>
        <hr>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="customerInput" class="form-label">Customer</label>
            <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
              list="jobs_customers" v-model="customerSelectedLabel">
            <datalist id="jobs_customers">
              <option v-for="customer in state.customers" :key="customer.customer_id"
                :label="`${customer.name} (${customer.customer_id})`" :value="customer.customer_id"/>
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label :for="`observations`" class="form-label">Observations</label>
            <select
              :id="`observations`"
              class="form-control"
              v-model="state.flag_list"
            >
              <option value="all">All</option>
              <option value="promon">Proactive Monitoring</option>
              <option value="notes">Notes</option>
              <option value="flags">Flags</option>
              <option value="genie">Applications Only</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <label for="track_job">Track Job</label>
          <Checkbox v-model="state.track_job" :input-id="'track_job'" binary style="margin-right: 8px"/>
          <Button @click="run_job(state.track_job)" style="border: 1px solid #ccc" class="me-2"
          :disabled="!state.customer_id || state.running">Run Job</Button>
          <Button @click="closePopup" style="border: 1px solid #ccc" class="me-2" severity="secondary">Close</Button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
  <div class="" v-else-if="state.job_type === 'cleanup'">
    <div class="modal-dialog" v-if="!state.loading">
      <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <h5><b>Finalize Refresh:</b></h5>
            </div>
        </div>
        <hr>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="customerInput" class="form-label">Customer</label>
            <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
              list="jobs_customers" v-model="customerSelectedLabel">
            <datalist id="jobs_customers">
              <option v-for="customer in state.customers" :key="customer.customer_id"
                :label="`${customer.name} (${customer.customer_id})`" :value="customer.customer_id"/>
            </datalist>
          </div>
        </div>
        <div class="modal-footer">
          <label for="track_job">Track Job</label>
          <Checkbox v-model="state.track_job" :input-id="'track_job'" binary style="margin-right: 8px"/>
          <Button @click="run_job(state.track_job)" style="border: 1px solid #ccc" class="me-2"
          :disabled="!state.customer_id">Run Job</Button>
          <Button @click="closePopup" style="border: 1px solid #ccc" class="me-2" severity="secondary">Close</Button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
</template>

<script setup lang="ts">

import {computed, onMounted, reactive} from 'vue';
import type {Customer} from "@/helpers/interface/trueload";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import { useAPI } from "@/helpers/services/api";
import { useRouter } from "vue-router"
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";

const api = useAPI()
const router = useRouter()

const emits = defineEmits(['closePopup'])
const closePopup = () => emits("closePopup", false)

const props = defineProps({
	customer_id: {
		type: String,
		default: '',
	},
	job_type: {
		type: String,
		required: true,
	}
})

const state = reactive({
	customer_id: '',
	job_type: '',
	new_metadata: false,
	refresh: false,
	alert: false,
	flag_list: [],
	extracts: [],
	track_job: true,
	customers: [] as Array<Customer>,
	loading: false,
	running: false,
})

const schema = computed(() => state.customers.find(c => c.customer_id == state.customer_id)?.schema_name || '');

const celery_task_name = computed((): string => {
	if (state.job_type === 'scorecard') {
		return 'tasks.csse.handle_generate_scorecard'
	}
	else if (state.job_type === 'generate_extracts') {
		return 'tasks.csse.handle_generate_extracts'
	}
	else if (state.job_type === 'generate_observations') {
		return 'tasks.csse.handle_generate_observations'
	}
	else if (state.job_type === 'cleanup') {
		return 'tasks.csse.handle_cleanup'
	}
	else {
		return ''
	}
})

const meta = computed((): any => {
	if (state.job_type === 'scorecard') {
		return {
			source: 'webapp',
			schema_name: schema.value,
			new_metadata: state.new_metadata,
			refresh: state.refresh,
			alert: state.alert,
		}
	}
	else if (state.job_type === 'generate_extracts') {
		return {
			schema_name: schema.value,
			extract_list: state.extracts,
			source: 'webapp',
			queue: 'beefy',
			customer_id: state.customer_id
		}
	}
	else if (state.job_type === 'generate_observations') {
		return {
			source: 'webapp',
			queue: 'beefy',
			schema_name: schema.value,
			flag_list: state.flag_list
		}
	}
	else if (state.job_type === 'cleanup') {
		return {
			source: 'webapp',
			schema_name: schema.value,
		}
	}
	else {
		return {}
	}
})

const kwargs = computed((): any => {
	if (state.job_type === 'scorecard') {
		return {
			schema_name: schema.value,
			customer_id: state.customer_id,
			new_metadata: state.new_metadata,
			refresh: state.refresh,
			alert: state.alert,
		}
	}
	else if (state.job_type === 'generate_extracts') {
		return {
			schema_name: schema.value,
			extract_list: state.extracts,
			customer_id: state.customer_id
		}
	}
	else if (state.job_type === 'generate_observations') {
		return {
			schema_name: schema.value,
			flag_list: state.flag_list
		}
	}
	else if (state.job_type === 'cleanup') {
		return {
			customer_id: state.customer_id,
		}
	}
	else {
		return {}
	}
})

const payload = computed(() => ({
	customer_id: state.customer_id,
	celery_task_name: celery_task_name.value,
	pass_job_id: true,
	job_type: state.job_type,
	queue: 'beefy',
	kwargs: kwargs.value,
	meta: meta.value,
}))


const getCustomers = async () => {
	if (state.job_type === 'finalize-refresh') {
		const response = await api.get(`customers/?valid_schemas=true&has_clone=true`);
		state.customers = response.data;
	}
	else {
		const response = await api.get(`customers/?valid_schemas=true`);
		state.customers = response.data;
	}
}

const customerSelectedLabel = computed({
	get() {
		return state.customer_id ? `${state.customers.find(c => c.customer_id == state.customer_id)?.name} (${state.customer_id})` : ''
	},
	set(value) {
		if (state.customers.find(c => c.customer_id == value)) {
			state.customer_id = value
		}
	}
})

const run_job = async (track: boolean) => {
	state.running = true
	const response = await api.post(`jobs/run_job`, payload.value);
	if (track) {
		window.open(router.resolve({
			name: "background-job",
			params: {job_id: response.data!}
		}).href, '_blank')
	}
	closePopup()
};

onMounted(async () => {
	state.loading = true
	state.customer_id = props.customer_id
	state.job_type = props.job_type
	await getCustomers()
	state.loading = false
})

</script>
