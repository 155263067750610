<template>
  <div class="">
    <div class="modal-dialog" v-if="currentCustomer && !(buttonSelected)">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
              <h5><b>Select Application Type:</b></h5>
          </div>
        </div>
        <div class="modal-body">
          <div class="d-flex">
            <button type="button"
              class="searchButton cbFormTextField-sm mt-3"
              @click="selfServeDataRequest('Paper Applications')">
              <span class="fa-solid fa-file-pdf" aria-hidden="true"></span>
              Paper/PDF Applications
            </button>
            <button :disabled="!currentCustomer.allow_digital_applications"
              class="searchButton cbFormTextField-sm mt-3"
              @click="selfServeDataRequest('Digital Applications')">
              <span class="fa-solid fa-table-list" aria-hidden="true"></span>
              Digital Applications
            </button>
          </div>
        </div>
        <br>
        <div class="modal-footer">
          <button @click="closePopup" style="border: 1px solid #ccc" class="btn btn-default">Close</button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
</template>

<script setup lang="ts">

import {computed, ref} from 'vue';
import router from "@/router"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import {useAPI} from "@/helpers/services/api";
import type { NewDataRequest } from "@/helpers/interface/trueload";
import {useProfile} from "@/stores/profile";
import { getApiErrorMessage } from "@/helpers/common"
import type {AxiosError} from "axios";
import {toast} from "@/helpers/toast";

const api = useAPI()

const emits = defineEmits(['closePopup'])
const closePopup = () => emits("closePopup", false)

const storeProfile = useProfile();
const currentCustomer = computed(() => storeProfile.getCustomerAccess)

const buttonSelected = ref<boolean>(false)


const selfServeDataRequest = async (request_type: string) => {
	const dataRequest: NewDataRequest = {
		customer_id: currentCustomer.value?.customer_id || "Invalid Customer ID",
		title: `${currentCustomer.value?.name} Self-Service Application Upload`,
		recipient_emails: "",
		request_type: request_type,
		description: "Please submit all applications below",
		items: [{ "id": "applications", "title": "Applications" }],
		osm_emails: "onboarding@trueroll.io"
	}
	buttonSelected.value = true
	try {
		const resp = await api.post(`data-requests/`, dataRequest)
		await router.push({ name: 'dataDrop', params: { data_drop_id: resp.data.id } })
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
}

</script>
