<template>
    <div class="row pt-2">
        <div class="col-4">
            <label for="candidate-mail-status">
                Status <span class="required">*</span>
            </label>
        </div>
        <div class="col-6">
            <select id="candidate-mail-status" class="cbFormTextField" v-model="model.mail_status" required>
                <option v-for="status in statusOptions" v-bind:key="status.value" :value="status.value">
                    {{ status.text }}
                </option>
            </select>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label>
                Template
            </label>
        </div>
        <div class="col-6">
            <span>{{ model.mail_template }}</span>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label for="candidate-mail-to">
                Mail To
            </label>
        </div>
        <div class="col-6" v-if="!readOnly">
            <input id="candidate-mail-to" type="text" class="cbFormTextField" maxlength="255" v-model="model.mail_to">
        </div>
        <div class="col-6" v-else>
            <span>{{ model.mail_to }}</span>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label for="candidate-mail-address">
                Mail Address Line 1
            </label>
        </div>
        <div class="col-6" v-if="!readOnly">
            <input id="candidate-mail-address" type="text" class="cbFormTextField" maxlength="255"
                v-model="model.mail_address">
        </div>
        <div class="col-6" v-else>
            <span>{{ model.mail_address }}</span>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label for="candidate-mail-address2">
                Mail Address (City, State, Zip)
            </label>
        </div>
        <div class="col-6" v-if="!readOnly">
            <input id="candidate-mail-address2" type="text" class="cbFormTextField" maxlength="255"
                v-model="model.mail_address2">
        </div>
        <div class="col-6" v-else>
            <span>{{ model.mail_address2 }}</span>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label for="candidate-mail-date">
                Mail Date
            </label>
        </div>
        <div class="col-6" v-if="!readOnly">
            <input id="candidate-mail-date" type="date" class="cbFormTextField" v-model="model.mailed_at">
        </div>
        <div class="col-6" v-else>
            <span>{{ getDisplayDate(model.mailed_at) }}</span>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label for="candidate-mail-note">
                Note for template
            </label>
        </div>
        <div class="col-6">
            <textarea id="candidate-mail-note" class="cbFormTextField" v-model="model.note_for_template"
                :maxlength="MAX_NOTE_LENGTH"></textarea>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label>
                Created On
            </label>
        </div>
        <div class="col-6">
            <span>{{ getDisplayDate(model.created_at) }}</span>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-4">
            <label>
                Updated On
            </label>
        </div>
        <div class="col-6">
            <span>{{ getDisplayDate(model.updated_at) }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { PropType } from "vue"
import { mailStatusOptions as statusOptions } from "@/helpers/dropdownOptions";
import type { CandidateFormMail } from "@/helpers/interface/candidates"
import { MAX_NOTE_LENGTH, getDisplayDate } from "@/helpers/common";

const props = defineProps({
	item: { type: Object as PropType<CandidateFormMail>, required: true },
})

const emits = defineEmits([
	'update:modelValue'
])

const model = computed({  // Use computed to wrap the object
	get: () => props.item,
	set: (value) => emits('update:modelValue', value),
});

const readOnly = computed(() => (model.value.mail_status !== 'created') ? true : false)
</script>
