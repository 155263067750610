<template>
    <div id="flagsNotes">
        <div :class="{ 'd-none': fetchFlagsNotesLoading, 'd-block': !fetchFlagsNotesLoading, }">
            <DataTable :value="flagsNotes?.flags" tableStyle="min-width: 50rem" class="align-top" id="flags">
                <template #empty>
                    <div class="p-2 text-center">
                        No records found.
                    </div>
                </template>
                <Column field="name" header="Category"></Column>
                <Column field="display_html" header="Details">
                    <template v-slot:body="slotProps">
                        <!-- nosemgrep -->
                        <div v-html="slotProps.data?.display_html"></div>
                    </template>
                </Column>
                <Column field="event_date" header="Date">
                    <template v-slot:body="slotProps">
                        {{ formatDate(slotProps.data.event_date) }}
                    </template>
                </Column>
            </DataTable>

            <div id="additional-notes-header-title" class="p-2">
                <span class="p-tabview-title"> Additional Details
                </span>
            </div>
            <DataTable :value="flagsNotes?.notes" tableStyle="min-width: 50rem" class="align-top" id="notes">
                <template #empty>
                    <div class="p-2 text-center">
                        No records found.
                    </div>
                </template>
                <Column field="name" header="Type"></Column>
                <Column field="display_html" header="Additional Details">
                    <template v-slot:body="slotProps">
                        <!-- nosemgrep -->
                        <div v-html="slotProps.data?.display_html"></div>
                    </template>
                </Column>
                <Column field="event_date" header="Date">
                    <template v-slot:body="slotProps">
                        {{ formatDate(slotProps.data.event_date) }}
                    </template>
                </Column>
            </DataTable>
        </div>

        <div :class="{ 'd-none': !fetchFlagsNotesLoading, 'd-block': fetchFlagsNotesLoading, }">
            <table class="table" id="flags">
                <thead>
                    <tr>
                        <th width="20%">Category</th>
                        <th width="60%">Details</th>
                        <th width="20%">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in 4" :key="i">
                        <td>
                            <Skeleton height="1.2rem" width="120px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="200px" class="mb-2"></Skeleton>
                            <div class="d-flex mb-2">
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                            </div>
                            <div class="d-flex mb-2">
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                            </div>
                            <Skeleton height="1.2rem" width="250px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="120px"></Skeleton>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div id="additional-notes-header-title" class="p-2">
                <span class="p-tabview-title"> Additional Details
                </span>
            </div>

            <table class="table">
                <thead>
                    <tr>
                        <th width="20%">Type</th>
                        <th width="60%">Additional Details</th>
                        <th width="20%">Date</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in 3" :key="i">
                        <td>
                            <Skeleton height="1.2rem" width="120px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="200px" class="mb-2"></Skeleton>
                            <div class="d-flex mb-2">
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                            </div>
                            <div class="d-flex mb-2">
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                                <Skeleton height="1.2rem" width="120px" class="me-2"></Skeleton>
                            </div>
                            <Skeleton height="1.2rem" width="250px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="120px"></Skeleton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <OmnisearchPopUp @closePopup="toggleNotification" class="notification" :ulID="selectedUlID"
        v-if="notificationVisibility.showNotification" />

</template>

<script setup lang="ts">
import type { Observations } from "@/helpers/interface/appvet"
import type { AxiosError } from "axios"
import { onMounted, ref } from "vue"
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import { getApiErrorMessage, formatDate } from "@/helpers/common"
import { toast } from "@/helpers/toast"
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import OmnisearchPopUp from "@/components/Shared/OmnisearchPopUp.vue";

const props = defineProps({
	tru_id: {
		type: String,
		required: true
	}
})

const api = useAPI()
const selectedUlID = ref<string>("")
const fetchFlagsNotesLoading = ref(true)
const flagsNotes = ref<Observations | null>(null)
const notificationVisibility = ref({ showNotification: false })
const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden'
const loadFailureHandler = (error: any) => {
	const message = getApiErrorMessage(error)
	toast.error(message)
}
const toggleNotification = () => {
	notificationVisibility.value.showNotification = notificationVisibility.value.showNotification ? false : true
	toggleBodyScrolling(!notificationVisibility.value.showNotification)
}
const fetchFlagsNotes = async () => {
	const tru_id = props.tru_id
	fetchFlagsNotesLoading.value = true

	try {
		const { data } = await api.get(`applications/${tru_id}/observations`)
		flagsNotes.value = data

		setTimeout(() => {
			const unitLevelElement = document.querySelectorAll<HTMLTableCellElement>('#flags table td[unit_level_id]')
			unitLevelElement.forEach((td: HTMLTableCellElement) => {
				const ulID = td.getAttribute("unit_level_id") as string
				if (!ulID || ulID === "None") return
				const text = td.innerText
				const popupAnchor = document.createElement("a") as HTMLAnchorElement
				popupAnchor.innerText = text
				popupAnchor.href = "#"
				popupAnchor.addEventListener("click", (event) => {
					event.preventDefault()
					selectedUlID.value = ulID as string
					toggleNotification()
				})
				td.innerHTML = ""
				td.appendChild(popupAnchor)
			})
		}, 1000)

	} catch (error: unknown) {
		const err = error as AxiosError
		loadFailureHandler(err)
	}

	fetchFlagsNotesLoading.value = false
}

onMounted(() => fetchFlagsNotes())
</script>
