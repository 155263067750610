<template>
	<DatePicker v-model="dateValue as any" date-format="m/d/yy" class="tr-date-combo" :show-icon="true"
		:show-on-focus="false" iconDisplay="input" :minDate="minDate" :maxDate="maxDate"
		@invalid.capture.prevent="elementRequiredHandler" :pt="{ pcInput: { root: inputPassThroughOptions } }" />
</template>
<script setup lang="ts">
import { computed, type PropType } from "vue"
import DatePicker from "primevue/datepicker"

const props = defineProps({
	required: {
		type: Boolean,
		required: false,
		default: false,
	},
	id: {
		type: String,
		required: false,
	},
	minDate: {
		type: Date,
		required: false,
	},
	maxDate: {
		type: Date,
		required: false,
	},
	elementRequiredHandler: {
		type: Function as PropType<(e: Event) => void>,
		required: false,
		default: () => { }
	},
})

const dateValue = defineModel("value")

const inputPassThroughOptions = computed(() => {
	/* By default, PrimeVue disables keyboard for the DatePicker component
		by using attribute inputmode="none", which forces mobile users 
		to user the picker.  This is a poor user experience on mobile 
		devices when you need to choose a date far in the past, such 
		as a birthdate. Therefore, we will override the default setting
		and use inputmode="text" to make things easier for mobile users. 
	*/
	return {
		required: props.required || false,
		id: (props.id || null),
		inputmode: 'text'   /* see comment above */
	}
})
</script>