<template>
    <div class="row align-items-center mb-3">
        <div class="col-6">
            <h3 class="config-header">Config: Outreach Codes</h3>
        </div>
        <div class="col-6 text-end">
            <Button type="button" class="float-end" @click="showAddCodeModal()">
                <span class="fa fa-circle-plus" aria-hidden="true"></span> Add New
            </Button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <article class="card overflow-auto">
                <div class="card-body">
                    <DataTable :value="outreachCodesList" tableStyle="min-width: 50rem" class="align-top"
                        v-if="!fetchOutreachCodesListLoading">
                        <template #empty>
                            <div class="p-2 text-center">
                                No records found.
                            </div>
                        </template>
                        <Column field="code" header="Name" style="width: 25%"></Column>
                        <Column field="description" header="Description" style="width: 35%"></Column>
                        <Column field="created_at" header="Created On" style="width: 15%">
                            <template #body="slotProps">
                                <span v-tooltip.bottom="handledBy('Created by: ', slotProps.data.created_by)"
                                    class="cursor-default">
                                    {{ formatDate(slotProps.data.created_at) }}
                                </span>
                            </template>
                        </Column>
                        <Column field="updated_at" header="Updated On" style="width: 15%">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.updated_by"
                                    v-tooltip.bottom="handledBy('Updated by: ', slotProps.data.updated_by)"
                                    class="cursor-default">
                                    {{ formatDate(slotProps.data.updated_at) }}
                                </span>
                            </template>
                        </Column>
                        <Column field="updated_at" header="" style="width: 10%">
                            <template #body="slotProps">
                                <Button size="small" class="me-2" @click="showCodeInfo(slotProps.data.id)">
                                    <span class="fa fa-edit"></span>
                                </Button>
                                <Button severity="danger" size="small" @click="showDeleteCodeModal(slotProps.data.id)">
                                    <span class="fa fa-trash"></span>
                                </Button>
                            </template>
                        </Column>
                    </DataTable>
                    <table class="table" v-else>
                        <thead>
                            <tr>
                                <th style="width: 25%">
                                    <Skeleton height="1.2rem" width="100px"></Skeleton>
                                </th>
                                <th style="width: 35%">
                                    <Skeleton height="1.2rem" width="120px"></Skeleton>
                                </th>
                                <th style="width: 15%">
                                    <Skeleton height="1.2rem" width="100px"></Skeleton>
                                </th>
                                <th style="width: 15%">
                                    <Skeleton height="1.2rem" width="100px"></Skeleton>
                                </th>
                                <th style="width: 10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="i in 4" :key="i">
                                <td>
                                    <Skeleton height="1.2rem" width="100%"></Skeleton>
                                </td>
                                <td>
                                    <Skeleton height="1.2rem" width="100%"></Skeleton>
                                </td>
                                <td>
                                    <Skeleton height="1.2rem" width="150px"></Skeleton>
                                </td>
                                <td>
                                    <Skeleton height="1.2rem" width="150px"></Skeleton>
                                </td>
                                <td class="d-flex">
                                    <Skeleton height="1.2rem" width="50px" class="me-2"></Skeleton>
                                    <Skeleton height="1.2rem" width="50px"></Skeleton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </article>
        </div>
    </div>

    <div id="admin-modals">
        <ModalDialog v-if="newCode.showModal.value" title="Add New Outreach Code" :close="() => closeAddCodeModal()">
            <div class="element-container">
                <label for="name" class="form-label form-label-required">Name</label>
                <InputText id="name" type="text" v-model="newCode.name.value" class="w-100" required />
            </div>

            <div class="element-container">
                <label for="description" class="form-label form-label-required">Description</label>
                <Textarea v-model="newCode.description.value" id="description" :maxlength="MAX_NOTE_LENGTH" />
            </div>
            <template #footer>
                <Button @click="addNewCode" :disabled="newCode.formLoading.value">
                    <span class="fa fa-plus-circle" aria-hidden="true" v-if="!newCode.formLoading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="newCode.formLoading.value"></span>
                    Add
                </Button>
                <Button @click="closeAddCodeModal" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="deleteCode.showConfirmationModal.value" title="Delete Outreach Code"
            :close="() => closeDeleteCodeModal()">
            <Message severity="warn" :closable="false" class="my-2">
                Are you sure you want to delete this outreach code?
                <br>
                <br>
                This action cannot be undone.
            </Message>

            <template #footer>
                <Button @click="deleteOutreachCode" severity="danger" :disabled="deleteCode.loading.value">
                    <span class="fa fa-times-circle" aria-hidden="true" v-if="!deleteCode.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="deleteCode.loading.value"></span>
                    Delete
                </Button>
                <Button @click="closeDeleteCodeModal" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="editCode.showModal.value" title="Edit Outreach Code" :close="() => closeEditCodeModal()">
            <div class="element-container">
                <label for="name" class="form-label form-label-required">Name</label>
                <InputText id="name" type="text" v-model="editCode.name.value" class="w-100" required />
            </div>

            <div class="element-container">
                <label for="description" class="form-label form-label-required">Description</label>
                <Textarea v-model="editCode.description.value" id="description" :maxlength="MAX_NOTE_LENGTH" />
            </div>
            <template #footer>
                <Button @click="editOutreachCode" :disabled="editCode.formLoading.value">
                    <span class="fa fa-edit" aria-hidden="true" v-if="!editCode.formLoading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="editCode.formLoading.value"></span>
                    Update
                </Button>
                <Button @click="closeEditCodeModal" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>
    </div>
</template>

<script setup lang="ts">
import type { OutreachCodes } from "@/helpers/interface/admin-page"
import type { AxiosError } from "axios"
import { onMounted, ref, computed } from "vue"
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import { formatDate, getApiErrorMessage, MAX_NOTE_LENGTH } from "@/helpers/common"
import { toast } from "@/helpers/toast"
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from "primevue/button"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import InputText from "primevue/inputtext"
import Textarea from "primevue/textarea"
import Message from "primevue/message"
import { useAdminDetails } from "@/stores/adminDetails"

const api = useAPI()
const storeAdminDetails = useAdminDetails()

const handledBy = (actionType: string, name: string | null) => {
	if (!name) return
	return actionType + name
}
const fetchOutreachCodesListLoading = ref(true)
const outreachCodesList = computed((): OutreachCodes[]  => storeAdminDetails.getOutreachList)
const loadFailureHandler = (error: any) => {
	const message = getApiErrorMessage(error)
	toast.error(message)
}
const fetchOutreachCodeList = async () => {
	fetchOutreachCodesListLoading.value = true
	await storeAdminDetails.fetchOutreachList(loadFailureHandler)
	fetchOutreachCodesListLoading.value = false
}
const showAddCodeModal = () => newCode.showModal.value = true
const closeAddCodeModal = () => newCode.showModal.value = false
const newCode = {
	showModal: ref(false),
	name: ref(""),
	description: ref(""),
	formLoading: ref(false)
}
const addNewCode = async () => {
	if (!newCode.name.value) {
		toast.error("Please enter a name")
		return
	}

	newCode.formLoading.value = true
	const payload = {
		code: newCode.name.value,
		description: newCode.description.value
	}

	try {
		await api.post(`outreach`, payload)
		toast.success("Your submission was successful")
		fetchOutreachCodeList()
		newCode.showModal.value = false
		newCode.name.value = ""
		newCode.description.value = ""
	} catch (error: unknown) {
		const err = error as AxiosError
		loadFailureHandler(err)
	}
	finally {
		newCode.formLoading.value = false
	}
}

const showDeleteCodeModal = (id: number) => {
	deleteCode.id.value = id
	deleteCode.showConfirmationModal.value = true
}
const closeDeleteCodeModal = () => deleteCode.showConfirmationModal.value = false
const deleteCode = {
	id: ref<number | null>(null),
	showConfirmationModal: ref(false),
	loading: ref(false)
}
const deleteOutreachCode = async () => {
	deleteCode.loading.value = true
	try {
		await api.delete(`outreach/${deleteCode.id.value}`)
		toast.success("Your deletion was successful")
		fetchOutreachCodeList()
		deleteCode.id.value = null
		deleteCode.showConfirmationModal.value = false
	} catch (error: unknown) {
		const err = error as AxiosError
		loadFailureHandler(err)
	}
	finally {
		deleteCode.loading.value = false
	}
}

const showCodeInfo = (id: number) => {
	editCode.showModal.value = true
	const codeInfo = outreachCodesList.value?.filter(o => o.id === id)[0]
	if (!codeInfo) {
		toast.error("This item cannot be edited")
		return
	}

	editCode.id.value = codeInfo?.id
	editCode.name.value = codeInfo?.code
	editCode.description.value = codeInfo?.description
}
const closeEditCodeModal = () => editCode.showModal.value = false
const editCode = {
	id: ref<number | null>(null),
	showModal: ref(false),
	name: ref(""),
	description: ref(""),
	formLoading: ref(false)
}
const editOutreachCode = async () => {
	if (!editCode.name.value) {
		toast.error("Please enter a name")
		return
	}

	editCode.formLoading.value = true
	const payload = {
		code: editCode.name.value,
		description: editCode.description.value
	}
	try {
		await api.patch(`outreach/${editCode.id.value}`, payload)
		toast.success(`Your update has been saved`)
		fetchOutreachCodeList()
		editCode.showModal.value = false
		editCode.name.value = ""
		editCode.description.value = ""
	} catch (error: unknown) {
		const err = error as AxiosError
		loadFailureHandler(err)
	}
	finally {
		editCode.formLoading.value = false
	}
}
onMounted(() => fetchOutreachCodeList())
</script>
