<template>
    <div id="summary-details">
        <table class="table">
            <tbody v-if="!fetchSummaryDetailLoading">
            <tr>
                <td class="label">Last Visit</td>
                <td class="value">
                    <div v-if="lastView && lastView.length > 1 && lastView[1] && dayjs(lastView[1].timestamp).isValid() && lastView[1].timestamp">
                        {{ lastView[1].nickname }} - {{ formatDate(lastView[1].timestamp) }}
                        <span :class="{ 'recent-visit': getTimeSince(lastView[1].timestamp).isRecent }">
                ({{ getTimeSince(lastView[1].timestamp).timeSince }} ago)
            </span>
                    </div>
                    <div v-else-if="lastView && lastView.length > 1 && lastView[1]">
                        {{ lastView[1].timestamp }}
                    </div>
                    <div v-else>
                        N/A
                    </div>
                </td>
            </tr>
                <tr>
                    <td class="label">Application ID</td>
                    <td class="value">{{ summaryDetail?.application_id }}</td>
                </tr>
                <tr>
                    <td class="label">Parcel Number</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.situs_parcel_id }}
                        <a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'" class="copy-to-clipboard"
                            @click="copyTextToClipboard(summaryDetail?.situs_parcel_id)"
                            v-if="summaryDetail?.situs_parcel_id">
                            <span class="fa-regular fa-copy fa-sm"></span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="label">Situs Address</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.situs_full }}
                        <a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'" class="copy-to-clipboard"
                            @click="copyTextToClipboard(summaryDetail?.situs_full)" v-if="summaryDetail?.situs_full">
                            <span class="fa-regular fa-copy fa-sm"></span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="label">Applicant</td>
                    <!-- nosemgrep - value is sanitized -->
                    <td class="value text-uppercase" v-html="applicantNamesDisplayHTML"></td>
                </tr>
                <tr>
                    <td class="label">Mail Address</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.mail_full }}
                        <span class="clipboard-copy-target" :id="'mail_full_' + summaryDetail?.tru_id || ''"
                            :data-copy-text="summaryDetail?.mail_full"></span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Previous Address</td>
                    <td class="value">{{ summaryDetail?.prev_situs_full }}</td>
                </tr>
                <tr>
                    <td class="label">Exemptions</td>
                    <td class="value">{{ summaryDetail?.exemptions?.join(", ") }}</td>
                </tr>
                <tr>
                    <td class="label">Submission Date</td>
                    <td class="value">{{ formatDate(summaryDetail?.application_timestamp) }}</td>
                </tr>
                <tr>
                    <td class="label">Effective Date</td>
                    <td class="value">{{ formatDate(summaryDetail?.effective_date) }}</td>
                </tr>
                <tr>
                    <td class="label">Status</td>
                    <td class="value text-capitalize">
                        <Chip :label="summaryDetail?.application_status"
                            :class="`tr-chip status-chip-${summaryDetail?.application_status}`" />

                        <template v-if="summaryDetail?.application_status === 'snooze' && summaryDetail?.snooze_until">
                            until {{ formatDate(summaryDetail?.snooze_until) }}
                        </template>
                    </td>
                </tr>
                <tr>
                    <td class="label">Assigned To</td>
                    <td class="value">
                        <Chip v-if="summaryDetail?.assigned_user" :label="summaryDetail?.assigned_user"
                            class="tr-chip assigned-to-chip cursor-pointer" @click="displayUserInfo($event, summaryDetail.assigned_user_id as string)"/>
                        <span v-else>
                            <i>Unassigned</i>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Labels</td>
                    <td class="value">
                        <Chip v-for="label in summaryDetail?.labels" :key="label" :label="label"
                            class="me-2 mb-1 tr-chip label-chip" />
                    </td>
                </tr>
                <tr v-if="summaryDetail?.outreach_code">
                    <td class="label">Outreach</td>
                    <td class="value">
                        <Chip :key="summaryDetail?.outreach_code" :label="summaryDetail?.outreach_code"
                            aria-hidden="true" class="me-2 mb-1 tr-chip outreach-chip" removable>
                            <template #removeicon>
                                <span v-tooltip.bottom="'Remove'" class="fa fa-times-circle cursor-pointer" @click="() => removeCode.showConfirmationModal.value = true"></span>
                            </template>
                        </Chip>
                    </td>
                </tr>
                <tr v-if="promonMatched.length">
                    <td class="label">Status in Proactive Monitoring</td>
                    <td class="value">
                        <a v-if="promonMatched.length > 1" href="javascript:;"
                            @click="showListOfPromonMatchedModal = true" v-tooltip="'See all'">
                            Matched
                            <Badge :value="promonMatched.length" severity="success" class="p-badge-circle p-badge-xs" />
                        </a>
                        <a v-else :href="`/candidate-details/${promonMatched[0].tru_id}`" target="_blank"
                            v-tooltip="'Go to details page'">
                            {{ promonStatus(promonMatched[0]) }}
                            <span class="fa fa-link"></span>
                        </a>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in 8" :key="i">
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td>
                        <Skeleton height="1.2rem" width="250px"></Skeleton>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td class="d-flex">
                        <Skeleton height="1.2rem" width="50px" class="me-2"></Skeleton>
                        <Skeleton height="1.2rem" width="50px" class="me-2"></Skeleton>
                        <Skeleton height="1.2rem" width="100px" class="me-2"></Skeleton>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ModalDialog v-if="showListOfPromonMatchedModal" title="Matched Record(s)"
        :close="() => showListOfPromonMatchedModal = false">
        <DataTable :value="promonMatched">
            <Column style="width: 5%" field="owners" header="">
                <template #body="slotProps">
                    <a :href="`/candidate-details/${slotProps.data.tru_id}`" target="_blank">
                        <span class="fa fa-external-link-alt"></span>
                    </a>
                </template>
            </Column>
            <Column style="width: 45%" field="owners" header="Owner Name">
                <template #body="slotProps">
                    <div v-if="slotProps.data.owners?.length">
                        <div class="mb-2" v-for="(owner, index) in slotProps.data.owners" :key="index">
                            {{ owner }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </template>
            </Column>
            <Column style="width: 25%" field="queue" header="Queue">
                <template #body="slotProps">
                    <div v-if="slotProps.data.queue">
                        {{ ucwords(slotProps.data.queue) }}
                    </div>
                    <div v-else>-</div>
                </template>
            </Column>
            <Column style="width: 25%" field="effective_date" header="Effective Date">
                <template #body="slotProps">
                    <div v-if="slotProps.data.effective_date">
                        {{ formatDate(slotProps.data.effective_date) }}
                    </div>
                    <div v-else>-</div>
                </template>
            </Column>
        </DataTable>
    </ModalDialog>

    <div id="application-vetter-modals">
        <ModalDialog v-if="removeCode.showConfirmationModal.value" title="Remove Outreach"
            :close="() => closeRemoveOutreachCodeModal()">
            <div class="element-container">
                <Textarea v-model="removeCode.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            </div>

            <template #footer>
                <Button @click="removeOutreachCode" severity="danger" :disabled="removeCode.loading.value">
                    <span class="fa fa-times-circle" aria-hidden="true" v-if="!removeCode.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="removeCode.loading.value"></span>
                    Remove
                </Button>
                <Button @click="closeRemoveOutreachCodeModal" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>
    </div>

	<Popover ref="op">
        <UserInfo :userId="selectedUserInfo" />
    </Popover>
</template>

<script setup lang="ts">
import type { AxiosError } from "axios"
import {computed, nextTick, onMounted, ref} from "vue"
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import Chip from 'primevue/chip'
import {
	getApiErrorMessage,
	formatDate,
	sanitizeHTML,
	copyTextToClipboard,
	ucwords,
	QueueMap,
	MAX_NOTE_LENGTH
} from "@/helpers/common"
import { toast } from "@/helpers/toast"
import Badge from "primevue/badge"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import type { CandidateList } from "@/helpers/interface/candidates"
import Button from "primevue/button"
import Textarea from "primevue/textarea"
import { useTaxroll } from "@/stores/taxroll"
import Popover from "primevue/popover"
import UserInfo from "@/components/Shared/UserInfo.vue"
import { useLastView } from "@/hooks/LastView"
import {getTimeSince} from "@/helpers/time-formatting";
import dayjs from "dayjs";

const props = defineProps({
	tru_id: {
		type: String,
		required: true
	}
})
const api = useAPI()
const storeTaxroll = useTaxroll()
const fetchSummaryDetailLoading = computed(() => (storeTaxroll.fetchAppSummaryDetailLoading))
const summaryDetail = computed(() => (storeTaxroll.getAppSummaryDetail))
const { lastView } = useLastView('application')

const loadFailureHandler = (error: any) => {
	const message = getApiErrorMessage(error)
	toast.error(message)
}

const op = ref()
const selectedUserInfo = ref("")
const displayUserInfo = (event: any, userId: string) => {
	op.value.hide()
	selectedUserInfo.value = userId
	nextTick(() => {
		op.value.show(event)
	})
}

const fetchSummaryDetail = async () => {
	await storeTaxroll.fetchAppSummaryDetail(props.tru_id, loadFailureHandler)
	await storeTaxroll.fetchLastView(props.tru_id, loadFailureHandler)
	if (!summaryDetail.value) return
	searchForPromonLink(summaryDetail.value.situs_parcel_id)
}

const applicantNamesDisplayHTML = computed(() => {
	const names = summaryDetail.value?.applicants_names
	if (!names || !names.length) {
		return ""
	}
	return sanitizeHTML(names.join("<br>"))
})

const promonMatched = ref<any[]>([])
const promonMatchedCount = ref("")
const showListOfPromonMatchedModal = ref(false)
const searchForPromonLink = async (parcelNum: string) => {
	try {
		const payload = {
			"filter": [
				{ "field": "parcel_num", "type": "=", "value": parcelNum },
				{ "field": "active", "type": "=", "value": true },
			],
			"page": 1,
			"page_size": 25,
			"sort": [],
			"include_total": false
		}
		const response = await api.post("/taxroll/list", payload)
		promonMatchedCount.value = response.data.data?.length || 0
		promonMatched.value = response.data.data || []
	}
	catch (error: any) {
		console.log(getApiErrorMessage(error))
	}
}

const promonStatus = (promon: CandidateList) => {
	switch (promon.queue) {
		case QueueMap.Inbox.id:
		case QueueMap.InvestigationReports.id:
		case QueueMap.InvestigateNow.id:
			return "Flagged, Awaiting Review"
		case QueueMap.Monitor.id:
			return "Reviewed"
		case QueueMap.Questionnaire.id:
			return "Questionnaire Sent"
		case QueueMap.Snooze.id:
			return "Snoozed"
		case QueueMap.Unqualified.id:
			return "Unqualified"
		case QueueMap.Archive.id:
			return ucwords(promon.final_outcome)
		default:
			return "None"
	}
}

const closeRemoveOutreachCodeModal = () => removeCode.showConfirmationModal.value = false
const removeCode = {
	note: ref(""),
	showConfirmationModal: ref(false),
	loading: ref(false)
}
const removeOutreachCode = async () => {
	removeCode.loading.value = true
	const payload = {
		tru_ids: [
			props.tru_id
		],
		note: removeCode.note.value,
	}
	try {
		await api.post("applications/outreach/delete", payload)
		toast.success("Your submission was successful")
		fetchSummaryDetail()
		storeTaxroll.fetchAppHistoryList(props.tru_id, loadFailureHandler)
		removeCode.showConfirmationModal.value = false
	} catch (error: unknown) {
		const err = error as AxiosError
		loadFailureHandler(err)
	}
	finally {
		removeCode.loading.value = false
	}
}

onMounted(() => fetchSummaryDetail())
</script>