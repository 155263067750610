<template>
	<OfflineOverlay />
	<main class="public-container">
		<noscript>This page requires JavaScript to be enabled in your browser.</noscript>
		<div class="content-wrapper">
			<div class="content" v-if="!formSubmitted">
				<form class="public-form" @submit.prevent="submitFormHandler">
					<ApplicationSection title="APPLICATION FOR HOMESTEAD AND RELATED TAX EXEMPTIONS">
						<div class="mb-5 text-center">
							Permanent Florida residency required on January 1.
							<br>
							Application due to property appraiser by March 1.
						</div>
						<div class="element-container">
							<label for="parcel-id-number" class="form-label form-label-required">
								Parcel Identification Number 
							</label>
							<InputText id="parcel-id-number" v-model="formFields.parcel_id_number"
								:disabled="isADAccountNumDisabled" @invalid.capture.prevent="elementRequiredHandler"
								required />
						</div>
						<div class="element-container">
							<label class="form-label form-label-required">
								I am applying for
							</label>
							<CustomRadioGroup groupId="applying-for" v-model="formFields.applying_for" required
								:elementRequiredHandler="elementRequiredHandler" :options="[
									{ id: 'applying-for-new', name: 'applying-for-new', label: 'A new homestead exemption', value: false, },
									{ id: 'applying-for-change', name: 'applying-for-change', label: 'A change of homestead exemption', value: true, }
								]" :br="true" />
						</div>
						<div class="element-container">
							<label for="tax-year" class="form-label form-label-required">
								Tax Year
							</label>
							<DatePicker id="tax-year" v-model="formFields.tax_year" view="year" dateFormat="yy" showIcon
								iconDisplay="input" :pt="requiredInputAttribute"
								@invalid.capture.prevent="elementRequiredHandler" />
						</div>
					</ApplicationSection>

					<PrimaryApplicant :elementRequiredHandler="elementRequiredHandler"
						:acceptFileTypes="ACCEPT_FILE_TYPES" :uploadFiles="uploadFiles"
						:maritalStatusOptions="maritalStatusOptions" />

					<CoApplicant :elementRequiredHandler="elementRequiredHandler" :acceptFileTypes="ACCEPT_FILE_TYPES"
						:uploadFiles="uploadFiles" />

					<HomesteadAddress :elementRequiredHandler="elementRequiredHandler" />

					<MailingAddress :elementRequiredHandler="elementRequiredHandler" />

					<PreviousAddress :elementRequiredHandler="elementRequiredHandler" />

					<ProofOfResidency :elementRequiredHandler="elementRequiredHandler"
						:acceptFileTypes="ACCEPT_FILE_TYPES" :uploadFiles="uploadFiles" />

					<CoApplicantSpousePOR v-if="hasCoApplicant" :elementRequiredHandler="elementRequiredHandler"
						:acceptFileTypes="ACCEPT_FILE_TYPES" :uploadFiles="uploadFiles" />

					<FollowingBenefits :elementRequiredHandler="elementRequiredHandler"
						:acceptFileTypes="ACCEPT_FILE_TYPES" :uploadFiles="uploadFiles"
						:floridaCounties="floridaCounties" />

					<Signature />

					<ApplicationSection title="PENALTIES">
						<p>
							The property appraiser has a duty to put a tax lien on your property if you received a
							homestead
							exemption during the past 10 years that you were not entitled to receive. The property
							appraiser will
							notify you that taxes with penalties and interest are due. You will have 30 days to pay
							before a lien
							is recorded. If this was not an error by the property appraiser, you will be subject to a
							penalty of 50
							percent of the unpaid taxes and 15 percent interest each year (see ss. s. 196.011(10) and
							196.161(1)(b), F.S.).</p>
						<p>
							If you improperly receive a homestead exemption as a result of the property appraiser’s
							clerical
							mistake or omission, you will not be assessed penalties or interest.
						</p>
						<p>
							For tax years beginning in 2025, if you improperly receive an exemption as a result of the
							property
							appraiser’s clerical mistake or omission, and you disclose the error to the property
							appraiser before
							you receive a notice of intent to record a lien, you will not be charged back taxes,
							penalties or
							interest. For special requirements for estates probated or administered outside Florida, see
							s.
							196.161(1), F.S.
						</p>
						<p>
							The information in this application will be given to the Department of Revenue. Under s.
							196.121,
							F.S., the Department and property appraisers can give this information to any state where
							the
							applicant has resided. Social security numbers will remain confidential under s.193.114(5),
							F.S.</p>
					</ApplicationSection>

					<ApplicationSection title="EXEMPTION AND DISCOUNT REQUIREMENTS">
						<p>
							<b>Homestead</b> Every person who owns real property in Florida on January 1, makes the
							property his or her permanent residence or the permanent residence of a legal or natural
							dependent,
							and files an application may receive a property tax exemption up to $50,000. The first
							$25,000
							applies to all property taxes. The added $25,000 applies to assessed value over $50,000 and
							only to
							non-school taxes.
						</p>
						<p>
							Your local property appraiser will determine whether you are eligible. The appraiser may
							consider information such as the items requested on the bottom of page 1.
						</p>
						<p>
							<b>Save our Homes (SOH)</b> Beginning the year after you receive homestead exemption, the
							assessment on your home cannot increase by more than the lesser of the change in the
							Consumer Price Index or 3 percent each year, no matter how much the just value increases. If
							you have
							moved from one Florida homestead to another within the last three years, you may be eligible
							to take some of your SOH savings with you. See your property appraiser for more information.
						</p>
					</ApplicationSection>

					<SubmitButtonContainer v-if="isValidUUID" />

					<Message severity="error" :closable="false" class="m-2 mb-5" v-if="isValidUUID === false">
						<i class="fas fa-exclamation-triangle"></i>
						{{ NOT_VALID_UUID_ERR_MESSAGE }}
					</Message>
				</form>
			</div>
			<div class="content text-center" v-else>
				<ApplicationSection title="APPLICATION FOR HOMESTEAD AND RELATED TAX EXEMPTIONS">
					<p>
						Your submission was successful.
						<br>
						<br>
						Application ID: <b>{{ confirmationNumber }}</b>
					</p>
					<p>
						<a href="#" @click="refreshForm()">Go back</a>
					</p>
				</ApplicationSection>
			</div>
		</div>
	</main>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useRoute } from "vue-router"
import dayjs from "dayjs"
import type { NamForm } from "@/helpers/interface/applicationForm"
import { formatDate, generateUUIDv4, getApiErrorMessage, hidePFGetHelpButton, requiredInputAttribute, setPageTitle } from "@/helpers/common"

import InputText from "primevue/inputtext"
import DatePicker from "primevue/datepicker"
import Message from "primevue/message"

import OfflineOverlay from "@/components/OfflineOverlay.vue"
import ApplicationSection from "@/components/ApplicationForm/ApplicationSection.vue"
import { useApplicationForm } from "@/stores/applicationForm"
import PrimaryApplicant from "@/components/ApplicationForm/fl/ApplicationFormPrimaryApplicant.vue"
import CoApplicant from "@/components/ApplicationForm/fl/ApplicationFormCoApplicant.vue"
import HomesteadAddress from "@/components/ApplicationForm/fl/ApplicationFormHomesteadAddress.vue"
import MailingAddress from "@/components/ApplicationForm/fl/ApplicationFormMailingAddress.vue"
import PreviousAddress from "@/components/ApplicationForm/fl/ApplicationFormPreviousAddress.vue"
import ProofOfResidency from "@/components/ApplicationForm/fl/ApplicationFormProofOfResidency.vue"
import CoApplicantSpousePOR from "@/components/ApplicationForm/fl/ApplicationFormCoApplicantSpousePOR.vue"
import FollowingBenefits from "@/components/ApplicationForm/fl/ApplicationFormFollowingBenefits.vue"
import Signature from "@/components/ApplicationForm/fl/ApplicationFormSignature.vue"
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import { getFileExtension, getFileSizeText, isValidFileType } from "@/helpers/files"
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"
import type { StateIdExtraction } from "@/helpers/interface/general"
import type { AxiosError } from "axios"
import { isValidEmail } from "@/helpers/regex"
import SubmitButtonContainer from "@/components/ApplicationForm/SubmitButtonContainer.vue"

const INVALID_EMAIL_MESSAGE = "You must enter a valid email address. Example: name@example.com"
const publicAPI = useAPI({ authGuard: false })
const route = useRoute()
sessionStorage.setItem("state", "fl")
const storeApplicationForm = useApplicationForm()
const ACCEPT_FILE_TYPES = ".png, .jpg, .jpeg, .bmp, .gif, .pdf"

const formSubmitted = ref(false)
const formFields = computed(() => (storeApplicationForm.flFields))
const uploadState = computed(() => (storeApplicationForm.uploadState))
const hasCoApplicant = computed((): boolean => (formFields.value.applicant_add_spouse === true || formFields.value.applicant_add_coapplicant === true))

/* this key must be included in all HTTP requests to the server as a header
	for file uploads and form submission itself
*/
const instanceKey = ref("")
instanceKey.value = generateUUIDv4()
const confirmationNumber = ref(0)
const formID = route.params.form_id as string
const form = ref({
	id: formID,
	customer_id: '',
	name: '',
	state: '',
	fips_code: '',
	api_key: '',
} as NamForm)

const maritalStatusOptions = ref([
	{ label: 'Single', value: 'single' },
	{ label: 'Married', value: 'married' },
	{ label: 'Divorced', value: 'divorced' },
	{ label: 'Widowed', value: 'widowed' }
])
const floridaCounties: string[] = [
	"Alachua", "Baker", "Bay", "Bradford", "Brevard", "Broward", "Calhoun",
	"Charlotte", "Citrus", "Clay", "Collier", "Columbia", "DeSoto", "Dixie",
	"Duval", "Escambia", "Flagler", "Franklin", "Gadsden", "Gilchrist",
	"Glades", "Gulf", "Hamilton", "Hardee", "Hendry", "Hernando", "Highlands",
	"Hillsborough", "Holmes", "Indian River", "Jackson", "Jefferson", "Lafayette",
	"Lake", "Lee", "Leon", "Levy", "Liberty", "Madison", "Manatee", "Marion",
	"Martin", "Miami-Dade", "Monroe", "Nassau", "Okaloosa", "Okeechobee",
	"Orange", "Osceola", "Palm Beach", "Pasco", "Pinellas", "Polk", "Putnam",
	"St. Johns", "St. Lucie", "Santa Rosa", "Sarasota", "Seminole", "Sumter",
	"Suwannee", "Taylor", "Union", "Volusia", "Wakulla", "Walton", "Washington"
]

const isFormLoaded = computed(() => {
	return form.value.customer_id !== ''
})


const elementRequiredHandler = (e: Event) => {
	const input = e.target as HTMLInputElement;
	let container: any = input.closest("fieldset");
	const min = input.getAttribute("min") || null
	const max = input.getAttribute("max") || null
	if (!container) {
		container = input.parentNode as HTMLElement;
	}
	if (!container)
		throw "Unable to process required element because it's not attached to the DOM"

	let displayText = "This field is required";
	switch (input.type) {
		case "radio":
			displayText = "Please choose an option"
			break;
		case "file":
			displayText = "You must upload a file"
			break;
		case "checkbox":
			displayText = "Please check this box to proceed"
			break;
		case "tel":
			displayText = input.getAttribute("title")?.toString() || "You must follow this format (555-123-4567)"
			break;
		case "email":
			displayText = input.getAttribute("title")?.toString() || INVALID_EMAIL_MESSAGE
			break;
		case "number":
			displayText = (min && max) ? `Value must be a number between ${min} and ${max}` : "Value must be a number"
			if ((~~input.step) > 1) {
				displayText += ` in increments of ${input.step}`
			}
			break;
	}
	displayInvalidField(container, displayText)
}

const displayInvalidField = (target: HTMLElement, text: string) => {
	const INVALID = "public-field-invalid"

	if (!target.classList?.contains("element-container")) {
		if (target.parentElement) {
			target = target.parentElement
		}
	}

	target.setAttribute("data-invalid-text", text)
	target.classList.add(INVALID)
	// target.scrollIntoView()

	setTimeout(() => {
		target.setAttribute("data-invalid-text", "")
		target.classList.remove(INVALID)
	}, 5000)
}

const convertBase64ToFile = (base64: string, filename: string, contentType: string) => {
	const byteCharacters = atob(base64)
	const byteNumbers = new Array(byteCharacters.length)
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}
	const byteArray = new Uint8Array(byteNumbers)
	return new File([byteArray], filename, { type: contentType })
}

const uploadSignature = async (base64Signature: string) => {
	return await storeApplicationForm.uploadFile(
		instanceKey.value,
		convertBase64ToFile(base64Signature, "signature.png", "image/png"),
		form.value.customer_id!,
		"application_attachments",
		"signature_of_applicant"
	)
}

const createSubmissionPayload = async () => {
	const payload = { ...formFields.value } as any

	Object.keys(payload).forEach(key => {
		// convert all dates (from PrimeVue datepicker) to ISO strings
		if (payload[key] instanceof Date) {
			if (isNaN(+payload[key])) {
				payload[key] = null
			} else {
				payload[key] = payload[key].toISOString().substring(0, 10)
			}
		}
		if (payload[key] === "") {
			payload[key] = null;
		}
	})

	const idSeed = instanceKey.value.slice(0, 4)

	payload['tru_id'] = instanceKey.value
	payload['application_id'] = parseInt(idSeed, 16).toString()
	payload['form_id'] = form.value.id

	const sigUpl = await uploadSignature(payload['signature_of_applicant'])
	const sigUpl2 = await uploadSignature(payload['signature_of_coapplicant'])
	payload['signature_of_applicant'] = sigUpl?.id
	payload['signature_of_coapplicant'] = sigUpl2?.id

	return payload
}

const submitFormHandler = async () => {

	const validateSignature = (fieldValue: string, elementId: string, message: string): boolean => {
		const target = document.getElementById(elementId) as HTMLElement | null
		if (fieldValue.length === 0 && target) {
			displayInvalidField(target, message)
			return false
		}
		return true
	}

	const validateEmail = (email: string, elementId: string): boolean => {
		const input = document.getElementById(elementId) as HTMLElement | null
		if (!isValidEmail(email) && input) {
			let container = input.closest("fieldset") as HTMLElement | null
			if (!container) container = input.parentNode as HTMLElement | null
			if (container) {
				displayInvalidField(container, INVALID_EMAIL_MESSAGE)
				return false
			}
		}
		return true
	}

	// Validation logic
	if (
		!validateSignature(formFields.value.signature_of_applicant, "applicant-signature-container", "Your signature is required") ||
		(hasCoApplicant.value &&
			!validateSignature(formFields.value.signature_of_coapplicant, "coapplicant-signature-container", "Co-Applicant signature is required")) ||
		!validateEmail(formFields.value.applicant_email, "primary-applicant-email") ||
		(hasCoApplicant.value &&
			formFields.value.coapplicant_email &&
			!validateEmail(formFields.value.coapplicant_email, "coapplicant-email"))
	) {
		return
	}

	// Dropdowns
	if ((!formFields.value.applicant_marital_status)) {
		const target = document.getElementById("applicant-marital-status") as HTMLElement | null
		if (target && target.parentElement) {
			displayInvalidField(target.parentElement, "Please select your marital status")
			return false
		}
	}

	if ((!formFields.value.selected_county && formFields.value.prorated_refund === true)) {
		const target = document.getElementById("select-county") as HTMLElement | null
		if (target && target.parentElement) {
			displayInvalidField(target.parentElement, "Please select your county")
			return false
		}
	}

	if ((!formFields.value.veteran_selected_county && formFields.value.veteran_prorated_refund === true)) {
		const target = document.getElementById("veteran-select-county") as HTMLElement | null
		if (target && target.parentElement) {
			displayInvalidField(target.parentElement, "Please select the county of your veteran exemption")
			return false
		}
	}

	if ((!formFields.value.homestead_county && formFields.value.homestead_transfer === true)) {
		const target = document.getElementById("homestead-county") as HTMLElement | null
		if (target && target.parentElement) {
			displayInvalidField(target.parentElement, "Please select the county related to your homestead transfer")
			return false
		}
	}

	// Pattern
	if ((formFields.value.applicant_imigration_no)) {
		const pattern = /^[A-Z0-9]{8,15}$/
		if (!pattern.test(formFields.value.applicant_imigration_no)) {
			const target = document.getElementById("applicant-immigration-no") as HTMLElement | null
			if (target && target.parentElement) {
				displayInvalidField(target.parentElement, "Only uppercase letters and numbers are allowed.");
				return false
			}
		}
	}

	if (uploadState.value > 0) {
		toast.info("Hold tight, we're processing your upload.")
		return
	}

	storeApplicationForm.getOrSetFormLoading(true)
	const payload = await createSubmissionPayload()
	try {
		const resp = await publicAPI.post(
			`/applications/${form.value.fips_code}`,
			payload,
			{
				"headers": {
					"Content-Type": "application/json",
					"X-Api-Key": form.value.api_key,
				}
			}
		)
		if (resp.data && resp.data.application_id) {
			confirmationNumber.value = resp.data.application_id as number
		}
		formSubmitted.value = true
		AppState.clear()
	} catch (error) {
		toast.error(getApiErrorMessage(error as AxiosError))
	}
	storeApplicationForm.getOrSetFormLoading(false)
}

const NOT_VALID_UUID_ERR_MESSAGE = "Error: The form cannot be submitted due to an invalid ID. Please report this issue to the county."
const isValidUUID = ref<Boolean | null>(null)
const loadNamForm = async (formID: string) => {
	isValidUUID.value = true
	try {
		const response = await publicAPI.get(`/applications/form/${formID}`)
		form.value = response.data
	} catch (error) {
		isValidUUID.value = false
		toast.error(NOT_VALID_UUID_ERR_MESSAGE, {
			duration: 0,
			dismissible: false,
			position: "top-right"
		})
	}
}


const uploadFiles = async (e: Event) => {
	if (!isFormLoaded.value) {
		await loadNamForm(formID)
	}
	if (!isFormLoaded.value) {
		throw new Error("Unauthorized form")
	}
	fileInputChangeHandler(e)
	const input = e.target as HTMLInputElement
	if (!input.files) return;
	const file = input.files && input.files[0]
	const id = input.getAttribute("id")?.toString() || ""

	const completeUpload = await storeApplicationForm.uploadFile(
		instanceKey.value,
		file,
		form.value.customer_id!,
		"application_attachments",
		id,
		input,
		form.value.api_key
	)
	if (completeUpload?.meta.extraction) {
		populateFromTextExtraction(completeUpload.meta.extraction, id)
	}
}

const populateFromTextExtraction = (data: StateIdExtraction, id: string) => {
	if (id == "property-owner-id-file-1") {
		// Primary Application
		if (!formFields.value.applicant_first_name) {
			formFields.value.applicant_first_name = data.first_name || ""
		}

		if (!formFields.value.applicant_middle_name) {
			formFields.value.applicant_middle_name = data.middle_name || ""
		}

		if (!formFields.value.applicant_last_name) {
			formFields.value.applicant_last_name = data.last_name || ""
		}

		if (!formFields.value.applicant_dob) {
			formFields.value.applicant_dob = formatDate(data.dob) || ""
		}

		if (!formFields.value.applicant_suffix) {
			formFields.value.applicant_suffix = data.suffix || ""
		}

		if (!formFields.value.state) {
			formFields.value.state = data.state || ""
		}

		if (!formFields.value.street) {
			formFields.value.street = data.address || ""
		}

		if (!formFields.value.city) {
			formFields.value.city = data.city || ""
		}

		if (!formFields.value.postal_code) {
			formFields.value.postal_code = data.postal_code || ""
		}

		if (!formFields.value.id_number) {
			formFields.value.id_number = data.document_number || ""
		}

		if (!formFields.value.id_issue_date) {
			formFields.value.id_issue_date = formatDate(data.date_issued) || ""
		}
	}

	if (id == "property-owner-id-file-2") {
		// Primary Application
		if (!formFields.value.coapplicant_first_name) {
			formFields.value.coapplicant_first_name = data.first_name || ""
		}

		if (!formFields.value.coapplicant_middle_name) {
			formFields.value.coapplicant_middle_name = data.middle_name || ""
		}

		if (!formFields.value.coapplicant_last_name) {
			formFields.value.coapplicant_last_name = data.last_name || ""
		}

		if (!formFields.value.coapplicant_dob) {
			formFields.value.coapplicant_dob = formatDate(data.dob) || ""
		}

		if (!formFields.value.coapplicant_suffix) {
			formFields.value.coapplicant_suffix = data.suffix || ""
		}

		if (!formFields.value.coapplicant_id_number) {
			formFields.value.coapplicant_id_number = data.document_number || ""
		}

		if (!formFields.value.coapplicant_id_issue_date) {
			formFields.value.coapplicant_id_issue_date = formatDate(data.date_issued) || ""
		}
	}
}

const fileInputChangeHandler = (e: Event, isDriversLicense: boolean = false) => {
	const maxMegaBytes = isDriversLicense ? 10 : 20
	const MAX_FILE_SIZE_BYTES = maxMegaBytes * 1024 * 1024  // MB converted by bytes
	const input = e.target as HTMLInputElement
	if (!input.files)
		return;
	const selectedFile = input.files[0]

	if (selectedFile) {
		if (!isValidFileType(selectedFile.name, ACCEPT_FILE_TYPES.split(","))) {
			const extension = getFileExtension(selectedFile.name)
			alert(`Files of type '${extension.toUpperCase()}'' may not be uploaded.`)
			input.value = "";
			return;
		}
		if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
			const fileSizeText = getFileSizeText(selectedFile.size)
			const maxSizeText = getFileSizeText(MAX_FILE_SIZE_BYTES)
			alert(`The selected file is ${fileSizeText}, which is larger than the maximum allowed (${maxSizeText}).`)
			input.value = "";
			return;
		}
		if (selectedFile.size === 0) {
			alert("The selected file contains no data and may not be uploaded.");
			input.value = "";
			return;
		}
	}
}

watch(() => formFields.value.tax_year, (year: string | null | undefined) => {
	if (!year) return
	formFields.value.tax_year = dayjs(year).format("YYYY")
})

const refreshForm = () => window.location.reload()

const AppState = {
	KEY: storeApplicationForm.FL_STATE_STORAGE_KEY,

	clear: () => {
		try {
			sessionStorage.removeItem(AppState.KEY)
		} catch (ex) {
			console.warn("Unable to clear form state from storage")
		}
	},

	save: () => {
		try {
			if (isFormLoaded.value) {
				sessionStorage.setItem(AppState.KEY, JSON.stringify(formFields.value));
			}
		} catch (ex) {
			console.warn("Unable to utilize storage to persist form state.")
		}
	},

	events: {
		windowBeforeUnload: () => {
			if (formSubmitted.value) {
				return;
			}
			return "Are you sure you want to leave this page? Your progress may be lost."
		}
	}

}
watch(formFields.value, AppState.save, { deep: true });
const isADAccountNumDisabled = computed(() => !!route.query.account_num)

onMounted(async () => {
	setPageTitle("Application For Homestead And Related Tax Exemptions")
	formFields.value.parcel_id_number = (route.query.account_num as string) || ""
	await loadNamForm(formID)
	window.onbeforeunload = AppState.events.windowBeforeUnload
	hidePFGetHelpButton()
})
</script>



<style>
@import "@/assets/public/public-base.css";
</style>