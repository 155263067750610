<template>
    <div id="documentList">
        <div :class="{ 'd-none': fetchDocumentListLoading, 'd-block': !fetchDocumentListLoading, }">
            <DataTable :value="documentList" tableStyle="min-width: 50rem" class="align-top">
                <template #paginatorstart>
                </template>
                <template #paginatorend>
                </template>
                <template #empty>
                    <div class="p-2 text-center">
                        No records found.
                    </div>
                </template>
                <Column field="document_type" header="Category">
                    <template v-slot:body="slotProps">
                        {{ getDocumentCategoryName(slotProps.data.document_type) }}
                    </template>
                </Column>
                <Column field="file_name" header="File name">
                    <template v-slot:body="slotProps">
                        <span v-if="slotProps.data.content_type.includes('image/')">
                            <a href="javascript:;" class="file-name-link" @click="imageClick(slotProps.data.file_name)"
                                v-if="galleriaItems.length > 0">
                                {{ slotProps.data.file_name }}
                            </a>
                            <span v-else>
                                {{ slotProps.data.file_name }}
                            </span>
                        </span>
                        <a v-else :href="slotProps.data.download_url" target="_blank" class="file-name-link">
                            {{ slotProps.data.file_name }}
                        </a>
                    </template>
                </Column>
                <Column field="created_at" header="Date">
                    <template v-slot:body="slotProps">
                        {{ formatDate(slotProps.data.created_at) }}
                    </template>
                </Column>
            </DataTable>
        </div>

        <div :class="{ 'd-none': !fetchDocumentListLoading, 'd-block': fetchDocumentListLoading, }">
            <table class="table">
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>File name</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in 4" :key="i">
                        <td>
                            <Skeleton height="1.2rem" width="150px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="100px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="100px"></Skeleton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <Galleria v-model:visible="isGalleriaVisible" v-model:activeIndex="activeIndex" :value="galleriaItems"
            :numVisible="7" :thumbnailsPosition="'bottom'" containerStyle="max-width: 60%" :circular="true"
            :fullScreen="true" :showItemNavigators="true">
            <template #item="slotProps">
                <img :src="slotProps.item.image.src" :alt="slotProps.item.image.alt" @load="onGalleriaImageRender" />

                <div class="tr-galleria-extra-toolbar">
                    <button type="button" @click="handleGalleriaImageZoomOut" aria-label="Zoom Out"
                        v-tooltip="'Zoom Out'">
                        <i class="fa fa-search-minus" aria-hidden="true"></i>
                    </button>
                    <button type="button" @click="handleGalleriaImageZoomIn" aria-label="Zoom In" v-tooltip="'Zoom In'">
                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                    </button>
                    <button type="button" @click="handleGalleriaImageRotate(90)" aria-label="Rotate right"
                        v-tooltip="'Rotate Right'">
                        <i class="fa fa-rotate-right" aria-hidden="true"></i>
                    </button>
                    <button type="button" @click="handleGalleriaImageRotate(-90)" aria-label="Rotate left"
                        v-tooltip="'Rotate Left'">
                        <i class="fa fa-rotate-left" aria-hidden="true"></i>
                    </button>
                </div>
            </template>
            <template #thumbnail="slotProps">
                <img :src="slotProps.item.image.src" :alt="slotProps.item.image.alt" style="display: block" />
            </template>
            <template #caption="slotProps">
                <a :href="slotProps.item.image.src" target="_blank" class="text-white">
                    <span class="fa fa-download me-1"></span> Download | {{ slotProps.item.filename }}
                </a>
            </template>
        </Galleria>
    </div>

</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from "vue";
import Skeleton from "primevue/skeleton"
import {
	formatDate,
	galleriaKeyboardSupport,
	getApiErrorMessage,
	getDocumentCategoryName,
	getQueryParams,
	galleriaThumbnailButtons
} from "@/helpers/common";
import { toast } from "@/helpers/toast";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Galleria from 'primevue/galleria';
import dayjs from "dayjs"
import { useTaxroll } from "@/stores/taxroll";
import {
	onGalleriaImageRender,
	handleGalleriaImageZoomIn,
	handleGalleriaImageZoomOut,
	handleGalleriaImageRotate,
} from "@/helpers/galleria"

const props = defineProps({
	tru_id: {
		type: String,
		required: true
	}
})

const taxroll = useTaxroll()
const fetchDocumentListLoading = computed(() => taxroll.getDocumentListLoadingState)
const documentList = computed(() => taxroll.getDocumentList)
const galleriaItems = ref<any[]>([])

const activeIndex = ref(0)
const isGalleriaVisible = ref(false)
const imageClick = (file_name: string) => {
	isGalleriaVisible.value = true
	activeIndex.value = galleriaItems.value?.findIndex(doc => doc.filename === file_name) as number
	galleriaThumbnailButtons()
	setTimeout(() => galleriaKeyboardSupport('attach', handleKeyDown), 500)
}

const loadFailureHandler = (error: any) => {
	const message = getApiErrorMessage(error)
	toast.error(message)
}

watch(() => documentList.value, async (documentList) => {
	const dataImages = documentList?.filter(item => item.content_type.startsWith('image/'))
	if (!dataImages?.length) return
	let images = []
	for (const item of dataImages) {
		const img = new Image()
		img.src = item.download_url
		img.alt = item.file_name
		images.push({
			image: img,
			filename: item.file_name,
			expirationDate: getQueryParams(item.download_url).get("Expires") || "",
			url: item.download_url
		})
	}
	galleriaItems.value = images
})

const handleKeyDown = (event: KeyboardEvent) => {
	const key = event.key
	switch (key) {
		case 'ArrowLeft':
			if (activeIndex.value === 0) {
				activeIndex.value = galleriaItems.value.length - 1
			}
			else activeIndex.value -= 1
			break;
		case 'ArrowRight':
			if (activeIndex.value === galleriaItems.value.length - 1) {
				activeIndex.value = 0
			}
			else activeIndex.value += 1
			break
		case 'Escape':
			isGalleriaVisible.value = false
			break
		default:
			break
	}
}

const checkExpiration = async () => {
	const now = dayjs().unix()
	if (galleriaItems.value.length <= 0) return
	if (now > galleriaItems.value[0]?.expirationDate) {
		await taxroll.fetchDocumentList(props.tru_id, loadFailureHandler)
	}
}

const intervalId = ref<number | null>(null)
const startExpirationCheck = () => {
	if (intervalId.value === null) {
		intervalId.value = window.setInterval(checkExpiration, 10000) // Check every 10 seconds
	}
}

const stopExpirationCheck = () => {
	if (intervalId.value !== null) {
		clearInterval(intervalId.value)
		intervalId.value = null
	}
}

onMounted(() => {
	startExpirationCheck()
})

onUnmounted(() => {
	stopExpirationCheck()
	galleriaKeyboardSupport('detach', handleKeyDown)
})

onBeforeUnmount(() => galleriaKeyboardSupport('detach', handleKeyDown))
</script>
