<template>
  <main class="public-container">
    <noscript>This page requires JavaScript to be enabled in your browser.</noscript>
    <div class="content-wrapper">
      <div class="content" v-if="!formSubmitted">
        <form class="public-form" @submit.prevent="submitFormHandler">
          <ApplicationSection title="Claim for Home Exemption">
            <b>Important Eligibility Information</b>
            <p class="mt-2">
              1. The property is owned and occupied as your principal home more than 200 calendar days of a calendar year. The term
                “principal home” is defined as the place where an individual has a true, fixed, permanent home and principal establishment
                and to which place the individual has whenever absent, the intention of returning. It is the place in which a person has
                voluntarily fixed habitation, not for mere special, temporary or vacation purposes, but with the intention of making a
                permanent home. If any portion of the property is utilized as a rental of less than 180 days, it will result in the disallowance of
                the Homeowner tax class (rate) and the 3% assessment cap.
            </p>
            <p class="mt-2">
              2. The ownership is recorded at the Bureau of Conveyances or Land Court in Honolulu on or before December 31 preceding
                the tax year for which the exemption is claimed or by June 30. All leases must be for a term of ten years or more and
                at recorded the Bureau of Conveyances for the lessee to qualify for the home exemption. In the case of Hawaiian Homestead
                Land, either lessee and/or spouse shall be entitled to the home exemption. Proof of marriage must be submitted for the
                non-Hawaiian spouse claiming the home exemption.
            </p>
            <p class="mt-2">
              3. You must file a claim for home exemption with the Real Property Tax Division on or before December 31
                preceding the tax year for the first half payment or June 30 for the second half payment.
            </p>
            <p class="mt-2">
              4. You have filed a State of Hawai’i Resident Income Tax Return (N-11) within the last 12 months or have requested a waiver
                from this requirement for one of the following reasons: You are a new resident to the State of Hawai’i and will file a State of
                Hawai’i Resident Income Tax Return (N-11) within the next 12 months or you are not required to file under State of Hawai’i
                Income Tax Law and not required to file income tax in any other jurisdiction other than at the U.S. Federal level and
                understand that you are required to refile this waiver every three (3) years. The social security number and date of birth as
                provided will be used to confirm compliance with this requirement. Failure to provide this information will result in the
                Disqualification of this application and the benefits of the home exemption.
            </p>
            <p class="text-attention">This form will not be received by the Appraisal District until you
                  press Submit Application and receive a submission confirmation number.</p>
          </ApplicationSection>
          <ApplicationSection title="Owner Information">
            <div class="element-container">
              <span class="form-label form-label-required">
                Are you filing jointly with another person?
              </span>
              <fieldset id="has_owner_2">
                <input v-model="formFields.has_owner_2" type="radio" class="custom-radio"
                       name="has_owner_2" id="has_owner_2-1" :value="true" required
                       @invalid.capture.prevent="elementRequiredHandler">
                <label for="has_owner_2-1">Yes</label>
                <input v-model="formFields.has_owner_2" type="radio" class="custom-radio"
                       name="has_owner_2" id="has_owner_2-0" :value="false" required>
                <label for="has_owner_2-0">No</label>
              </fieldset>
            </div>
            <div class="my-3">
              <h3 class="subsection-header">Property Owner 1</h3>
              <div class="element-container">
                <label class="form-label form-label-required" for="property-owner-id-file-1">
                  Upload: Driver License, State ID, or Passport Card
                </label>
                <FileUploader id="property-owner-id-file-1" :element-required-handler="elementRequiredHandler" required
                              @change="uploadID"/>
                <ApplicationFileUploadStatus containerID="property-owner-id-file-1"/>
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_name">Owner Name</label>
                <input type="text" id="owner_name" maxlength="255"
                       autocapitalize="words" autocomplete="name" spellcheck="false"
                       required @invalid.capture.prevent="elementRequiredHandler"
                       v-model="formFields.owner_name">
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_ssn">Social Security Number</label>
                <InputMask v-model="formFields.owner_ssn" mask="999-99-9999" id="owner_ssn" maxlength="11" spellcheck="false"
                           required @invalid.capture.prevent="elementRequiredHandler" />
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_dob">Date of Birth</label>
                <DateComboBox v-model="formFields.owner_dob" id="owner_dob"
                              :element-required-handler="elementRequiredHandler" :required="true"/>
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_email">Email</label>
                <input type="email" id="owner_email" maxlength="255"
                       spellcheck="false" autocapitalize="off" autocomplete="email"
                       required @invalid.capture.prevent="elementRequiredHandler"
                       v-model="formFields.owner_email">
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_phone">Cell Phone Number</label>
                <PhoneField id="owner_phone" v-model="formFields.owner_phone"
                            :required="true" :elementRequiredHandler="elementRequiredHandler"/>
              </div>
              <div class="element-container">
                <label class="form-label" for="owner_alt_phone">Alternate Phone Number</label>
                <PhoneField id="owner_alt_phone" v-model="formFields.owner_alt_phone"
                            :required="false" :elementRequiredHandler="elementRequiredHandler"/>
              </div>
            </div>
            <div class="my-3" v-if="formFields.has_owner_2">
              <h3 class="subsection-header">Property Owner 2</h3>
              <div class="element-container">
                <label class="form-label form-label-required" for="property-owner-id-file-2">
                  Upload: Driver License or state issued personal identification certificate
                </label>
                <FileUploader id="property-owner-id-file-2" :element-required-handler="elementRequiredHandler" :required="formFields.has_owner_2"
                              @change="uploadID"/>
                <ApplicationFileUploadStatus containerID="property-owner-id-file-2"/>
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_2_name">Owner 2 Name</label>
                <input type="text" id="owner_2_name" maxlength="255" v-model="formFields.owner_2_name"
                       autocapitalize="words" autocomplete="name" spellcheck="false"
                       :required="formFields.has_owner_2" @invalid.capture.prevent="elementRequiredHandler">
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_2_ssn">Social Security Number</label>
                <InputMask v-model="formFields.owner_2_ssn" mask="999-99-9999" id="owner_2_ssn" maxlength="11" spellcheck="false"
                           :required="formFields.has_owner_2" @invalid.capture.prevent="elementRequiredHandler" />
              </div>
              <div class="element-container">
                <label class="form-label form-label-required" for="owner_2_dob">Date of Birth</label>
                <DateComboBox v-model="formFields.owner_2_dob" id="owner_2_dob"
                              :required="formFields.has_owner_2" @invalid.capture.prevent="elementRequiredHandler"/>
              </div>
            </div>
          </ApplicationSection>
          <ApplicationSection title="Address Information">
            <div class="element-container">
              <label class="form-label form-label-required" for="parcel_number">12-digit Parcel Number</label>
              <InputMask v-model="formFields.parcel_number" mask="9-9-999-999-9999" id="owner_ssn" maxlength="16"
                         inputmode="numeric" spellcheck="false" required @invalid.capture.prevent="elementRequiredHandler"/>
            </div>
            <div class="element-container">
              <label class="form-label form-label-required" for="property_address_line_1">Site Address</label>
              <input type="text" id="property_address_line_1" maxlength="255"
                     required @invalid.capture.prevent="elementRequiredHandler"
                     autocapitalize="words" autocomplete="street-address" spellcheck="false"
                     v-model="formFields.property_address_line_1">
            </div>
            <div class="element-container">
              <label class="form-label" for="property_unit_number">Unit Number</label>
              <input type="text" id="property_unit_number" maxlength="255"
                     v-model="formFields.property_unit_number">
            </div>
            <div class="element-container">
              <label class="form-label form-label-required" for="property_city">City</label>
              <input type="text" id="property_city" maxlength="255"
                     required @invalid.capture.prevent="elementRequiredHandler"
                     autocapitalize="words" autocomplete="address-level2" spellcheck="false"
                     v-model="formFields.property_city">
            </div>
            <div class="element-container">
              <label class="form-label form-label-required" for="property_state">State</label>
              <input type="text" id="property_state" maxlength="2"
                     required @invalid.capture.prevent="elementRequiredHandler"
                     autocapitalize="words" autocomplete="address-level1" spellcheck="false"
                     v-model="formFields.property_state">
            </div>
            <div class="element-container">
              <label class="form-label form-label-required" for="property_postal_code">Zip Code</label>
              <input type="text" id="property_postal_code" maxlength="5"
                     required @invalid.capture.prevent="elementRequiredHandler"
                     autocapitalize="words" autocomplete="postal-code" spellcheck="false"
                     v-model="formFields.property_postal_code">
            </div>
            <div class="element-container">
              <span class="form-label form-label-required">
                Does the mailing address differ from the property address?
              </span>
              <fieldset id="diff_mail">
                <input v-model="formFields.diff_mail" type="radio" class="custom-radio"
                       name="diff_mail" id="diff_mail-1" :value="true" required
                       @invalid.capture.prevent="elementRequiredHandler">
                <label for="diff_mail-1">Yes</label>
                <input v-model="formFields.diff_mail" type="radio" class="custom-radio"
                       name="diff_mail" id="diff_mail-0" :value="false" required>
                <label for="diff_mail-0">No</label>
              </fieldset>
            </div>
            <div class="element-container" v-if="formFields.diff_mail">
              <label class="form-label form-label-required" for="mail_address_line_1">Mail Address:</label>
              <input type="text" id="mail_address_line_1" maxlength="255"
                     v-model="formFields.mail_address_line_1" :required="formFields.diff_mail"
                     autocapitalize="words" autocomplete="street-address" spellcheck="false"
                     @invalid.capture.prevent="elementRequiredHandler">
            </div>
            <div class="element-container" v-if="formFields.diff_mail">
              <label class="form-label" for="mail_unit_number">Unit Number</label>
              <input type="text" id="mail_unit_number" maxlength="255"
                     v-model="formFields.mail_unit_number">
            </div>
            <div class="element-container" v-if="formFields.diff_mail">
              <label class="form-label form-label-required" for="mail_city">City</label>
              <input type="text" id="mail_city" maxlength="255"
                     v-model="formFields.mail_city" :required="formFields.diff_mail"
                     autocapitalize="words" autocomplete="address-level2" spellcheck="false"
                     @invalid.capture.prevent="elementRequiredHandler">
            </div>
            <div class="element-container" v-if="formFields.diff_mail">
              <label class="form-label form-label-required" for="mail_state">State</label>
              <input type="text" id="mail_state" maxlength="2"
                     v-model="formFields.mail_state" :required="formFields.diff_mail"
                     autocapitalize="words" autocomplete="address-level1" spellcheck="false"
                     @invalid.capture.prevent="elementRequiredHandler">
            </div>
            <div class="element-container" v-if="formFields.diff_mail">
              <label class="form-label form-label-required" for="mail_postal_code">Zip Code</label>
              <input type="text" id="mail_postal_code" maxlength="5"
                     v-model="formFields.mail_postal_code" :required="formFields.diff_mail"
                     autocapitalize="words" autocomplete="postal-code" spellcheck="false"
                     @invalid.capture.prevent="elementRequiredHandler">
            </div>
          </ApplicationSection>
          <ApplicationSection title="Property Attributes">
            <div class="element-container">
              <label class="form-label form-label-required" for="unit_count">
                How many units are located on the parcel? (NOTE: Your co-op/condominium unit is one living unit.)
              </label>
              <input type="number" id="unit_count" min="1"
                     required @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.unit_count">
            </div>
            <div class="element-container" v-if="formFields.unit_count > 1">
              <label class="form-label form-label-required" for="floorplan">
                If more than one living unit, provide a plot/floor diagram showing the location of each living unit on the parcel.
              </label>
              <FileUploader id="floorplan" :element-required-handler="elementRequiredHandler" :required="formFields.unit_count > 1"
                            @change="uploadFiles"/>
              <ApplicationFileUploadStatus containerID="floorplan"/>
            </div>
            <div class="element-container">
              <label class="form-label form-label-required" for="owner_count">How many owners live on this parcel?</label>
              <input type="number" id="owner_count" min="1"
                     required @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.owner_count">
            </div>
            <div class="element-container" v-if="formFields.unit_count > 1">
              <label class="form-label form-label-required" for="owners_per_unit">
                If more than one living unit, state the number of owners residing in each unit.
              </label>
              <input type="text" id="owners_per_unit"
                     :required="formFields.unit_count > 1" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.owners_per_unit">
            </div>
            <div class="element-container">
              <span class="form-label form-label-required">
                Is any portion of this unit used for income producing purposes?
              </span>
              <fieldset id="is_commerical">
                <input v-model="formFields.is_commerical" type="radio" class="custom-radio"
                       name="is_commerical" id="is_commerical-1" :value="true" required
                       @invalid.capture.prevent="elementRequiredHandler">
                <label for="is_commerical-1">Yes</label>
                <input v-model="formFields.is_commerical" type="radio" class="custom-radio"
                       name="is_commerical" id="is_commerical-0" :value="false" required>
                <label for="is_commerical-0">No</label>
              </fieldset>
            </div>
            <div class="element-container" v-if="formFields.is_commerical">
              <label class="form-label form-label-required" for="commercial_area">
                How much square footage is used for income producing purposes?
              </label>
              <input type="number" id="commercial_area" min="0"
                     :required="formFields.is_commerical" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.commercial_area">
            </div>
            <div class="element-container" v-if="formFields.is_commerical">
              <label class="form-label form-label-required" for="owner_days">
                How many days per year is the property used as a residence by the owners?
              </label>
              <input type="number" id="owner_days" min="0"
                     :required="formFields.is_commerical" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.owner_days">
            </div>
            <div class="element-container" v-if="formFields.is_commerical">
              <label class="form-label form-label-required" for="long_term_rental_days">
                How many days per year is the property used for long-term rentals?
              </label>
              <input type="number" id="long_term_rental_days" min="0"
                     :required="formFields.is_commerical" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.long_term_rental_days">
            </div>
            <div class="element-container" v-if="formFields.is_commerical">
              <label class="form-label form-label-required" for="short_term_rental_days">
                How many days per year is the property used for short-term rentals?
              </label>
              <input type="number" id="short_term_rental_days" min="0"
                     :required="formFields.is_commerical" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.short_term_rental_days">
            </div>
            <div class="element-container" v-if="formFields.is_commerical">
              <label class="form-label form-label-required" for="other_days">
                How many days per year is the property used for other business purposes?
              </label>
              <input type="number" id="other_days" min="0"
                     :required="formFields.is_commerical" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.other_days">
            </div>
            <div class="element-container" v-if="formFields.is_commerical">
              <label class="form-label form-label-required" for="vacant_days">
                How many days per year is the property vacant?
              </label>
              <input type="number" id="vacant_days" min="0"
                     :required="formFields.is_commerical" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.vacant_days">
            </div>
            <div class="element-container">
              <span class="form-label form-label-required">
                Do you have any other home exemptions anywhere else?
              </span>
              <fieldset id="alternate_exemption">
                <input v-model="formFields.alternate_exemption" type="radio" class="custom-radio"
                       name="alternate_exemption" id="alternate_exemption-1" :value="true" required
                       @invalid.capture.prevent="elementRequiredHandler">
                <label for="alternate_exemption-1">Yes</label>
                <input v-model="formFields.alternate_exemption" type="radio" class="custom-radio"
                       name="alternate_exemption" id="alternate_exemption-0" :value="false" required>
                <label for="alternate_exemption-0">No</label>
              </fieldset>
            </div>
            <div class="element-container" v-if="formFields.alternate_exemption">
              <label class="form-label form-label-required" for="alternate_exemption_address">
                What is the address for the other property?
              </label>
              <input type="text" id="alternate_exemption_address"
                     :required="formFields.alternate_exemption" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.alternate_exemption_address">
            </div>
            <div class="element-container">
              <span class="form-label form-label-required">
                Do you live separately from your spouse?
              </span>
              <fieldset id="has_separated_spouse">
                <input v-model="formFields.has_separated_spouse" type="radio" class="custom-radio"
                       name="has_separated_spouse" id="has_separated_spouse-1" :value="true" required
                       @invalid.capture.prevent="elementRequiredHandler">
                <label for="has_separated_spouse-1">Yes</label>
                <input v-model="formFields.has_separated_spouse" type="radio" class="custom-radio"
                       name="has_separated_spouse" id="has_separated_spouse-0" :value="false" required>
                <label for="has_separated_spouse-0">No</label>
              </fieldset>
            </div>
            <div class="element-container" v-if="formFields.has_separated_spouse">
              <label class="form-label form-label-required" for="spouse_name">
                What is your spouse's name?
              </label>
              <input type="text" id="spouse_name"
                     :required="formFields.has_separated_spouse" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.spouse_name">
            </div>
            <div class="element-container" v-if="formFields.has_separated_spouse">
              <label class="form-label form-label-required" for="spouse_address">
                What is your spouse's address?
              </label>
              <input type="text" id="spouse_address"
                     :required="formFields.has_separated_spouse" @invalid.capture.prevent="elementRequiredHandler"
                     v-model="formFields.spouse_address">
            </div>
            <div class="element-container">
              <span class="form-label form-label-required">
                Is the property owned or held in a Trust (e.g. Doe Family Trust)?
              </span>
              <fieldset id="is_trust">
                <input v-model="formFields.is_trust" type="radio" class="custom-radio"
                       name="is_trust" id="is_trust-1" :value="true" required
                       @invalid.capture.prevent="elementRequiredHandler">
                <label for="is_trust-1">Yes</label>
                <input v-model="formFields.is_trust" type="radio" class="custom-radio"
                       name="is_trust" id="is_trust-0" :value="false" required>
                <label for="is_trust-0">No</label>
              </fieldset>
            </div>
            <div class="element-container" v-if="formFields.is_trust">
              <label class="form-label form-label-required" for="trust_cert">
                Attach a copy of the Certificate of Trust if you are the Originator/Creator/Settlor of the Trust
                or a copy of the Long Form Trust if you are a Beneficiary of the Trust.
              </label>
              <FileUploader id="trust_cert" :element-required-handler="elementRequiredHandler" :required="formFields.is_trust"
                            @change="uploadFiles"/>
              <ApplicationFileUploadStatus containerID="trust_cert"/>
            </div>
          </ApplicationSection>
          <ApplicationSection title="Certification">
            <p class="mt-2">
              I certify that I own and occupy this home as my principal residence, and in the case where a trust (or trusts)
              owns the home I certify that I am the settlor or a beneficiary entitled to occupy this home in accordance with
              ROH § 8-10.3. I also certify that the foregoing is true and correct to the best of my knowledge. I understand
              that any misstatement of facts will be grounds for disallowance. I also understand if I cease to qualify for
              such exemption, I must report this change in facts or status to the assessor within 30 days, or by November
              1 preceding the tax year to which the change applies. Failure to report a change in facts or status will result
              in disallowance and penalties.
            </p>
            <div class="element-container">
              <span class="form-label form-label-required">
                Signature of Property Owner/Applicant or Authorized Representative
              </span>
              <Signature v-model:signature_of_applicant="formFields.signature_of_applicant"/>
            </div>
            <div class="element-container" v-if="formFields.has_owner_2">
              <span class="form-label form-label-required">
                Signature of Property Owner/Applicant 2 or Authorized Representative
              </span>
              <Signature v-model:signature_of_applicant="formFields.signature_of_applicant"/>
            </div>

            <div class="element-container" v-if="formFields.signature_date">
              <span>Dated: {{ today.format("M/D/YYYY") }}</span>
            </div>
          </ApplicationSection>
          <ApplicationSection title="">
            <SubmitButtonContainer v-if="istValidUUID" />

            <Message severity="error" :closable="false" class="m-2 mb-5" v-if="istValidUUID === false">
              <i class="fas fa-exclamation-triangle"></i>
              {{ NOT_VALID_UUID_ERR_MESSAGE }}
            </Message>
          </ApplicationSection>
        </form>
      </div>
      <div class="content text-center" v-else>
        <ApplicationSection title="Thank You!">
          <p>
            Your submission was successful.
            <br>
            <br>
            Application ID: <b>{{ confirmationNumber }}</b>
          </p>
          <p>
            <a href="#" @click="refreshForm()">Go back</a>
          </p>
        </ApplicationSection>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import {useAPI} from "@/helpers/services/api";
import {useRoute} from "vue-router";
import {useApplicationForm} from "@/stores/applicationForm";
import dayjs from "dayjs";
import {computed, onMounted, ref, watch} from "vue";
import {generateUUIDv4, getApiErrorMessage, hidePFGetHelpButton, setPageTitle} from "@/helpers/common"
import { type NamForm } from '@/helpers/interface/applicationForm';
import {toast} from "@/helpers/toast";
import ApplicationSection from "@/components/ApplicationForm/ApplicationSection.vue";
import DateComboBox from "@/components/Shared/DateComboBox.vue";
import PhoneField from "@/components/Shared/PhoneField.vue"
import FileUploader from "@/components/Shared/FileUploader.vue"
import Signature from "@/components/ApplicationForm/Signature.vue";
import {isValidEmail} from "@/helpers/regex";
import type {AxiosError} from "axios";
import {getFileExtension, getFileSizeText, isValidFileType} from "@/helpers/files";
import Message from "primevue/message";
import SubmitButtonContainer from "@/components/ApplicationForm/SubmitButtonContainer.vue";
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue";
import InputMask from "primevue/inputmask";
import type {StateIdExtraction} from "@/helpers/interface/general";

const INVALID_EMAIL_MESSAGE = "You must enter a valid email address. Example: name@example.com"
const publicAPI = useAPI({ authGuard: false })
const route = useRoute()
sessionStorage.setItem("state", "hi")
const storeApplicationForm = useApplicationForm()
const ACCEPT_FILE_TYPES = ".png, .jpg, .jpeg, .bmp, .gif, .pdf"
const today = dayjs()

const formSubmitted = ref(false)
const formFields = computed(() => (storeApplicationForm.hiFields))
const uploadState = computed(() => (storeApplicationForm.uploadState))

/* this key must be included in all HTTP requests to the server as a header
    for file uploads and form submission itself
*/
const instanceKey = ref("")
instanceKey.value = generateUUIDv4()
const confirmationNumber = ref(0)
const formID = route.params.form_id as string
const form = ref({
	id: formID,
	customer_id: '',
	name: '',
	state: '',
	fips_code: '',
	api_key: '',
} as NamForm)

const isFormLoaded = computed(() => {
	return form.value.customer_id !== ''
})

const NOT_VALID_UUID_ERR_MESSAGE = "Error: The form cannot be submitted due to an invalid ID. Please report this issue to the county."
const istValidUUID = ref<Boolean | null>(null)
const loadNamForm = async (formID: string) => {
	istValidUUID.value = true
	try {
		const response = await publicAPI.get(`/applications/form/${formID}`)
		form.value = response.data
	} catch (error) {
		istValidUUID.value = false
		toast.error(NOT_VALID_UUID_ERR_MESSAGE, {
			duration: 0,
			dismissible: false,
			position: "top-right"
		});
	}
}

const elementRequiredHandler = (e: Event) => {
	const input = e.target as HTMLInputElement;
	let container: any = input.closest("fieldset");
	if (!container) {
		container = input.parentNode as HTMLElement;
	}
	if (!container)
		throw "Unable to process required element because it's not attached to the DOM"

	let displayText = "This field is required";
	switch (input.type) {
		case "radio":
			displayText = "Please choose an option"
			break;
		case "file":
			displayText = "You must upload a file"
			break;
		case "tel":
			displayText = input.getAttribute("title")?.toString() || "You must follow this format (555-123-4567)"
			break;
		case "email":
			displayText = input.getAttribute("title")?.toString() || INVALID_EMAIL_MESSAGE
			break;
		case "number":
			displayText = `Value must be a number between ${input.getAttribute("min")} and ${input.getAttribute("max")}`
			if ((~~input.step) > 1) {
				displayText += ` in increments of ${input.step}`
			}
			break;
		case "text":
			if (input.getAttribute("id") == "parcel_number") {
				displayText = "A 12-digit parcel number is required"
			}
			break;
	}
	displayInvalidField(container, displayText)
}

const displayInvalidField = (target: HTMLElement, text: string) => {
	const INVALID = "public-field-invalid";

	target.setAttribute("data-invalid-text", text)
	target.classList.add(INVALID);
	target.scrollIntoView()

	setTimeout(() => {
		target.setAttribute("data-invalid-text", "")
		target.classList.remove(INVALID)
	}, 5000);
}

const submitFormHandler = async () => {
	if (formFields.value.signature_of_applicant.length === 0) {
		const target = document.getElementById("signature-container") as HTMLElement
		displayInvalidField(target, "Your signature is required")
		return;
	}
	if (!isValidEmail(formFields.value.owner_email)) {
		const input = document.getElementById("owner_email") as HTMLElement
		let container: any = input.closest("fieldset");
		if (!container) container = input.parentNode as HTMLElement;
		if (container) {
			displayInvalidField(container, INVALID_EMAIL_MESSAGE)
			return;
		}
	}

	if (uploadState.value > 0) {
		toast.info("Hold tight, we're processing your upload.")
		return;
	}
	storeApplicationForm.getOrSetFormLoading(true)
	const payload = await createSubmissionPayload()

	try {
		const resp = await publicAPI.post(
			`/applications/${form.value.fips_code}`,
			payload,
			{
				"headers": {
					"Content-Type": "application/json",
					"X-Api-Key": form.value.api_key,
				}
			}
		)
		if (resp.data && resp.data.application_id) {
			confirmationNumber.value = resp.data.application_id as number
		}
		formSubmitted.value = true
		AppState.clear()
	} catch (error) {
		toast.error(getApiErrorMessage(error as AxiosError))
	}
	storeApplicationForm.getOrSetFormLoading(false)
}

const createSubmissionPayload = async () => {
	const payload = { ...formFields.value } as any

	Object.keys(payload).forEach(key => {
		// convert all dates (from PrimeVue datepicker) to ISO strings
		if (payload[key] instanceof Date) {
			if (isNaN(+payload[key])) {
				payload[key] = null
			} else {
				payload[key] = payload[key].toISOString().substring(0, 10)
			}
		}
		if (payload[key] === "") {
			payload[key] = null;
		}
	})

	const idSeed = instanceKey.value.slice(0, 4)

	payload['tru_id'] = instanceKey.value
	payload['application_id'] = parseInt(idSeed, 16).toString()
	payload['form_id'] = form.value.id

	const sigUpl = await uploadSignature(payload['signature_of_applicant'])
	payload['signature_of_applicant'] = sigUpl?.id

	return payload
}

const uploadSignature = async (base64Signature: string) => {
	return await storeApplicationForm.uploadFile(
		instanceKey.value,
		convertBase64ToFile(base64Signature, "signature.png", "image/png"),
        form.value.customer_id!,
        "application_attachments",
        "signature_of_applicant"
	)
}

const convertBase64ToFile = (base64: string, filename: string, contentType: string) => {
	const byteCharacters = atob(base64)
	const byteNumbers = new Array(byteCharacters.length)
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}
	const byteArray = new Uint8Array(byteNumbers)
	return new File([byteArray], filename, { type: contentType })
}

const uploadFiles = async (e: Event) => {
	if (!isFormLoaded.value) {
		await loadNamForm(formID)
	}
	if (!isFormLoaded.value) {
		throw new Error("Unauthorized form")
	}
	fileInputChangeHandler(e)
	const input = e.target as HTMLInputElement
	if (!input.files) return;
	const file = input.files && input.files[0]
	const id = input.getAttribute("id")?.toString() || ""
	await storeApplicationForm.uploadFile(
		instanceKey.value,
		file,
        form.value.customer_id!,
        "application_attachments",
        id,
        input
	)
}

const uploadID = async (e: Event) => {
	if (!isFormLoaded.value) {
		await loadNamForm(formID)
	}
	if (!isFormLoaded.value) {
		throw new Error("Unauthorized form")
	}
	fileInputChangeHandler(e)
	const input = e.target as HTMLInputElement
	if (!input.files) return;
	const file = input.files && input.files[0]
	const id = input.getAttribute("id")?.toString() || ""
	const completeUpload = await storeApplicationForm.uploadFile(
		instanceKey.value,
		file,
        form.value.customer_id!,
        "application_attachments",
        id,
        input,
        form.value.api_key
	)
	if (completeUpload?.meta.extraction) {
		populateFromTextExtraction(completeUpload.meta.extraction, id)
	}
}

const populateFromTextExtraction = (data: StateIdExtraction, id: string) => {
	if (id == "property-owner-id-file-1")
	{
		if (!formFields.value.owner_name) {
			formFields.value.owner_name = `${data.first_name} ${data.last_name}`.trim()
		}
		if (data.dob && !formFields.value.owner_dob) {
			formFields.value.owner_dob = dayjs(data.dob).toDate()
		}
		if (data.address && !formFields.value.property_address_line_1) {
			formFields.value.property_address_line_1 = data.address
		}
		if (data.city && !formFields.value.property_city) {
			formFields.value.property_city = data.city
		}
		if (data.state && !formFields.value.property_state) {
			formFields.value.property_state = data.state
		}
		if (data.postal_code && !formFields.value.property_postal_code) {
			formFields.value.property_postal_code = data.postal_code
		}
	}
	else if (id == "property-owner-id-file-2")
	{
		if (!formFields.value.owner_2_name) {
			formFields.value.owner_2_name = `${data.first_name} ${data.last_name}`.trim()
		}
		if (data.dob && !formFields.value.owner_2_dob) {
			formFields.value.owner_2_dob = dayjs(data.dob).toDate()
		}
	}
}

const fileInputChangeHandler = (e: Event, isDriversLicense: boolean = false) => {
	const maxMegaBytes = isDriversLicense ? 10 : 20
	const MAX_FILE_SIZE_BYTES = maxMegaBytes * 1024 * 1024  // MB converted by bytes
	const input = e.target as HTMLInputElement
	if (!input.files)
		return;
	const selectedFile = input.files[0]

	if (selectedFile) {
		if (!isValidFileType(selectedFile.name, ACCEPT_FILE_TYPES.split(","))) {
			const extension = getFileExtension(selectedFile.name)
			alert(`Files of type '${extension.toUpperCase()}'' may not be uploaded.`)
			input.value = "";
			return;
		}
		if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
			const fileSizeText = getFileSizeText(selectedFile.size)
			const maxSizeText = getFileSizeText(MAX_FILE_SIZE_BYTES)
			alert(`The selected file is ${fileSizeText}, which is larger than the maximum allowed (${maxSizeText}).`)
			input.value = "";
			return;
		}
		if (selectedFile.size === 0) {
			alert("The selected file contains no data and may not be uploaded.");
			input.value = "";
			return;
		}
	}
}

watch(() => formFields.value.diff_mail, (field: boolean | null) => {
	if (!field) return

	// Clear mailing address when not needed
	formFields.value.mail_address_line_1 = null
	formFields.value.mail_unit_number = null
	formFields.value.mail_city = null
	formFields.value.mail_state = null
	formFields.value.mail_postal_code = null
})

watch(() => formFields.value.has_owner_2, (field: boolean | null) => {
	if (field){
		formFields.value.owner_count = 2
		return
	}

	// Clear 2nd owner when not needed
	formFields.value.owner_count = 1
	formFields.value.owner_2_name = null
	formFields.value.owner_2_dob = null
	formFields.value.owner_2_ssn = null
})

watch(() => formFields.value.is_commerical, (field: boolean | null) => {
	if (!field) return

	// Clear commmercial use data
	formFields.value.commercial_area = null
	formFields.value.owner_days = null
	formFields.value.short_term_rental_days = null
	formFields.value.long_term_rental_days = null
	formFields.value.other_days = null
	formFields.value.vacant_days = null
})

watch(() => formFields.value.alternate_exemption, (field: boolean | null) => {
	if (field) return

	// Clear alt exemption info
	formFields.value.alternate_exemption_address = null
})

watch(() => formFields.value.has_separated_spouse, (field: boolean | null) => {
	if (field) return

	// Clear alt exemption info
	formFields.value.spouse_name = null
	formFields.value.spouse_address = null
})

watch(() => formFields.value.unit_count, (field: number) => {
	if (field > 1) return

	// clear owners per unit
	formFields.value.owners_per_unit = null
})

const AppState = {
	KEY: storeApplicationForm.HI_STATE_STORAGE_KEY,

	clear: () => {
		try {
			sessionStorage.removeItem(AppState.KEY)
		} catch (ex) {
			console.warn("Unable to clear form state from storage")
		}
	},

	save: () => {
		try {
			if (isFormLoaded.value) {
				sessionStorage.setItem(AppState.KEY, JSON.stringify(formFields.value));
			}
		} catch (ex) {
			console.warn("Unable to utilize storage to persist form state.")
		}
	},

	events: {
		windowBeforeUnload: () => {
			if (formSubmitted.value) {
				return;
			}
			return "Are you sure you want to leave this page? Your progress may be lost."
		}
	}

}
watch(formFields.value, AppState.save, { deep: true });
const refreshForm = () => window.location.reload()

onMounted(async () => {
	setPageTitle("Application for Homestead Exemption")
	await loadNamForm(formID)
	window.onbeforeunload = AppState.events.windowBeforeUnload
	formFields.value.parcel_number = (route.query.account_num as string) || ""
	hidePFGetHelpButton()
	formFields.value.signature_of_applicant = ""
	formFields.value.signature_date = today.toDate()
})

</script>

<style scoped>
@import "@/assets/public/public-base.css";
</style>