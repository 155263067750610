<template>
    <div class="position-relative m-2">
        <div class="actions-description">
            Use this screen to remove mailers, update the mailing address, download the csv file to merge with your
            office's
            templates. Once you've downloaded the records, select which mailers you would like to send and choose a
            mailing
        </div>
        <div class="admin-mail-module-not-mailed mt-2">
            <form @submit.prevent="submitFilter()">
                <div id="mail-module-not-mailed-filter">
                    <div class="row" style="width: 35%;">
                        <div class="col-sm mt-2">
                            <select v-model="filterFields.mail_template_id" class="search-field" id="mailTemplateID">
                                <option value="" selected>-- Template --</option>
                                <option v-for="template in props.mailTemplateList" :value="template.id"
                                    :key="template.name">
                                    {{ ucfirst(template.name) }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm mt-2">
                            <input type="text" placeholder="Parcel Number" maxlength="255"
                                v-model="filterFields.parcel_number" class="search-field" size="25" id="parcelNumber">
                        </div>
                    </div>
                    <div class="row p-1">
                        <div class="col-md-6 mt-2">
                            <label :for="'nm_queue_' + queue" v-for="queue in candidateQueues" :key="queue"
                                style="margin-right: 5px;" :id="'label_queue_' + queue">
                                <input type="checkbox" :checked="filterFields.candidate_queue.includes(queue)"
                                    @click="toggleQueue(queue)" :id="'nm_queue_' + queue" />
                                {{ ucfirst(queue) }}
                            </label>
                        </div>
                    </div>
                    <div class="row" style="width: 35%;">
                        <div class="col-sm">
                            Created After
                            <br>
                            <input type="date" placeholder="Created After" v-model="filterFields.created_at_after"
                                class="search-field" id="createdAtAfter">
                        </div>
                        <div class="col-sm">
                            Created Before
                            <br>
                            <input type="date" placeholder="Created Before" v-model="filterFields.created_at_before"
                                class="search-field" id="createdAtBefore">
                        </div>
                    </div>
                    <div class="row p-1">
                        <div class="col-md-6 mt-2">
                            <label for="exemption_description_is_blank">
                                <input type="checkbox" :checked="filterFields.exemption_description_is_blank"
                                    @change="toggleCheckbox('exemption_description_is_blank')"
                                    id="exemption_description_is_blank" />
                                Exemption Description is Blank
                            </label>
                        </div>
                    </div>
                    <div class="mt-3 mb-3 d-block">
                        <div class="row p-1">
                            <div class="col-md-6">
                                <button type="submit" class="searchButton">Search</button>
                                <button type="button" @click="confirmClearSearchField" class="clearButton">Clear
                                    Search</button>
                            </div>
                            <div class="col-md-6 text-end d-block">
                                <div class="d-inline-flex">
                                    <loading-icon v-if="exportCSVLoading" />
                                    <button type="button" @click="exportMailers" class="actionButton"
                                        :disabled="exportCSVLoading" id="downloadCSV">
                                        <span class="fa fa-download" title="Download CSV" aria-hidden="true"></span>
                                        Download CSV
                                    </button>
                                    <button type="button" @click="() => showEditMailDateDialog = true"
                                        class="warningButton" :disabled="selectedMailIDs.length <= 0"
                                        v-if="hasPermissionToUpdatePostalContact" id="bulkUpdate">
                                        <span class="fa fa-edit" title="Update Mailed Date" aria-hidden="true"></span>
                                        Set Mailing Date
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="mt-2" id="mail-module-not-mailed-list"></div>
        </div>

        <teleport to="body">
            <ModalDialog v-if="showClearSearchConfirmation" title="Confirm Clear Search"
                :close="() => showClearSearchConfirmation = false">
                Are you sure you want to clear the search?
                <br>
                <br>
                This action cannot be undone, and all current search filters
                and input will be removed.

                <template #footer>
                    <button @click="clearSearchField" class="btn btn-danger confirmClearSearch">Confirm</button>
                    <button style="border: 1px solid #ccc" @click="showClearSearchConfirmation = false"
                        class="btn btn-default">Close</button>
                </template>
            </ModalDialog>
            <ModalDialog v-if="editMailModule" title="Edit Mail Module" :close="() => editMailModule = null">
                <AdminWorkflowMailModuleEdit @edit-mail-module="() => fetchMailModuleList(filterFields)"
                    :mailModule="editMailModule" :mailTemplateList="props.mailTemplateList" />
            </ModalDialog>
            <ModalDialog v-if="removeMailModuleElement.removeElement" title="Delete Mail"
                :close="() => removeMailModuleElement.removeElement = null">
                Are you sure you want to delete this mail for parcel {{ removeMailModuleElement.parcelNumber }}?
                <br>
                <br>
                This action cannot be undone.

                <template #footer>
                    <button style="border: 1px solid #ccc" @click="confirmDeleteMailModule" class="btn btn-danger"
                        id="confirmDeleteMailModule">Confirm</button>
                    <button style="border: 1px solid #ccc" @click="removeMailModuleElement.removeElement = null"
                        class="btn btn-default">Close</button>
                </template>
            </ModalDialog>

            <ModalDialog v-if="showEditMailDateDialog" :title="`Editing ${selectedMailIDs.length} Record(s)`"
                :close="() => showEditMailDateDialog = false">
                <div class="row">
                    <div class="col-3">
                        <label for="new-mail-date">Mail Date <span class="required">*</span></label>
                    </div>
                    <div class="col-9">
                        <input type="date" id="new-mail-date" v-model="newMailDate" class="cbFormTextField">
                    </div>
                </div>

                <template #footer>
                    <loading-icon v-if="bulkUpdateLoading" />
                    <button style="border: 1px solid #ccc; background-color: var(--main-warning-color);"
                        @click="mailerBulkUpdate" :disabled="(bulkUpdateLoading || (newMailDate.length === 0))"
                        class="btn btn-success">Update</button>
                    <button style="border: 1px solid #ccc" @click="() => showEditMailDateDialog = false"
                        class="btn btn-default">Close</button>
                </template>
            </ModalDialog>
        </teleport>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { getApiErrorMessage, ucfirst, downloadCSV, validateUserPermission } from "@/helpers/common";
import { ref, onMounted, onUnmounted, computed } from "vue"
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import type { MailModuleFilter, MailModuleDeleteFunc } from "@/helpers/interface/admin-page";
import { createAjaxTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import type { TabulatorFull as Tabulator, ColumnDefinition, CellComponent } from 'tabulator-tables'
import AdminWorkflowMailModuleEdit from "@/components/Admin/AdminWorkflowMailModuleEdit.vue"
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios";
import { useAPI } from "@/helpers/services/api";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";

const api = useAPI()
const props = defineProps({
	tableColumns: {
		type: Array as () => ColumnDefinition[],
		required: true
	},
	baseUrl: String,
	mailTemplateList: {
		type: Array as () => { name: string, id: number }[]
	},
	candidateQueues: {
		type: Array as () => string[]
	},
	toggleQueueSelection: {
		type: Function,
		required: true
	}
})
const hasPermissionToUpdatePostalContact = computed(() => validateUserPermission("update", "postal_contact_workflows"))
const editMailModule = ref<Partial<MailModuleFilter> | null>()
const removeMailModule = ref<Partial<MailModuleFilter> | null>()
const removeMailModuleElement = ref<MailModuleDeleteFunc>({
	removeElement: null,
	loadingElement: null,
	parcelNumber: ""
})
let tabulatorMailModule: Tabulator | null;
const showClearSearchConfirmation = ref<boolean>(false)

// Bulk update vars
const selectedMailIDs = ref<string[]>([])
const showEditMailDateDialog = ref<boolean>(false)
const bulkUpdateLoading = ref<boolean>(false)
const newMailDate = ref<string>("")

const candidateQueues = ref<string[]>(props.candidateQueues?.filter(q => (!["snooze"].includes(q))) || [])
const defaultCandidateQueueChecked = ["questionnaire", "unqualified"]
const defaultCandidateQueue = candidateQueues.value.filter(q => (defaultCandidateQueueChecked.includes(q)))
const filterFieldsInitialState = {
	mail_id: "",
	mail_template_id: "",
	parcel_number: "",
	candidate_queue: [...defaultCandidateQueue],
	created_at_after: "",
	created_at_before: "",
	sold_at_not_blank: false,
	sold_at_blank: false,
	exemption_description_is_blank: false,
	candidate_deactivated_at: false
}
const filterFields = ref<MailModuleFilter>({ ...filterFieldsInitialState })
const tableFilter = ref<{ field: string; type: string; value: any; }[]>([])
const exportCSVLoading = ref<boolean>(false)
const toggleQueue = (queue: string) => filterFields.value.candidate_queue = props.toggleQueueSelection(queue, filterFields.value)
const toggleCheckbox = (field: string) => {
	if (field === "exemption_description_is_blank") filterFields.value.exemption_description_is_blank = !filterFields.value.exemption_description_is_blank
}
let tableColumns: ColumnDefinition[] = [...props.tableColumns]

const confirmClearSearchField = () => showClearSearchConfirmation.value = true
const clearSearchField = () => {
	showClearSearchConfirmation.value = false
	filterFields.value = { ...filterFieldsInitialState }
	filterFields.value.candidate_queue = [...defaultCandidateQueue]
	fetchMailModuleList(filterFields.value)
}
const getDateRangeEnd = (isoDateString: string) => {
	if (!isoDateString)
		return ""
	return dayjs(isoDateString).add(1, "day").toISOString()
}
const submitFilter = () => fetchMailModuleList(filterFields.value)
const fetchMailModuleList = async (filterFieldsValue: MailModuleFilter) => {
	editMailModule.value = null // // Close dialog
	const tabulatorURL = `${props.baseUrl}/mailing/recipients-list/`
	const created_at_after = filterFieldsValue.created_at_after ? dayjs(filterFieldsValue.created_at_after).toISOString() : ""
	const created_at_before = filterFieldsValue.created_at_before ? dayjs(filterFieldsValue.created_at_before).toISOString() : ""
	const parcel_number = filterFieldsValue.parcel_number ? `${filterFieldsValue.parcel_number}%` : ''
	const defineFilterOperators: { field: string, type: string, value: any }[] = [
		{ field: "mail_id", type: "=", value: filterFieldsValue.mail_id },
		{ field: "mail_template_id", type: "=", value: filterFieldsValue.mail_template_id },
		{ field: "mail_status", type: "=", value: "created" }, // Default value for 'not mailed'
		{ field: "parcel_number", type: "like", value: parcel_number },
		{ field: "created_at__gte", type: "=", value: created_at_after },
		{ field: "created_at", type: "<", value: getDateRangeEnd(created_at_before) },
	]
	defineFilterOperators.push({ field: "candidate_queue", type: "in", value: filterFieldsValue.candidate_queue })
	if (filterFieldsValue.exemption_description_is_blank) defineFilterOperators.push({ field: "exemption_description_is_blank", type: "=", value: true })

	tableFilter.value = defineFilterOperators.filter((object) => object.value !== "")

	let previousSelectedData: any[]

	if (tabulatorMailModule) {
		selectedMailIDs.value = []
		tabulatorMailModule.clearAlert()
		tabulatorMailModule.setFilter(tableFilter.value)
		tabulatorMailModule.setData(tabulatorURL)
		return;
	}

	tabulatorMailModule = await createAjaxTabulator("#mail-module-not-mailed-list", {
		columns: tableColumns,
		initialSort: [
			{ column: "mail_template", dir: "asc" },
		],
		pagination: true,
	})
    
	if (!tabulatorMailModule) return
	tabulatorMailModule.on("tableBuilt", () => {
		if (!tabulatorMailModule) return

		tabulatorMailModule.on("dataLoading", () => previousSelectedData = tabulatorMailModule!.getSelectedData())
		tabulatorMailModule.on("dataProcessed", () => {
			if (previousSelectedData && previousSelectedData.length) {
				const ids = previousSelectedData.map(c => c.id)
				const previousSelectedRows = tabulatorMailModule!.getRows().filter(row => ids.includes(row.getData().id))
				if (previousSelectedRows.length) {
                    tabulatorMailModule!.selectRow(previousSelectedRows)
				}
			}
		})

		tabulatorMailModule.on("rowSelectionChanged", () => {
			const items = tabulatorMailModule?.getSelectedData().map(x => x.id) as string[]
			selectedMailIDs.value = items;
		})

		tabulatorMailModule.setFilter(tableFilter.value)
		tabulatorMailModule.setData(tabulatorURL)
	})
}

onMounted(async () => {
	tableColumns.push(
		{
			title: "&nbsp;",
			field: "name",
			width: "5vh",
			visible: hasPermissionToUpdatePostalContact.value,
			headerSort: false,
			formatter: function (cell: CellComponent) {
				const data = cell.getData() as MailModuleFilter
				const edit = {
					id: data.id,
					tru_id: data.tru_id,
					mail_to: data.mail_to,
					mail_address: data.mail_address,
					mail_status: data.mail_status,
					mail_address2: data.mail_address2,
					note_for_template: data.note_for_template,
					mail_template_id: data.mail_template_id,
				}

				const remove = {
					tru_id: data.tru_id,
					mail_id: data.mail_id,
				}
				const actionParent = document.createElement("div")
				const editButton = document.createElement("a")
				editButton.innerHTML = "<span class='fa fa-edit' aria-hidden='true'></span> "
				editButton.title = "Edit"
				editButton.classList.add("edit-btn")
				editButton.classList.add("mr-1")
				editButton.href = "javascript:;"
				editButton.addEventListener("click", () => editMailModule.value = edit)

				const removeButton = document.createElement("a")
				removeButton.innerHTML = "<span class='fa fa-trash' aria-hidden='true'></span>"
				removeButton.classList.add("remove-btn")
				removeButton.setAttribute("id", `remove-btn-${data.tru_id}`)
				removeButton.href = "javascript:;"
				removeButton.addEventListener("click", () => {
					removeMailModule.value = remove
					const id = `remove-btn-loading-${data.tru_id}`

					const removeButtonLoading = document.createElement("div")
					removeButtonLoading.classList.add("remove-btn")
					removeButtonLoading.classList.add("d-inline")
					removeButtonLoading.classList.add("d-none")
					removeButtonLoading.setAttribute("id", id)
					removeButtonLoading.innerHTML = '<span class="fa fa-spinner fa-spin"></span>'

					if (!document.getElementById(id)) actionParent.append(removeButtonLoading)
					removeMailModuleElement.value = {
						removeElement: removeButton,
						loadingElement: removeButtonLoading,
						parcelNumber: data.parcel_number,
					}
				})

				actionParent.append(editButton)
				actionParent.append(removeButton)
				return actionParent
			},
		})

	await fetchMailModuleList(filterFields.value)
})

const mailerBulkUpdate = async () => {
	const data = {
		ids: Object.values(selectedMailIDs.value),
		mailed_at: dayjs(newMailDate.value).toISOString()

	}
	bulkUpdateLoading.value = true
	try {
		await api.patch(`/taxroll/bulk_mail_update`, data);
		toast.success(`Your update has been saved`)
		await fetchMailModuleList(filterFields.value)
		newMailDate.value = ""
		showEditMailDateDialog.value = false
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	bulkUpdateLoading.value = false
}

const confirmDeleteMailModule = () => {
	const removeButton = removeMailModuleElement.value
	deleteMailModule(removeButton)
}

const exportMailers = async () => {
	const filter = {
		"export_type": "not-mailed",
		"filter": tableFilter.value
	}
	exportCSVLoading.value = true
	try {
		const response = await api.post(`/mailing/export`, filter)
		const filename = `TrueRoll Mail Merge ${dayjs().format('M_D_YYYY')}`
		downloadCSV(response.data, filename)
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	exportCSVLoading.value = false
}

const deleteMailModule = async (removeButton: MailModuleDeleteFunc) => {
	const tru_id = removeMailModule.value?.tru_id
	const mail_id = removeMailModule.value?.mail_id
	removeButton.removeElement?.classList.add("d-none")
	removeButton.loadingElement?.classList.remove("d-none")
	removeMailModuleElement.value = {
		removeElement: null,
		loadingElement: null,
		parcelNumber: ""
	}
	try {
		await api.patch(`/taxroll/mails/${tru_id}/delete`, { mail_id });
		toast.success(`Mail Module successfully deleted`)
		await fetchMailModuleList(filterFields.value)
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	removeButton.removeElement?.remove()
	removeButton.loadingElement?.classList.remove("d-none")
}

onUnmounted(() => destroyTabulator(tabulatorMailModule))
</script>

<style>
@import "@/assets/admin-page.css";
</style>
