import { useAPI } from "@/helpers/services/api"
import type { PageView } from "@/helpers/interface/general"
import { getUuidFromPath } from "@/helpers/common"

export const logPageView = async (data: PageView) => {
	const api = useAPI()

	if (!data.itemId) {
		data.itemId = getUuidFromPath()
	}

	api.post("/insights/view", {
		"page_name": data.pageName,
		"url": data.url || window.location.pathname,
		"query": data.query || window.location.search,
		"item_id": data.itemId,
		"context": data.context
	})
}
