<template>
    <div id="parcel">
        <table class="table">
            <tbody v-if="!fetchParcelLoading">
                <tr v-for="item in parcelData" :key="item.label">
                    <td class="label">{{ item.label }}</td>
                    <td class="value">
                        <template v-if="item.value && typeof item.value === 'object'">
                            <a href="javascript:;" @click="showTableDialogClickHandler(item.label, item.value)">View</a>
                        </template>
                        <template v-else>{{ item.value }}</template>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in 6" :key="i">
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td>
                        <Skeleton height="1.2rem" width="250px"></Skeleton>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ModalDialog v-if="isTableDialogVisible" :title="tableDialogTitle" :close="closeTableDialogHandler" :size="'modal-xl'" :maxHeightPercentage="80">
        <div ref="tableDialogContainerRef"></div>
    </ModalDialog>
</template>

<script setup lang="ts">
import type { AxiosError } from "axios"
import { onMounted, ref, watch } from "vue";
import { useAPI } from "@/helpers/services/api"
import ModalDialog from '@/components/Shared/ModalDialog.vue';
import Skeleton from "primevue/skeleton"
import { getApiErrorMessage, stringifyPrimitive, generateAdHocDataTable } from "@/helpers/common";
import { toast } from "@/helpers/toast";


interface DataEntity {
    label: string
    value: string | HTMLElement
}

const props = defineProps({
	tru_id: {
		type: String,
		required: true
	}
})

const api = useAPI()


const fetchParcelLoading = ref(true)
const parcelData = ref<DataEntity[] | null>(null)
const isTableDialogVisible = ref(false)
const tableDialogContainerRef = ref<null | HTMLDivElement>(null)
const tableDialogContentRef = ref<null | HTMLElement>(null)
const tableDialogTitle = ref("")


const loadFailureHandler = (error: any) => {
	const message = getApiErrorMessage(error)
	toast.error(message)
}

const showTableDialogClickHandler = (title: string, table: HTMLElement) => {
	tableDialogTitle.value = title
	tableDialogContentRef.value = table
	isTableDialogVisible.value = true
}

const closeTableDialogHandler = () => {
	isTableDialogVisible.value = false
	tableDialogContentRef.value = null
}


const transformData = (rawData: {} | any) : DataEntity[] =>  {
	const ignoreKeys = ["tru_id"]

	const targetKeys = Object.keys(rawData).filter(key => {
		return (!ignoreKeys.includes(key)) && (rawData[key] !== null)
	})

	targetKeys.sort((a,b) => a.toLowerCase().localeCompare(b.toLowerCase()))

	return targetKeys.map(key => {
		const rawValue = rawData[key]
		if (Array.isArray(rawValue) && rawValue.every(item => typeof item === "object" && item !== null)) {
			return { label: key, value: generateAdHocDataTable(rawValue) };
		} else {
			return {label: key, value: stringifyPrimitive(rawValue)}
		}
	})
}


watch(tableDialogContainerRef, () => {
	if (tableDialogContainerRef.value && tableDialogContentRef.value) {
		tableDialogContainerRef.value.innerHTML = "";
		tableDialogContainerRef.value.appendChild(tableDialogContentRef.value);
	}
});

const fetchParcel = async () => {
	const tru_id = props.tru_id
	fetchParcelLoading.value = true

	try {
		const { data } = await api.get(`applications/${tru_id}/parcel`)
		parcelData.value = transformData(data)
	} catch (error: unknown) {
		const err = error as AxiosError
		loadFailureHandler(err)
	}

	fetchParcelLoading.value = false
}

onMounted(() => fetchParcel())
</script>
