<template>
    <div v-if="isOffline" class="offline-maintenance-panel">
        <h1 class="offline-header">
            <img alt="Logo" src="/images/trueroll_app_logo6.png" class="offline-header-logo" />
        </h1>
        <p>This website is temporarily offline for maintenance.</p>
        <p v-if="expectedRestoreTime">
            We anticipate restoring service at: {{ formatLocalDateTime(expectedRestoreTime) }}
        </p>
        <p>
            Thank you for your patience!
        </p>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const isOffline = ref(false)
const expectedRestoreTime = ref<string | null | undefined>(null)


interface MaintenanceStatusResponse {
    maintenanceMode: boolean;
    expectedRestoreTime: string | null | undefined; // ISO date
}

const formatLocalDateTime = (isoString: string): string => {
	const date = new Date(isoString);
	return new Intl.DateTimeFormat(navigator.language, {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		hour12: true
	}).format(date);
}


const getWorkerUrl = () => {
	return import.meta.env.VITE_MAINTENANCE_MODE_URL
}


const getMaintenanceMessage = async () => {
	const workerUrl = getWorkerUrl();
	if (!workerUrl) {
		return null;
	}

	const response = await fetch(workerUrl, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		}
	})

	if (!response.ok) {
		console.error(`Check for maintenance status yielded ${response.status} - ${response.statusText}`)
		return
	}

	const data: MaintenanceStatusResponse = response.json() as any
	return data;
}


onMounted(async () => {
	const status = await getMaintenanceMessage()
	isOffline.value = status?.maintenanceMode || false
	expectedRestoreTime.value = status?.expectedRestoreTime

});

</script>

<style>
.offline-maintenance-panel {
    padding: 2em;
    background-color: #fff;
    color: var(--general-text-color);
    font-size: 18px;
    text-align: center;

    /* position */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* overlay all content, including toasts */
    z-index: 10000;  
}

.offline-header {
    margin-bottom: 1em;
}

.offline-header-logo {
    max-width: 350px;
}
</style>