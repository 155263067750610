<template>
    <div class="element-container">
        <FileUploader :id="getId('id-file')" :required="isDriversLicenseRequired" :disabled="!customerID"
            :accept="acceptFileTypes" :elementRequiredHandler="elementRequiredHandler" @change="uploadEvent">
            <template #label>
                <label :class="getRequiredClasses(isDriversLicenseRequired)">
                    Upload: Driver License or state issued personal identification certificate
                </label>
            </template>
        </FileUploader>
        <ApplicationFileUploadStatus :containerID="getId('id-file')" />
    </div>

    <div class="element-container">
        <label :for="getId('name')" class="form-label form-label-required">
            Name
        </label>
        <InputText v-model="name" :id="getId('name')" required maxlength="255" autocapitalize="words"
            autocomplete="name" @invalid.capture.prevent="elementRequiredHandler" spellcheck="false" />
    </div>

    <div class="element-container">
        <label :class="getRequiredClasses(requireBirthdate)" :for="getId('birthdate')">Birth Date
        </label>
        <DateComboBox v-model="birthdate" :element-required-handler="elementRequiredHandler"
            :required="props.requireBirthdate" :id="getId('birthdate')" />

        <div class="bg-attention mt-2" v-if="showBirthDateWarning">
            If you are older than 65 or you are turning 65 this year. You may qualify for the Persons 65 or Older
            exemption
            in Section 1, but have not selected it.
        </div>
    </div>

    <div class="element-container">
        <label :for="getId('id-number')" :class="getRequiredClasses(isDriversLicenseRequired)">
            Driver License, Personal ID Certificate
        </label>
        <InputText v-model="licenseNumber" :id="getId('id-number')" inputmode="numeric" maxlength="30"
            @invalid.capture.prevent="elementRequiredHandler" :required="isDriversLicenseRequired" />
    </div>

    <div class="element-container">
        <label :for="getId('email')" :class="getRequiredClasses(ownerNumber === 1)">
            Email Address
        </label>
        <InputText v-model="email" :id="getId('email')" type="email" maxlength="255" spellcheck="false"
            autocomplete="email" @invalid.capture.prevent="elementRequiredHandler" autocorrect="false"
            autocapitalize="off" title="A valid email address. Example: name@example.com"
            :required="ownerNumber === 1" />
    </div>

    <div class="element-container">
        <label :for="getId('phone')" :class="getRequiredClasses(ownerNumber === 1)">
            Primary Phone Number (with area code)
        </label>
        <PhoneField v-model="phone" :elementRequiredHandler="elementRequiredHandler" :id="getId('phone')"
            :required="ownerNumber === 1" />
    </div>

    <div class="element-container">
        <label :for="getId('owner-street-address')" class="form-label form-label-required">
            Street Address:
        </label>
        <InputText :id="getId('owner-street-address')" v-model="addressLine" maxlength="75" spellcheck="false" required
            @invalid.capture.prevent="elementRequiredHandler" />
    </div>
    <div class="element-container">
        <label :for="getId('owner-unit-number')" class="form-label">
            Unit Number:
        </label>
        <InputText :id="getId('owner-unit-number')" v-model="unitNumber" maxlength="75" spellcheck="false" />
    </div>
    <div class="element-container">
        <label :for="getId('owner-city')" class="form-label form-label-required">City:</label>
        <InputText :id="getId('owner-city')" v-model="city" maxlength="75" spellcheck="false" required
            @invalid.capture.prevent="elementRequiredHandler" />
    </div>
    <div class="element-container">
        <label :for="getId('owner-state')" class="form-label form-label-required">State:</label>
        <InputText :id="getId('owner-state')" v-model="state" maxlength="75" spellcheck="false" required
            @invalid.capture.prevent="elementRequiredHandler" />
    </div>
    <div class="element-container">
        <label :for="getId('owner-postal-code')" class="form-label form-label-required">Zip:</label>
        <InputText :id="getId('owner-postal-code')" v-model="zip" maxlength="75" spellcheck="false" required
            @invalid.capture.prevent="elementRequiredHandler" />
    </div>
  <div class="element-container">
    <label :for="getId('owner-ssn')" class="form-label form-label-required">
      Last 4 digits of Social Security No.:
    </label>
    <InputMask v-model="ssn" mask="9999" :id="getId('owner-ssn')" maxlength="4" spellcheck="false" required
               @invalid.capture.prevent="elementRequiredHandler" class="ssn-last-4" />
  </div>
</template>


<script setup lang="ts">
import { ref, computed } from "vue"
import type { PropType } from "vue"
import { useApplicationForm } from "@/stores/applicationForm"
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import type { StateIdExtraction } from "@/helpers/interface/general"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import InputMask from "primevue/inputmask"
import InputText from 'primevue/inputtext'
import dayjs from "dayjs"
import PhoneField from "@/components/Shared/PhoneField.vue"
import FileUploader from "@/components/Shared/FileUploader.vue"

const name = defineModel<string | null>("name")
const birthdate: any = defineModel("birthdate")
if (!dayjs(birthdate.value).isValid()) birthdate.value = ""
const licenseNumber = defineModel<string | null>("licenseNumber")
const email = defineModel<string | null>("email")
const phone: any = defineModel("phone")
const addressLine: any = defineModel("addressLine")
const unitNumber: any = defineModel("unitNumber")
const city: any = defineModel("city")
const state: any = defineModel("state")
const zip: any = defineModel("zip")
const ssn: any = defineModel("ssn")

// models updated from ID text extraction (Owner 1 only)
const propertyAddressLine1 = defineModel("propertyAddressLine1")
const propertyCity = defineModel("propertyCity")
const propertyState = defineModel("propertyState")
const propertyPostalCode = defineModel("propertyPostalCode")

const storeApplicationForm = useApplicationForm()
const props = defineProps({
	ownerNumber: {
		type: Number,
		required: true
	},
	fileInputChangeHandler: {
		type: Function as PropType<(e: Event, isDriversLicense: boolean | undefined) => void>,
		required: true
	},
	elementRequiredHandler: {
		type: Function as PropType<(e: Event) => void>,
		required: true
	},
	acceptFileTypes: {
		type: String,
		required: true
	},
	maxDateString: {
		type: String,
		required: true
	},
	requireDriversLicenseDefault: {
		type: Boolean,
		default: false
	},
	requireBirthdate: {
		type: Boolean,
		default: false
	},
	instanceKey: {
		type: String,
		required: true,
	},
	customerID: {
		type: String,
		required: true,
	},
	api_key: {
		type: String,
		required: true,
	}
})

const instanceKey = ref(props.instanceKey)
const getId = (text: string) => (`property-owner-${text}-${props.ownerNumber}`)
const getRequiredClasses = (required: boolean) => ((required) ? ["form-label", "form-label-required"] : ["form-label"])
const isDriversLicenseRequired = computed(() => (props.ownerNumber === 1))
const showBirthDateWarning = computed(() => (storeApplicationForm.showBirthDateWarning()))


const populateFromTextExtraction = (data: StateIdExtraction) => {
	if (!ssn.value) {
		ssn.value = data.ssn || ""
	}
	if (!name.value) {
		name.value = `${data.first_name} ${data.last_name}`.trim()
	}
	if (!licenseNumber.value) {
		licenseNumber.value = data.document_number || ""
	}
	if (data.dob && !birthdate.value) {
		// ensure DOB is correctly converted from ISO string to Date
		birthdate.value = dayjs(data.dob).toDate()
	}
	if (!addressLine.value) {
		addressLine.value = data.address || ""
	}
	if (!city.value) {
		city.value = data.city || ""
	}
	if (!state.value) {
		state.value = data.state || ""
	}
	if (!zip.value) {
		zip.value = data.postal_code || ""
	}

	if (props.ownerNumber == 1) {
		if (data.address && !propertyAddressLine1.value) {
			propertyAddressLine1.value = data.address
		}
		if (data.city && !propertyCity.value) {
			propertyCity.value = data.city
		}
		if (data.state && !propertyState.value) {
			propertyState.value = data.state
		}
		if (data.postal_code && !propertyPostalCode.value) {
			propertyPostalCode.value = data.postal_code
		}
	}
}
const uploadEvent = async (event: Event) => {
	props.fileInputChangeHandler(event, true)

	const input = event.target as HTMLInputElement
	const file = input.files?.[0]
	if (!file) return

	// Uploading new File
	const id = input.getAttribute("id") || ""
	const completeUpload = await storeApplicationForm.uploadFile(
		instanceKey.value,
		file,
		props.customerID,
		"application_attachments",
		id,
		input,
		props.api_key
	)
	console.log({completeUpload})
	if (completeUpload?.meta.extraction) {
		populateFromTextExtraction(completeUpload.meta.extraction)
	}
}

defineExpose({
	populateFromTextExtraction,
	uploadEvent
})
</script>