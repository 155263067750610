<template>
    <div>
        <LoadingIcon v-if="isLoading" />
        <template v-else>

            <template v-if="filteredHistory.length">
                <div v-for="item in filteredHistory" :key="item.id">
                    <Card>
                        <template #title>
                            Last Action on {{ formatDate(item.created_at) }} by {{ getDisplayUserName(item.created_by) }}
                        </template>
                        <template #content>
                            <!-- nosemgrep -->
                            <div v-html="getDisplayDetails(item.update_details)"></div>
                        </template>
                    </Card>
                </div>
            </template>
            <template v-else>
                <p class="text-center">
                    There is no history for this parcel.
                </p>
            </template>
            

            <div class="mt-2 text-center">
                <a :href="`/candidate-details/${props.tru_id}`" target="_blank">
                    <span>View Full Details</span>
                    <i class="fa fa-external-link p-2" aria-hidden="true"></i>
                </a>
            </div>
        </template>
    </div>

</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import Card from "primevue/card"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import { useAPI } from "@/helpers/services/api"
import type { HistoryAPIResponse } from "@/helpers/interface/candidates"
import { formatDate, automationUsers, wrapTextWithParagraphs, sanitizeHTML } from "@/helpers/common"


const api = useAPI()

const isLoading = ref(true)
const historyData = ref([] as HistoryAPIResponse[])

const props = defineProps({
	tru_id: String
})


const fetchHistory = async () => {
	isLoading.value = true
	const response = await api.get(`/taxroll/history?tru_id=${props.tru_id}`)
	if (!response?.data || !response?.data.length) {
		historyData.value = []
	} else {
		historyData.value = response.data
	}
	isLoading.value = false
}

const filteredHistory = computed(() => {
	// exclude history created by system events
	const userActionHistory = historyData.value.filter(x => !automationUsers.includes(x.created_by))

	// limit the list to just the most recent item
	const output = userActionHistory.length ? [userActionHistory[0]] : []
	return output
})

const getDisplayUserName = (value: string) => {
	/* we should have a user name, but the ID will 
    be substituted if no record exists in the database.
    */
	if (!value || value.startsWith("auth0|"))
		return ""

	return value
}

const getDisplayDetails = (value: string) => {
	let output = wrapTextWithParagraphs(value)
	output = sanitizeHTML(output)
	return output
}


onMounted(async () => {
	fetchHistory()
})
</script>
