import type { StateIdExtraction } from "@/helpers/interface/general";

export enum OwnerType {
	Single = "single",
	Married = "married",
	Other = "other",
	Blank = ""
}

export enum ActiveDutyDocumentationType {
	MilitaryID = "Military ID",
	UtilityBill = "Utility Bill"
}

export interface AddressFieldsModel {
	address: string;
	unitNumber: string;
	city: string;
	state: string;
	postalCode: string;
	county?: string;
}

export interface ApplicationForm {
	appraisal_district_account_number: string;
	filing_late_application: boolean | null;
	start_year_of_exemption: number | null;
	exemption_general: boolean;
	exemption_disabled_person: boolean;
	exemption_age_65_plus: boolean;
	exemption_disabled_veteran: boolean;
	is_veteran_disability_permanent: boolean | null,
	exemption_surviving_spouse_armed_services: boolean;
	exemption_surviving_spouse_first_responder: boolean;
	exemption_donated_residence: boolean;
	percent_disability_rating: number | null;
	living_on_property: boolean | null;
	co_op_housing: boolean | null;
	co_op_housing_name: string | null;
	receiving_exemption_previous_residence: boolean | null;
	transferring_exemption_previous_residence: boolean | null;
	transferring_tax_limitation: boolean | null;
	previous_residence_address_line_1: string | null;
	previous_residence_unit_number: string | null;
	previous_residence_city: string | null;
	previous_residence_state: string | null;
	previous_residence_postal_code: string | null;
	previous_residence_county: string | null;
	property_owner_type_1: OwnerType;
	property_owner_name_1: string;
	property_owner_birthdate_1: Date | null;
	property_owner_id_number_1: string;
	property_owner_percent_ownership_interest_1: number | null;
	property_owner_email_1: string;
	property_owner_phone_1: string;
	property_owner_name_2: string | null;
	property_owner_birthdate_2: Date | null;
	property_owner_id_number_2: string | null;
	property_owner_percent_ownership_interest_2: number | null;
	property_owner_email_2: string | null;
	property_owner_phone_2: string | null;
	deceased_spouse_deathdate: Date | null;
	deceased_spouse_name: string | null;
	affirm_id_property_address_match: boolean | null;
	applicant_mailing_address_different: boolean | null;
	property_date_acquired: Date | null;
	property_date_occupied: Date | null;
	property_address_line_1: string;
	property_unit_number: string | null;
	property_city: string;
	property_state: string;
	property_postal_code: string;
	property_legal_description: string;
	applicant_mail_line_1: string | null;
	applicant_mail_unit_number: string | null;
	applicant_mail_city: string | null;
	applicant_mail_state: string | null;
	applicant_mail_postal_code: string | null;
	applicant_identified_on_deed: boolean | null;
	court_filing_number: string | null;
	is_heir_property: boolean | null;
	heir_property_owners_occupy_property: boolean | null;
	property_manufactured_home: boolean | null;
	manufactured_home_make: string;
	manufactured_home_model: string;
	manufactured_home_id: string;
	property_produces_income: boolean | null;
	percent_producing_income: string;
	property_acres_occupied: number | null;
	waive_reason_resident_of_facility: boolean;
	facility_name_address: string; // | null
	waive_reason_participator_address_confidential_program: boolean;
	waive_reason_active_duty: boolean;
	active_duty_documentation_type: string | null;
	waive_reason_special_drivers_license: boolean;
	additional_info: string;
	other_owned_properties: string;
	authorized_print_name: string;
	title_authorization: string;
	signature_of_applicant: string;
	id_details_property_owner_1: StateIdExtraction | null;
	id_details_property_owner_2: StateIdExtraction | null;
}


export interface ApplicationDataGA {
	tru_id: string;

	// section a
	other_property_homestead_exemption_address: string | null;
	georgia_resident_and_immigration_status: boolean | null;
	legal_alien_registration_number: string | null;

	// property info
	parcel_number: string | null;
	property_date_purchased: string;
	property_from_whom_purchased: string;
	property_purchase_price: number;
	property_amount_lien: number;
	property_title_type: number,
	property_lien_due_to: number | null,
	property_kind_of_title_held: string,
	property_lot_size_or_acres: number,
	property_land_lot_numbers: number,
	property_land_district_number: number,
	property_book: string,
	property_page: string,

	// property address
	property_address_line_1: string;
	property_unit_number: string;
	property_city: string;
	property_state: string;
	property_postal_code: string;

	// residence
	is_primary_residence: boolean | null;
	residence_address_line_1: string;
	residence_unit_number: string;
	residence_city: string;
	residence_state: string;
	residence_postal_code: string;

	has_spouse_or_additional_owner: boolean | null,

	// owner 1
	property_owner_name_1: string;
	property_owner_address_line_1: string;
	property_owner_unit_number_1: string;
	property_owner_city_1: string;
	property_owner_state_1: string;
	property_owner_postal_code_1: string;
	property_owner_ssn_1: string;  // last 4 digits of SSN
	property_owner_birthdate_1: string;
	property_owner_phone_1: string;
	property_owner_email_1: string;
	property_owner_id_number_1: string | null,
	property_owner_car_registration_county_1: string;
	property_owner_voter_registration_county_1: string;

	// owner 1 continued
	is_resident_citizen_or_authorized: boolean | null;  // not required to answer
	legal_alien_registration: string;

	// owner 2
	property_owner_name_2: string | null;
	property_owner_address_line_2: string | null;
	property_owner_unit_number_2: string | null;
	property_owner_city_2: string | null;
	property_owner_state_2: string | null;
	property_owner_postal_code_2: string | null;
	property_owner_ssn_2: string | null;  // last 4 digits of SSN
	property_owner_birthdate_2: string | null;
	property_owner_phone_2: string | null;
	property_owner_email_2: string | null;
	property_owner_id_number_2: string | null,
	property_owner_voter_registration_county_2: string | null;
	military_service_home_of_record: string | null;
	receiving_homestead_exemption_elsewhere: boolean | null;
	location_of_homestead_exemption: string | null;

	// owner continued
	is_applicant_100_percent_disabled: boolean | null;
	is_applicant_100_percent_disabled_file: string | null;
	is_surviving_spouse_of_service_member: boolean | null;
	is_surviving_spouse_of_service_member_file: string | null;
	is_surviving_spouse_of_first_responder: boolean | null;
	is_surviving_spouse_of_first_responder_file: string | null;
	is_disabled_since_jan1_and_below_income_threshold: boolean | null;
	is_disabled_since_jan1_and_below_income_threshold_file: string | null;
	age_70_or_older_as_of_jan_1: boolean | null;
	age_70_or_older_as_of_jan_1_file: string | null;
	age_65_or_older_and_income_limit_10280: boolean | null;
	age_65_or_older_and_income_limit_10280_file: string | null;
	age_62_or_older_and_income_limit_25000: boolean | null;
	age_62_or_older_and_income_limit_25000_file: string | null;
	disability_and_income_below_25000_as_of_jan_1: boolean | null;
	disability_and_income_below_25000_as_of_jan_1_file: string | null;
	disability_and_income_below_25000_as_of_jan_1_file_2: string | null;

	is_100_percent_disabled_veteran_or_surviving_spouse: boolean | null;
	is_surviving_spouse_of_kia_firefighter_or_officer: boolean | null;

	// property continued
	is_property_used_for_business: boolean | null;
	property_business_use_description: string | null;
	is_property_rented: boolean | null;
	property_part_rented: string | null;

	// signature & date
	signature_of_applicant: string;
	signature_date: string;

	sectionC1_income_tax_year: number | null,
	sectionC1: ApplicationDataGAC1[] | null;

	sectionC2_income_tax_year: number | null,
	sectionC2: ApplicationDataGAC2[] | null;
	sectionC2_gross_income_total: number | null;
	sworn_date: string | null;
}

export interface ApplicationDataGAC1 {
	description: string;
	applicant1: number;
	applicant2: number;
}

export interface ApplicationDataGAC2 {
	description: string;
	name: string;
	value: number;
}

export interface ApplicationDataHI {
    tru_id: string;

    // owner info
    owner_name: string;
    owner_ssn: string;
    owner_dob: Date | null;
    has_owner_2: boolean | null;
    owner_2_name: string | null;
    owner_2_ssn: string | null;
    owner_2_dob: Date | null;
    owner_email: string;
    owner_phone: string;
    owner_alt_phone: string;

    // property info
    parcel_number: string | null;
    property_address_line_1: string;
    property_unit_number: string;
    property_city: string;
    property_state: string;
    property_postal_code: string;

    // mail info
    diff_mail: boolean | null;
    mail_address_line_1: string | null;
    mail_unit_number: string | null;
    mail_city: string | null;
    mail_state: string | null;
    mail_postal_code: string | null;


    // commercial status
    unit_count: number;
    owner_count: number;
    owners_per_unit: string | null;
    is_commerical: boolean | null
    commercial_area: number | null;
    owner_days: number | null;
    short_term_rental_days: number | null;
    long_term_rental_days: number | null;
    other_days: number | null;
    vacant_days: number | null;

    // secondary exemption
    alternate_exemption: boolean | null;
    alternate_exemption_address: string | null;

    // seperated owners
    has_separated_spouse: boolean | null;
    spouse_name: string | null;
    spouse_address: string | null;

    // trust ownership
    is_trust: boolean | null;

    // signature & date
    signature_of_applicant: string;
    signature_date: Date | null;
}

export interface NamForm {
	id: string;
	customer_id: string;
	fips_code: string;
	name: string;
	state: string;
	api_key: string;
}

export interface ApplicationDataFL {
	applying_for: boolean | null;
	// Applicant Information
	applicant_first_name: string;
	applicant_middle_name?: string;
	applicant_last_name: string;
	applicant_suffix?: string;
	applicant_sss_no?: string;
	applicant_imigration_no?: string;
	applicant_dob: string; // YYYY-MM-DD
	applicant_percent_ownership: number | null;
	applicant_date_permanent_residency: string; // YYYY-MM-DD
	applicant_email: string;
	applicant_daytime_phone?: string;
	applicant_claim_residency: boolean | null;
	applicant_marital_status: "single" | "married" | "divorced" | "widowed" | null;
	applicant_add_coapplicant: boolean | null;
	applicant_add_spouse?: boolean | null;
	applicant_is_us_citizen: boolean | null;

	// Co-Applicant Information
	coapplicant_first_name?: string;
	coapplicant_middle_name?: string;
	coapplicant_last_name?: string;
	coapplicant_suffix?: string;
	coapplicant_sss_no?: string;
	coapplicant_imigration_no?: string;
	coapplicant_dob?: string; // YYYY-MM-DD
	coapplicant_percent_ownership?: number | null;
	coapplicant_date_permanent_residency?: string; // YYYY-MM-DD
	coapplicant_email?: string;
	coapplicant_daytime_phone?: string;
	coapplicant_claim_residency?: boolean | null;
	coapplicant_is_us_citizen: boolean | null;

	// Homestead Address
	street: string;
	unit?: string;
	state: string;
	city: string;
	postal_code: string;
	mailing_address_different: boolean | null;
	mailing_street?: string;
	mailing_unit?: string;
	mailing_city?: string;
	mailing_state?: string;
	mailing_postal_code?: string;
	homestead_legal_description: string;

	// Proof of Residency
	termination_residency?: boolean | null;
	termination_date?: string; // YYYY-MM-DD
	relinquish_license?: boolean | null;
	fl_id?: boolean | null;
	id_number?: string;
	id_issue_date?: string; // YYYY-MM-DD
	fl_tag?: boolean | null;
	vehicle_tag_number?: string;
	fl_voter_registration?: boolean | null;
	voter_registration_number?: string;
	voter_registration: string;
	registration_date?: string; // YYYY-MM-DD
	domicile_declaration: string; // YYYY-MM-DD
	current_employer?: string;
	irs_address?: string;
	school_location?: string;
	bank_statement?: boolean | null;
	utilities_proof?: boolean | null;
	other_owners?: string;

	deed_type?: string;
	deed_date?: string; // YYYY-MM-DD
	deed_book?: string;
	deed_page?: string;
	deed_recorded_date?: string; // YYYY-MM-DD
	deed_instrument_number?: string;
	applicant_receive_exemption_last_year: boolean | null;

	// Co-Applicant Proof of Residency
	termination_residency_alt?: boolean | null;
	termination_date_alt?: string; // YYYY-MM-DD
	relinquish_license_alt?: boolean | null;
	fl_tag_alt?: boolean | null;
	fl_voter_registration_alt?: boolean | null;
	voter_registration_number_alt?: string;
	registration_date_alt?: string; // YYYY-MM-DD
	declaration_domicile: string; // YYYY-MM-DD
	other_owners_alt?: string;
	bank_statement_alt?: boolean | null;
	utilities_proof_alt?: boolean | null;
	coapplicant_fl_id?: boolean | null;
	prev_address_street?: string;
	prev_address_unit?: string;
	prev_address_city?: string;
	prev_address_state?: string;
	prev_address_postal_code?: string;
	coapplicant_id_number?: string;
	coapplicant_id_issue_date?: string;

	// Additional Exemptions
	age_limited_income: boolean | null;
	dr_501sc_uploaded?: boolean | null;
	age_limited_income_25: boolean | null;
	dr_501sc_25_uploaded?: boolean | null;
	household_income_25?: number | null;
	age_65_limited_income?: boolean | null;
	proof_of_age_uploaded?: boolean | null;
	household_income?: number | null;
	permanent_resident_25_years?: boolean | null;
	widowed?: boolean | null;
	blind?: boolean | null;
	totally_disabled?: boolean | null;
	quadriplegic?: boolean | null;
	certain_disabilities?: boolean | null;
	first_responder_disabled?: boolean | null;
	first_responder_surviving_spouse?: boolean | null;
	disabled_veteran_discount?: boolean | null;
	disabled_veteran_10_percent?: boolean | null;
	disabled_veteran_wheelchair?: boolean | null;
	disabled_veteran_permanently?: boolean | null;
	surviving_spouse_veteran?: boolean | null;
	property_transfer_request?: boolean | null;
	previous_homestead_street?: string;
	previous_homestead_unit?: string;
	previous_homestead_city?: string;
	previous_homestead_state?: string;
	previous_homestead_postal_code?: string;
	previous_homestead_parcel_id?: string;
	previous_homestead_county?: string;
	date_sold?: string; // YYYY-MM-DD

	co_applicants_owned_and_lived?: string;
	previous_owners_not_moving?: string;
	any_owners_stayed?:  boolean | null;

	parcel_id_number?: string;
	tax_year?: string | null;
	limited_disability: boolean | null;
	dr_416_proof_uploaded?: boolean | null;
	dva_letter_uploaded?: boolean | null;
	household_income_disabled?: number | null;
	first_responder: boolean | null;
	first_responder_proof_uploaded?: boolean | null;
	surviving_spouse_responder: boolean | null;
	responder_death_proof_uploaded?: boolean | null;
	disabled_veteran: boolean | null;
	disabled_veteran_proof_age_uploaded?: boolean | null;
	disabled_veteran_dr501dv_uploaded?: boolean | null;
	veteran_disabled_10: boolean | null;
	veteran_disabled_10_proof_uploaded?: boolean | null;
	service_disabled_veteran: boolean | null;
	service_disabled_veteran_proof_uploaded?: boolean | null;
	prorated_refund: boolean | null;
	parcel_number?: string;
	selected_county?: string;
	veteran_death_proof_uploaded?: boolean | null;
	veteran_prorated_refund: boolean | null;
	veteran_parcel_number?: string;
	veteran_selected_county?: string;
	other_specify: boolean | null;
	other_text?: string;
	homestead_transfer: boolean | null;
	homestead_street?: string;
	homestead_parcel_id?: string;
	homestead_county?: string;
	disabled: boolean | null;
	
	// signature & date
	signature_of_applicant: string;
	signature_of_coapplicant: string;
	signature_date_applicant: string;
	signature_date_coapplicant: string;
}