<template>
    <main class="p-2">
        <component :is="selectedPageComponent" />
    </main>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import AdminConfigUsers from './AdminConfigUsers.vue';
import AdminWorkflowMailModule from './AdminWorkflowMailModule.vue';
import AdminConfigMailTemplates from './AdminConfigMailTemplates.vue';
import AdminConfigLabels from './AdminConfigLabels.vue';
import AdminUnclaimedExmptDataRequest from './AdminUnclaimedExmptDataRequest.vue';
import AdminDownloadUnclaimedList from './AdminDownloadUnclaimedList.vue';
import AdminConfigInternalSettings from './AdminConfigInternalSettings.vue';
import AdminConfigSettings from './AdminConfigSettings.vue';
import AdminUserPreference from './AdminUserPreference.vue';
import AdminConfigOutreachCodes from './AdminConfigOutreachCodes.vue';
import ConfigScheduledTasks from './ConfigScheduledTasks.vue';

const props = defineProps({
	page: {
		type: String,
		required: true
	}
})

const pageComponents: any = {
	"mail-module": AdminWorkflowMailModule,
	"labels": AdminConfigLabels,
	"mail-templates": AdminConfigMailTemplates,
	"users": AdminConfigUsers,
	"internal-settings": AdminConfigInternalSettings,
	"settings": AdminConfigSettings,
	"dl-unclaimed-list": AdminDownloadUnclaimedList,
	"unclaimed-exmpt-data-request": AdminUnclaimedExmptDataRequest,
	"user-preference": AdminUserPreference,
	"outreach-codes": AdminConfigOutreachCodes,
	"scheduled-tasks": ConfigScheduledTasks
}
const selectedPageComponent = computed(() => pageComponents[props.page]);

</script>

<style>
@import "@/assets/admin-page.css";
@import "tabulator-tables";
</style>
