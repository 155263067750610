<template>
	<ApplicationSection title="Homestead Address">
		<div class="element-container" v-for="field in homesteadFields" :key="`homestead-${field.id}`">
			<label :for="`homestead-${field.id}`" class="form-label" :class="{ 'form-label-required': field.required }">
				{{ field.label }}
			</label>
			<component :id="`homestead-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required" @invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label class="form-label form-label-required">
				Is the primary applicant's mailing address different from the homestead address?
			</label>
			<CustomRadioGroup groupId="mailing-address-different" v-model="formFields.mailing_address_different"
				required :elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'mailing-yes', name: 'mailing-address-different', label: 'Yes', value: true },
					{ id: 'mailing-no', name: 'mailing-address-different', label: 'No', value: false }
				]" />

		</div>
	</ApplicationSection>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed } from 'vue'
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"

const {
	elementRequiredHandler
} = defineProps<{ elementRequiredHandler: <T extends Event>(e: T) => void; }>()

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

interface HomesteadField {
	id: string;
	label: string;
	component: any;
	model: keyof typeof formFields.value;
	required: boolean;
	props?: Record<string, any>;
}

const homesteadFields: HomesteadField[] = [
	{ id: 'street', label: 'Street', component: InputText, model: 'street', required: true },
	{ id: 'unit', label: 'Unit', component: InputText, model: 'unit', required: false },
	{ id: 'city', label: 'City', component: InputText, model: 'city', required: true },
	{ id: 'state', label: 'State', component: InputText, model: 'state', required: false },
	{ id: 'postal-code', label: 'Postal Code', component: InputText, model: 'postal_code', required: true },
	{ id: 'homestead-legal-description', label: 'Homestead Legal Description', component: InputText, model: 'homestead_legal_description', required: false }
]
</script>
