<template>
    <div class="position-relative">
        <label class="config-header">TrueRoll Mail Module</label>
        <div class="actions-description">
            The TrueRoll Mail Module saves you time by helping you batch, print, and track mail you need to send to
            exemption recipients using your own mail templates. TrueRoll Mail Module maps to your business process by
            allowing you to send questionnaires, denial letters, re-application forms, etc. to Candidates, and set
            expiration dates so you know when to take the net action.
            <br>
            <br>
            <a href="https://docs.google.com/document/d/10__Pm-EskdgcF5rICR-h6XJZfS--jJ_TvBAlyMhNvOQ/edit?usp=sharing"
                target="blank">Click here to view the Step-by-step User Guide.</a>
        </div>
        <div class="card-body">
            <ul class="nav nav-tabs" style="background: #e5e5e5">
                <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
                    <a class="nav-link" :class="{ 'mail-module-active-tab': activeTab === tab.id }"
                        @click="changeTab(tab.id)">
                        {{ tab.title }}
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane" @click="activeTab = 'notMailed'" :class="{ active: activeTab === 'notMailed' }">
                    <AdminWorkflowMailModuleNotMailed :table-columns="tableColumns" :base-url="baseURL"
                        :mail-template-list="mailTemplateList" :candidate-queues="candidateQueues"
                        :toggleQueueSelection="toggleQueueSelection" />
                </div>
                <div class="tab-pane" @click="activeTab = 'allMailers'" :class="{ active: activeTab === 'allMailers' }">
                    <AdminWorkflowMailModuleAll :table-columns="tableColumns" :base-url="baseURL"
                        :mail-template-list="mailTemplateList" :candidate-queues="candidateQueues"
                        :mail-status="mailStatus" :toggleQueueSelection="toggleQueueSelection" />
                </div>
                <div class="tab-pane" @click="activeTab = 'scanReplies'"
                    :class="{ active: activeTab === 'scanReplies' }">
                    scanReplies
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { ref, onMounted, computed } from "vue"
import { useAPI } from "@/helpers/services/api";
import AdminWorkflowMailModuleAll from "./AdminWorkflowMailModuleAll.vue";
import AdminWorkflowMailModuleNotMailed from "./AdminWorkflowMailModuleNotMailed.vue";
import type { ColumnDefinition, CellComponent } from 'tabulator-tables'
import type { MailModuleFilter } from "@/helpers/interface/admin-page";
import { blankIf, ucfirst, validateUserPermission, getApiErrorMessage } from "@/helpers/common";
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios"

const api = useAPI()
const activeTab = ref('notMailed')
const tabs: { id: string, title: string }[] = [
	{ id: "notMailed", title: "Not Mailed" },
	{ id: "allMailers", title: "All Mailers" },
	// { id: "scanReplies", title: "Scan Replies" }, // REMOVED - its not working yet
]
let changeTab = (tabId: string) => activeTab.value = tabId;
const baseURL = import.meta.env.VITE_DEV_API_URL
const mailTemplateList = ref<{ name: string, id: number }[]>([])
const candidateQueues = ["monitor", "inbox", "questionnaire", "unqualified", "snooze", "archive"]
const mailStatus = [
	{
		label: "Created",
		value: "created"
	},
	{
		label: "Mailed",
		value: "mailed"
	},
	{
		label: "Completed",
		value: "completed"
	},
	{
		label: "Expired",
		value: "expired"
	},
	{
		label: "Mailing Issues",
		value: "mailing_issues"
	},
	{
		label: "Collections",
		value: "collections"
	}
]
const hasPermissionToUpdatePostalContact = computed(() => validateUserPermission("update", "postal_contact_workflows"))

const toggleQueueSelection = (queue: string, filterField: any) => {
	const index = filterField.candidate_queue.indexOf(queue);
	if (index === -1) filterField.candidate_queue.push(queue);
	else filterField.candidate_queue.splice(index, 1);
	return filterField.candidate_queue
};

const tableColumns: ColumnDefinition[] = [
	{
		title: "",
		formatter: "rowSelection",
		titleFormatter: "rowSelection",
		headerSort: false,
		visible: hasPermissionToUpdatePostalContact.value
	},
	{
		title: "Candidate",
		field: "name",
		width: "7vh",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as MailModuleFilter
			const tru_id = data.tru_id
			return `<a href='/candidate-details/${tru_id}' target='_blank'>Link</a>`
		}
	},
	{
		title: "Parcel",
		field: "parcel_number",
		width: "7vh",
		headerSort: false
	},
	{
		title: "Template",
		field: "mail_template",
		width: "7vh",
		headerSort: false,
		formatter: (cell: CellComponent) => (`<label class='text-wrap'>${cell.getValue()}</label>`)
	},
	{
		title: "Queue",
		field: "candidate_queue",
		width: "8vh",
		headerSort: false,
		formatter: (cell: CellComponent) => ucfirst(cell.getValue())
	},
	{
		title: "Mail To",
		field: "mail_to",
		width: "7vh",
		headerSort: false,
		formatter: (cell: CellComponent) => (`<label class='text-wrap'>${blankIf(cell.getValue())}</label>`)
	},
	{
		title: "Mail 1 & 2",
		field: "mail_address",
		width: "8vh",
		headerSort: false,
		formatter: (cell: CellComponent) => {
			const data = cell.getData() as { mail_address: string, mail_address2: string }
			const mail_address = data.mail_address
			const mail_address2 = data.mail_address2
			let mail: string = ""

			if (mail_address) mail += `<label class='text-wrap'>${mail_address}</label>`
			if (mail_address && mail_address2) mail += " & "
			if (mail_address2) mail += `<label class='text-wrap'>${mail_address2}</label>`
			return mail
		}
	},
	{
		title: "Situs",
		field: "situs_address",
		width: "8vh",
		headerSort: false,
		formatter: (cell: CellComponent) => (`<label class='text-wrap'>${cell.getValue()}</label>`)
	},
	{
		title: "Mailer Status",
		field: "mail_status",
		width: "8vh",
		headerSort: false,
		formatter: (cell: CellComponent) => ucfirst(cell.getValue())
	},
	{
		title: "Created On",
		field: "created_at",
		width: "8vh",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			const data = cell.getValue()
			return (data) ? dayjs(data).format("M/D/YYYY") : ""
		}
	},
	{
		title: "Mailed On",
		field: "mailed_at",
		width: "8vh",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			const data = cell.getValue()
			return (data) ? dayjs(data).format("M/D/YYYY") : ""
		}
	},
	{
		title: "Note",
		field: "note_for_template",
		width: "8vh",
		headerSort: false
	},
	{
		title: "ID",
		field: "mail_id",
		width: "8vh",
		headerSort: false
	}
]

const fetchMailTemplatesList = async () => {
	try {
		const response = await api.post("/mailing/templates-list/")
		const data = (response.data?.data || []) as { name: string, id: number }[]
		mailTemplateList.value = data.map(item => ({ name: item.name, id: item.id }))
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
}

onMounted(() => {
	fetchMailTemplatesList()
})
</script>

<style>
@import "@/assets/admin-page.css";
</style>