import { computed, ref } from "vue"
import { defineStore } from "pinia";
import dayjs from "dayjs";
import {
	type ApplicationDataGA,
	type ApplicationDataHI,
	type ApplicationDataFL,
	type ApplicationForm,
	OwnerType
} from "@/helpers/interface/applicationForm"
import axios from "axios"
import { getApiErrorMessage } from "@/helpers/common";
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import type { Upload } from "@/helpers/interface/uploads";
import type { StateIdExtraction } from "@/helpers/interface/general";
import type { FormUploadFilesIDs, StorageKeys } from "@/helpers/interface/public";

export const useApplicationForm = defineStore("applicationForm", () => {
	const currentState: keyof StorageKeys = (sessionStorage.getItem("state") || "tx") as keyof StorageKeys
	const today = dayjs()

	const initialFormDataTX: ApplicationForm = {
		appraisal_district_account_number: "",
		filing_late_application: null,
		start_year_of_exemption: null,
		exemption_general: false,
		exemption_disabled_person: false,
		exemption_age_65_plus: false,
		exemption_disabled_veteran: false,
		is_veteran_disability_permanent: null,
		exemption_surviving_spouse_armed_services: false,
		exemption_surviving_spouse_first_responder: false,
		exemption_donated_residence: false,
		percent_disability_rating: null,
		living_on_property: null,
		co_op_housing: null,
		co_op_housing_name: "",
		receiving_exemption_previous_residence: null,
		transferring_exemption_previous_residence: null,
		transferring_tax_limitation: null,
		previous_residence_address_line_1: null,
		previous_residence_unit_number: null,
		previous_residence_city: null,
		previous_residence_state: null,
		previous_residence_postal_code: null,
		previous_residence_county: null,
		property_owner_type_1: OwnerType.Blank,
		property_owner_name_1: "",
		property_owner_birthdate_1: null,
		property_owner_id_number_1: "",
		property_owner_percent_ownership_interest_1: null,
		property_owner_email_1: "",
		property_owner_phone_1: "",
		property_owner_name_2: null,
		property_owner_birthdate_2: null,
		property_owner_id_number_2: null,
		property_owner_percent_ownership_interest_2: null,
		property_owner_email_2: null,
		property_owner_phone_2: null,
		deceased_spouse_deathdate: null,
		deceased_spouse_name: null,
		affirm_id_property_address_match: null,
		applicant_mailing_address_different: null,
		property_date_acquired: null,
		property_date_occupied: null,
		property_address_line_1: "",
		property_unit_number: null,
		property_city: "",
		property_state: "",
		property_postal_code: "",
		property_legal_description: "",
		applicant_mail_line_1: null,
		applicant_mail_unit_number: null,
		applicant_mail_city: null,
		applicant_mail_state: null,
		applicant_mail_postal_code: null,
		applicant_identified_on_deed: null,
		court_filing_number: null,
		is_heir_property: null,
		heir_property_owners_occupy_property: null,
		property_manufactured_home: null,
		manufactured_home_make: "",
		manufactured_home_model: "",
		manufactured_home_id: "",
		property_produces_income: null,
		percent_producing_income: "",
		property_acres_occupied: null,
		waive_reason_resident_of_facility: false,
		facility_name_address: "",
		waive_reason_participator_address_confidential_program: false,
		waive_reason_active_duty: false,
		active_duty_documentation_type: "",
		waive_reason_special_drivers_license: false,
		additional_info: "",
		other_owned_properties: "",
		authorized_print_name: "",
		title_authorization: "",
		signature_of_applicant: "",
		id_details_property_owner_1: null,
		id_details_property_owner_2: null,
	}

	const gaDefaultSectionC1 = [
		{
			description: 'Line 1: Total Income from Public or Private retirement, disability or pension system',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 2: Total Income from Social Security',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 3: Total Income from both retirement and Social Security (Line 1 plus Line 2)',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 4: Maximum Social Security amount (from Tax Receiver)',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 5: Retirement Income over maximum Social Security (Line 3 less Line 4) - If less than 0, use 0',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 6: Other income from all sources',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 7: Adjusted Income (Line 5 plus Line 6)',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 8: Standard or Itemized Deductions from Georgia Income Tax Return',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 9: Personal Exemption amount from Georgia Income Tax Return',
			applicant1: 0,
			applicant2: 0
		},
		{
			description: 'Line 10: Net Income (Line 7 less Lines 8 and 9)',
			applicant1: 0,
			applicant2: 0
		}
	]

	const gaDefaultSectionC2 = [
		{
			description: "Line 1",
			name: "",
			value: 0
		},
		{
			description: "Line 2",
			name: "",
			value: 0
		},
		{
			description: "Line 3",
			name: "",
			value: 0
		},
		{
			description: "Line 4",
			name: "",
			value: 0
		},
		{
			description: "Line 5",
			name: "",
			value: 0
		},
		{
			description: "Line 6",
			name: "",
			value: 0
		},
		{
			description: "Line 7",
			name: "",
			value: 0
		}
	]

	const initialFormDataGA: ApplicationDataGA = {
		tru_id: "",

		// section a
		other_property_homestead_exemption_address: null,
		georgia_resident_and_immigration_status: null,
		legal_alien_registration_number: null,

		// property info
		parcel_number: null,
		property_date_purchased: "",
		property_from_whom_purchased: "",
		property_purchase_price: 0,
		property_amount_lien: 0,
		property_title_type: 0,
		property_lien_due_to: null,
		property_kind_of_title_held: "",
		property_lot_size_or_acres: 0,
		property_land_lot_numbers: 0,
		property_land_district_number: 0,
		property_book: "",
		property_page: "",

		// property address
		property_address_line_1: "",
		property_unit_number: "",
		property_city: "",
		property_state: "",
		property_postal_code: "",

		// residence
		is_primary_residence: null,
		residence_address_line_1: "",
		residence_unit_number: "",
		residence_city: "",
		residence_state: "",
		residence_postal_code: "",

		// owner 1
		property_owner_name_1: "",
		property_owner_address_line_1: "",
		property_owner_unit_number_1: "",
		property_owner_city_1: "",
		property_owner_state_1: "",
		property_owner_postal_code_1: "",
		property_owner_ssn_1: "",
		property_owner_birthdate_1: "",
		property_owner_phone_1: "",
		property_owner_email_1: "",
		property_owner_id_number_1: "",
		property_owner_car_registration_county_1: "",
		property_owner_voter_registration_county_1: "",

		// owner 1 continued
		is_resident_citizen_or_authorized: null,
		legal_alien_registration: "",

		// owner 2
		property_owner_name_2: null,
		property_owner_address_line_2: null,
		property_owner_unit_number_2: null,
		property_owner_city_2: null,
		property_owner_state_2: null,
		property_owner_postal_code_2: null,
		property_owner_ssn_2: null,
		property_owner_birthdate_2: null,
		property_owner_phone_2: null,
		property_owner_email_2: null,
		property_owner_id_number_2: null,
		property_owner_voter_registration_county_2: null,
		military_service_home_of_record: null,
		receiving_homestead_exemption_elsewhere: null,
		location_of_homestead_exemption: null,
		has_spouse_or_additional_owner: null,

		// owner continued
		is_applicant_100_percent_disabled: null,
		is_applicant_100_percent_disabled_file: null,
		is_surviving_spouse_of_service_member: null,
		is_surviving_spouse_of_service_member_file: null,
		is_surviving_spouse_of_first_responder: null,
		is_surviving_spouse_of_first_responder_file: null,
		is_disabled_since_jan1_and_below_income_threshold: null,
		is_disabled_since_jan1_and_below_income_threshold_file: null,
		age_70_or_older_as_of_jan_1: null,
		age_70_or_older_as_of_jan_1_file: null,
		age_65_or_older_and_income_limit_10280: null,
		age_65_or_older_and_income_limit_10280_file: null,
		age_62_or_older_and_income_limit_25000: null,
		age_62_or_older_and_income_limit_25000_file: null,

		is_100_percent_disabled_veteran_or_surviving_spouse: null,
		is_surviving_spouse_of_kia_firefighter_or_officer: null,
		disability_and_income_below_25000_as_of_jan_1: null,
		disability_and_income_below_25000_as_of_jan_1_file: null,
		disability_and_income_below_25000_as_of_jan_1_file_2: null,

		// property continued
		is_property_used_for_business: null,
		property_business_use_description: null,
		is_property_rented: null,
		property_part_rented: "",

		// signature & date
		signature_of_applicant: "",
		signature_date: "",

		sectionC1_income_tax_year: null,
		sectionC1: gaDefaultSectionC1,

		sectionC2_income_tax_year: null,
		sectionC2: gaDefaultSectionC2,
		sectionC2_gross_income_total: null,

		sworn_date: null
	}

	const initialFormDataHI: ApplicationDataHI = {
		tru_id: "",

		// owner info
		owner_name: "",
		owner_ssn: "",
		owner_dob: null,
		owner_email: "",
		owner_phone: "",
		owner_alt_phone: "",
		has_owner_2: null,
		owner_2_name: null,
		owner_2_ssn: null,
		owner_2_dob: null,

		// property info
		parcel_number: "",
		property_address_line_1: "",
		property_unit_number: "",
		property_city: "",
		property_state: "",
		property_postal_code: "",

		// mail info
		diff_mail: null,
		mail_address_line_1: null,
		mail_unit_number: null,
		mail_city: null,
		mail_state: null,
		mail_postal_code: null,

		// commercial status
		unit_count: 1,
		owner_count: 1,
		owners_per_unit: null,
		is_commerical: null,
		commercial_area: null,
		owner_days: null,
		short_term_rental_days: null,
		long_term_rental_days: null,
		other_days: null,
		vacant_days: null,

		// secondary exemption
		alternate_exemption: null,
		alternate_exemption_address: null,

		// seperated owners
		has_separated_spouse: null,
		spouse_name: null,
		spouse_address: null,

		// trust ownership
		is_trust: null,

		// signature & date
		signature_of_applicant: "",
		signature_date: null,
	}

	const initialFormDataFL: ApplicationDataFL = {
		applying_for: null,
		// Applicant Information
		applicant_first_name: "",
		applicant_middle_name: "",
		applicant_last_name: "",
		applicant_suffix: "",
		applicant_sss_no: "",
		applicant_imigration_no: "",
		applicant_dob: "",
		applicant_percent_ownership: null,
		applicant_date_permanent_residency: "",
		applicant_email: "",
		applicant_daytime_phone: "",
		applicant_claim_residency: null,
		applicant_marital_status: null,
		applicant_add_coapplicant: null,
		applicant_add_spouse: null,
		applicant_is_us_citizen: null,

		// Co-Applicant Information
		coapplicant_first_name: "",
		coapplicant_middle_name: "",
		coapplicant_last_name: "",
		coapplicant_suffix: "",
		coapplicant_sss_no: "",
		coapplicant_imigration_no: "",
		coapplicant_dob: "",
		coapplicant_percent_ownership: null,
		coapplicant_date_permanent_residency: "",
		coapplicant_email: "",
		coapplicant_daytime_phone: "",
		coapplicant_claim_residency: null,
		coapplicant_is_us_citizen: null,

		// Homestead Address
		street: "",
		unit: "",
		state: "",
		city: "",
		postal_code: "",
		mailing_address_different: null,
		mailing_street: "",
		mailing_unit: "",
		mailing_city: "",
		mailing_state: "",
		mailing_postal_code: "",
		homestead_legal_description: "",

		// Proof of Residency
		termination_residency: null,
		termination_date: "",
		relinquish_license: null,
		fl_id: null,
		id_number: "",
		id_issue_date: "",
		fl_tag: null,
		vehicle_tag_number: "",
		fl_voter_registration: null,
		voter_registration_number: "",
		voter_registration: "",
		registration_date: "",
		domicile_declaration: "",
		current_employer: "",
		irs_address: "",
		school_location: "",
		bank_statement: null,
		utilities_proof: null,

		deed_type: "",
		deed_date: "",
		deed_book: "",
		deed_page: "",
		deed_recorded_date: "",
		deed_instrument_number: "",
		applicant_receive_exemption_last_year: null,

		// Co-Applicant Proof of Residency
		termination_residency_alt: null,
		termination_date_alt: "",
		relinquish_license_alt: null,
		fl_tag_alt: null,
		fl_voter_registration_alt: null,
		voter_registration_number_alt: "",
		registration_date_alt: "",
		declaration_domicile: "",
		other_owners: "",
		coapplicant_fl_id: null,
		prev_address_street: "",
		prev_address_unit: "",
		prev_address_city: "",
		prev_address_state: "",
		prev_address_postal_code: "",
		coapplicant_id_number: "",
		coapplicant_id_issue_date: "",

		// Additional Exemptions
		age_limited_income: null,
		dr_501sc_uploaded: null,
		age_limited_income_25: null,
		dr_501sc_25_uploaded: null,
		household_income_25: null,
		age_65_limited_income: null,
		proof_of_age_uploaded: null,
		household_income: null,
		permanent_resident_25_years: null,
		widowed: null,
		blind: null,
		totally_disabled: null,
		quadriplegic: null,
		certain_disabilities: null,
		first_responder_disabled: null,
		first_responder_surviving_spouse: null,
		disabled_veteran_discount: null,
		disabled_veteran_10_percent: null,
		disabled_veteran_wheelchair: null,
		disabled_veteran_permanently: null,
		surviving_spouse_veteran: null,
		property_transfer_request: null,
		previous_homestead_street: "",
		previous_homestead_unit: "",
		previous_homestead_city: "",
		previous_homestead_state: "",
		previous_homestead_postal_code: "",
		previous_homestead_parcel_id: "",
		previous_homestead_county: "",
		date_sold: "",

		co_applicants_owned_and_lived: "",
		previous_owners_not_moving: "",
		any_owners_stayed: null,

		parcel_id_number: "",
		tax_year: null,
		limited_disability: null,
		dr_416_proof_uploaded: null,
		dva_letter_uploaded: null,
		household_income_disabled: null,
		first_responder: null,
		first_responder_proof_uploaded: null,
		surviving_spouse_responder: null,
		responder_death_proof_uploaded: null,
		disabled_veteran: null,
		disabled_veteran_proof_age_uploaded: null,
		disabled_veteran_dr501dv_uploaded: null,
		veteran_disabled_10: null,
		veteran_disabled_10_proof_uploaded: null,
		service_disabled_veteran: null,
		service_disabled_veteran_proof_uploaded: null,
		prorated_refund: null,
		parcel_number: "",
		selected_county: "",
		veteran_death_proof_uploaded: null,
		veteran_prorated_refund: null,
		veteran_parcel_number: "",
		veteran_selected_county: "",
		other_specify: null,
		other_text: "",
		homestead_transfer: null,
		homestead_street: "",
		homestead_parcel_id: "",
		homestead_county: "",
		disabled: null,

		signature_of_applicant: "",
		signature_of_coapplicant: "",
		signature_date_applicant: "",
		signature_date_coapplicant: "",
	}

	const TX_STATE_STORAGE_KEY = "myApplicationTx50114"
	const GA_STATE_STORAGE_KEY = "myApplicationGA1008"
	const HI_STATE_STORAGE_KEY = "myApplicationHI1971"
	const FL_STATE_STORAGE_KEY = "myApplicationFL501"

	const storageKeys: StorageKeys = {
		ga: GA_STATE_STORAGE_KEY,
		tx: TX_STATE_STORAGE_KEY,
		fl: FL_STATE_STORAGE_KEY,
		hi: HI_STATE_STORAGE_KEY,
	}
	const dateFields = [
		"property_owner_birthdate_1",
		"property_owner_birthdate_2",
		"deceased_spouse_deathdate",
		"property_date_acquired",
		"property_date_occupied"
	]
	const uploadFileIds = ref<FormUploadFilesIDs[]>([])

	try {
		const key = storageKeys[currentState]
		const jsonString = sessionStorage.getItem(key)
		if (jsonString) {
			// console.log("Restoring form state....")
			const parsedData = JSON.parse(jsonString, (key, value) => {
				if (dateFields.includes(key) && typeof value === "string") {
					return new Date(value);
				}
				return value;
			});
			if (currentState === "ga") {
				// nosemgrep formatting date field
				Object.assign(initialFormDataGA, parsedData)
			}
			else if (currentState === "tx") {
				// nosemgrep formatting date field
				Object.assign(initialFormDataTX, parsedData)
			}
			else if (currentState === "hi") {
				// nosemgrep formatting date field
				Object.assign(initialFormDataHI, parsedData)
			}
			else if (currentState === "fl") {
				// nosemgrep formatting date field
				Object.assign(initialFormDataFL, parsedData)
			}
		} else {
			// console.log("Initializing state with defaults...")
		}
	} catch (ex) {
		console.warn("Failed to restore state from storage", ex)
	}
	const txFields = ref<ApplicationForm>(initialFormDataTX)
	const gaFields = ref<ApplicationDataGA>(initialFormDataGA)
	const hiFields = ref<ApplicationDataHI>(initialFormDataHI)
	const flFields = ref<ApplicationDataFL>(initialFormDataFL)

	const formLoading = ref(false)
	const uploadState = ref<number>(0)
	const publicApi = useAPI({ authGuard: false })


	const getOrSetFormLoading = (value?: boolean) => {
		if (value !== undefined) formLoading.value = value
		return formLoading.value;
	}

	const showBirthDateWarning = (): boolean => {
		// Rules: age >= 65 AND 'person age 65 or older' is unchecked
		// then show warning
		const age = today.diff(txFields.value.property_owner_birthdate_1, "year")
		return (!txFields.value.exemption_age_65_plus && (age >= 65))
	}

	const extractText = async (applicationID: string, uploadID: string, api_key: string) => {
		const payload = {
			"tru_id": applicationID,
			"upload_id": uploadID
		}
		const options = {
			headers: {
				"Content-Type": "application/json",
				"X-Api-Key": api_key,
			}
		}
		try {
			const response = await axios.post("applications/extract_text", payload, options)
			return response ? response.data as StateIdExtraction : null
		} catch (ex) {
			return null;
		}
	}

	const uploadFile = async (instanceKey: string, file: File, customerID: string, itemID: string = "application_attachments", feedbackElementID: string = "attachments", input: HTMLInputElement | null = null, api_key: string | null = null): Promise<Upload | null> => {

		// Delete any previous uploaded file
		if (uploadFileIds.value.length) {
			const id = feedbackElementID
			const upload = uploadFileIds.value.filter((upload: FormUploadFilesIDs) => upload.id === id)[0]
			if (upload) {
				deletePrevUploadedFile(upload.uploadID)
				uploadFileIds.value = uploadFileIds.value.filter(
					(upload: FormUploadFilesIDs) => upload.id !== id
				)
				console.log("Successfully deleted the previously uploaded file")
			}
		}

		if (input && input.value.length <= 0) {
			uploadFileMessage(input, feedbackElementID, { message: "No file was selected.", status: 2 })
			return null
		}
		if (input) {
			uploadFileMessage(input, feedbackElementID, { message: "Loading", status: 0 })
		}
		const applicationID = instanceKey
		// 1 create upload - get put url
		// 2 put content
		// 3 mark upload as completed
		const resp = await publicApi.post('/uploads/', {
			tru_id: applicationID,
			customer_id: customerID,
			item_id: itemID,
			file_name: file.name,
			file_size: file.size,
			content_type: file.type,
			meta: {
				application_id: applicationID,
				...feedbackElementID ? { document_type: feedbackElementID } : {}
			}
		}
		)
		const upload: Upload = resp.data
		const uploadCfg = upload.upload_url!

		uploadState.value += 1
		const uplRes = await axios.request({
			...uploadCfg,
			data: file,
			validateStatus: () => true
		})
		
		if (uplRes.status !== 200) {
			const message = getApiErrorMessage({ response: uplRes } as any)
			toast.error(message)
			if (input) {
				uploadFileMessage(input, feedbackElementID, { message: message, status: 2 })
			}
			await deletePrevUploadedFile(upload.id)
		} else {
			await publicApi.post(`/uploads/${upload.id}/complete`);

			const regex = /^property-owner-id-file-([1-2])$/;
			const match = feedbackElementID.match(regex);
			if (match && api_key) {
				const ownerIndex = match[1];
				const MAX_SIZE_BYTES = 10 * 1024 * 1024;

				if (file.size < MAX_SIZE_BYTES) {
					const extractionResult = await extractText(applicationID, upload.id, api_key);
					if (extractionResult) {
						if (ownerIndex === '1') {
							txFields.value.id_details_property_owner_1 = extractionResult;
						} else if (ownerIndex === '2') {
							txFields.value.id_details_property_owner_2 = extractionResult;
						}
						upload.meta.extraction = extractionResult;
					}
				} else {
					console.warn(`File size of ${file.size} bytes exceeds the maximum permitted size for text extraction of ${MAX_SIZE_BYTES} bytes`)
				}
			}
			if (input) {
				uploadFileMessage(input, feedbackElementID, { message: `Successfully uploaded file with the filename: '${upload.file_name}'`, status: 1 })
			}
		}
		uploadState.value -= 1

		// Save upload history
		uploadFileIds.value?.push({
			id: feedbackElementID,
			uploadID: upload?.id as string
		})

		return upload
	}

	const deletePrevUploadedFile = async (uploadID: string) => {
		try {
			await publicApi.delete(`/uploads/${uploadID}`)
		} 
		catch (error: any) {
			console.warn("Unable to delete the previously uploaded file")
			toast.error(getApiErrorMessage(error))
		}
	}

	const uploadFileMessage = (input: HTMLInputElement, elementID: string, response: { message: string; status: number }) => {
		const fileUploadStatusID = elementID + "-upload-status"
		const fileUploadStatusSuccess = document.getElementById(fileUploadStatusID + "-1") as HTMLDivElement
		const fileUploadStatusSuccessMessage = document.getElementById(fileUploadStatusID + "-1-message") as HTMLDivElement
		const fileUploadStatusFailed = document.getElementById(fileUploadStatusID + "-2") as HTMLDivElement
		const fileUploadStatusFailedMessage = document.getElementById(fileUploadStatusID + "-0-message") as HTMLDivElement
		const fileUploadStatusLoading = document.getElementById(fileUploadStatusID + "-0") as HTMLDivElement

		if (response.status === 1) { // Success
			if (fileUploadStatusSuccess) {
				fileUploadStatusSuccess.style.display = "inline-block"
				fileUploadStatusFailed.style.display = "none"
				fileUploadStatusLoading.style.display = "none"
				fileUploadStatusSuccessMessage.textContent = response.message
			}
		}
		else if (response.status === 2) { // Failed
			if (fileUploadStatusFailed) {
				fileUploadStatusSuccess.style.display = "none"
				fileUploadStatusFailed.style.display = "inline-block"
				fileUploadStatusLoading.style.display = "none"
				fileUploadStatusFailedMessage.textContent = response.message

				// If there is an error, remove the
				// file in the input field
				input.value = ""
			}
		}
		else if (response.status === 0) { // Loading
			if (fileUploadStatusFailed) {
				fileUploadStatusSuccess.style.display = "none"
				fileUploadStatusFailed.style.display = "none"
				fileUploadStatusLoading.style.display = "inline-block"
			}
		}
	}

	const getUploadedFiles = computed(() => uploadFileIds.value)

	return {
		extractText,
		formLoading,
		getUploadedFiles,
		currentState,
		GA_STATE_STORAGE_KEY,
		TX_STATE_STORAGE_KEY,
		HI_STATE_STORAGE_KEY,
		FL_STATE_STORAGE_KEY,
		initialFormDataTX,
		initialFormDataGA,
		initialFormDataHI,
		initialFormDataFL,
		gaDefaultSectionC1,
		gaDefaultSectionC2,
		txFields,
		gaFields,
		hiFields,
		flFields,
		getOrSetFormLoading,
		showBirthDateWarning,
		uploadFile,
		uploadFileMessage,
		uploadState,
		deletePrevUploadedFile
	}
})