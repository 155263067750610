<template>
	<ModalDialog v-if="showModal" :title="label.dialogTItle.value" :close="closeLabelDialog">
		<Message severity="warn" :closable="false" class="mb-3" v-if="isRecordsInCurrentPageSelected">
			<span v-if="!isAllRecordsMatchingFiltersSelected">
				Changes will be applied to all records on this page.
			</span>
			<span v-else>
				Changes will be applied to all records in this view.
			</span>
		</Message>
		<template v-if="!isAssignedLabelLoading">
			<div class="element-container always-show-scrollbar" id="bulk-label-container">
				<label for="label" class="form-label">Labels</label>
				<div class="add-label-list ms-2">
					<div class="d-block mb-2" v-for="label in labelList" :key="label.id">
						<Checkbox :value="label.id" v-model="labelSelected" :input-id="`add-label-${label.id}`"
							:indeterminate="initialState[label.id] === 'indeterminate'"
							@change="toggleSelection(initialState, trackLabels, label.id, $event)" class="me-2" />
						<label class="me-2" :for="`add-label-${label.id}`">{{ label.label_name }}</label>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="add-label-list ms-2">
				<div v-for="n in 6" :key="n">
					<div class="d-inline-flex mb-2">
						<Skeleton width="30px" height="1.5rem" class="me-2"></Skeleton>
						<Skeleton width="250px" height="1.5rem"></Skeleton>
					</div>
				</div>
			</div>
		</template>
		<Textarea v-model="label.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
		<template #footer>
			<Button @click="saveBulkLabel()" id="add-bulk-label"
				:disabled="isAssignedLabelLoading || label.loading.value || !trackLabels.isChanged.value">
				<span class="fa fa-save" aria-hidden="true" v-if="!label.loading.value"></span>
				<span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="label.loading.value"></span>
				Save
			</Button>
			<Button @click="closeLabelDialog()" severity="secondary">
				<span class="fa fa-times-circle" aria-hidden="true"></span> Close
			</Button>
		</template>
	</ModalDialog>
</template>


<script setup lang="ts">
import type { Label } from '@/helpers/interface/candidates'
import type { CheckboxTriState, TrackChanges } from '@/helpers/interface/general'
import Checkbox from 'primevue/checkbox'
import Skeleton from 'primevue/skeleton'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import Message from 'primevue/message'
import ModalDialog from '@/components/Shared/ModalDialog.vue'
import { computed, ref, watch } from 'vue'
import { performLabelUpdate, setInitialCheckboxStates } from '@/helpers/bulkActions'
import { useTaxroll } from '@/stores/taxroll'
import { useAPI } from '@/helpers/services/api'
import { formatNumberWithCommas, MAX_NOTE_LENGTH } from '@/helpers/common'

const {
	config,
	loadFailureHandler,
	getFilterList,
	isRecordsInCurrentPageSelected
} = defineProps<{
	config: "candidates" | "applications";
	loadFailureHandler: (error: any) => void;
	getFilterList?: () => void;
	isRecordsInCurrentPageSelected?: boolean;
}>()

const storeTaxroll = useTaxroll()
const api = useAPI()
const emits = defineEmits(["save", "close"])

const showModal = defineModel<boolean>("showModal", { required: true })
const selectedItems = defineModel<string | string[]>("selectedItems", { required: true })
const labelList = defineModel<Label[]>("labelList", { required: true })
const totalSelectedRecord = defineModel<any>("totalSelectedRecord", { default: 0 })
const isAllRecordsMatchingFiltersSelected = defineModel<boolean>("isAllRecordsMatchingFiltersSelected", { required: false, default: false })

const getCountAssignedLabel = computed(() => storeTaxroll.getCountAssignedLabel)
const labelSelected = ref<number[]>([])
const isAssignedLabelLoading = computed(() => storeTaxroll.countAssignedLabelLoading)
const recordLabel = ref("record")


// Track only changed labels for efficient updates
const trackLabels: TrackChanges = {
	changedToChecked: ref<number[]>([]),
	changedToUnchecked: ref<number[]>([]),
	isChanged: computed((): boolean => {
		return trackLabels.changedToChecked.value.length > 0 ||
			trackLabels.changedToUnchecked.value.length > 0
	})
}
// Stores the initial states of checkboxes (used for comparison)
const initialState = ref<Record<string, CheckboxTriState>>({})
const label = {
	add: ref<Label[]>([]),
	remove: ref<Label[]>([]),
	optionalNote: ref(""),
	loading: ref(false),
	parentURL: "/taxroll",
	dialogTItle: computed(() => {
		if (isAssignedLabelLoading.value) return "Loading..."

		const countIndeterminate = Object.values(initialState.value).filter(value => value === "indeterminate").length
		const totalRecords = formatNumberWithCommas(totalSelectedRecord.value)
		const recordText = computed(() => ((totalSelectedRecord.value > 1) ? `${totalRecords} ${recordLabel.value}` : `this record`))

		if (trackLabels.isChanged.value) {
			const hasIndeterminate = countIndeterminate > 0
			const noLabelSelected = labelSelected.value.length <= 0
			const hasAssignedLabels = getCountAssignedLabel.value.length > 0

			// If there are indeterminate labels,
			// and all of them were changed to unchecked,
			// and there are assigned labels, but none selected now
			// OR
			// If there are no indeterminate labels, but the other two conditions are true
			if (
				(hasIndeterminate && countIndeterminate === trackLabels.changedToUnchecked.value.length && hasAssignedLabels && noLabelSelected) ||
				(!hasIndeterminate && hasAssignedLabels && noLabelSelected)
			) {
				return `Clear labels from ${recordText.value}`
			}
		}
		let dialogText = `Update labels for ${recordText.value}`
		return dialogText
	})
}

// Track and update selection changes
const watchSelectionChanges = (
	trackChanges: TrackChanges,
	itemId: number | string,
	initial: CheckboxTriState,
	current: CheckboxTriState
) => {
	// Remove itemId from all change tracking arrays
	trackChanges.changedToChecked.value = trackChanges.changedToChecked.value.filter(id => id !== itemId)
	trackChanges.changedToUnchecked.value = trackChanges.changedToUnchecked.value.filter(id => id !== itemId)

	// If state has changed, record it in the appropriate tracking list
	if (initial !== current) {
		if (current === "checked") {
			trackChanges.changedToChecked.value.push(itemId)
		} else if (current === "unchecked") {
			trackChanges.changedToUnchecked.value.push(itemId)
		}
	}
}

// Toggle the checkbox state based on user interaction
const toggleSelection = (
	initialState: Record<string, CheckboxTriState>,
	trackChanges: TrackChanges,
	itemId: number | string,
	event: Event
) => {
	const fieldCheck = (event.target as HTMLInputElement).checked
	let currentState: CheckboxTriState = fieldCheck ? "checked" : "unchecked"

	if (initialState[itemId] === "indeterminate") {
		currentState = "checked"
		// Handle special logic for transitioning from indeterminate state
		const isUnchecked = trackChanges.changedToUnchecked.value.includes(itemId)
		if (!isUnchecked && fieldCheck) {
			currentState = "unchecked"
		} else if (isUnchecked && !fieldCheck) {
			currentState = "checked"
		} else if (isUnchecked && fieldCheck) {
			currentState = "checked"
		} else {
			currentState = "unchecked"
		}
	}

	// Track any state changes
	watchSelectionChanges(trackChanges, itemId, initialState[itemId], currentState)
}

const saveBulkLabel = async () => {
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value && getFilterList ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	let requestMade = false

	if (trackLabels.changedToChecked.value.length > 0) {
		label.add.value = labelList.value.filter(label =>
			trackLabels.changedToChecked.value.includes(label.id)
		)
		await performLabelUpdate(api, "add", label, additionalParam)
		requestMade = true
	}

	if (trackLabels.changedToUnchecked.value.length > 0) {
		label.remove.value = labelList.value.filter(label =>
			trackLabels.changedToUnchecked.value.includes(label.id)
		)
		await performLabelUpdate(api, "remove", label, additionalParam)
		requestMade = true
	}

	if (requestMade) closeLabelDialog()

	emits("save", { requestMade })
	label.loading.value = false
}

// Initialize current checkbox state
watch(() => showModal.value, async (show: boolean) => {
	if (!show) return
	const truIDs = (Array.isArray(selectedItems.value) ? selectedItems.value : [selectedItems.value[0]]) as string[]
	await storeTaxroll.getLabelSelected(truIDs, `${config}=true`, loadFailureHandler)
	setInitialCheckboxStates(labelList.value, getCountAssignedLabel.value, truIDs, labelSelected.value, initialState.value)
	recordLabel.value = truIDs.length > 1 ? "records" : "record"
})


const closeLabelDialog = () => {
	label.add.value = []
	label.remove.value = []
	label.optionalNote.value = ""
	showModal.value = false

	labelSelected.value = []
	initialState.value = {}
	trackLabels.changedToChecked.value = []
	trackLabels.changedToUnchecked.value = []
}

</script>