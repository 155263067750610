<template>
    <div id="config-edit-mail-module-container">
        <form @submit.prevent="updateMailModule()">
            <div class="row">
                <div class="col-12">
                    <label for="config-edit-template-name">
                        Template <span class="required">*</span>
                    </label>
                    <select v-model="formEditMailModule.mail_template_id" class="cbFormTextField cbFormTextField-xl"
                        id="template" required>
                        <option v-for="template in props.mailTemplateList" :value="template.id" :key="template.name">
                            {{ ucfirst(template.name) }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-2">
                    <label for="config-edit-template-name">
                        Mail To <span class="required">*</span>
                    </label>
                    <input type="text" class="cbFormTextField cbFormTextField-xl" maxlength="255" required
                        id="configEditMailTo" v-model="formEditMailModule.mail_to" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 mt-2">
                    <label for="config-edit-template-name">
                        Mail 1
                    </label>
                    <input type="text" class="cbFormTextField cbFormTextField-xl" maxlength="255" id="configEditMail1"
                        v-model="formEditMailModule.mail_address" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 mt-2">
                    <label for="config-edit-template-name">
                        Mail 2
                    </label>
                    <input type="text" class="cbFormTextField cbFormTextField-xl" maxlength="255" id="configEditMail2"
                        v-model="formEditMailModule.mail_address2" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 mt-2">
                    <label for="config-edit-template-name">
                        Note
                    </label>
                    <br>
                    <textarea v-model="formEditMailModule.note_for_template" class="cbFormTextField cbFormTextField-xl"
                        id="note" :maxlength="MAX_NOTE_LENGTH"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-2 d-flex">
                    <button type="submit" class="searchButton" :disabled="formLoading">
                        <span class="fa fa-edit" aria-hidden="true"></span>
                        Update
                    </button>
                    <loading-icon v-if="formLoading" />
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import type { AxiosError } from "axios"
import { MAX_NOTE_LENGTH, getApiErrorMessage, ucfirst } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import type { MailModuleFilter } from "@/helpers/interface/admin-page"
import type { PropType } from 'vue';

const props = defineProps({
	mailModule: {
		type: Object as PropType<Partial<MailModuleFilter>>,
		required: true
	},
	mailTemplateList: {
		type: Array as () => { name: string, id: number }[]
	},
})

const api = useAPI()
const formEditMailModule = ref({
	mail_address: props.mailModule.mail_address,
	mail_address2: props.mailModule.mail_address2,
	mail_template_id: props.mailModule.mail_template_id,
	mail_to: props.mailModule.mail_to,
	note_for_template: props.mailModule.note_for_template,
	mail_status: props.mailModule.mail_status,
	id: props.mailModule.id,
})
const formLoading = ref(false)
const emits = defineEmits(["editMailModule"])
const updateMailModule = async () => {
	const tru_id = props.mailModule.tru_id
	formLoading.value = true
	try {
		await api.patch(`/taxroll/mails/${tru_id}`, formEditMailModule.value)
		toast.success(`Your update has been saved`)
		emits("editMailModule")
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	formLoading.value = false;
}
</script>
