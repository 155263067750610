<template>
	<div class="card-cl" style="height: 500px; width: 100%">
		<div class="card-body">
			<h2 class="info-header">SITUS INFO</h2>
			<table class="table table-striped" v-if="info.tru_id != ''">
				<tbody>
                <tr>
                    <td class="key">Last Visit</td>
                    <td class="value">
                        <div v-if="lastView && lastView.length > 0 && lastView[1] && dayjs(lastView[1].timestamp).isValid() && lastView[1].timestamp">
                            {{ lastView[1].nickname }} - {{ cleanDate(lastView[1].timestamp) }}
                            <span :class="{ 'recent-visit': getTimeSince(lastView[1].timestamp).isRecent }">
                                ({{ getTimeSince(lastView[1].timestamp).timeSince }} ago)
                            </span>
                        </div>
                        <div v-else-if="lastView && lastView.length > 0 && lastView[1]">
                            {{ lastView[1].timestamp }}
                        </div>
                        <div v-else>
                            N/A
                        </div>
                    </td>
                </tr>

                <tr v-for="si in siteInfo" :key="si.key">
						<td class="key">{{ si.key }}</td>
						<td class="value">
							<div v-if="si.key.toLocaleLowerCase().includes('url')">
								<a :href="(si.value as string)" target="_blank">
									Link
									<span class="fa fa-link"></span>
								</a>
							</div>
							<div v-else-if="si.key.toLocaleLowerCase().includes('date')">
								<span v-if="dayjs(si.value as string).isValid()">
									{{ cleanDate(si.value as string) }}
								</span>
							</div>
							<div v-else-if="si.key.toLocaleLowerCase() === 'assigned to'">
								<Chip v-if="si.value" :label="si.value" class="tr-chip assigned-to-chip cursor-pointer"
									@click="displayUserInfo($event)" />
								<span v-else>
									<i>Unassigned</i>
								</span>
							</div>
							<div v-else-if="si.key.toLocaleLowerCase().startsWith('exemption')">
								<div v-for="exemption in si.value" :key="exemption">
									{{ exemption }}
								</div>
							</div>
							<div v-else
								:class="{ 'clipboard-copy-wrapper': ['Address', 'Parcel Num'].includes(si.key) }">
								{{ si.value }}

								<!-- Append copy button  -->
								<div v-if="['Address', 'Parcel Num'].includes(si.key) && si.value"
									class="d-inline-flex">
									<a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'"
										class="copy-to-clipboard" @click="copyTextToClipboard(si.value as string)">
										<span class="fa-regular fa-copy fa-sm"></span>
									</a>

								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td class="key" colspan="2">
							<a :href="info.d2p_url" target="_blank">
								Parcel Lookup URL
								<span class="fa fa-link"></span>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
			<h2 class="info-header">MAILING INFO</h2>
			<table class="table table-striped" v-if="info.tru_id != ''">
				<tbody>
					<tr v-for="si in mailingInfo" :key="si.key">
						<td class="key">{{ si.key }}</td>
						<td class="value" :class="{ 'clipboard-copy-wrapper': ['Address'].includes(si.key) }">
							{{ si.value }}

							<!-- Append copy button  -->
							<div v-if="['Address'].includes(si.key) && si.value" class="d-inline-flex">
								<span class="clipboard-copy-target"
									:id="'mailing-' + (si.key as string).replace(' ', '-') || ''"
									:data-copy-text="si.value"></span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<h2 class="info-header">CANDIDATE STATUS</h2>
			<table class="table table-striped" v-if="info.tru_id != '' && info.queue">
				<tbody>
					<tr v-for="si in candidateStatus" :key="si.key">
						<td class="key">{{ si.key }}</td>
						<td class="value">
							<div v-if="si.key.toLocaleLowerCase().includes('recovered amount')">
								{{ formatter.format((si.value as number)) }}
							</div>
							<div v-else-if="si.key.toLocaleLowerCase() === 'queue'">
								{{ ucwords(getQueueTextByID(si.value as string) as string) }}

								<!-- Show 'Add to To-Do' or 'Undo Add to To-Do' icon based on the current state of a single record -->
								<a href="javascript:;" v-if="info.queue !== QueueMap.Todo.id"
									v-tooltip.bottom="isUndoVisible ? 'Undo Add to To-Do' : 'Add to To-Do list'"
									:class="isUndoVisible ? 'undo-to-todo-list' : 'add-to-todo-list'" class="ms-2"
									@click="isUndoVisible ? undoAddToTodoList() : addToTodoList()"
									:id="!isUndoVisible ? 'add-todo' : ''">
									<span
										:class="isUndoVisible ? 'fas fa-undo fa-sm' : 'fas fa-thumbtack fa-sm'"></span>
								</a>

								<!-- Show 'Added to To-Do' icon only if the record is in the To-Do queue -->
								<span class="ms-2 fas fa-thumbtack fa-sm added-to-todo-list" v-tooltip.bottom="'Pinned'"
									v-else></span>
							</div>
							<div v-else>
								{{ getDisplayValue(si.value) }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table class="table table-striped" v-if="info.tru_id != '' && !info.queue">
				<tbody>
					<tr>
						<td class="value">Not a Candidate</td>
					</tr>
				</tbody>
			</table>

			<a target="_blank" :href="'/admin-candidate-docket/' + info.tru_id">
				<span class="fa fa-print" aria-hidden="true"></span>
				Printer-friendly screen
			</a>
		</div>
	</div>

	<Popover ref="op">
		<UserInfo :userId="selectedUserInfo" />
	</Popover>
</template>

<script setup lang="ts">
import { ucwords, copyTextToClipboard, formatProperties, getCurrencyFormatter, getQueueTextByID, getApiErrorMessage, QueueMap } from "@/helpers/common"
import { computed, nextTick, ref, watch } from "vue";
import { situsInfoPrettyNames, filterStatus, getDisplayValue, setPageTitle, cleanDate } from "@/helpers/common"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import dayjs from "dayjs";
import Popover from "primevue/popover";
import UserInfo from "./UserInfo.vue";
import Chip from "primevue/chip";
import { useAPI } from "@/helpers/services/api";
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios";
import { useTaxroll } from "@/stores/taxroll";
import { useLastView } from "@/hooks/LastView";
import { getTimeSince } from "@/helpers/time-formatting";

const storeTaxroll = useTaxroll()
const { lastView } = useLastView('candidate')
const storeCandidateDetails = useCandidateDetailsStore();
const info = computed(() => (storeCandidateDetails.situsInfo))


const mailingFields = [
	"mail_full_taxroll",
	"mail_county",
];
const statusFields = [
	"queue",
	"reason",
	"unqualified_start_year",
	"lien_or_back_taxes",
	"recovered_amount",
	"snooze_until",
	"final_outcome",
]
const siteInfo = computed(() => {
	let formatted = formatProperties(info.value, situsInfoPrettyNames, [], [...mailingFields, ...statusFields])

	// Check if secondary_id is undefined
	if (info.value?.secondary_id === undefined) {

		// Add Alternate ID to the third position (under the Parcel Number)
		formatted.splice(2, 0, {
			"key": "Alternate ID",
			"value": (info.value?.secondary_id || "") as string
		})
	}
	return formatted
});
const mailingInfo = computed(() => (formatProperties(info.value, situsInfoPrettyNames, mailingFields)))
const candidateStatus = computed(() => {
	/* if we have a queue, then its a candidate and we display candidate fields
		otherwise, it's just a taxroll parcel, and there's no table */
	if (info.value.queue)
		return formatProperties(info.value, situsInfoPrettyNames, statusFields.filter(x => { return filterStatus(info.value, x) }))
	else
		return []
})

const formatter = getCurrencyFormatter()
watch(() => info.value, () => {
	const address = info.value.situs_full_taxroll || info.value.parcel_num;
	const addressPrefix = address ? address + " - " : ""
	const pageName = window.location.pathname.startsWith("/taxroll") ? "Tax Roll Parcel Details" : "Candidate Details"
	setPageTitle(addressPrefix + pageName)
})

const op = ref()
const selectedUserInfo = ref("")
const displayUserInfo = (event: any) => {
	if (!info.value.assigned_user_id) return
	op.value.hide()
	selectedUserInfo.value = info.value.assigned_user_id
	nextTick(() => {
		op.value.show(event)
	})
}

const loadFailureHandler = (error: AxiosError) => {
	const message = getApiErrorMessage(error, { "featureName": "Candidate" })
	toast.error(message)
}

const api = useAPI()
const isUndoVisible = ref<boolean>(false)
const isInTodoQueue = ref<boolean>(false)
let timeoutId: NodeJS.Timeout | null = null

const addToTodoList = (): void => {
	if (timeoutId) clearTimeout(timeoutId)

	isUndoVisible.value = true

	timeoutId = setTimeout(async () => {
		try {
			const payload = { tru_ids: [info.value.tru_id], note: "", queue: "todo" }
			await api.patch(`taxroll/queue`, payload)

			isInTodoQueue.value = true
			toast.success("Added to the To-Do list")

			storeCandidateDetails.fetchSitusInfo(info.value.tru_id, loadFailureHandler),
			storeCandidateDetails.fetchHistory(info.value.tru_id, loadFailureHandler)

		} catch (error: any) {
			toast.error(getApiErrorMessage(error))
		} finally {
			isUndoVisible.value = false
		}
	}, storeTaxroll.MOVE_TO_TODO_LIST_TIMER)
}

const undoAddToTodoList = (): void => {
	if (timeoutId) {
		clearTimeout(timeoutId)
		timeoutId = null
		isUndoVisible.value = false
		toast.info("Action undone")
	}
}

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
