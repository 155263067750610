<template>
    <div v-if="!!impersonation" class="tr-impersonation-container d-inline-block"
        :class="{ 'container-position-right': props.position === 'right' }"
         style="margin-left: 1em;">
        <Chip :label="displayName" class="tr-chip tr-chip-error" removable 
            v-tooltip.bottom="TOOLTIP" v-on:remove="removeImpersonationHandler">
        </Chip>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import { StorageKeyEnum, storageManager } from '@/helpers/storageManager';
import type { ImpersonationCache } from "@/helpers/interface/general";
import Chip from "primevue/chip"

const props = defineProps({
	position: {
		type: String,
		required: false
	}
})


const TOOLTIP = `You are impersonating this role. The features available are similar to users 
that are members of this role. Press the remove button (X) to restore your actual permissions.
`.replace("\n", "")

const getCachedImpersontationData = (): ImpersonationCache | null => {
	const json = storageManager.getItem(StorageKeyEnum.ImpersonationCache) 
	if (!json)
		return null
	return JSON.parse(json) as ImpersonationCache
}


const impersonation = ref<ImpersonationCache | null>(getCachedImpersontationData())

const displayName = computed(() => "Viewing as " + (impersonation.value?.roleName || "Unknown Role"))


const removeImpersonationHandler = () => {
	storageManager.removeItem(StorageKeyEnum.ImpersonationCache)
	window.location.reload() 
}

</script>
