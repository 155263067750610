import { PanZoomer } from "@/helpers/image-tools";

let pz: PanZoomer | null = null;

export const onGalleriaImageRender = () => {
	pz = new PanZoomer(".p-galleria-item img")
}

export const handleGalleriaImageZoomIn = () => {
	if (pz) {
		pz.zoomIn()
	}
}

export const handleGalleriaImageZoomOut = () => {
	if (pz) {
		pz.zoomOut()
	}
}

export const handleGalleriaImageRotate = (value: number) => {
	if (pz) {
		pz.rotate(value)
	}
}
