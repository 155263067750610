<template>
	<TheHeader />
	<main class="p-2">
		<SidebarNavigation :menus="sidebarMenus" :collapsed="isCollapsed" :activeMenu="activeMenu"
			:ignoreVal="ignoreVals" :key="activeMenu" @menuSelected="handleMenuSelected"
			@collapseChanged="handleCollapsed" />
		<div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
			<div class="sidebar-content">
				<p v-if="profile.loadingState" class="text-center">Loading...</p>
				<p v-else-if="!isAuthorized" class="text-center">
					Your user level does not have permission to access this page
				</p>
				<template v-else>
					<Card v-if="activeMenu === 'all-configs'">
						<template #content>
							<configList />
						</template>
					</Card>
					<Card v-else-if="activeMenu === 'client-configs'">
						<template #content>
							<customerConfigs />
						</template>
					</Card>
					<Card v-else-if="activeMenu === 'ingestions'">
						<template #content>
							<ingestions />
						</template>
					</Card>
					<Card v-else-if="activeMenu === 'data-requests'">
						<template #content>
							<dataRequests />
						</template>
					</Card>
					<Card v-else-if="activeMenu === 'scorecards'">
						<template #content>
							<scorecards />
						</template>
					</Card>
					<Card v-else>
						<template #content>
							<configList />
						</template>
					</Card>
				</template>
			</div>
		</div>
	</main>
	<teleport to="body">
		<div class="modal-overlay" v-if="notificationVisibility.showNotification" @click="toggleNotification"></div>
		<OnboardingJobsPopup @closePopup="toggleNotification" class="notification" :job_type="activePopUp"
			v-if="notificationVisibility.showNotification" />
	</teleport>
</template>
<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { setPageTitle } from "@/helpers/common";
import { useRouter } from "vue-router";


import configList from '@/components/Admin/Onboarding/Onboarding-configList.vue';
import customerConfigs from '@/components/Admin/Onboarding/Onboarding-customerConfigs.vue'
import ingestions from '@/components/Admin/Onboarding/Onboarding-ingestionList.vue';
import dataRequests from '@/components/Admin/Onboarding/Onboarding-dataRequestList.vue';
import scorecards from '@/components/Admin/Onboarding/Onboarding-scorecardViewer.vue'
import TheHeader from "@/components/TheHeader.vue";
import type { SidebarNavigationMenu } from "@/helpers/interface/candidates";
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue";
import Card from "primevue/card";
import { useProfile } from "@/stores/profile";
import OnboardingJobsPopup from "@/components/Admin/Onboarding/Onboarding-jobPopup.vue";

const isCollapsed = ref(false)
const router = useRouter()
const profile = useProfile()

const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed

const isAuthorized = computed((): boolean => (!profile.loadingState && profile.isTRs === true))

const activeMenu = computed(() => router.currentRoute.value.name as string);
const activePopUp = ref('');
const sidebarMenus = ref<SidebarNavigationMenu[]>([
	{ label: "All Configs", iconClass: "fa-solid fa-scroll", id: "all-configs" },
	{ label: "Client Configs", iconClass: "fa-regular fa-address-book", id: "client-configs" },
	{ label: "Ingestions", iconClass: "fa-solid fa-rotate-right", id: "ingestions" },
	{ label: "Data Requests", iconClass: "fa-solid fa-file-arrow-down", id: "data-requests" },
	{ label: "Client Reports", iconClass: "fa-solid fa-file-circle-check", id: "scorecards" },
	{ label: "Finalize Refresh", iconClass: "fa-solid fa-screwdriver-wrench", id: "cleanup" },
	{ label: "Generate Scorecard", iconClass: "fa-solid fa-screwdriver-wrench", id: "scorecard" },
])

const ignoreVals = ['scorecard', 'cleanup']

const handleMenuSelected = (menuId: string) => {
	if (!ignoreVals.includes(menuId)) {
		setPageTitle(sidebarMenus.value.find((menu) => menu.id === menuId)?.label || 'Onboarding Tools')
		router.push({ path: `/onboarding/${menuId}`, name: menuId })
	}
	else {
		activePopUp.value = menuId
		toggleNotification()
	}
}

const notificationVisibility = ref({ showNotification: false })
const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden';
const toggleNotification = () => {
	notificationVisibility.value.showNotification = !notificationVisibility.value.showNotification;
	toggleBodyScrolling(!notificationVisibility.value.showNotification);
}

onBeforeUnmount(() => toggleBodyScrolling(true));

onMounted(async ()=> await profile.fetchUserPermissions())
</script>