import { ref, computed } from "vue"
import { defineStore } from "pinia";
import { useAPI } from "@/helpers/services/api"
import { useAuth0 } from '@auth0/auth0-vue';
import { sortObjectsByKey, getApiErrorMessage, InternalSettings, ClientSettings } from "@/helpers/common"
import type { Customer, UserRoles, Labels, Auth0User, OutreachCodes } from '@/helpers/interface/admin-page';
import { ClaimsFields, Roles } from "@/helpers/roles"
import type { ToastPluginApi } from "vue-toast-notification";

export const useAdminDetails = defineStore("adminDetails", () => {

	const api = useAPI()
	const { user } = useAuth0()
	const vettingApplications = ref([])
	const allAvailableRoles = ref<UserRoles[]>([])
	const customerList = ref({
		hasAccess: false,
		data: <Customer[]>[]
	})
	const labelsList = ref<Labels[]>([])
	const userList = ref<(Auth0User & { id?: string })[]>([])
	const assignableUserList = ref<(Auth0User & { id?: string })[]>([])
	const internalSettings = ref<{ parameter: string, value: any }[]>([])
	const clientSettings = ref<{ parameter: string, value: any }[]>([])
	const outreachCodesList = ref<OutreachCodes[]>([])

	const fetchCustomersList = async (errorHandler: Function) => {
		await api.get("/customers/?listed=true")
			?.then(response => {
				const items = response.data

				// sort by customer name
				sortObjectsByKey(items, "name")

				customerList.value = {
					hasAccess: user.value[ClaimsFields['roles']].includes(Roles['trueroll_employee']),
					data: items
				}
			})
			?.catch(error => errorHandler(error))
	}

	const changeCustomerAccess = async (customer_id: string, toast: ToastPluginApi, errorHandler: Function) => {
		return await api.patch(`/users/me`, { customer_id })
			?.then(() => {
				toast.success(`Your update has been saved`)
				// Delay 1.5s
				setTimeout(() => window.location.reload(), 1000)
			})
			?.catch(error => errorHandler(error))
	}

	const fetchAllAvailableRoles = async (errorHandler: Function) => {
		await api.get("/users/roles/")
			?.then(response => {
				const roles = (response.data?.roles || []) as UserRoles[]
				allAvailableRoles.value = roles
			})
			?.catch(error => errorHandler(error))

	}

	const fetchLabelsList = async (errorHandler: Function) => {
		await api.get("/labels/")
			?.then(response => {
				labelsList.value = response.data
			})
			?.catch(error => errorHandler(error))
	}

	const fetchUsersList = async (errorHandler: Function) => {
		await api.get(`/users/auth0/?include_roles=false&blocked=false`)
			?.then(response => {
				const users = (response.data?.users || [])
				userList.value = users
			})
			?.catch(error => {
				userList.value = []
				errorHandler(error)
			})
	}

	const fetchAssignableUsersList = async (errorHandler: Function) => {
		await api.get(`/users/auth0/?include_roles=false&blocked=false&include_investigators=true`)
			?.then(response => {
				const users = (response.data?.users || [])
				assignableUserList.value = users
			})
			?.catch(error => {
				assignableUserList.value = []
				errorHandler(error)
			})
	}

	const fetchSettings = async (path: string, errorHandler: Function) => {
		try {
			const response = await api.get(path)
			return response.data
		} catch (error) {
			errorHandler(error)
			return null
		}
	}

	const fetchSettingsItem = async (path: string, setting: string, errorHandler?: Function) => {
		if (!errorHandler) {
			errorHandler = (error: any) => {
				const message = getApiErrorMessage(error, { featureName: "Setting" })
				console.error(message)
			}
		}

		try {
			const response = await api.get(`${path}/${setting}`)
			return response.data
		} catch (error) {
			errorHandler(error)
			return null
		}
	}

	const fetchInternalSettings = async (errorHandler: Function) => {
		internalSettings.value = await fetchSettings("/settings/internal", errorHandler)
	}

	const fetchInternalSettingsItem = async (setting: InternalSettings, errorHandler?: Function) => {
		return await fetchSettingsItem("/settings/internal", setting, errorHandler)
	}

	const fetchClientSettings = async (errorHandler: Function) => {
		clientSettings.value = await fetchSettings("/settings/client", errorHandler)
	}

	const fetchClientSettingsItem = async (setting: ClientSettings, errorHandler?: Function) => {
		return await fetchSettingsItem("/settings/client", setting, errorHandler)
	}

	const fetchOutreachList = async (errorHandler: Function) => {
		await api.get("outreach")
			?.then(response => {
				outreachCodesList.value = response.data
			})
			?.catch(error => errorHandler(error))
	}

	const getDefaultSnoozeDays = async (app: InternalSettings) => {
		const response = await fetchInternalSettingsItem(app)
		const snoozeDays = (response?.value) ? response.value : 0
		return snoozeDays
	}

	const getCustomerList = computed(() => customerList.value)
	const getVettingApplications = computed(() => vettingApplications.value)
	const getAllAvailableRoles = computed(() => allAvailableRoles.value)
	const getAllLabels = computed(() => labelsList.value)
	const getUsersList = computed(() => userList.value)
	const getAssignableUsersList = computed(() => assignableUserList.value)
	const getInternalSettings = computed(() => internalSettings.value)
	const getClientSettings = computed(() => clientSettings.value)
	const getOutreachList = computed(() => outreachCodesList.value)

	return {
		outreachCodesList,
		customerList,
		labelsList,
		allAvailableRoles,
		userList,
		fetchCustomersList,
		fetchAllAvailableRoles,
		fetchLabelsList,
		fetchUsersList,
		fetchAssignableUsersList,
		fetchInternalSettings,
		fetchInternalSettingsItem,
		fetchClientSettings,
		fetchClientSettingsItem,
		fetchOutreachList,
		getCustomerList,
		getUsersList,
		getAssignableUsersList,
		getVettingApplications,
		getAllAvailableRoles,
		getAllLabels,
		getInternalSettings,
		getClientSettings,
		getOutreachList,
		getDefaultSnoozeDays,
		changeCustomerAccess
	}
})