
const getFileExtension = (fileName: string) => {
	if ( !fileName.includes(".") || /\.$/.test(fileName))
		return "";

	return fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase()
}

const getFileSizeText = (valueInBytes: number, digits: number = 2) => {
	let outValue = valueInBytes,
		level = null;

	const levels = [
		"B", "KB", "MB", "GB", "TB", "PB"
	];

	for(let i = 0; i < levels.length; i++) {
		if ( outValue < 1000) {
			level = i;
			break;
		}
		outValue = outValue / 1024;
	}

	if ( level == null )
		level = (levels.length - 1);

	let valueText = outValue.toFixed( digits ).toString();
	valueText = valueText.replace( "." + "0".repeat(digits), "");

	return valueText + " " + levels[level].toString();
};

const isValidFileType = (fileName: string, allowedExtensions: string[]) => {
	const normalizedExtensions = allowedExtensions.map(x => x.trim().replace(".", "").toLowerCase())
	const extension = getFileExtension(fileName)
	return normalizedExtensions.includes(extension)
}


export {
	getFileExtension,
	getFileSizeText,
	isValidFileType,
}
