import dayjs from "dayjs";

/**
 * Takes a timestamp and returns an object with two properties: `timeSince` and `isRecent`.
 *
 * `timeSince` is a string that describes the time difference between the given timestamp and the current
 * time. The string is pluralized correctly (e.g. "1 minute", "2 minutes", etc).
 *
 * `isRecent` is a boolean that indicates whether the timestamp is within the last 45 minutes.
 *
 * If the given timestamp is invalid or not provided, the function returns `{ timeSince: "", isRecent: false }`.
 */
export const getTimeSince = (timestamp?: string | null): { timeSince: string; isRecent: boolean } => {
	if (!timestamp || !dayjs(timestamp).isValid()) return { timeSince: "", isRecent: false };

	const now: dayjs.Dayjs = dayjs();
	const visitTime: dayjs.Dayjs = dayjs(timestamp);
	const diffMinutes = now.diff(visitTime, 'minute');

	let timeSince: string = "";
	let isRecent: boolean = false;

	if (diffMinutes < 60) {
		// Less than an hour
		timeSince = `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''}`;
		isRecent = diffMinutes < 45;
	} else if (diffMinutes < 24 * 60) {
		// Less than a day
		const hours: number = Math.floor(diffMinutes / 60);
		timeSince = `${hours} hour${hours !== 1 ? 's' : ''}`;
	} else if (diffMinutes < 30 * 24 * 60) {
		// Less than a month
		const days: number = Math.floor(diffMinutes / (24 * 60));
		timeSince = `${days} day${days !== 1 ? 's' : ''}`;
	} else if (diffMinutes < 365 * 24 * 60) {
		// Less than a year
		const months: number = Math.floor(diffMinutes / (30 * 24 * 60));
		timeSince = `${months} month${months !== 1 ? 's' : ''}`;
	} else {
		// More than a year
		const years: number = Math.floor(diffMinutes / (365 * 24 * 60));
		timeSince = `${years} year${years !== 1 ? 's' : ''}`;
	}

	return { timeSince, isRecent };
}
