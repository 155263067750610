<template>
    <div class="position-relative m-2">
        <div class="admin-mail-module-all">
            <form @submit.prevent="submitFilter()">
                <div id="mail-module-all-filter">
                    <div class="row">
                        <div class="col-sm">
                            <div class="mt-2">
                                <input type="text" placeholder="Mailer ID" maxlength="255"
                                    v-model="filterFields.mail_id" class="search-field" size="25" id="mailID">
                            </div>
                            <div class="mt-2">
                                <select v-model="filterFields.mail_template_id" class="search-field"
                                    id="mailTemplateID">
                                    <option value="" selected>-- Template --</option>
                                    <option v-for="template in props.mailTemplateList" :value="template.id"
                                        :key="template.name">
                                        {{ ucfirst(template.name) }}
                                    </option>
                                </select>
                            </div>
                            <div class="mt-2">
                                <select v-model="filterFields.mail_status" class="search-field" id="mailStatus">
                                    <option value="">-- Status --</option>
                                    <option v-for="status in props.mailStatus" :value="status.value" :key="status.value">
                                        {{ ucfirst(status.label) }}
                                    </option>
                                </select>
                            </div>
                            <div class="mt-2">
                                <input type="text" placeholder="Parcel Number" maxlength="255"
                                    v-model="filterFields.parcel_number" class="search-field" size="25"
                                    id="parcelNumber">
                            </div>
                            <div class="mt-2">
                                <label :for="'queue_' + queue" v-for="queue in candidateQueues" :key="queue"
                                    style="margin-right: 5px;" :id="'label_queue_' + queue">
                                    <input type="checkbox" :checked="filterFields.candidate_queue.includes(queue)"
                                        @click="toggleQueue(queue)" :id="'queue_' + queue" />
                                    {{ ucfirst(queue) }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="row" style="width: 35%;">
                            <div class="col-sm">
                                Created After
                                <br>
                                <input type="date" placeholder="Created After" v-model="filterFields.created_at_after"
                                    class="search-field" id="createdAtAfter">
                            </div>
                            <div class="col-sm">
                                Created Before
                                <br>
                                <input type="date" placeholder="Created Before" v-model="filterFields.created_at_before"
                                    class="search-field" id="createdAtBefore">
                            </div>
                        </div>
                        <div class="row" style="width: 35%">
                            <div class="col-sm">
                                Mailed After
                                <br>
                                <input type="date" placeholder="Mailed After" v-model="filterFields.mailed_at_after"
                                    class="search-field" id="mailedAtAfter">
                            </div>
                            <div class="col-sm">
                                Mailed Before
                                <br>
                                <input type="date" placeholder="Mailed Before" v-model="filterFields.mailed_at_before"
                                    class="search-field" id="mailedAtBefore">
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mb-3">
                        <div class="row p-1">
                            <div class="col-md-6">
                                <button type="submit" class="searchButton">Search</button>
                                <button type="button" @click="confirmClearSearchField" class="clearButton">Clear
                                    Search</button>
                            </div>
                            <div class="col-md-6 text-end d-block">
                                <div class="d-inline-flex">
                                    <button type="button" @click="() => showBulkUpdateDialog = true"
                                        class="warningButton" :disabled="selectedUpdateMailIDs.length === 0"
                                        v-if="hasPermissionToUpdatePostalContact" id="bulkUpdate">
                                        <span class="fa fa-edit" title="Bulk Update" aria-hidden="true"></span>
                                        Bulk update Queue or Add Templates
                                    </button>
                                </div>
                                <div class="d-inline-flex">
                                    <loading-icon v-if="exportCSVLoading" />
                                    <button type="button" @click="exportMailers" class="actionButton"
                                        :disabled="exportCSVLoading" id="downloadCSV">
                                        <span class="fa fa-download" title="Download CSV" aria-hidden="true"></span>
                                        Download CSV
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="mt-2" id="mail-module-all-list"></div>
        </div>

        <teleport to="body">
            <ModalDialog v-if="showClearSearchConfirmation" title="Confirm Clear Search"
                :close="() => showClearSearchConfirmation = false">
                Are you sure you want to clear the search?
                <br>
                <br>
                This action cannot be undone, and all current search filters
                and input will be removed.

                <template #footer>
                    <button style="border: 1px solid #ccc" @click="clearSearchField"
                        class="btn btn-danger confirmClearSearch">Confirm</button>
                    <button style="border: 1px solid #ccc" @click="showClearSearchConfirmation = false"
                        class="btn btn-default">Close</button>
                </template>
            </ModalDialog>

            <ModalDialog v-if="showBulkUpdateDialog" :title="`Editing ${selectedUpdateMailIDs.length} Record(s)`"
                :close="() => showBulkUpdateDialog = false">
                <form @submit.prevent="bulkMailUpdate()" id="bulkUpdateForm">
                    <div class="actions-description">
                        Bulk update Candidate Queues or bulk add a second mailing template to your Candidates to
                        progress
                        your business process.
                    </div>
                    <div class="actions-description">
                        Tip #1: Adding a second Mailing Template can be helpful for sending second notices.
                        <br>
                        Tip #2: Add a unique note to describe why are you taking this action.
                        <br>
                        <b>Important</b>: Bulk actions cannot be undone
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-5">
                            <label for="add-new-template">Add Mail Template</label>
                        </div>
                        <div class="col-7">
                            <select class="cbFormTextField cbFormTextField-xl" v-model="bulkUpdate.mail_template_id"
                                id="mailTemplateID">
                                <option value="" selected>-- Select --</option>
                                <option v-for="template in mailingTemplateDropdown" :value="template.id"
                                    :key="template.id">
                                    {{ template.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-5">
                            <label for="update-queue">Update Queue</label>
                        </div>
                        <div class="col-7">
                            <select class="cbFormTextField cbFormTextField-xl" v-model="bulkUpdate.queue"
                                id="updateQueue">
                                <option value="" selected>-- Select --</option>
                                <option v-for="q in updateQueueOptions" :value="q.value" :key="q.value">
                                    {{ q.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="bulkUpdate.queue">
                        <br>
                        <div class="row" v-if="bulkUpdate.queue === 'unqualified'">
                            <div class="col-5">
                                <label for="update-queue">Unqualified Start Year</label>
                            </div>
                            <div class="col-7">
                                <UnqualifiedStartYear @fieldUpdate="(value) => updateUnqualifiedStartYear(value)"
                                    :unqualifiedStartYear="bulkUpdate.unqualified_start_year" class="cbFormTextField-xl"
                                    id="unqualifiedStartYear" />
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-5">
                            <label for="update-queue">
                                Include Note
                                <span class="required">*</span>
                                <span class="ms-1 fa fa-question-circle"
                                    title="Add a note that will help you track what action you're currently taking"></span>
                            </label>
                        </div>
                        <div class="col-7">
                            <textarea v-model="bulkUpdate.note" class="textwrapper" :maxlength="MAX_NOTE_LENGTH" required
                                id="note"></textarea>
                        </div>
                    </div>
                </form>

                <template #footer>
                    <loading-icon v-if="bulkUpdateLoading" />
                    <button style="border: 1px solid #ccc; background-color: var(--main-warning-color);"
                        @click="validateAndSubmitForm"
                        :disabled="(bulkUpdateLoading || selectedUpdateMailIDs.length === 0)" form="bulkUpdateForm"
                        class="btn btn-success">Update</button>
                    <button style="border: 1px solid #ccc" @click="() => showBulkUpdateDialog = false"
                        class="btn btn-default">Close</button>
                </template>
            </ModalDialog>
        </teleport>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import type { AxiosError } from "axios";
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import { queueOptions } from "@/helpers/dropdownOptions"
import { ref, onMounted, onUnmounted, computed } from "vue"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import type { MailModuleFilter } from "@/helpers/interface/admin-page";
import { MAX_NOTE_LENGTH, ucfirst, downloadCSV, getApiErrorMessage, filterObjectWithValues, validateUserPermission } from "@/helpers/common";
import type { TabulatorFull as Tabulator, ColumnDefinition } from 'tabulator-tables'
import { createAjaxTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import UnqualifiedStartYear from "@/components/Shared/UnqualifiedStartYear.vue"

const api = useAPI()
const props = defineProps({
	tableColumns: {
		type: Array as () => ColumnDefinition[],
		required: true
	},
	baseUrl: String,
	mailTemplateList: {
		type: Array as () => { name: string, id: number }[]
	},
	candidateQueues: {
		type: Array as () => string[]
	},
	mailStatus: {
		type: Array as () => {label: string, value: string}[]
	},
	toggleQueueSelection: {
		type: Function,
		required: true
	}
})
const storeCandidateDetails = useCandidateDetailsStore();
let tabulatorMailModule: Tabulator | null;
const showClearSearchConfirmation = ref<boolean>(false)
const candidateQueues = ref<string[]>(props.candidateQueues || [])
const filterFieldsInitialState = {
	mail_id: "",
	mail_template_id: "",
	mail_status: "",
	parcel_number: "",
	created_at_after: "",
	created_at_before: "",
	mailed_at_after: "",
	mailed_at_before: "",
	candidate_queue: [...candidateQueues.value]
}
const hasPermissionToUpdatePostalContact = computed(() => validateUserPermission("update", "postal_contact_workflows"))

const showBulkUpdateDialog = ref(false)
const selectedUpdateMailIDs = ref<string[]>([])
const bulkUpdateLoading = ref<boolean>(false)
const bulkUpdateInitialState = {
	ids: [] as string[],
	mail_template_id: "",
	queue: "",
	note: "",
	unqualified_start_year: "",
}
const bulkUpdate = ref({ ...bulkUpdateInitialState })
const mailingTemplateDropdown = computed(() => storeCandidateDetails.getMailTemplateList)
const updateQueueOptions = queueOptions.filter(x => !["archive", "snooze"].includes(x.value))

const filterFields = ref<MailModuleFilter>({ ...filterFieldsInitialState })
const tableFilter = ref<{ field: string; type: string; value: any; }[]>([])
const exportCSVLoading = ref<boolean>(false)
const toggleQueue = (queue: string) => filterFields.value.candidate_queue = props.toggleQueueSelection(queue, filterFields.value)
const confirmClearSearchField = () => {
	showClearSearchConfirmation.value = true
}
const clearSearchField = () => {
	showClearSearchConfirmation.value = false
	filterFields.value = { ...filterFieldsInitialState }
	filterFields.value.candidate_queue = [...candidateQueues.value]
	fetchMailModuleList(filterFields.value)
}
const submitFilter = () => fetchMailModuleList(filterFields.value)
const fetchMailModuleList = async (filterFieldsValue: MailModuleFilter) => {
	const tabulatorURL = `${props.baseUrl}/mailing/recipients-list/`
	const created_at_after = filterFieldsValue.created_at_after ? dayjs(filterFieldsValue.created_at_after).toISOString() : ""
	const created_at_before = filterFieldsValue.created_at_before ? dayjs(filterFieldsValue.created_at_before).toISOString() : ""
	const mailed_at_after = filterFieldsValue.mailed_at_after ? dayjs(filterFieldsValue.mailed_at_after).toISOString() : ""
	const mailed_at_before = filterFieldsValue.mailed_at_before ? dayjs(filterFieldsValue.mailed_at_before).toISOString() : ""
	const parcel_number = filterFieldsValue.parcel_number ? `${filterFieldsValue.parcel_number}%` : ''
	const defineFilterOperators: { field: string, type: string, value: any }[] = [
		{ field: "mail_id", type: "like", value: filterFieldsValue.mail_id ? filterFieldsValue.mail_id.toUpperCase() + "%" : "" },
		{ field: "mail_template_id", type: "=", value: filterFieldsValue.mail_template_id },
		{ field: "mail_status", type: "=", value: filterFieldsValue.mail_status },
		{ field: "parcel_number", type: "like", value: parcel_number },
		{ field: "created_at__gte", type: "=", value: created_at_after },
		{ field: "created_at", type: "<", value: getDateRangeEnd(created_at_before) },
		{ field: "mailed_at__gte", type: "=", value: mailed_at_after },
		{ field: "mailed_at", type: "<", value: getDateRangeEnd(mailed_at_before) },
	]
	defineFilterOperators.push({ field: "candidate_queue", type: "in", value: filterFieldsValue.candidate_queue })

	tableFilter.value = defineFilterOperators.filter((object) => object.value !== "")
	if (tabulatorMailModule) {
		tabulatorMailModule.clearAlert()
		tabulatorMailModule.setFilter(tableFilter.value)
		tabulatorMailModule.setData(tabulatorURL)
		return
	}

	tabulatorMailModule = await createAjaxTabulator("#mail-module-all-list", {
		columns: props.tableColumns,
		initialSort: [
			{ column: "mail_template", dir: "asc" },
		],
		pagination: true,
	})

	if (!tabulatorMailModule) return
	tabulatorMailModule.on("tableBuilt", () => {
		if (!tabulatorMailModule) return

		tabulatorMailModule.on("rowSelectionChanged", () => {
			const items = tabulatorMailModule?.getSelectedData()?.map(x => x.id) as string[]
			selectedUpdateMailIDs.value = items;
			bulkUpdate.value.ids = selectedUpdateMailIDs.value
		})

		tabulatorMailModule.setFilter(tableFilter.value)
		tabulatorMailModule.setData(tabulatorURL)
	})
}

const getDateRangeEnd = (isoDateString: string) => {
	if (!isoDateString)
		return ""
	return dayjs(isoDateString).add(1, "day").toISOString()
}

const exportMailers = async () => {
	const filter = {
		"export_type": "all-mailers",
		"filter": tableFilter.value
	}
	exportCSVLoading.value = true
	try {
		const response = await api.post(`/mailing/export`, filter)
		const filename = `Mail Module - All Mailer Report`
		downloadCSV(response.data, filename)
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	exportCSVLoading.value = false
}

const loadFailureHandler = (error: AxiosError) => {
	const message = getApiErrorMessage(error)
	toast.error(message)
}

const validateAndSubmitForm = () => {
	const form = document.getElementById('bulkUpdateForm') as HTMLFormElement
	if (!form) return
	form.checkValidity()
};

const updateUnqualifiedStartYear = (value: string) => bulkUpdate.value.unqualified_start_year = value

const bulkMailUpdate = async () => {
	const filteredBulkUpdate = filterObjectWithValues(bulkUpdate.value)

	if (bulkUpdate.value.queue === '' && bulkUpdate.value.mail_template_id === '') {
		toast.error(`You must provide either a queue or a mailing template`)
		return
	}
	bulkUpdateLoading.value = true
	try {
		await api.patch(`/taxroll/bulk_mail_update_queue`, filteredBulkUpdate);
		toast.success(`Your update has been saved`)
		await fetchMailModuleList(filterFields.value)
		bulkUpdate.value = { ...bulkUpdateInitialState }
		showBulkUpdateDialog.value = false
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	bulkUpdateLoading.value = false

}

onMounted(async () => {
	await fetchMailModuleList(filterFields.value)
	await storeCandidateDetails.fetchMailTemplateList(loadFailureHandler)
})
onUnmounted(() => destroyTabulator(tabulatorMailModule))
</script>

<style>
@import "@/assets/admin-page.css";
</style>
