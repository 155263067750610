<template>
    <div>
        <TheHeader />
        <div class="row g-2" style="background-color: #F9F9F9;">
            <div class="col-sm-3 col-xs-3 col-md-3 col-lg-2 sub-menu">
                <div class="box p-1">
                    <div>
                        <AdminSubMenu @changeSubPage="updateSubPage" :selectedSubPage="currentSubPage"
                            :menu="SubMenuSettingsConfig_Filtered" :header="'Settings'"
                            v-if="SubMenuSettingsConfig_Filtered?.length !== 0" />
                        <AdminSubMenu @changeSubPage="updateSubPage" :selectedSubPage="currentSubPage"
                            :menu="SubMenuAdminConfig_Filtered" :header="'Admin'"
                            v-if="SubMenuAdminConfig_Filtered?.length !== 0" />
                    </div>
                    <hr>
                    <div class="container text-center pb-2" id="user-info-container">
                        {{ ucfirst(user.name) }} @ {{ currentCustomerAccess }}
                    </div>
                </div>
            </div>
            <div class="col-sm-9 col-xs-3 col-md-9 col-lg-10 sub-menu">
                <div class="box p-1" v-if="isReady">
                    <template v-if="hasAccessToPage">
                        <AdminSubPage v-if="currentSubPage" :page="currentSubPage" />
                    </template>
                    <div class="p-2" v-else>
                        {{ PERMISSION_DENIED_MESSAGE }}
                    </div>
                </div>
                <div class="p-2" v-else>
                    <div class="position-relative">
                        <label class="d-flex my-3">
                            <Skeleton width="100px" height="25px" class="me-2"></Skeleton>
                            <Skeleton width="300px" height="25px" class="me-2"></Skeleton>
                        </label>
                        <Skeleton width="100%" height="25px" class="me-2"></Skeleton>
                        <div class="mt-3">
                            <Skeleton class="m-1" height="100px"></Skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TheFooter />
    </div>
    <AppMonitor />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue"
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import AdminSubMenu from "@/components/Admin/AdminSubMenu.vue"
import AdminSubPage from "@/components/Admin/AdminSubPage.vue"
import { useAuth0 } from '@auth0/auth0-vue';
import { useRoute } from 'vue-router';
import { useProfile } from "@/stores/profile";
import { ucfirst, PERMISSION_DENIED_MESSAGE } from "@/helpers/common"
import { AdminConfig, SettingsConfig } from "@/helpers/adminSubMenuConfig"
import router from "@/router"
import Skeleton from "primevue/skeleton";
import { usePendoStore } from "@/stores/pendo";

const { user } = useAuth0();
const storeProfile = useProfile();
const route = useRoute()
const isReady = ref(false)
const currentSubPage = ref<string>("")
const pendoStore = usePendoStore();
const hasAccessToPage = ref(false)

const updateSubPage = (page: string) => {
	console.log({ page })
	currentSubPage.value = page
	router?.push({ name: "admin", params: { page } })
}

const currentCustomerAccess = computed(() => (storeProfile.getCustomerAccess?.name))
const currentPermissions = computed(() => (storeProfile.getCurrentPermissions))

const SubMenuAdminConfig_Filtered = computed(() => {
	return AdminConfig.filter((menu) => {
		const requiredPermissions = menu.requiredPermissions
		if (requiredPermissions.length === 0) return true

		return requiredPermissions.some(permission => {
			return currentPermissions.value.includes(permission)
		})
	})
})

const SubMenuSettingsConfig_Filtered = computed(() => {
	return SettingsConfig.filter((menu) => {
		const requiredPermissions = menu.requiredPermissions
		if (requiredPermissions.length === 0) return true

		return requiredPermissions.some(permission => {
			return currentPermissions.value.includes(permission)
		})
	})
})

const redirectIfAccessDenied = (page?: string | string[]) => {
	const currentPage = (page ? page : currentSubPage.value) as string
	hasAccessToPage.value = SubMenuAdminConfig_Filtered.value.some(settings => settings.url === currentSubPage.value) || SubMenuSettingsConfig_Filtered.value.some(settings => settings.url === currentSubPage.value)
	if (hasAccessToPage.value) {
		updateSubPage(currentPage)
		return
	}
}

watch(() => route?.params.page, (page: string | string[]) => {
	redirectIfAccessDenied(page)
})

onMounted(async () => {
	if (!route.params.page) return

	const requests = [
		storeProfile.fetchUserPermissions(),
		storeProfile.fetchCustomerAccess()
	]

	await Promise.all(requests).then(() => {
		isReady.value = true
		pendoStore.track()
	})

	currentSubPage.value = route.params.page as string
	redirectIfAccessDenied()
})
</script>

<style>
@import "@/assets/admin-page.css";
</style>