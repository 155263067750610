<template>
    <div class="tr-rich-text-editor">
        <ckeditor :editor="editor" v-model="data" :config="config" @input="handleRIchTextInput">
        </ckeditor>
        <p class="char-count">
            <span :class="{ 'exceed-char-limit': currentCharCount > maxCharacters }">{{ currentCharCount }}</span> /
            {{ maxCharacters }} characters
        </p>
    </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface EditorConfig {
    toolbar: string[];
    height: string;
}

const props = defineProps({
	editorData: {
		type: String,
		required: false,
		default: ''
	},
	editorConfig: {
		type: Object as () => EditorConfig,
		default: () => ({
			toolbar: [
				'undo',
				'|',
				'bold',
				'italic',
				'|',
				'numberedList',
				'bulletedList',
				'indent',
				'outdent',
				'|',
				'link',
			],
			height: 300,
		}),
	},
	fieldID: {
		type: String,
		default: '',
		required: false,
	}
});

const data = ref(props.editorData)
const config = ref(props.editorConfig)

const maxCharacters = 64000;
const editor = ClassicEditor

const currentCharCount = ref(data.value.length);

const getCharCount = (html: string): number => {
	const temp = document.createElement("div")
	temp.innerHTML = html
	return temp.textContent?.length || 0
}

const handleRIchTextInput = () => {
	const editorContent = data.value;
	currentCharCount.value = getCharCount(editorContent)
	emit('exceedCharLimit', (currentCharCount.value > maxCharacters), props.fieldID)
}

const emit = defineEmits(['update:editorData', 'exceedCharLimit'])
watch(() => data.value, (value) => emit('update:editorData', value))
watch(() => props.editorData, (newValue) => {
	data.value = newValue
	handleRIchTextInput()
})
</script>

<style>
.ck-editor__editable_inline {
    min-height: 200px;
    border: 1px solid var(--main-input-border-color) !important;

}

.exceed-char-limit {
    color: red;
}
</style>