<template>
    <div class="position-relative">
        <label class="config-header">
            Config: Users <strong>(<i>{{ currentCustomerAccess }}</i>)</strong>
        </label>
        <div class="actions-description">
            View user details, edit roles and block user access.
            (To block user access, just click the status <span class='user-active'><i
                    class='fa fa-check-circle'></i></span>
            Active and
            select <span class='user-blocked'><i class='fa fa-times-circle'></i></span> Block User)
        </div>
        <label for="filter-user" class="me-2">Filter by:</label>
        <Select :options="filterOptions" optionLabel="label" v-model="filterBy" optionValue="value" :input-id="'filter-user'"
            placeholder="All users" class="my-2" @change="fetchUsersList()">
        </Select>
        <div class="admin-config-users">
            <div id="config-users-list"></div>
        </div>
        <div class="position-absolute" :style="{ top: 0, right: 0 }">
            <button type="button" class="searchButton" @click="showInviteModal = true"
                v-if="hasPermissionToCreateUser">Invite User</button>
        </div>
        <teleport to="body">
            <ModalDialog v-if="showInviteModal" title="Invite User" :close="() => showInviteModal = false">
                <AdminConfigUserInvite @user-invitation-sent="onInvitationSent" />
            </ModalDialog>

            <ModalDialog v-if="showEditRoleDialog" title="Edit Roles" :close="closeEditRoleDialog">
                <form @submit.prevent="addNewUserRole()">
                    <div class="row">
                        <div class="col-6">
                            <label for="roles-select">
                                Assign new role <span class="required">*</span>
                            </label>
                            <br>
                            <select v-model="selectedNewRole" id="roles-select"
                                class="cbFormTextField cbFormTextField-xl" selected required>
                                <option value="" selected>-- Select --</option>
                                <option v-for=" role in rolesDropDownItems " :value="role.id" :key="role.id">
                                    {{ role.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3 d-flex">
                            <button :disabled="updateUserRoleLoading" class="searchButton" type="submit">Add</button>
                            <loading-icon v-if="updateUserRoleLoading" />
                        </div>
                    </div>
                </form>

                <hr>
                <div class="row">
                    <div class="col-12">
                        <b>Current Role(s)</b>
                        <table class="table table-striped table-bordered mt-2" v-if="currentRoles.length !== 0">
                            <thead>
                                <tr>
                                    <th style="width: 70%">Role Name</th>
                                    <th style="width: 25%">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="role in currentRoles" :key="role.id">
                                    <td>{{ role.name }}</td>
                                    <td>
                                        <a href="#" class="remove-btn" @click="removeRole(role.id, role.name)"
                                            v-if="!selectedRemoveRoleIDs.includes(role.id)">
                                            <span class='fa fa-times' aria-hidden='true'></span>
                                            Remove
                                        </a>
                                        <loading-icon v-if="selectedRemoveRoleIDs.includes(role.id)" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="mt-2">
                            No assigned roles
                        </div>
                    </div>
                </div>
            </ModalDialog>

            <ModalDialog v-if="showEditUserDialog" title="Edit User" :close="closeEditRoleDialog">
                <form @submit.prevent="editUser()">
                    <div class="row">
                        <div class="col-sm-12">

                            <label for="config-user-invite-name">
                                Name <span class="required">*</span>
                            </label>
                            <input id="config-user-invite-name" type="name" class="cbFormTextField cbFormTextField-xl"
                                maxlength="255" required title="Name" v-model="selectedEditUser.name" />
                            <label for="config-user-invite-email" class="mt-2">
                                Email address <span class="required">*</span>
                            </label>
                            <input id="config-user-invite-email" type="email" class="cbFormTextField cbFormTextField-xl"
                                maxlength="255" required title="A valid email address" spellcheck="false"
                                autocomplete="off" autocorrect="false" autocapitalize="off"
                                v-model="selectedEditUser.email" />
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12 mt-2 d-flex">
                            <button type="submit" class="searchButton" :disabled="editUserLoading">
                                <span class='fa fa-edit' aria-hidden='true'></span>
                                Update
                            </button>
                            <LoadingIcon v-if="editUserLoading" />
                        </div>
                    </div>
                </form>
            </ModalDialog>
        </teleport>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { useProfile } from "@/stores/profile"
import { toast } from "@/helpers/toast";
import { ref, onMounted, watch, computed, onUnmounted } from "vue"
import AdminConfigUserInvite from './AdminConfigUserInvite.vue'
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import type { ConfigUsers, UserRoles } from "@/helpers/interface/admin-page"
import { getApiErrorMessage, validateUserPermission } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import type {
	ColumnDefinition,
	CellComponent,
} from "tabulator-tables";
import type { TabulatorFull as Tabulator } from 'tabulator-tables'
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import { useAdminDetails } from "@/stores/adminDetails";
import type { AxiosError } from "axios"
import Select from "primevue/select";

interface Auth0User {
    user_id: string;
    name: string;
    email: string;
}

const api = useAPI()
const storeProfile = useProfile()
const storeAdminDetails = useAdminDetails()

// Active = (?blocked=false)
// Blocked = (?blocked=true)
// All = null
const filterBy = ref(false)
const filterOptions = [
	{
		label: "Active users only",
		value: false
	},
	{
		label: "Blocked users",
		value: true
	},
	{
		label: "All users",
		value: null
	}
]
const userList = ref<Auth0User[]>([])
const formLoading = ref<boolean>(false)
const editUserLoading = ref<boolean>(false)
const currentCustomerAccess = computed(() => (storeProfile.getCustomerAccess?.name))
const showInviteModal = ref<boolean>(false)

const allAvailableRoles = computed((): UserRoles[] => storeAdminDetails.getAllAvailableRoles)
const showEditRoleDialog = ref<boolean>(false)
const currentRoles = ref<UserRoles[]>([])
const selectedNewRole = ref<string>("")
const selectedRemoveRoleIDs = ref<string[]>([])
const selectedUserID = ref<string>("")
const updateUserRoleLoading = ref(false)
const removeRoleLoading = ref(false)
const hasModifications = ref(false)
const hasPermissionToCreateUser = computed(() => validateUserPermission("create", "users"))
const hasPermissionToUpdateUser = computed(() => validateUserPermission("update", "users"))

const showEditUserDialog = ref<boolean>(false)
const selectedEditUser = ref({
	user_id: "",
	name: "",
	email: ""
})

const rolesDropDownItems = computed(() => {
	return allAvailableRoles.value
		.filter(item1 =>
			!currentRoles.value.some(item2 => item2.id === item1.id)
		);
});

const statusCellEdited = async (cell: CellComponent) => {
	const blocked = cell.getValue()
	const data = cell.getData() as ConfigUsers
	const email = data.email
	const userID = data.user_id

	if (typeof blocked !== "boolean") return

	try {
		if (blocked) await api.delete(`/users/auth0/${userID}`);
		else await api.post(`/users/auth0/${userID}/unblock`);
		toast.success(`User ${email} has been blocked`)
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
	}
}

const statusCellEditor = (cell: any) => {
	const blocked = cell.getValue();
	return {
		values: (!blocked) ? [
			{ label: "<span class='user-blocked' id='cell-editor-block'><i class='fa fa-times-circle'></i></span> Block User", value: true },
		] : [
			{ label: "<span class='user-active' id='cell-editor-unblock'><i class='fa fa-check-circle'></i></span> Unblock User", value: false },
		]
	}
}

let tabulator: Tabulator | null;
const tableColumns = ref<ColumnDefinition[]>([
	{
		title: "Name",
		field: "name",
		width: "20vh",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as ConfigUsers
			let picture = data.picture
			let name = data.name
			return `<img class="users-list-picture" src="${picture}"></img> ${name}`
		}
	},
	{
		title: "Email",
		field: "email",
		width: "20vh",
	},
	{
		title: "Role",
		field: "roles",
		width: "20vh",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as ConfigUsers
			const roles = data.roles as { description: string, id: string, name: string }[]
			const userID = data.user_id
			const actionParent = document.createElement("div")
			actionParent.classList.add("text-wrap")
			const editRolesButton = document.createElement("a")
			editRolesButton.innerHTML = "<span class='fa fa-edit' aria-hidden='true'></span>"
			editRolesButton.classList.add("edit-btn", "ms-2")
			editRolesButton.title = "Edit Roles"
			editRolesButton.href = "javascript:;"
			editRolesButton.addEventListener("click", () => {
				updateSelectedUserInfo(roles, userID)
				showEditRoleDialog.value = true
			})
			actionParent.append(roles.map(role => role.name).join(", "))
			actionParent.append(editRolesButton)
			return actionParent
		}
	},
	{
		title: "Last Login",
		field: "last_login",
		width: "15vh",
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as ConfigUsers
			const last_login = data.last_login
			return (!last_login) ? "Never" : dayjs(last_login).format("MM/DD/YYYY HH:mm:ss")
		}
	},
	{
		title: "Status",
		width: "10vh",
		field: "blocked",
		editor: "list",
		cellEdited: statusCellEdited,
		editorParams: statusCellEditor,
		editable: function () {
			// Allow editing if it has the permission the edit the user
			return hasPermissionToUpdateUser.value
		},
		formatter: function (cell: CellComponent) {
			const blocked = cell.getValue();
			return !blocked
				? "<span class='user-active'><i class='fa fa-check-circle'></i></span> Active"
				: "<span class='user-blocked'><i class='fa fa-times-circle'></i></span> Blocked";
		},
	},
	{
		title: "&nbsp;",
		field: "roles",
		width: "15vh",
		visible: hasPermissionToUpdateUser.value,
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as ConfigUsers
			const user_id = data.user_id
			const email = data.email
			const name = data.name
			const editUserButton = document.createElement("a")
			editUserButton.innerHTML = "<span class='fa fa-edit' aria-hidden='true'></span> Edit User"
			editUserButton.classList.add("edit-btn")
			editUserButton.href = "javascript:;"
			editUserButton.addEventListener("click", () => {
				selectedEditUser.value = {
					user_id: user_id,
					name: name,
					email: email,
				}
				showEditUserDialog.value = true
			})

			return editUserButton
		}
	}
])

const updateSelectedUserInfo = (roles: UserRoles[], userID: string) => {
	currentRoles.value = roles
	selectedUserID.value = userID
}

const loadFailureHandler = (error: AxiosError) => {
	const message = getApiErrorMessage(error, { "featureName": "Customer List" })
	toast.error(message)
}

const addNewUserRole = async () => {
	updateUserRoleLoading.value = true
	hasModifications.value = true
	try {
		const roles = [selectedNewRole.value]
		const userID = selectedUserID.value
		await api.post(`/users/auth0/${userID}/roles`, { roles })
		await fetchUserRoles()
		selectedNewRole.value = ''
		toast.success(`Your update has been saved`)
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	updateUserRoleLoading.value = false;
}

const editUser = async () => {
	editUserLoading.value = true
	hasModifications.value = true
	try {
		const data = {
			name: selectedEditUser.value.name,
			email: selectedEditUser.value.email,
		}
		const userID = selectedEditUser.value.user_id
		await api.patch(`/users/auth0/${userID}`, data)
		toast.success(`Your update has been saved`)
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	editUserLoading.value = false;
}

const removeRole = async (roleId: string, roleName: string) => {
	removeRoleLoading.value = true
	hasModifications.value = true

	if (!selectedRemoveRoleIDs.value.includes(roleId)) selectedRemoveRoleIDs.value.push(roleId)

	try {
		const roles = [roleId]
		const userID = selectedUserID.value
		await api.delete(`/users/auth0/${userID}/roles`, { data: { roles } })
		await fetchUserRoles()
		selectedNewRole.value = ''
		toast.success(`Role '${roleName}' has been successfully removed from the user`)
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}

	selectedRemoveRoleIDs.value = selectedRemoveRoleIDs.value.filter(r => r !== roleId);
	removeRoleLoading.value = false
}

const closeEditRoleDialog = () => {
	if (hasModifications.value) {
		hasModifications.value = false
		fetchUsersList()
	}
	showEditRoleDialog.value = false;
	showEditUserDialog.value = false;
	selectedNewRole.value = '';
	selectedEditUser.value = {
		user_id: "",
		name: "",
		email: "",
	};
}

const fetchUserRoles = async () => {
	const userID = selectedUserID.value
	await api.get(`/users/auth0/${userID}/roles`).then(response => {
		const roles = (response.data?.roles || [])
		currentRoles.value = roles
	})
}

const fetchUsersList = async (newUser?: Auth0User) => {
	let users: Auth0User[] = []
	let url = "/users/auth0/"
	formLoading.value = true
	if (filterBy.value !== null) {
		url += "?blocked=" + filterBy.value
	}
	await api.get(url)?.then(response => {
		users = (response.data?.users || [])
		if (newUser) {
			/* if the user just added isn't found in the response for the list,
                 then add them manually 
            */
			if (!(users.find(x => x.user_id === newUser.user_id))) {
				users.push(newUser)
				users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
			}
		}
		userList.value = users
	})?.catch(error => {
		userList.value = []
		loadFailureHandler(error)
	})
	formLoading.value = false
}

const onInvitationSent = async (newUser: Auth0User) => {
	await fetchUsersList(newUser)
}

watch(() => formLoading.value, async (isLoading) => {
	tabulator = await createTabulator("#config-users-list", {
		data: userList.value,
		columns: tableColumns.value,
		initialSort: [
			{ column: "name", dir: "asc" },
		]
	}, isLoading)
})

onMounted(async () => {
	await fetchUsersList()
	await storeAdminDetails.fetchAllAvailableRoles(loadFailureHandler)
})

onUnmounted(() => destroyTabulator(tabulator))
</script>

<style>
@import "@/assets/admin-page.css";
</style>
