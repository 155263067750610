
const expressions = {
	REGEX_EMAIL: '^([a-zA-Z0-9_\\-])([a-zA-Z0-9\\.!\\#\\$\\%\\&\'\\*\\+\\-=\\?\\^_`\\{\\|}~]*)@(\\[((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}|((([a-zA-Z0-9\\-]+)\\.)+))([a-zA-Z]{2,}|(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\])$'
}
const escapeRegEx = (value: string) => {
	const exp = "(\\" + ["/", ".", "*", "+", "?", "|", "(", ")", "[", "]", "{", "}", "^", "$"].join("|\\") + ")";
	return value.replace( new RegExp(exp,"g"), "\\$1" );
}

const isValidEmail = (value: string | null | undefined) => {
	if ( !value ) {
		return false;
	}
	if ( typeof value !== "string" ) {
		return false;
	}
	if ( value.startsWith(".") || value.includes(".@") || value.includes("..") ) {
		return false;
	}
	return new RegExp(expressions.REGEX_EMAIL).test(value);
}

export {
	expressions, 
	escapeRegEx,
	isValidEmail
}
