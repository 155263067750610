<template>
    <div>
        <TheHeader />
    </div>
</template>

<script setup lang="ts">
// The Header component is not necessary for the callback page,  
// but it is included to prevent the page from appearing "empty" while authentication is processed in the background.
import TheHeader from "@/components/TheHeader.vue"
import { useProfile } from "@/stores/profile"
import { useAuth0 } from "@auth0/auth0-vue"
import { computed, onMounted, watch } from "vue"
import router from "@/router"

const { user, isAuthenticated } = useAuth0()
const storeProfile = useProfile()
const landingPage = computed(() => storeProfile.landingPage)
const hasPromonAccess = computed(() => storeProfile.hasPromonAccess)
const hasApplicationVetterAccess = computed(() => storeProfile.hasApplicationVetterAccess)

// Note: If the user tries to access the unauthorized page directly to the URL, 
// Callback will still handle it and redirect the user to the default page.
const redirect = async () => {
	if (!isAuthenticated.value) return

	await Promise.all([
		storeProfile.fetchUserPermissions(),
		storeProfile.fetchCustomerAccess(),
	])

	const { name: routerName, path: routerPath } = router.currentRoute.value
	const basePath = routerPath.split("/")?.[1]

	if (routerName === "callback") {
		return router.push(landingPage.value)
	}

	const accessRules = [
		{
			// Promon URLs
			urls: [
				"candidates-list",
			],
			hasAccess: hasPromonAccess.value,
		},
		{
			// Application Vetter URLs
			urls: [
				"application-vetter",
			],
			hasAccess: hasApplicationVetterAccess.value,
		},
	]

	if (accessRules.some(({ urls, hasAccess }) => urls.some(url => basePath.startsWith(url)) && !hasAccess)) {
		router.push(landingPage.value)
	}

	// Note: Paths that are not specified in the conditions above will continue accessing the requested page
}

watch(() => user.value, async () => redirect())

onMounted(() => redirect())
</script>