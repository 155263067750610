<template>
	<ApplicationSection title="PROOF OF RESIDENCY">

		<div class="mb-2 fw-bold">Please provide as much information as possible. Your county property appraiser will
			make the final determination.</div>
		<div class="mb-5 fw-bold">Applicant 1: Please select all types of proof of residency you are able to provide
		</div>

		<div class="element-container">
			<label for="termination-residency" class="form-label">
				Termination of previous residency outside of Florida
			</label>
			<CustomRadioGroup required groupId="applicant-termination-residency"
				v-model="formFields.termination_residency" :elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'termination-residency-yes', name: 'applicant-termination-residency', label: 'Yes', value: true },
					{ id: 'termination-residency-no', name: 'applicant-termination-residency', label: 'No', value: false }
				]" />
		</div>

		<div v-if="formFields.termination_residency === true">
			<div class="element-container ms-2">
				<FileUploader id="termination-residency-upload-document" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
					<template #label>
						<label for="termination-residency-upload-document" class="form-label-required">
							Upload Document
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="termination-residency-upload-document" />
			</div>

			<div class="element-container ms-2">
				<label for="termination-date" class="form-label form-label-required">
					Termination Date
				</label>
				<DateComboBox :pt="requiredInputAttribute" v-model="formFields.termination_date" id="termination-date"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container">
			<label for="fl-id" class="form-label">
				FL Driver License or ID Card Number
			</label>
			<CustomRadioGroup required groupId="applicant-fl-id" v-model="formFields.fl_id"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'fl-id-yes', name: 'applicant-fl-id', label: 'Yes', value: true },
					{ id: 'fl-id-no', name: 'applicant-fl-id', label: 'No', value: false }
				]" />
		</div>

		<div v-if="formFields.fl_id">
			<div class="element-container ms-2" v-for="field in proofOfResidencyFields" :key="`por-${field.id}`">
				<label :for="`por-${field.id}`" class="form-label" :class="{ 'form-label-required': field.required }">
					{{ field.label }}
				</label>
				<component :id="`por-${field.id}`" :is="field.component" v-bind="{
					...field.props,
					onInvalid: elementRequiredHandler
				}" v-model="formFields[field.model]" :required="field.required"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container">
			<label for="relinquish-license" class="form-label">
				Evidence of relinquishing driver license from other state
			</label>
			<CustomRadioGroup required groupId="applicant-relinquish-license" v-model="formFields.relinquish_license"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'relinquish-license-yes', name: 'applicant-relinquish-license', label: 'Yes', value: true },
					{ id: 'relinquish-license-no', name: 'applicant-relinquish-license', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.relinquish_license === true">
			<FileUploader id="upload-relinquish" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="upload-relinquish" class="form-label form-label-required">
						Upload Document
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="upload-relinquish" />
		</div>

		<div class="element-container">
			<label for="fl-tag" class="form-label">
				Florida Vehicle Tag Number
			</label>
			<CustomRadioGroup required groupId="applicant-fl-tag" v-model="formFields.fl_tag"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'fl_tag-yes', name: 'applicant-fl_tag', label: 'Yes', value: true },
					{ id: 'fl_tag-no', name: 'applicant-fl_tag', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.fl_tag === true">
			<label for="vehicle-tag-number" class="form-label form-label-required">
				Vehicle Tag Number
			</label>
			<InputText id="vehicle-tag-number" required v-model="formFields.vehicle_tag_number"
				@invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label for="fl-voter-registration" class="form-label">
				Florida Voter Registration Number (if US citizen)
			</label>
			<CustomRadioGroup required groupId="applicant-fl-voter-registration"
				v-model="formFields.fl_voter_registration" :elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'fl-voter-registration-yes', name: 'applicant-fl-voter-registration', label: 'Yes', value: true },
					{ id: 'fl-voter-registration-no', name: 'applicant-fl-voter-registration', label: 'No', value: false }
				]" />
		</div>
		<div v-if="formFields.fl_voter_registration === true">
			<div class="element-container ms-2">
				<label for="voter-registration" class="form-label form-label-required">
					Voter Registration Number
				</label>
				<InputText id="voter-registration" required v-model="formFields.voter_registration"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container ms-2">
				<label for="registration-date" class="mt-2 form-label form-label-required">
					Registration Date
				</label>
				<DateComboBox required :pt="requiredInputAttribute" v-model="formFields.registration_date"
					id="registration-date" @invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container" v-for="field in proofOfResidencyFields2" :key="`por2-${field.id}`">
			<label :for="`por2-${field.id}`" class="form-label" :class="{ 'form-label-required': field.required }">
				{{ field.label }}
			</label>
			<component :id="`por2-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required"
				@invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label for="bank-statement" class="mt-2 form-label">
				Bank Statement with Address at Homestead
			</label>
			<CustomRadioGroup required groupId="bank-statement" v-model="formFields.bank_statement"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'bank-statement-yes', name: 'bank-statement', label: 'Yes', value: true },
					{ id: 'bank-statement-no', name: 'bank-statement', label: 'No', value: false }
				]" />

		</div>

		<div class="element-container ms-2" v-if="formFields.bank_statement === true">
			<FileUploader id="upload-bank-statement" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="upload-bank-statement" class="form-label form-label-required">
						Attach File
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="upload-bank-statement" />
		</div>

		<div class="element-container">
			<label for="utilities-proof" class="mt-2 form-label">
				Proof of Payment of Utilities at Homestead Address
			</label>
			<CustomRadioGroup required groupId="utilities-proof" v-model="formFields.utilities_proof"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'utilities-proof-yes', name: 'utilities-proof', label: 'Yes', value: true },
					{ id: 'utilities-proof-no', name: 'utilities-proof', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.utilities_proof === true">
			<FileUploader id="upload-utilities-proof" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="upload-utilities-proof" class="form-label form-label-required">
						Attach File
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="upload-utilities-proof" />
		</div>


	</ApplicationSection>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed, watch } from 'vue'
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import FileUploader from "@/components/Shared/FileUploader.vue"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"
import { requiredInputAttribute } from "@/helpers/common"

const {
	elementRequiredHandler,
	acceptFileTypes,
	uploadFiles
} = defineProps<{
	elementRequiredHandler: <T extends Event>(e: T) => void;
	acceptFileTypes: string;
	uploadFiles: (e: Event) => void;
}>()

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

interface ProofOfResidencyField {
	id: string;
	label: string;
	component: any;
	model: keyof typeof formFields.value;
	required: boolean;
	props?: Record<string, any>;
	condition?: () => any;
}

const proofOfResidencyFields = computed((): ProofOfResidencyField[] => [
	{ id: 'id-number', label: 'ID Number', component: InputText, model: 'id_number', required: !!formFields.value.fl_id },
	{ id: 'id-issue-date', label: 'ID Issue Date', component: DateComboBox, model: 'id_issue_date', required: !!formFields.value.fl_id },
])

const proofOfResidencyFields2: ProofOfResidencyField[] = [
	{ id: 'domicile-declaration', label: 'Declaration of Domicile', component: DateComboBox, model: 'domicile_declaration', required: true },
	{ id: 'current-employer', label: 'Current Employer', component: InputText, model: 'current_employer', required: false },
	{ id: 'irs-address', label: 'Address on your last IRS return', component: InputText, model: 'irs_address', required: false },
	{ id: 'school-location', label: 'School Location of Dependent Children', component: InputText, model: 'school_location', required: false },
]

watch(() => formFields.value.termination_residency, (newVal) => {
	// If termination residency is not selected, clear related fields
	if (!newVal) {
		formFields.value.termination_date = ""
	}
})

watch(() => formFields.value.fl_id, (newVal) => {
	// If FL ID is not selected, clear related fields
	if (!newVal) {
		formFields.value.id_number = ""
		formFields.value.id_issue_date = ""
	}
})

watch(() => formFields.value.relinquish_license, (newVal) => {
	// If relinquish license is not selected, clear related fields
	if (!newVal) {
		formFields.value.relinquish_license = null
	}
})

watch(() => formFields.value.fl_tag, (newVal) => {
	// If FL vehicle tag is not selected, clear related fields
	if (!newVal) {
		formFields.value.vehicle_tag_number = ""
	}
})

watch(() => formFields.value.fl_voter_registration, (newVal) => {
	// If FL voter registration is not selected, clear related fields
	if (!newVal) {
		formFields.value.voter_registration = ""
		formFields.value.registration_date = ""
	}
})

watch(() => formFields.value.bank_statement, (newVal) => {
	// If bank statement is not selected, clear related fields
	if (!newVal) {
		formFields.value.bank_statement = null
	}
})

watch(() => formFields.value.utilities_proof, (newVal) => {
	// If utilities proof is not selected, clear related fields
	if (!newVal) {
		formFields.value.utilities_proof = null
	}
})

</script>