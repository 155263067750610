<template>
    <form @submit.prevent="" class="my-3">
        <div class="card">
            <div class="card-body">
                <div class="row pt-2">
                    <div class="col-12">
                        <label :for="inputID">Default Snooze Days</label>
                        <br>
                        <InputNumber v-model="defaultSnoozeDays" :inputId="inputID" class="mt-2 me-2"
                            @focus="handleFocus($event)" :min="1" :max="999" @blur="handleBlur($event.originalEvent)"
                            :disabled="isSavingItem" />

                        <loading-icon class="ms-2 d-inline-flex" v-if="isSavingItem" />
                    </div>
                </div>
            </div>
        </div>
    </form>


</template>

<script setup lang="ts">
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import InputNumber from 'primevue/inputnumber'
import { ClientSettings, InternalSettings } from '@/helpers/common';
import type { PropType } from "vue";

const { isSavingItem, inputID, settingsName } = defineProps({
	isSavingItem: Boolean,
	inputID: String,
	settingsName: String as PropType<ClientSettings | InternalSettings>
})

const defaultSnoozeDays = defineModel<number>("defaultSnoozeDays")
const emits = defineEmits(["handleFocus", "handleBlur", "isSavingItem"])
const handleFocus = (event: Event) => emits("handleFocus", event)
const handleBlur = (originalEvent: any) => emits("handleBlur", originalEvent, settingsName)
</script>
