<template>
    <select :id="props.inputID" v-model="recommendation" required :class="props.customClass">
        <option value="">-- Select --</option>
        <template v-for="option in reportRecommendations" :key="option.value">
            <option :value="option.value">{{  option.text }}</option>
        </template>
    </select>
</template>

<script setup lang="ts">
import { reportRecommendations } from "@/helpers/dropdownOptions";

const props = defineProps({
	inputID: {
		type: String,
		required: false,
		default: "report-recommendation"
	},
	customClass: {
		type: String,
		required: false,
		default: ""
	}
})

const recommendation = defineModel<string | null>("modelValue", {
	required: false,
	default: null
});

</script>
