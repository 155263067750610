enum StorageKeyEnum {
    EnableBetaFeatures = "enable-beta-features",
    EnableBetaFeaturesCL = "enable-beta-features-cl",
    SecurityNoticeAccepted = "user-data-security-notice-accepted", 
    SessionExpiration = "session-expiration",
    ImpersonationCache = "impersonation-cache",
}

const storageManager = {

	getItem: (key: StorageKeyEnum) => {
		try {
			return window.localStorage.getItem(key)
		} catch (ex) {
			console.warn(ex)
			return null
		}
	},

	setItem: (key: StorageKeyEnum, value: string) => {
		try {
			window.localStorage.setItem(key, value)
		} catch (ex) {
			console.warn(ex)
		}
	},

	removeItem: (key: StorageKeyEnum) => {
		try {
			window.localStorage.removeItem(key)
		} catch (ex) {
			console.warn(ex)
		}
	},

	cleanup: () => {
		const deprecatedKeys = ["betaFeatures"]
		const tempKeys = [StorageKeyEnum.ImpersonationCache]
		const allKeys = [...deprecatedKeys, ...tempKeys]

		allKeys.forEach((key) => {
			try {
				window.localStorage.removeItem(key)
			} catch (ex) {
				console.warn(ex)
			}
		})
	}, 

};


export {
	StorageKeyEnum,
	storageManager,
}
