<template>
	<ApplicationSection title="Co-applicant/Spouse">
		<div class="mb-5 fw-bold">
			Please select all types of proof of residency you are able to provide
		</div>

		<div class="element-container">
			<label for="coapplicant-termination-residency" class="mt-2 form-label">
				Termination of previous residency outside of Florida
			</label>
			<CustomRadioGroup required groupId="coapplicant-termination-residency"
				v-model="formFields.termination_residency_alt" :elementRequiredHandler="elementRequiredHandler"
				:options="[
					{ id: 'coapplicant-termination-residency-yes', name: 'coapplicant-termination-residency', label: 'Yes', value: true },
					{ id: 'coapplicant-termination-residency-no', name: 'coapplicant-termination-residency', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.termination_residency_alt === true">
			<FileUploader id="coapplicant-termination-residency-upload-document" :required="true"
				:accept="acceptFileTypes" :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="coapplicant-termination-residency-upload-document" class="form-label-required">
						Upload Document
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="coapplicant-termination-residency-upload-document" />
		</div>

		<div class="element-container ms-2" v-if="formFields.termination_residency_alt === true">
			<label for="coapplicant-termination-date" class="mt-2 form-label form-label-required">
				Termination Date
			</label>
			<DateComboBox id="coapplicant-termination-date" required v-model="formFields.termination_date_alt"
				@invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label for="fl-id" class="form-label">
				FL Driver License or ID Card Number
			</label>
			<CustomRadioGroup required groupId="coapplicant-fl-id" v-model="formFields.coapplicant_fl_id"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'coapplicant-fl-id-yes', name: 'coapplicant-fl-id', label: 'Yes', value: true },
					{ id: 'coapplicant-fl-id-no', name: 'coapplicant-fl-id', label: 'No', value: false }
				]" />
		</div>

		<div v-if="formFields.coapplicant_fl_id">
			<div class="element-container ms-2" v-for="field in coapplicantProofOfResidencyFields"
				:key="`coapplicant-por-${field.id}`">
				<label :for="`coapplicant-por-${field.id}`" class="form-label"
					:class="{ 'form-label-required': field.required }">
					{{ field.label }}
				</label>
				<component :id="`coapplicant-por-${field.id}`" :is="field.component" v-bind="{
					...field.props,
					onInvalid: elementRequiredHandler
				}" v-model="formFields[field.model]" :required="field.required"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container">
			<label for="coapplicant-relinquish-license" class="mt-2 form-label">
				Evidence of relinquishing driver license from other state
			</label>
			<CustomRadioGroup required groupId="coapplicant-relinquish-license"
				v-model="formFields.relinquish_license_alt" :elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'coapplicant-relinquish-license-yes', name: 'coapplicant-relinquish-license', label: 'Yes', value: true },
					{ id: 'coapplicant-relinquish-license-no', name: 'coapplicant-relinquish-license', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.relinquish_license_alt">
			<FileUploader id="coapplicant-relinquish-license-upload-document" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="coapplicant-relinquish-license-upload-document" class="form-label-required">
						Upload Document
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="coapplicant-relinquish-license-upload-document" />
		</div>

		<div class="element-container">
			<label for="coapplicant-fl-tag" class="mt-2 form-label">
				Florida Vehicle Tag Number
			</label>
			<CustomRadioGroup required groupId="coapplicant-fl-tag" v-model="formFields.fl_tag_alt"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'coapplicant-fl-tag-yes', name: 'coapplicant-fl-tag', label: 'Yes', value: true },
					{ id: 'coapplicant-fl-tag-no', name: 'coapplicant-fl-tag', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.fl_tag_alt">
			<FileUploader id="coapplicant-fl-tag-upload-document" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="coapplicant-fl-tag-upload-document" class="form-label-required">
						Upload Document
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="coapplicant-fl-tag-upload-document" />
		</div>

		<div class="element-container">
			<label for="coapplicant-fl-voter-registration" class="mt-2 form-label">
				Florida Voter Registration Number (if US citizen)
			</label>
			<CustomRadioGroup required groupId="coapplicant-fl-voter-registration"
				v-model="formFields.fl_voter_registration_alt" :elementRequiredHandler="elementRequiredHandler"
				:options="[
					{ id: 'coapplicant-fl-voter-registration-yes', name: 'coapplicant-fl-voter-registration', label: 'Yes', value: true },
					{ id: 'coapplicant-fl-voter-registration-no', name: 'coapplicant-fl-voter-registration', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.fl_voter_registration_alt">
			<FileUploader id="coapplicant-fl-voter-registration-upload-document" :required="true"
				:accept="acceptFileTypes" :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="coapplicant-fl-voter-registration-upload-document" class="form-label-required">
						Upload Document
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="coapplicant-fl-voter-registration-upload-document" />
		</div>

		<div class="element-container ms-2" v-if="formFields.fl_voter_registration_alt === true">
			<label for="coapplicant-registration-date" class="mt-2 form-label form-label-required">
				Registration Date
			</label>
			<DateComboBox id="coapplicant-registration-date" required v-model="formFields.registration_date_alt"
				@invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container" v-for="field in coapplicantProofOfResidencyFields2"
			:key="`coapp-spouse-${field.id}`">
			<label :for="`coapp-spouse-${field.id}`" class="form-label"
				:class="{ 'form-label-required': field.required }">
				{{ field.label }}
			</label>
			<component :id="`coapp-spouse-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required" @invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label for="coapplicant-bank-statement" class="mt-2 form-label">
				Bank Statement with Address at Homestead
			</label>
			<CustomRadioGroup required groupId="coapplicant-bank-statement" v-model="formFields.bank_statement_alt"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'coapplicant-bank-statement-yes', name: 'coapplicant-bank-statement', label: 'Yes', value: true },
					{ id: 'coapplicant-bank-statement-no', name: 'coapplicant-bank-statement', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.bank_statement_alt === true">
			<FileUploader id="coapplicant-bank-statement-upload-document" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="coapplicant-bank-statement-upload-document" class="form-label-required">
						Attach File
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="coapplicant-bank-statement-upload-document" />
		</div>

		<div class="element-container">
			<label for="coapplicant-utilities-proof" class="mt-2 form-label">
				Proof of Payment of Utilities at Homestead Address
			</label>
			<CustomRadioGroup required groupId="coapplicant-utilities-proof" v-model="formFields.utilities_proof_alt"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'coapplicant-utilities-proof-yes', name: 'coapplicant-utilities-proof', label: 'Yes', value: true },
					{ id: 'coapplicant-utilities-proof-no', name: 'coapplicant-utilities-proof', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.utilities_proof_alt === true">
			<FileUploader id="coapplicant-upload-utilities-proof-upload-document" :required="true"
				:accept="acceptFileTypes" :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="coapplicant-upload-utilities-proof-upload-document" class="form-label-required">
						Attach File
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="coapplicant-upload-utilities-proof-upload-document" />
		</div>

		<div class="element-container">
			<label for="coapplicant-other-owners" class="mt-2 form-label">
				Name and Address of Any Owners Not Residing on the Property
			</label>
			<InputText id="coapplicant-other-owners" v-model="formFields.other_owners_alt" />
		</div>

	</ApplicationSection>

</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed, watch } from 'vue'
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import FileUploader from "@/components/Shared/FileUploader.vue"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"

const {
	elementRequiredHandler,
	acceptFileTypes,
	uploadFiles
} = defineProps<{
	elementRequiredHandler: <T extends Event>(e: T) => void;
	acceptFileTypes: string;
	uploadFiles: (e: Event) => void;
}>()

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

interface CoapplicantProofOfResidencyField {
	id: string;
	label: string;
	component: any;
	model: keyof typeof formFields.value;
	required: boolean;
	props?: Record<string, any>;
}

const coapplicantProofOfResidencyFields = computed((): CoapplicantProofOfResidencyField[] => [
	{ id: 'coapplicant-id-number', label: 'ID Number', component: InputText, model: 'coapplicant_id_number', required: !!formFields.value.coapplicant_fl_id },
	{ id: 'coapplicant-id-issue-date', label: 'ID Issue Date', component: DateComboBox, model: 'coapplicant_id_issue_date', required: !!formFields.value.coapplicant_fl_id },
])

const coapplicantProofOfResidencyFields2: CoapplicantProofOfResidencyField[] = [
	// { id: 'coapplicant-registration-date', label: 'Registration Date', component: DateComboBox, model: 'registration_date_alt', required: !!formFields.value.fl_voter_registration_alt },
	{ id: 'coapplicant-declaration-domicile', label: 'Declaration of Domicile', component: DateComboBox, model: 'declaration_domicile', required: true },
	{ id: 'coapplicant-current-employer', label: 'Current Employer', component: InputText, model: 'current_employer', required: false },
	{ id: 'coapplicant-irs-address', label: 'Address on your last IRS return', component: InputText, model: 'irs_address', required: false },
	{ id: 'coapplicant-school-location', label: 'School Location of Dependent Children', component: InputText, model: 'school_location', required: false }
]

watch(() => formFields.value.termination_residency_alt, (newVal) => {
	// If termination residency is not selected, clear related fields
	if (!newVal) {
		formFields.value.termination_date_alt = ""
	}
})

watch(() => formFields.value.coapplicant_fl_id, (newVal) => {
	// If FL ID is not selected, clear related fields
	if (!newVal) {
		formFields.value.coapplicant_id_number = ""
		formFields.value.coapplicant_id_issue_date = ""
	}
})

watch(() => formFields.value.relinquish_license_alt, (newVal) => {
	// If relinquish license is not selected, clear related fields
	if (!newVal) {
		formFields.value.relinquish_license_alt = null
	}
})

watch(() => formFields.value.fl_tag_alt, (newVal) => {
	// If FL vehicle tag is not selected, clear related fields
	if (!newVal) {
		formFields.value.vehicle_tag_number = ""
	}
})

watch(() => formFields.value.fl_voter_registration_alt, (newVal) => {
	// If FL voter registration is not selected, clear related fields
	if (!newVal) {
		formFields.value.registration_date_alt = ""
	}
})

watch(() => formFields.value.bank_statement_alt, (newVal) => {
	// If bank statement is not selected, clear related fields
	if (!newVal) {
		formFields.value.bank_statement_alt = null
	}
})

watch(() => formFields.value.utilities_proof_alt, (newVal) => {
	// If utilities proof is not selected, clear related fields
	if (!newVal) {
		formFields.value.utilities_proof_alt = null
	}
})

</script>
