<template>
    <div id="config-new-mail-template-container">
        <form @submit.prevent="submitMailTemplate()">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="config-mail-temp-name">
                            Template Name <span class="required">*</span>
                        </label>
                        <input type="text" class="cbFormTextField cbFormTextField-xl" maxlength="255" required
                            id="config-mail-temp-name" v-model="templateName" />
                    </div>
                    <div class="form-group mt-2">
                        <label for="config-response-days">
                            Response Days
                        </label>
                        <input type="number" class="cbFormTextField cbFormTextField-xl" min="0" max="999"
                            id="config-response-days" v-model="responseDays" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-2 d-flex">
                    <button type="submit" class="searchButton" :disabled="formLoading">
                        <span class="fa fa-plus-circle" aria-hidden="true"></span>
                        Add
                    </button>
                    <loading-icon v-if="formLoading" />
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import type { AxiosError } from "axios"
import { getApiErrorMessage } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import type { MailTemplates } from "@/helpers/interface/admin-page"

const api = useAPI()
const templateName = ref<string>("")
const responseDays = ref<string>("")
const formLoading = ref(false)
const emits = defineEmits(["addNewMailTemp"])
const submitMailTemplate = async () => {
	formLoading.value = true
	try {
		const requestBody: Partial<MailTemplates> = {
			"name": templateName.value,
			"response_days": parseInt(responseDays.value),
		}
		await api.post(`/mailing/templates/`, requestBody)
		toast.success("Your submission was successful")
		templateName.value = responseDays.value = ""
		emits("addNewMailTemp")
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
	formLoading.value = false;
}
onMounted(() => {
	(document.querySelector("#config-mail-temp-name") as HTMLInputElement)?.focus()
})
</script>