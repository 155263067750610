<template>
	<ApplicationSection title="Primary Applicant">
		<div class="element-container">
			<FileUploader :id="getId('id-file-1')" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label :for="getId('id-file-1')" class="form-label form-label-required">
						Upload: Driver License or state issued personal identification certificate
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus :containerID="getId('id-file-1')" />
		</div>
		<div class="element-container" v-for="field in applicantFields" :key="`primary-${field.id}`">
			<label :for="`primary-${field.id}`" class="form-label" :class="{ 'form-label-required': field.required }">
				{{ field.label }}
				<span class="fa fa-info-circle" v-if="field.tooltip" v-tooltip="field.tooltip"></span>
			</label>
			<component :id="`primary-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required" @invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label class="form-label form-label-required">
				Are you a citizen of the United States?
			</label>
			<CustomRadioGroup required groupId="is-us-citizen" v-model="formFields.applicant_is_us_citizen"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'is-us-citizen-yes', name: 'is-us-citizen', label: 'Yes', value: true },
					{ id: 'is-us-citizen-no', name: 'is-us-citizen', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.applicant_is_us_citizen === true">
			<label for="applicant-sss-no" class="form-label form-label-required">
				Social Security #
				<span class="fa fa-info-circle"
					v-tooltip="'Disclosure of your social security number is mandatory. It is required by section 196.011(1)(b), Florida Statutes. The social security number will be used to verify taxpayer identity and homestead exemption information submitted to property appraisers.'"></span>
			</label>
			<InputMask id="applicant-sss-no" v-model="formFields.applicant_sss_no" mask="999-99-9999"
				@invalid.capture.prevent="elementRequiredHandler" :pt="requiredInputAttribute" />
		</div>

		<div class="element-container ms-2" v-if="formFields.applicant_is_us_citizen === false">
			<label for="applicant-imigration-no" class="form-label form-label-required">
				Immigration #
			</label>
			<InputText id="applicant-immigration-no" v-model="formFields.applicant_imigration_no"
				@invalid.capture.prevent="elementRequiredHandler" required />
		</div>

		<div class="element-container" v-for="field in applicantFields2" :key="`primary2-${field.id}`">
			<label :for="`primary2-${field.id}`" class="form-label" :class="{ 'form-label-required': field.required }">
				{{ field.label }}
				<span class="fa fa-info-circle" v-if="field.tooltip" v-tooltip="field.tooltip"></span>
			</label>
			<component :id="`primary2-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required" @invalid.capture.prevent="elementRequiredHandler"
				:pt="field.pt" />
		</div>

		<div class="element-container">
			<label class="form-label form-label-required">
				Does the primary applicant claim residency in another county or state?
			</label>
			<CustomRadioGroup required groupId="applicant-claim-residency"
				v-model="formFields.applicant_claim_residency" :elementRequiredHandler="elementRequiredHandler"
				:options="[
					{ id: 'claim-residency-yes', name: 'applicant-claim-residency', label: 'Yes', value: true },
					{ id: 'claim-residency-no', name: 'applicant-claim-residency', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container">
			<label for="applicant-marital-status" class="form-label form-label-required">
				Marital Status
			</label>
			<Select @invalid.capture.prevent="elementRequiredHandler" inputId="applicant-marital-status"
				v-model="formFields.applicant_marital_status" showClear :options="maritalStatusOptions"
				placeholder="-- Select --" optionLabel="label" optionValue="value" />
		</div>

		<div class="element-container"
			v-if="formFields.applicant_marital_status && formFields.applicant_marital_status !== 'married'">
			<label for="applicant-add-coapplicant" class="form-label form-label-required">
				Add a co-applicant?
			</label>
			<CustomRadioGroup required groupId="applicant-add-coapplicant"
				v-model="formFields.applicant_add_coapplicant" :elementRequiredHandler="elementRequiredHandler"
				:options="[
					{ id: 'coapplicant-yes', name: 'applicant-add-coapplicant', label: 'Yes', value: true },
					{ id: 'coapplicant-no', name: 'applicant-add-coapplicant', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container"
			v-if="formFields.applicant_marital_status && formFields.applicant_marital_status === 'married'">
			<label for="applicant-add-spouse" class="form-label form-label-required">
				Add spouse as a co-applicant?
				<span class="fa fa-info-circle" v-tooltip="'This is required unless married and living separately'">
				</span>
			</label>
			<CustomRadioGroup required groupId="applicant-add-spouse" v-model="formFields.applicant_add_spouse"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'spouse-yes', name: 'applicant-add-spouse', label: 'Yes', value: true },
					{ id: 'spouse-no', name: 'applicant-add-spouse', label: 'No', value: false }
				]" />
		</div>
	</ApplicationSection>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import InputNumber from "primevue/inputnumber"
import InputMask from "primevue/inputmask"
import Select from "primevue/select"
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import FileUploader from "@/components/Shared/FileUploader.vue"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed, watch } from 'vue'
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"
import { requiredInputAttribute } from "@/helpers/common"

const {
	elementRequiredHandler,
	acceptFileTypes,
	uploadFiles,
	maritalStatusOptions
} = defineProps<{
	elementRequiredHandler: <T extends Event>(e: T) => void;
	acceptFileTypes: string;
	uploadFiles: (e: Event) => void;
	maritalStatusOptions: { label: string; value: string; }[]
}>()

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

interface ApplicantField {
	id: string;
	label: string;
	component: any;
	model: keyof typeof formFields.value;
	required?: boolean;
	tooltip?: string;
	pt?: {};
	props?: Record<string, any>;
	condition?: () => boolean;
}

const getId = (text: string) => (`property-owner-${text}`)

const applicantFields = computed((): ApplicantField[] => [
	{ id: 'applicant-first-name', label: 'First Name', component: InputText, model: 'applicant_first_name', required: true },
	{ id: 'applicant-middle-name', label: 'Middle Name', component: InputText, model: 'applicant_middle_name', required: false },
	{ id: 'applicant-last-name', label: 'Last Name', component: InputText, model: 'applicant_last_name', required: true },
	{ id: 'applicant-suffix', label: 'Name Suffix (e.g. Sr., IV, etc.)', component: InputText, model: 'applicant_suffix', required: false }
])

const applicantFields2 = computed((): ApplicantField[] => [
	{ id: 'applicant-dob', label: 'Date of Birth', component: DateComboBox, model: 'applicant_dob', required: true, props: { required: true, maxDate: new Date(), minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)) }, pt: requiredInputAttribute },
	{ id: 'applicant-percent-ownership', label: 'Percent ownership #', component: InputNumber, model: 'applicant_percent_ownership', required: true, props: { min: 0, max: 100 }, tooltip: "Married couples own 100% of the property as a union: both applicants should be 100% unless living separately.", pt: requiredInputAttribute },
	{ id: 'applicant-date-permanent-residency', label: 'Date of permanent residency in Florida', component: DateComboBox, model: 'applicant_date_permanent_residency', required: true, pt: requiredInputAttribute },
	{ id: 'applicant-email', label: 'Email Address', component: InputText, model: 'applicant_email', required: true, props: { type: 'email' }, tooltip: "To be used exclusively for communication about this application." },
	{ id: 'applicant-daytime-phone', label: 'Daytime Phone #', component: InputText, model: 'applicant_daytime_phone', required: false },
])

watch(() => formFields.value.applicant_is_us_citizen, (newVal) => {
	if (newVal === true) {
		formFields.value.applicant_imigration_no = ""
	}

	if (newVal === false) {
		formFields.value.applicant_sss_no = ""
	}
})

watch(() => formFields.value.applicant_marital_status, (newVal) => {
	// If marital status is not set or is 'married', clear the co-applicant selection
	if (!newVal || newVal === 'married') {
		formFields.value.applicant_add_coapplicant = null
	}
})

watch(() => formFields.value.applicant_add_spouse, (newVal) => {
	// If spouse as co-applicant is deselected, reset the field to null
	if (!newVal) {
		formFields.value.applicant_add_spouse = null
	}
})

</script>
