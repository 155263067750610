<template>
    <select class="cbFormTextField" v-model="unqualifiedStartYearField" required>
        <option value="" selected>-- Select --</option>
        <option v-for="year in unqualifiedDropdown" :value="year" :key="year">
            {{ year }}
        </option>
    </select>
</template>
<script setup lang="ts">
import dayjs from "dayjs"
import { ref, onMounted, watch } from "vue"

const unqualifiedDropdown = ref<string[]>([])
const currentYear: number = dayjs().year()
const emit = defineEmits(['fieldUpdate'])
const props = defineProps({
	startYear: {
		default: 2009,
		type: Number
	},
	unqualifiedStartYear: {
		default: "",
		type: String
	}
})
const startYear = ref<number>(props.startYear)
const unqualifiedStartYearField = ref<String>(props.unqualifiedStartYear)

onMounted(() => {
	unqualifiedDropdown.value = []
	for (let index = (currentYear + 1); index >= startYear.value; index--) {
		unqualifiedDropdown.value.push(index.toString());
	}
})

watch(() => unqualifiedStartYearField.value, (unqualifiedStartYear) => {
	emit('fieldUpdate', unqualifiedStartYear)
})

watch(() => props.unqualifiedStartYear, (unqualifiedStartYear) => {
	unqualifiedStartYearField.value = unqualifiedStartYear
})
</script>