<template>
    <div :id="props.containerID + '-upload-status'">
        <div :id="props.containerID + '-upload-status-1'" style="display: none;">
            <span class="fa fa-check-circle fa-success mx-1" aria-hidden="true"></span>
            <span :id="props.containerID + '-upload-status-1-message'"></span>
        </div>
        <div :id="props.containerID + '-upload-status-2'" style="display: none;">
            <span class="fa fa-times-circle fa-danger mx-1" aria-hidden="true"></span>
            <span :id="props.containerID + '-upload-status-0-message'"></span>
        </div>
        <div :id="props.containerID + '-upload-status-0'" style="display: none;">
            <span class="fa fa-spinner fa-spin mx-2" aria-hidden="true"></span>
            Uploading, please wait.
        </div>
    </div>
</template>

<script setup lang="ts">

const props = defineProps({
	containerID: {
		type: String,
		required: true
	}
})
</script>
