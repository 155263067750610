
import type { Owners, QuickLinkItem, ParcelAddressInfo } from "@/helpers/interface/candidates";
import { ucwords, blankIf } from "@/helpers/common"
import dayjs from "dayjs";


const COORD_OFFSET = 0.003;

/* Careful: these functions are for use with services that use LATITUDE,LONGITUDE ->  y,x instead of x,y  
    such as Vrbo
*/
const getLatLongString = (latitude: number, longitude: number) => `${latitude.toFixed(5)},${longitude.toFixed(5)}`
const getLatLongStringFromObject = (info: ParcelAddressInfo) => getLatLongString(info.latitude!, info.longitude!)


const getAirBnbQuickLink = (info: ParcelAddressInfo): QuickLinkItem => {

	const formatter = (v?: string | null | undefined) => v ? v.split(" ").join("-") : ""

	const nbr = info.house_nbr;
	const street_name = formatter(info.street_name);
	const street_suffix = info.street_suffix;
	const city = formatter(info.city_name);
	const state = info.state;
	const zip = info.zip5;

	const query = {
		tab_id: "home_tab",
		query: info.address_full,
		search_by_map: "true",
		search_type: "user_map_move",
		search_mode: "regular_search",
		price_filter_input_type: "2",
		channel: "EXPLORE"
	}

	const queryParts = new URLSearchParams(query as any);

	if (info.latitude && info.longitude) {
		queryParts.append("ne_lat", (info.latitude + COORD_OFFSET).toString())
		queryParts.append("ne_lng", (info.longitude + COORD_OFFSET).toString())
		queryParts.append("sw_lat", (info.latitude - COORD_OFFSET).toString())
		queryParts.append("sw_lng", (info.longitude - COORD_OFFSET).toString())
	}

	const path = `s/${nbr}-${street_name}-${street_suffix}--${city}--${state}-${zip}--USA/homes`;
	const queryString = queryParts.toString();

	return { label: "Airbnb", url: `https://www.airbnb.com/${path}?${queryString}` };
}

const getVrboQuickLink = (info: ParcelAddressInfo): QuickLinkItem => {
	const destination = `${info.house_nbr} ${info.street_name} ${info.street_suffix}, ${info.city_name}, ${info.state} ${info.zip5}, USA`
		.replace("  ", " ");

	const queryParts = new URLSearchParams({ destination });

	if (info.latitude && info.longitude) {
		/*
        HEADS UP
        Vrbo uses coordinate strings with values {LATITUDE},{LONGITUDE} (aka Y, X)
        */
		const latLong = getLatLongStringFromObject(info)
		queryParts.append("latLong", latLong)

		const offsets = [COORD_OFFSET * -1, COORD_OFFSET]
		offsets.forEach(offset => {
			// Ensure order is LATITUDE, LONGITUDE for Vrbo
			const coordString = getLatLongString(info.latitude! + offset, info.longitude! + offset)
			queryParts.append("mapBounds", coordString)
		})
	}

	return { label: "Vrbo", url: `https://www.vrbo.com/search?${queryParts.toString()}` };
}

const createQuickLinkAnchor = (link: QuickLinkItem) => {
	const a = document.createElement("a")
	a.classList.add("text-white")
	a.classList.add("text-capitalize")
	a.href = link.url
	a.innerText = link.label
	a.target = "_blank"
	a.rel = "noopener noreferrer"

	const label = document.createElement("label")
	label.classList.add("chips-container")
	label.appendChild(a)
	return label
}

export const getPropertyQuickLinks = (info: ParcelAddressInfo): QuickLinkItem[] => {
	const handlers = [
		getAirBnbQuickLink,
		getVrboQuickLink,
	]
	return handlers.map(fn => fn.call(null, info))
}

export const getOwnerQuickLinks = (owner: Owners): QuickLinkItem[] => {
	const processName = (name: string): string => encodeURIComponent(ucwords(blankIf(name)))

	const joinNames = (names: string[], separator: string = " ", encode: boolean = false): string => {
		const joined = names.filter(name => name !== "").join(separator)
		return encode ? encodeURIComponent(joined) : joined
	}

	const firstName = processName(owner.first_name)
	const middleName = owner.commonality_score > 0.8 ? processName(owner.middle_name) : ""
	const lastName = processName(owner.last_name)
	const state = processName(owner.state)
	const city = blankIf(owner.city)

	const fullName = joinNames([firstName, middleName, lastName])
	const address = joinNames([city, state].filter(part => part !== ""), " ")

	const dob = encodeURIComponent(dayjs(owner.birth_date).toISOString())

	const baseGoogleKeywords = joinNames([fullName, address], " ", true)
	const obituaryKeywords = `${baseGoogleKeywords} obituary`
	const deathKeywords = `${baseGoogleKeywords} death`

	const fullNameAncestry = joinNames([joinNames([firstName, middleName], " ", true), lastName], "_", true)
	const firstAndMiddleNameLegacy = joinNames([firstName, middleName], " ", true)

	return [
		{
			label: "Google",
			url: `https://www.google.com/search?q=${baseGoogleKeywords}`
		},
		{
			label: "Google Obituary",
			url: `https://www.google.com/search?q=${obituaryKeywords}`
		},
		{
			label: "Google Death",
			url: `https://www.google.com/search?q=${deathKeywords}`
		},
		{
			label: "Facebook",
			url: `https://www.facebook.com/search/people?q=${fullName}`
		},
		{
			label: "LinkedIn",
			url: `https://www.linkedin.com/search/results/people/?keywords=${fullName}`
		},
		{
			label: "Ancestry",
			url: `https://www.ancestry.com/search/categories/34/?name=${fullNameAncestry}&birth=${dob}&priority=usa`
		},
		{
			label: "Legacy",
			url: `https://www.legacy.com/search?countryId=366899&countryUrl=united-states-of-america&dateRange=All&firstName=${firstAndMiddleNameLegacy}&lastName=${lastName}&stateUrl=${state}`
		},
	]
}

export const createQuickLinksList = (links: QuickLinkItem[] | null) => {
	const container = document.createElement("div")
	if (links && links.length) {
		links.forEach(link => {
			const label = createQuickLinkAnchor(link)
			container.appendChild(label)
		})
	}
	return container
}
