export class PanZoomer {
	img: HTMLElement | null;
	scale: number;
	rotation: number;
	startX: number;
	startY: number;
	translateX: number;
	translateY: number;
	dragging: boolean;

	constructor(imgSelector: string) {
		this.img = document.querySelector(imgSelector);
		this.scale = 1;
		this.rotation = 0;
		this.startX = 0;
		this.startY = 0;
		this.translateX = 0;
		this.translateY = 0;
		this.dragging = false;

		if (this.img) {
			if (!this.img.classList.contains("tr-pan-zoom-enabled")) {
				this.img.classList.add("tr-pan-zoom-enabled")
				this.addEventListeners();
			} else {
				this.reset()
			}
		}
	}

	private addEventListeners() {
		if (!this.img) return;

		this.img.addEventListener('wheel', this.onWheel.bind(this));
		this.img.addEventListener('mousedown', this.onMouseDown.bind(this));
		this.img.addEventListener('mouseup', this.onMouseUp.bind(this));
		this.img.addEventListener('mousemove', this.onMouseMove.bind(this));
	}

	private onWheel(e: WheelEvent) {
		e.preventDefault();
		this.scale += e.deltaY * -0.01;
		this.scale = Math.min(Math.max(0.5, this.scale), 3); // Limit zoom levels

		this.updateTransform();
	}

	private onMouseDown(e: MouseEvent) {
		e.preventDefault();
		this.dragging = true;

		// Save the mouse starting position relative to the current translation
		this.startX = e.clientX - this.translateX;
		this.startY = e.clientY - this.translateY;

		if (this.img) {
			this.img.classList.add("tr-pan-zoom-dragging")
		}
	}

	private onMouseUp(e: MouseEvent) {
		e.preventDefault();
		this.dragging = false;

		if (this.img) {
			this.img.classList.remove("tr-pan-zoom-dragging")
		}
	}

	private onMouseMove(e: MouseEvent) {
		e.preventDefault();
		if (!this.dragging) return;

		// Calculate the new translation using the difference from the start position
		this.translateX = e.clientX - this.startX;
		this.translateY = e.clientY - this.startY;

		this.updateTransform();
	}

	private updateTransform() {
		if (!this.img) {
			return;
		}
		this.img.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale}) rotate(${this.rotation}deg)`;
	}

	zoomIn() {
		this.scale = Math.min(this.scale + 0.1, 3);
		this.updateTransform();
	}

	zoomOut() {
		this.scale = Math.max(this.scale - 0.1, 0.5);
		this.updateTransform();
	}

	rotate(degrees: number) {
		this.rotation = (this.rotation + degrees) % 360;
		if (this.rotation < 0) {
			this.rotation += 360;
		}
		this.updateTransform();
	}

	reset() {
		this.scale = 1;
		this.rotation = 0;
		this.translateX = 0;
		this.translateY = 0;
		if (this.img)
			this.img.style.transform = "";
	}

}

