<template>
	<ApplicationSection title="Mailing Address">
		<div v-if="formFields.mailing_address_different === true">
			<div class="element-container" v-for="field in mailingFields" :key="`mailing-address-${field.id}`">
				<label :for="`mailing-address-${field.id}`" class="form-label"
					:class="{ 'form-label-required': field.required }">
					{{ field.label }}
				</label>
				<component :id="`mailing-address-${field.id}`" :is="field.component" v-bind="{
					...field.props,
					onInvalid: elementRequiredHandler
				}" v-model="formFields[field.model]" :required="field.required"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container" v-for="field in deedFields" :key="`deed-${field.id}`">
			<label :for="`deed-${field.id}`" class="form-label" :class="{ 'form-label-required': field.required }">
				{{ field.label }}
			</label>
			<component :id="`deed-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required"
				@invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container">
			<label class="form-label form-label-required">
				Did any applicant receive or file for exemptions last year?
			</label>
			<CustomRadioGroup groupId="applicant-receive-exemption-last-year" required
				v-model="formFields.applicant_receive_exemption_last_year"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'receive-exemption-last-year-yes', name: 'applicant-receive-exemption-last-year', label: 'Yes', value: true },
					{ id: 'receive-exemption-last-year-no', name: 'applicant-receive-exemption-last-year', label: 'No', value: false }
				]" />

		</div>
	</ApplicationSection>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed, watch } from 'vue'
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"

const {
	elementRequiredHandler
} = defineProps<{ elementRequiredHandler: <T extends Event>(e: T) => void; }>()

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

interface Field {
	id: string;
	label: string;
	component: any;
	model: keyof typeof formFields.value;
	required: boolean;
	props?: Record<string, any>;
}

const mailingFields: Field[] = [
	{ id: 'mailing-street', label: 'Mailing Street', component: InputText, model: 'mailing_street', required: true },
	{ id: 'mailing-unit', label: 'Mailing Unit', component: InputText, model: 'mailing_unit', required: false },
	{ id: 'mailing-city', label: 'Mailing City', component: InputText, model: 'mailing_city', required: true },
	{ id: 'mailing-state', label: 'Mailing State', component: InputText, model: 'mailing_state', required: true },
	{ id: 'mailing-postal-code', label: 'Mailing Postal Code', component: InputText, model: 'mailing_postal_code', required: true }
]

const deedFields: Field[] = [
	{ id: 'deed-type', label: 'Type of Deed', component: InputText, model: 'deed_type', required: false },
	{ id: 'deed-date', label: 'Date of Deed', component: DateComboBox, model: 'deed_date', required: true, props: { maxDate: new Date() } },
	{ id: 'deed-book', label: 'Deed Book', component: InputText, model: 'deed_book', required: false },
	{ id: 'deed-page', label: 'Deed Page', component: InputText, model: 'deed_page', required: false },
	{ id: 'deed-recorded-date', label: 'Deed Recorded Date', component: DateComboBox, model: 'deed_recorded_date', required: true, props: { maxDate: new Date() } },
	{ id: 'deed-instrument-number', label: 'Deed Instrument Number', component: InputText, model: 'deed_instrument_number', required: false }
]

watch(() => formFields.value.mailing_address_different, (newVal) => {
	if (!newVal) {
		formFields.value.mailing_street = ""
		formFields.value.mailing_unit = ""
		formFields.value.mailing_city = ""
		formFields.value.mailing_state = ""
		formFields.value.mailing_postal_code = ""
	}
})
</script>