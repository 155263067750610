<template>
	<TheHeader />
	<div v-if="!loadingState">
		<div v-if="hasCustomerAccessToApplicationVetter === true">
			<div v-if="hasPermissionToUpdateApplicationVetter">
				<SidebarNavigation :menus="appvetTabs" :collapsed="isCollapsed" :activeMenu="activeMenu"
					:key="activeMenu" :ignoreVal="['application_upload']" @menuSelected="handleMenuSelected"
					@collapseChanged="handleCollapsed" />
				<div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
					<div class="sidebar-content">
						<Card v-if="activeMenu === ApplicationVetterTabDefinitions.Inbox.id">
							<template #content>
								<ApplicationVetterList @updateStatus="handleUpdateStatus"
									@qsArchive="handleQuickSearchArchive" :queue="[APPLICATION_STATUS_MAP.PENDING]" />
							</template>
						</Card>
						<Card v-if="activeMenu === ApplicationVetterTabDefinitions.Snooze.id">
							<template #content>
								<ApplicationVetterList @updateStatus="handleUpdateStatus"
									@qsArchive="handleQuickSearchArchive" :queue="[APPLICATION_STATUS_MAP.SNOOZE]" />
							</template>
						</Card>
						<Card v-if="activeMenu === ApplicationVetterTabDefinitions.All.id">
							<template #content>
								<ApplicationVetterList @updateStatus="handleUpdateStatus"
									@qsArchive="handleQuickSearchArchive"
									:queue="[APPLICATION_STATUS_MAP.PENDING, APPLICATION_STATUS_MAP.SNOOZE, APPLICATION_STATUS_MAP.APPROVED, APPLICATION_STATUS_MAP.DENIED]" />
							</template>
						</Card>
						<Card v-if="activeMenu === ApplicationVetterTabDefinitions.Archive.id">
							<template #content>
								<ApplicationVetterList @updateStatus="handleUpdateStatus"
									@qsArchive="handleQuickSearchArchive"
									:queue="[APPLICATION_STATUS_MAP.APPROVED, APPLICATION_STATUS_MAP.DENIED]" />
							</template>
						</Card>
						<Card v-if="activeMenu === ApplicationVetterTabDefinitions.Activity.id">
							<template #content>
								<ApplicationVetterDashboard />
							</template>
						</Card>
						<Card v-if="activeMenu === ApplicationVetterTabDefinitions.AssignedToMe.id">
							<template #content>
								<ApplicationVetterList @updateStatus="handleUpdateStatus"
									@qsArchive="handleQuickSearchArchive"
									:queue="[ApplicationVetterTabDefinitions.AssignedToMe.id]" />
							</template>
						</Card>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="d-block text-center m-3">
			<loading-icon class="d-inline" /> Hang tight! We're fetching your data as quickly as possible.
		</div>
	</div>
	<AppMonitor />
	<teleport to="body">
		<div class="modal-overlay" v-if="notificationVisibility.showNotification" @click="toggleNotification"></div>
		<SelfServiceDataRequestPopUp @closePopup="toggleNotification" class="notification"
			v-if="notificationVisibility.showNotification" />
	</teleport>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue"
import TheHeader from "@/components/TheHeader.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import ApplicationVetterList from "@/components/ApplicationVetter/ApplicationVetterList.vue";
import ApplicationVetterDashboard from "@/components/ApplicationVetter/ApplicationVetterDashboard.vue";
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue"
import { ApplicationVetterTabDefinitions, APPLICATION_STATUS_MAP, validateUserPermission, setPageTitle } from "@/helpers/common";
import { useProfile } from "@/stores/profile"
import Card from 'primevue/card';
import { useAPI } from "@/helpers/services/api"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import dayjs from "dayjs";
import SelfServiceDataRequestPopUp from "@/components/ApplicationVetter/SelfServeDataRequestPopUp.vue";
import router from "@/router"
import { useRoute } from "vue-router";

const api = useAPI()
const storeProfile = useProfile()
const route = useRoute()
const routeTab = (route?.params?.tab || ApplicationVetterTabDefinitions.Inbox.id) as string
const activeMenu = ref(routeTab)

const appvetTabs = ref([
	{ label: ApplicationVetterTabDefinitions.Inbox.label, iconClass: "fas fa-inbox", id: ApplicationVetterTabDefinitions.Inbox.id },
	{ label: ApplicationVetterTabDefinitions.Snooze.label, iconClass: "fas fa-clock", id: ApplicationVetterTabDefinitions.Snooze.id, badge: 0 },
	{ label: ApplicationVetterTabDefinitions.All.label, iconClass: "fas fa-list", id: ApplicationVetterTabDefinitions.All.id },
	{ label: ApplicationVetterTabDefinitions.Archive.label, iconClass: "fas fa-archive", id: ApplicationVetterTabDefinitions.Archive.id },
	{ label: ApplicationVetterTabDefinitions.Activity.label, iconClass: "fas fa-chart-line", id: ApplicationVetterTabDefinitions.Activity.id },
	{ label: ApplicationVetterTabDefinitions.AssignedToMe.label, iconClass: "fas fa-user-tag", id: ApplicationVetterTabDefinitions.AssignedToMe.id },
	{ label: ApplicationVetterTabDefinitions.ApplicationUpload.label, iconClass: "fa-solid fa-cloud-arrow-up", id: ApplicationVetterTabDefinitions.ApplicationUpload.id }
])

const isCollapsed = ref(false)

// Create a lookup map outside the function
const TAB_LABELS_BY_ID = Object.freeze(
	Object.fromEntries(
		Object.values(ApplicationVetterTabDefinitions).map(tab => [tab.id, tab.label])
	)
)

const setPageTitleForTab = (menuId?: string) => {
	// Get tab ID from parameter or URL
	const tabId = menuId || window.location.pathname.split("/").pop() || ""

	// Direct lookup instead of find operation but also allowing a fallback
	const pageTitle = TAB_LABELS_BY_ID[tabId]?.concat(" - ") || ""

	setPageTitle(`${pageTitle} Application Vetter`)
}

const navigateTo = (menuId: string) => {
	if (menuId != "application_upload") {
		activeMenu.value = menuId
		setPageTitleForTab(activeMenu.value)
		router.push({ name: "applicationVetter", params: { tab: activeMenu.value } })
	}
	else toggleNotification()
}
const handleMenuSelected = (menuId: string) => navigateTo(menuId)
const handleQuickSearchArchive = () => activeMenu.value = ApplicationVetterTabDefinitions.Archive.id
const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed
const loadingState = computed(() => storeProfile.loadingState)
const hasPermissionToUpdateApplicationVetter = computed(() => validateUserPermission("update", "applications"))
const hasCustomerAccessToApplicationVetter = computed(() => storeProfile.getCustomerAccess?.application_vetter || validateUserPermission("read", "customers"))
const handleUpdateStatus = (bulkUpdate: { prevStatus: string, status: string }) => updateAllBadges(bulkUpdate.status.replace(" ", "_"), bulkUpdate.prevStatus, false)

const notificationVisibility = ref({ showNotification: false })
const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden';
const toggleNotification = () => {
	notificationVisibility.value.showNotification = !notificationVisibility.value.showNotification;
	toggleBodyScrolling(!notificationVisibility.value.showNotification);
}
onBeforeUnmount(() => toggleBodyScrolling(true))

const updateAllBadges = (status: string, prevStatus: string, all: boolean = true) => {
	if ([...prevStatus, status].includes(ApplicationVetterTabDefinitions.Snooze.id) || all) updateExpiredSnoozeCount()
}

const updateExpiredSnoozeCount = async () => {
	const today = dayjs().format('YYYY-MM-DD')
	const filters = [
		{ field: "application_status", type: "in", value: [ApplicationVetterTabDefinitions.Snooze.id] },
		{ field: "snooze_until", type: "<=", value: today }
	]

	const data = await api.post("/applications/count", filters)
	const count = data?.data?.count || 0
	const menuItem = appvetTabs.value.find(menu => menu.id === ApplicationVetterTabDefinitions.Snooze.id)
	if (menuItem) menuItem.badge = count
}

onMounted(async () => {
	if (route?.params?.tab) setPageTitleForTab()
	else handleMenuSelected("inbox")

	await storeProfile.fetchUserPermissions()
	updateAllBadges("", "", true)
})
</script>
