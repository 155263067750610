<template>
	<ApplicationSection title="Previous Address" v-if="formFields.applicant_receive_exemption_last_year">
		<div class="element-container" v-for="field in previousAddressFields" :key="`prev-address-${field.id}`">
			<label :for="`prev-address-${field.id}`" class="form-label"
				:class="{ 'form-label-required': field.required }">
				{{ field.label }}
			</label>
			<component :id="`prev-address-${field.id}`" :is="field.component" v-bind="{
				...field.props,
				onInvalid: elementRequiredHandler
			}" v-model="formFields[field.model]" :required="field.required" @invalid.capture.prevent="elementRequiredHandler" />
		</div>
	</ApplicationSection>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed, watch } from 'vue'

const {
	elementRequiredHandler
} = defineProps<{ elementRequiredHandler: <T extends Event>(e: T) => void; }>()

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

interface PreviousAddressField {
	id: string;
	label: string;
	component: any;
	model: keyof typeof formFields.value;
	required: boolean;
	props?: Record<string, any>;
}

const previousAddressFields: PreviousAddressField[] = [
	{ id: 'prev-address-street', label: 'Street', component: InputText, model: 'prev_address_street', required: true },
	{ id: 'prev-address-unit', label: 'Unit', component: InputText, model: 'prev_address_unit', required: false },
	{ id: 'prev-address-city', label: 'City', component: InputText, model: 'prev_address_city', required: true },
	{ id: 'prev-address-state', label: 'State', component: InputText, model: 'prev_address_state', required: true },
	{ id: 'prev-address-postal-code', label: 'Postal Code', component: InputText, model: 'prev_address_postal_code', required: true }
]

watch(() => formFields.value.applicant_receive_exemption_last_year, (newVal) => {
	if (!newVal) {
		formFields.value.prev_address_street = ""
		formFields.value.prev_address_unit = ""
		formFields.value.prev_address_city = ""
		formFields.value.prev_address_state = ""
		formFields.value.prev_address_postal_code = ""
	}
})
</script>
