<template>
  <div class="row" style="align-items: flex-end">
    <div class="col-5">
      <label for="schemaInput" class="form-label">Customer</label>
      <Select id="schemaInput" editable v-model="schema"  class="w-100"
              :options="customerList" optionLabel="name" optionValue="schema_name"
              @change="handleCustomerChange" :disabled="state.customersLoading"
              @input="handleCustomerInput" @focus="handleCustomerFocus"
              />
    </div>
    <div class="col-5">
      <label for="scorecardInput" class="form-label">Scorecard Date</label>
      <select id="scorecardInput" class="form-control" v-model="key" :disabled="!schema || state.scorecardsLoading">
        <option v-for="(scorecard, index) in scorecards" :key="index" :value="scorecard['key']">
          {{ new Date(scorecard['date']).toDateString() }} ({{scorecard['key'].replace('observation_report/', '')}})
        </option>>
      </select>
    </div>
    <div class="col-2">
      <Button type="button" class="me-5" @click="downloadScorecard" :disabled="!scorecard">
        <span class="fa-solid fa-file-arrow-down" aria-hidden="true"></span> Download Scorecard
      </Button>
    </div>
  </div>
  <div class="container" style="width: 100%;">
    <div v-if="state.scorecardLoading && key" class="spinner-border" role="status">
      <span class="visually-hidden">Loading scorecard...</span>
    </div>
    <div class="" v-else-if="scorecard">
      <iframe :srcdoc="scorecard" style="width: 100%; height: 100vh"></iframe>
    </div>
  </div>

</template>

<script setup lang="ts">

import {computed, onMounted, reactive, ref, watch} from "vue";
import { hidePFGetHelpButton } from "@/helpers/common";
import { useAPI } from "@/helpers/services/api";
import type { Customer, S3File } from "@/helpers/interface/trueload";
import Button from "primevue/button";
import Select from "primevue/select";

const api = useAPI()

const scorecard = ref('');
const key = ref('');
const schema = ref('');
const customerSearchValue = ref('')
const currentItemOriginalValue = ref('')

const customers = ref<Customer[]>([])
const scorecards = ref<S3File[]>([])

const state = reactive({
	customersLoading: false,
	scorecardsLoading: false,
	scorecardLoading: false
})

watch(() => key.value, () => {
	get_scorecard(key.value)
})


const customerList = computed((): Customer[] => {
	return customers.value.filter(c => {
		const search = customerSearchValue.value.toLowerCase()
		return !!c.schema_name && (!search || (search && c.name.toLowerCase().includes(search)))
	})
}
)

const handleCustomerInput = (e: Event) => {
	const input = e.target as HTMLInputElement
	customerSearchValue.value = input.value
}

const handleCustomerFocus = (e: Event) => {
	const input = e.target as HTMLInputElement
	input.select()
}

const handleCustomerChange = async () => {
	if (schema.value === currentItemOriginalValue.value) return
	if (!customerList.value.filter(d => d.schema_name === schema.value).length) return
	await get_scorecards(schema.value)
	currentItemOriginalValue.value = schema.value
}

const get_scorecards = async (schema: string) => {
	state.scorecardsLoading = true
	const response = await api.get(`reports/scorecard/list/${schema}`)
	scorecards.value = response.data
	state.scorecardsLoading = false
}

const get_customers = async () => {
	state.customersLoading = true
	const response = await api.get(`customers/?valid_schemas=true`)
	customers.value = response.data
	state.customersLoading = false
}

const get_scorecard = async (key: string) => {
	state.scorecardLoading = true
	const response = await api.get(`reports/scorecard/get/${key}`)
	scorecard.value = response.data
	state.scorecardLoading = false
}

const downloadScorecard = () => {
	const blob = new Blob([scorecard.value], { type: 'text/html' })
	const url = URL.createObjectURL(blob)
	const link = document.createElement('a')
	link.href = url
	link.download = `${schema.value}_scorecard.html`
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
	URL.revokeObjectURL(url);
};

onMounted(async () => {
	await get_customers()
	hidePFGetHelpButton()
});

</script>

<style scoped>

</style>