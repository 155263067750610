<template>
	<ApplicationSection title="">
		<p>
			I authorize this agency to obtain information to determine my eligibility for the exemptions
			applied for.
			I qualify for these exemptions under Florida Statutes. I own the property above, and it is
			my permanent
			residence or the permanent residence of my legal or natural dependent(s). (See s. 196.031,
			Florida Statutes.)
		</p>

		<p>
			I understand that under section 196.131(2), F.S., any person who knowingly and willfully
			gives false
			information to claim homestead exemption is guilty of a misdemeanor of the first degree,
			punishable by
			imprisonment up to one year, a fine up to $5,000, or both. I have read, or have had someone
			read to me,
			the contents of this form.
		</p>

		<p>
			I certify all information on this form and any attachments are true, correct, and in effect
			on January 1 of this
			year.
		</p>

		<div class="p-3">
			<div class="element-container">
				<label for="applicant-signature-container" class="mt-2 form-label form-label-required">
					Signature, Applicant
				</label>
				<Signature v-model:signature_of_applicant="formFields.signature_of_applicant" />

			</div>
			<div class="element-container">
				<label for="date" class="mt-2 form-label">
					Date
					<b> {{ dayjs(formFields.signature_date_applicant).format("MMMM D, YYYY") }}</b>
				</label>
			</div>
		</div>
		<hr v-if="hasCoApplicant">
		<div class="p-3" v-if="hasCoApplicant">
			<div class="element-container">
				<label for="applicant-signature-container" class="mt-2 form-label form-label-required">
					Signature, Co-Applicant
				</label>
				<Signature v-model:signature_of_applicant="formFields.signature_of_coapplicant" />
			</div>

			<div class="element-container">
				<span for="date-coapplicant" class="mt-2 form-label">
					Date:
					<b> {{ dayjs(formFields.signature_date_coapplicant).format("MMMM D, YYYY") }}</b>
				</span>
			</div>
		</div>
	</ApplicationSection>
</template>

<script setup lang="ts">
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed, onMounted, ref, watch } from 'vue'
import dayjs from "dayjs"
import Signature from "@/components/ApplicationForm/Signature.vue"
import { formatDate } from '@/helpers/common'

const now = ref(dayjs())
const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)
const hasCoApplicant = computed(() => (formFields.value.applicant_add_spouse || formFields.value.applicant_add_coapplicant))

const updateDate = () => {
	now.value = dayjs()
	formFields.value.signature_date_coapplicant = formatDate(now.value.toString())
	formFields.value.signature_date_applicant = formatDate(now.value.toString())
}


onMounted(() => {
	formFields.value.signature_of_applicant = ""
	formFields.value.signature_of_coapplicant = ""

	updateDate()
	setInterval(updateDate, 2 * 1000)
})

watch(() => formFields.value.applicant_add_spouse, (newVal) => {
	// If spouse as co-applicant is deselected, clear related signature fields
	if (!newVal) {
		formFields.value.signature_of_coapplicant = ""
		formFields.value.signature_date_coapplicant = ""
	}
})

watch(() => formFields.value.applicant_add_coapplicant, (newVal) => {
	// If co-applicant is deselected, clear related signature fields
	if (!newVal) {
		formFields.value.signature_of_coapplicant = ""
		formFields.value.signature_date_coapplicant = ""
	}
})

</script>