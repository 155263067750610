<template>
    <div id="sharedSideNav">
        <div :class="['sidebar', { 'collapsed': isCollapsed }]">
            <div class="sidebar-toggler">
                <a href="javascript:;" @click="toggleSidebar">
                    <i class="fas fa-bars"></i>
                </a>
            </div>
            <nav class="nav">
                <a href="javascript:;" class="nav-link" v-for="menu in props.menus" :key="menu.id"
                    @click="setActiveMenu(menu.id)" :class="{ 'active': menu.id === activeMenu }" :hidden="menu.hidden">
                    <span class="sidebar-icon-wrapper" v-tooltip.right="(isCollapsed ? menu.label : '')">
                        <i :class="menu.iconClass"></i>
                    </span>
                    <span class="sidebar-item-text" v-if="!isCollapsed">
                        {{ menu.label }}
                        <Badge v-if="menu.badge && menu.badge > 0" :value="menu.badge" severity="success"
                            class="p-badge-circle p-badge-xs"></Badge>
                    </span>
                </a>
            </nav>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import type { PropType } from 'vue'
import type { SidebarNavigationMenu } from "@/helpers/interface/candidates"
import Badge from 'primevue/badge';

const props = defineProps({
	menus: {
		type: Array as PropType<SidebarNavigationMenu[]>,
		required: true
	},
	collapsed: {
		type: Boolean,
		required: true
	},
	activeMenu: {
		type: String,
		required: false,
		default: null
	},
	ignoreVal: {
		type: Array as () => string[],
		required: false,
		default: () => ['ignoreVal']
	}
})

watch(() => props.activeMenu, (newVal) => activeMenu.value = newVal)
const emits = defineEmits(['menuSelected', 'collapseChanged'])
const isCollapsed = ref(props.collapsed)
const activeMenu = ref(props.activeMenu)
const ignoreVal = ref(props.ignoreVal)

const toggleSidebar = () => {
	isCollapsed.value = !isCollapsed.value
	emits('collapseChanged', isCollapsed.value)
}
const setActiveMenu = (id: string) => {
	if (!ignoreVal.value.includes(id)) {
		activeMenu.value = id
		emits('menuSelected', activeMenu.value)
	}
	else {
		emits('menuSelected', id)
	}
}
</script>