<template>
    <div>
        <DataView v-if="scrollHeight !== 0" class="strap-card-list-container" :class="{ 'd-none': props.loading }" :value="props.data" :paginator="true"
            dataKey="application_id" :first="props.firstIndex" :rows="props.rows" filterDisplay="row" :loading="loading"
            :id="'DataView-' + props.componentID" lazy :totalRecords="props.totalRecords"
            :rowsPerPageOptions="[25, 50, 100, 200, 500]"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink  PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" @page="onPage($event)">
            <template #paginatorstart>
            </template>
            <template #paginatorend>
            </template>
            <template #header>

            </template>
            <template #empty>
                <div class="p-3 text-center">
                    No results found.
                </div>
            </template>

            <template #list="slotProps">
                <div :style="`height: ${scrollHeight}px; overflow-y: auto;`">
                    <slot name="list" :data="slotProps.items"></slot>
                </div>
            </template>
        </DataView>
    </div>
</template>

<script setup lang="ts">
import DataView from 'primevue/dataview';
import type { DataTableEvent } from "@/helpers/interface/general"
import { onMounted, onUnmounted, ref, type PropType, watch } from 'vue';
import { calculateScrollHeight } from "@/helpers/common"
const props = defineProps({
	data: {
		type: Object as PropType<{}[]>, // temp
		required: true
	},
	loading: {
		type: Boolean,
		required: true
	},
	firstIndex: {
		type: Number,
		required: true
	},
	rows: {
		type: Number,
		required: true
	},
	componentID: {
		type: String
	},
	totalRecords: {
		type: Number,
		required: true
	},
	parentDataView: {
		type: null,
		required: true
	}

})
const loading = ref(props.loading)
const emits = defineEmits(["onpage"])
const onPage = async (event: DataTableEvent) => emits("onpage", event)
const scrollHeight = ref<number>(0)
const setListScrollHeight = () => {
	scrollHeight.value = calculateScrollHeight(props.parentDataView)
}

watch(() => props.data, () => setListScrollHeight())
onMounted(async () => {
	window.addEventListener("resize", setListScrollHeight)
})

onUnmounted(async () => {
	window.removeEventListener("resize", setListScrollHeight)
})
</script>