<template>

    <!-- Ownership Transferred -->
    <Message v-if="showOwnershipChangeWarning" severity="error">
        <div v-if="ownershipChangeWarning?.special.active_tru_id">
            <span class="fa fa-warning"></span>
            Full ownership transfer detected
            <span v-if="ownershipChangeWarning?.special?.sold_at">
                {{ showDateIfAvailable("on", ownershipChangeWarning.special?.sold_at) }}
            </span>

            <router-link :to="`/candidate-details/${ownershipChangeWarning?.special.active_tru_id}`" target="_blank"
                class="mx-2">
                <span class="fa fa-info-circle"></span>
                New eligibility record created
            </router-link>

            <a href="#" @click.prevent="showArchiveConfirmDialog = true" class="me-2">
                <span class="fa fa-paper-plane"></span>
                Archive as sold
            </a>

            <a href="#" @click.prevent="showConfirmMarkAsFalsePositive = true">
                <span class="fa fa-times-circle"></span>
                This is incorrect
            </a>
        </div>
        <div v-else>
            <span class="fa fa-warning"></span>
            <strong>
                Parcel removed from tax roll
                <span v-if="ownershipChangeWarning?.special?.sold_at">
                    {{ showDateIfAvailable("on", ownershipChangeWarning.special?.sold_at) }}
                </span>
            </strong>
        </div>
    </Message>

    <!-- MLS sale detected -->
    <Message v-if="showMlsSaleWarning" severity="error">
        <span class="fa fa-warning"></span>
        <strong>
            MLS indicates sale
        </strong>
        <span v-if="mlsSaleWarning?.special?.sold_at">
            {{ showDateIfAvailable("occurred on", mlsSaleWarning?.special?.sold_at) }}
        </span>

        <a href="#" @click.prevent="showArchiveConfirmDialog = true" class="mx-2">
            <span class="fa fa-paper-plane"></span>
            Archive as sold
        </a>

        <a href="#" @click.prevent="showConfirmMarkAsFalsePositive = true">
            <span class="fa fa-times-circle"></span>
            This is incorrect
        </a>
    </Message>

    <!-- Modal Dialogs -->
    <ModalDialog v-if="showConfirmMarkAsFalsePositive" title="Invalidate Sale Flag"
        :close="() => showConfirmMarkAsFalsePositive = false">
        <p>
            <span class="fa fa-info-circle"
                v-tooltip.bottom="'This will remove the sale alert and cannot be undone'"></span>
            Mark this sale as incorrect?
        </p>
        <template #footer>
            <loading-icon v-if="formLoading" />
            <Button @click="markAsFalsePositive" :disabled="formLoading">Confirm</Button>
            <Button @click="showConfirmMarkAsFalsePositive = false" severity="secondary">Cancel</Button>
        </template>
    </ModalDialog>
    <ModalDialog v-if="showArchiveConfirmDialog" title="Archive Record" :close="() => showArchiveConfirmDialog = false">
        <p>
            <span class="fa fa-info-circle"
                v-tooltip.bottom="'This action can only be reversed by an administrator.'"></span>
            Move to Archive?
        </p>
        <template #footer>
            <loading-icon v-if="formLoading" />
            <Button @click="archiveParcelAsSold" :disabled="formLoading">Archive</Button>
            <Button @click="showArchiveConfirmDialog = false" severity="secondary">Cancel</Button>
        </template>
    </ModalDialog>
</template>

<script setup lang="ts">
import Button from 'primevue/button'
import Message from 'primevue/message'
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import ModalDialog from '@/components/Shared/ModalDialog.vue'
import type { AxiosError } from "axios"
import { computed, ref } from 'vue'
import { useRoute } from "vue-router";
import { formatDate, getApiErrorMessage } from "@/helpers/common"
import { toast } from "@/helpers/toast"
import { useAPI } from "@/helpers/services/api";
import { useCandidateDetailsStore } from "@/stores/candidateDetails"

const route = useRoute();
const api = useAPI()
const store = useCandidateDetailsStore();
const situsInfo = computed(() => (store.situsInfo))
const showConfirmMarkAsFalsePositive = ref(false);
const showArchiveConfirmDialog = ref(false);
const tru_id = route?.params?.tru_id as string
const formLoading = ref(false)

const showDateIfAvailable = (prefix: string, date: string) => {
	return ` ${prefix} ${formatDate(date)}`
}

const ownershipChangeWarning = computed(() => {
	return situsInfo.value.status?.find(status =>
		status.status_type === "possible_sale"
        && status.special?.source === "taxroll_update"
        && status.expired_at == null
	);
});
const showOwnershipChangeWarning = computed(() => {
	return ownershipChangeWarning.value && store.situsInfo.queue != "archive"
});

const mlsSaleWarning = computed(() => {
	return situsInfo.value.status?.find(status =>
		status.status_type === "possible_sale"
        && status.special?.source?.toLowerCase() === "mls"
        && status.expired_at == null
	);
});
const showMlsSaleWarning = computed(() => {
	return mlsSaleWarning.value && store.situsInfo.queue != "archive"
});
const markAsFalsePositive = async () => {
	formLoading.value = true;
	const payload = {
		tru_ids: [tru_id],
		status_type: "possible_sale"
	}
	await api.post("/taxroll/status/expire", payload)?.then(async () => {
		store.situsInfo.queue = "archive";
		await store.fetchHistory(tru_id, loadFailureHandler);
		await store.fetchMailList(tru_id, loadFailureHandler);
		await store.fetchSitusInfo(tru_id, loadFailureHandler);
		toast.success("Your submission was successful")
	})?.catch((error: AxiosError) => {
		toast.error(getApiErrorMessage(error));
	})?.finally(() => {
		formLoading.value = false;
		showConfirmMarkAsFalsePositive.value = false;
	})
};
const archiveParcelAsSold = async () => {
	formLoading.value = true;
	const note = showOwnershipChangeWarning.value
		? `Parcel flagged for ownership change, superseded by ${ownershipChangeWarning.value?.special.active_tru_id}`
		: `MLS indicated sale occurred on ${mlsSaleWarning.value?.special?.sold_at}`;
	const payload = {
		tru_ids: [tru_id],
		queue: "archive",
		final_outcome: "Property sold",
		note: note
	};
	await api.patch("/taxroll/queue", payload)?.then(async () => {
		store.situsInfo.queue = "archive";
		await store.fetchHistory(tru_id, loadFailureHandler);
		await store.fetchMailList(tru_id, loadFailureHandler);
		await store.fetchSitusInfo(tru_id, loadFailureHandler);
		toast.success("Thank you for your Candidate update.");
	})?.catch((error: AxiosError) => {
		toast.error(getApiErrorMessage(error));
	})?.finally(() => {
		formLoading.value = false;
		showArchiveConfirmDialog.value = false;
	})
};

const loadFailureHandler = (error: AxiosError) => {
	const message = getApiErrorMessage(error)
	toast.error(message)
}
</script>