<template>
  <TheHeader />
  <div class="container">
    <div v-if="state.job?.id"><span>Job {{ state.job.id }} is {{ state.job.status }}</span><span v-if="state.job?.meta?.celery_task_id">&nbsp;[celery_task_id: {{ state.job.meta.celery_task_id }}]</span></div>
    <div>
      <textarea class="form-control" id="params" v-bind:value="JSON.stringify(state?.job?.meta, undefined, 4)" style="height: 30vh"
          :readonly="true"></textarea>
    </div>
    <div class="mb-3" style="margin-top: 20px">
      <label for="logs" class="form-label">Logs</label>
      <div v-if="state.logsLoading" class="spinner-border" role="status" style="width: 15px; height: 15px">
        <span class="visually-hidden">Loading...</span>
      </div>
      <a v-if="cloudwatchLink" :href="cloudwatchLink" target="_blank" class="sm">
        View in CloudWatch
      </a>
      <textarea 
        class="form-control" 
        id="logs" 
        v-bind:value="state.logsText" 
        style="height: 50vh"
        readonly
      ></textarea>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, reactive } from "vue";
import { useAPI } from "@/helpers/services/api";
import type { Log, LogRequest, Job } from "@/helpers/interface/onboarding";
import TheHeader from "@/components/TheHeader.vue";
import { useRouter } from "vue-router";

const api = useAPI();
const router = useRouter();
const job_id = computed(() => router.currentRoute.value.params.job_id as string);
const cloudwatchLink = computed(() => {
	const logStream = state.job?.meta?.log_stream_name;
	const logGroup = state.job?.meta?.log_group_name;
	const region = 'us-west-2';
	const startTime: number = state.job?.started_at ? Date.parse(state.job.started_at) : 0;
	const endTime: number = state.job?.stopped_at ? Date.parse(state.job.stopped_at) : (new Date()).getTime();
	if (!logGroup || !logStream || !startTime) return '';

	return `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/${logGroup.replace(/\//g, '$252F')}/log-events/${logStream.replace(/\//g, '$252F')}$3Fstart$3D${startTime}$26end$3D${endTime}`;
});

// Update the state initialization with the new interface
const state = reactive({
	logsLoading: false,
	customer_id: null,
	schema: '',
	enable_logs: false,
	logStreamEnded: false,
	logsText: '',
	lastLogLoadTime: 0,
	job: {} as Job,
	jobLoading: false,
});


const loadLogs = async () => {
	state.jobLoading = true
	const jobResponse = await api.get(`jobs/internal/${job_id.value}`);
	state.job = jobResponse.data;
  
	state.jobLoading = false
	if (!state.job) {
		return;
	}

	if (state.job.status === 'pending') {
		setTimeout(loadLogs, 5000);
		return;
	}
	// todo fetch logs from job start time
	const logRequest = {
		start_time: state.lastLogLoadTime || (state.job.started_at ? Date.parse(state.job.started_at) : 0)
	} as LogRequest
	state.logsLoading = true
	const response = await api.get(`jobs/logs/${job_id.value}`, { params: logRequest })
	const newLogs: Log[] = response.data
	state.logsLoading = false

	const textarea = document.getElementById('logs') as HTMLInputElement

	newLogs.forEach(
		(log: Log) => {
			if (log.message === "STREAM END") {
				state.logStreamEnded = true
				return
			}
			if (!state.logStreamEnded) {
				state.lastLogLoadTime = log.timestamp + 1
				state.logsText += `${log.message}\n`;
				textarea.scrollTop = textarea.scrollHeight;
			}
		}
	);
	if (!state.logStreamEnded) {
		setTimeout(loadLogs, 5000)
	}
}


onMounted(async () => {
	setTimeout(loadLogs, 500);
});

onBeforeUnmount(() => {
	state.logStreamEnded = true;
});
</script>
