<template>
	<ApplicationSection title="In addition to homestead exemption, I am applying for the following benefits."
		subtitle="By local ordinance only:">

		<div class="element-container">
			<label for="age-limited-income" class="mt-2 form-label">
				Age 65 and older with limited income (amount determined by ordinance)
			</label>
			<CustomRadioGroup :required="false" groupId="age-limited-income" v-model="formFields.age_limited_income"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'age-limited-income-yes', name: 'age-limited-income', label: 'Yes', value: true },
					{ id: 'age-limited-income-no', name: 'age-limited-income', label: 'No', value: false }
				]" />
		</div>
		<div v-if="formFields.age_limited_income === true">
			<div class="element-container ms-2">
				<FileUploader id="proof-of-age" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
					<template #label>
						<label for="proof-of-age" class="form-label-required">
							Proof of Age
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="proof-of-age" />
			</div>

			<div class="element-container ms-2">
				<FileUploader id="dr-501sc" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
					<template #label>
						<label for="dr-501sc" class="form-label-required">
							Completed for DR-501SC (link to form)
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="dr-501sc" />
			</div>

			<div class="element-container ms-2">
				<label for="household-income" class="mt-2 form-label form-label-required">
					Household Income asd
				</label>
				<InputNumber id="household-income" :pt="requiredInputAttribute" v-model="formFields.household_income"
					:min="0" @invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container">
			<label for="age-limited-income-25" class="mt-2 form-label">
				Age 65 and older with limited income and permanent residency for 25 years or more
			</label>
			<CustomRadioGroup :required="false" groupId="age-limited-income-25" v-model="formFields.age_limited_income_25"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'age-limited-income-25-yes', name: 'age-limited-income-25', label: 'Yes', value: true },
					{ id: 'age-limited-income-25-no', name: 'age-limited-income-25', label: 'No', value: false }
				]" />
		</div>

		<div v-if="formFields.age_limited_income_25 === true">
			<div class="element-container ms-2">
				<FileUploader id="dr-501sc-25" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
					<template #label>
						<label for="dr-501sc-25" class="form-label-required">
							Completed for DR-501SC (link to form)
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="dr-501sc-25" />
			</div>

			<div class="element-container ms-2">
				<label for="household-income-25" class="mt-2 form-label form-label-required">
					Household Income
				</label>
				<InputNumber id="household-income-25" :pt="requiredInputAttribute" :min="0"
					v-model="formFields.household_income_25" @invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container">
			<label for="widowed" class="mt-2 form-label">
				$5,000 Widowed
			</label>
			<CustomRadioGroup :required="false" groupId="widowed" v-model="formFields.widowed"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'widowed-yes', name: 'widowed', label: 'Yes', value: true },
					{ id: 'widowed-no', name: 'widowed', label: 'No', value: false }
				]" />

		</div>

		<div class="element-container ms-2" v-if="formFields.widowed === true">
			<FileUploader id="death-certificate" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="death-certificate" class="form-label-required">
						Death Certificate of Spouse
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="death-certificate" />
		</div>

		<div class="element-container">
			<label for="blind" class="mt-2 form-label">
				$5,000 Blind
			</label>
			<CustomRadioGroup :required="false" groupId="blind" v-model="formFields.blind"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'blind-yes', name: 'blind', label: 'Yes', value: true },
					{ id: 'blind-no', name: 'blind', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.blind === true">
			<FileUploader id="blind-proof" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="blind-proof" class="form-label-required">
						Proof of Blindness (Physician, DVA, SSA)
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="blind-proof" />
		</div>

		<div class="element-container">
			<label for="permanently-disabled" class="mt-2 form-label">
				$5,000 Totally and Permanently Disabled
			</label>
			<CustomRadioGroup :required="false" groupId="permanently-disabled" v-model="formFields.disabled"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'permanently-disabled-yes', name: 'permanently-disabled', label: 'Yes', value: true },
					{ id: 'permanently-disabled-no', name: 'permanently-disabled', label: 'No', value: false }
				]" />

		</div>

		<div class="element-container ms-2" v-if="formFields.disabled === true">
			<FileUploader id="permanently-disabled-proof" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="permanently-disabled-proof" class="form-label-required">
						Proof of Disability (Physician, DVA, SSA)
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="permanently-disabled-proof" />
		</div>

		<div class="element-container">
			<label for="permanently-quadriplegic" class="mt-2 form-label">
				Total and Permanent Disability - Quadriplegic
			</label>
			<CustomRadioGroup :required="false" groupId="permanently-quadriplegic" v-model="formFields.quadriplegic"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'permanently-quadriplegic-yes', name: 'permanently-quadriplegic', label: 'Yes', value: true },
					{ id: 'permanently-quadriplegic-no', name: 'permanently-quadriplegic', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.quadriplegic === true">
			<FileUploader id="permanently-quadriplegic-proof" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles" :multiple="true">
				<template #label>
					<label for="permanently-quadriplegic-proof" class="form-label-required">
						Proof from 2 FL Physicians or DVA
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="permanently-quadriplegic-proof" />
		</div>

		<div class="element-container">
			<label for="limited-disability" class="mt-2 form-label">
				Certain Total and Permanent Disabilities - Limited Income and Hemiplegic, Paraplegic,
				Wheelchair Required, or Legally Blind
			</label>
			<CustomRadioGroup :required="false" groupId="limited-disability" v-model="formFields.limited_disability"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'limited-disability-yes', name: 'limited-disability', label: 'Yes', value: true },
					{ id: 'limited-disability-no', name: 'limited-disability', label: 'No', value: false }
				]" />
		</div>
		<div v-if="formFields.limited_disability === true">
			<div class="element-container ms-2">
				<FileUploader id="dr-416-proof" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles" :multiple="true">
					<template #label>
						<label for="dr-416-proof" class="form-label-required">
							DR-416, DR-416B, or Letters from 2 FL Physicians (For legally blind, one can
							be an optometrist.)
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="dr-416-proof" />
			</div>

			<div class="element-container ms-2">
				<FileUploader id="dva-letter" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles" :multiple="true">
					<template #label>
						<label for="dva-letter" class="form-label-required">
							Letter from DVA and DR-501A
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="dva-letter" />
			</div>

			<div class="element-container ms-2">
				<label for="household-income-disabled" class="mt-2 form-label form-label-required">
					Household Income
				</label>
				<InputText id="household-income-disabled" required v-model="formFields.household_income_disabled"
					:min="0" @invalid.capture.prevent="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container">
			<label for="first-responder" class="mt-2 form-label">
				First Responder Totally and Permanently Disabled in the Line of Duty or Surviving Spouse
			</label>
			<CustomRadioGroup :required="false" groupId="first-responder" v-model="formFields.first_responder"
				:elementRequiredHandler="elementRequiredHandler" :options="[
					{ id: 'first-responder-yes', name: 'first-responder', label: 'Yes', value: true },
					{ id: 'first-responder-no', name: 'first-responder', label: 'No', value: false }
				]" />
		</div>

		<div class="element-container ms-2" v-if="formFields.first_responder === true">
			<FileUploader id="first-responder-proof" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles" :multiple="true">
				<template #label>
					<label for="first-responder-proof" class="form-label-required">
						Proof of Disability, Employer Certificate, Physician’s Certificate, and SSA (or
						Additional Physician Certificate)
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="first-responder-proof" />
		</div>

		<div class="element-container">
			<label for="surviving-spouse-responder" class="mt-2 form-label">
				Surviving Spouse of First Responder Who Died in the Line of Duty
			</label>
			<CustomRadioGroup :required="false" v-model="formFields.surviving_spouse_responder"
				groupId="surviving-spouse-responder" :options="[
					{ id: 'surviving-spouse-responder-yes', name: 'surviving-spouse-responder', label: 'Yes', value: true },
					{ id: 'surviving-spouse-responder-no', name: 'surviving-spouse-responder', label: 'No', value: false }
				]" :elementRequiredHandler="elementRequiredHandler" />
		</div>

		<div class="element-container ms-2" v-if="formFields.surviving_spouse_responder === true">
			<FileUploader id="responder-death-proof" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="responder-death-proof" class="form-label-required">
						Letter Attesting to the First Responder’s Death in the Line of Duty
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="responder-death-proof" />
		</div>

		<div class="element-container">
			<label for="disabled-veteran" class="mt-2 form-label">
				Disabled Veteran Discount, 65 or Older (Carries Over to Surviving Spouse)
			</label>
			<CustomRadioGroup :required="false" v-model="formFields.disabled_veteran" groupId="disabled-veteran" :options="[
				{ id: 'disabled-veteran-yes', name: 'disabled-veteran', label: 'Yes', value: true },
				{ id: 'disabled-veteran-no', name: 'disabled-veteran', label: 'No', value: false }
			]" :elementRequiredHandler="elementRequiredHandler" />
		</div>

		<div class="element-container ms-2" v-if="formFields.disabled_veteran === true">
			<FileUploader id="disabled-veteran-proof-age" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="disabled-veteran-proof-age" class="form-label-required">
						Proof of Age
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="disabled-veteran-proof-age" />
		</div>

		<div class="element-container ms-2" v-if="formFields.disabled_veteran === true">
			<FileUploader id="disabled-veteran-dr501dv" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="disabled-veteran-dr501dv" class="form-label-required">
						Completed DR-501DV (link to form)
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="disabled-veteran-dr501dv" />
		</div>

		<div class="element-container">
			<label for="veteran-disabled-10" class="mt-2 form-label">
				Veteran Disabled 10% or More 
			</label>
			<CustomRadioGroup :required="false" v-model="formFields.veteran_disabled_10" groupId="veteran-disabled-10" :options="[
				{ id: 'veteran-disabled-10-yes', name: 'veteran-disabled-10', label: 'Yes', value: true },
				{ id: 'veteran-disabled-10-no', name: 'veteran-disabled-10', label: 'No', value: false }
			]" :elementRequiredHandler="elementRequiredHandler" />
		</div>

		<div class="element-container ms-2" v-if="formFields.veteran_disabled_10 === true">
			<FileUploader id="veteran-disabled-10-proof" :required="true" :accept="acceptFileTypes"
				:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
				<template #label>
					<label for="veteran-disabled-10-proof" class="form-label-required">
						Proof of Disability (DVA or US Government)
					</label>
				</template>
			</FileUploader>
			<ApplicationFileUploadStatus containerID="veteran-disabled-10-proof" />
		</div>

		<div class="element-container">
			<label for="service-disabled-veteran" class="mt-2 form-label">
				Service-Connected Totally and Permanently Disabled Veteran or Veteran’s Surviving Spouse {{ formFields.service_disabled_veteran }}
			</label>
			<CustomRadioGroup :required="false" v-model="formFields.service_disabled_veteran" groupId="service-disabled-veteran"
				:options="[
					{ id: 'service-disabled-veteran-yes', name: 'service-disabled-veteran', label: 'Yes', value: true },
					{ id: 'service-disabled-veteran-no', name: 'service-disabled-veteran', label: 'No', value: false }
				]" :elementRequiredHandler="elementRequiredHandler" />
		</div>

		<div v-if="formFields.service_disabled_veteran === true">
			<div class="element-container ms-2">
				<FileUploader id="service-disabled-veteran-proof" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
					<template #label>
						<label for="service-disabled-veteran-proof" class="form-label-required">
							Proof of Disability (DVA or US Government)
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="service-disabled-veteran-proof" />
			</div>

			<div class="element-container ms-2">
				<label for="prorated-refund" class="mt-2 form-label">
					Applicants for this exemption qualify for a prorated refund of previous year’s taxes
					if in the previous year they acquired this parcel between January 1 and November 1 and provide proof
					of the
					disability as of January 1 of that tax year. Did you receive the same exemption on another parcel in
					the
					previous year?
				</label>
				<CustomRadioGroup required v-model="formFields.prorated_refund" groupId="prorated-refund" :options="[
					{ id: 'prorated-refund-yes', name: 'prorated-refund', label: 'Yes', value: true },
					{ id: 'prorated-refund-no', name: 'prorated-refund', label: 'No', value: false }
				]" :elementRequiredHandler="elementRequiredHandler" />
			</div>
		</div>

		<div class="element-container ms-2" v-if="formFields.prorated_refund === true">
			<label for="parcel-number" class="mt-2 form-label form-label-required">
				Parcel Number
			</label>
			<InputText id="parcel-number" required v-model="formFields.parcel_number"
				@invalid.capture.prevent="elementRequiredHandler" />
		</div>

		<div class="element-container ms-2" v-if="formFields.prorated_refund === true">
			<label for="select-county" class="mt-2 form-label form-label-required">
				Select County
			</label>
			<Select inputId="select-county" v-model="formFields.selected_county" showClear :options="floridaCounties"
				placeholder="-- Select --" />
		</div>

		<div class="element-container">
			<label for="surviving-spouse-veteran" class="mt-2 form-label">
				Surviving Spouse of Veteran Who Died While on Active Duty
			</label>
			<CustomRadioGroup :required="false" v-model="formFields.surviving_spouse_veteran" groupId="surviving-spouse-veteran"
				:options="[
					{ id: 'surviving-spouse-veteran-yes', name: 'surviving-spouse-veteran', label: 'Yes', value: true },
					{ id: 'surviving-spouse-veteran-no', name: 'surviving-spouse-veteran', label: 'No', value: false }
				]" :elementRequiredHandler="elementRequiredHandler" />
		</div>

		<div v-if="formFields.surviving_spouse_veteran === true">
			<div class="element-container ms-2">
				<FileUploader id="veteran-death-proof" :required="true" :accept="acceptFileTypes"
					:elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
					<template #label>
						<label for="veteran-death-proof" class="form-label-required">
							US Government or DVA Letter Attesting to the Veteran's Death While on Active
							Duty
						</label>
					</template>
				</FileUploader>
				<ApplicationFileUploadStatus containerID="veteran-death-proof" />
			</div>

			<div class="element-container ms-2" v-if="formFields.surviving_spouse_veteran === true">
				<label for="veteran-prorated-refund" class="mt-2 form-label">
					Applicants for this exemption qualify for a prorated refund of previous year's taxes
					if in the previous year
					they acquired this parcel between January 1 and November 1 and provide an official
					letter. Did you receive
					the same exemption on another parcel in the previous year?
				</label>
				<CustomRadioGroup required v-model="formFields.veteran_prorated_refund"
					groupId="veteran-prorated-refund" :options="[
						{ id: 'veteran-prorated-refund-yes', name: 'veteran-prorated-refund', label: 'Yes', value: true },
						{ id: 'veteran-prorated-refund-no', name: 'veteran-prorated-refund', label: 'No', value: false }
					]" :elementRequiredHandler="elementRequiredHandler" />
			</div>
		</div>

		<div v-if="formFields.veteran_prorated_refund === true">
			<div class="element-container ms-2">
				<label for="veteran-parcel-number" class="mt-2 form-label form-label-required">
					Parcel Number
				</label>
				<InputText id="veteran-parcel-number" required v-model="formFields.veteran_parcel_number"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>


			<div class="element-container ms-2">
				<label for="veteran-select-county" class="mt-2 form-label form-label-required">
					Select County
				</label>
				<Select id="veteran-select-county" required v-model="formFields.veteran_selected_county"
					:options="floridaCounties" placeholder="Select a county" />
			</div>
		</div>

		<div class="element-container">
			<label for="other-specify" class="mt-2 form-label">
				Other:
			</label>
			<CustomRadioGroup :required="false" v-model="formFields.other_specify" groupId="other-specify" :options="[
				{ id: 'other-specify-yes', name: 'other-specify', label: 'Yes', value: true },
				{ id: 'other-specify-no', name: 'other-specify', label: 'No', value: false }
			]" :elementRequiredHandler="elementRequiredHandler" />
		</div>

		<div class="element-container ms-2" v-if="formFields.other_specify === true">
			<label for="other-text" class="mt-2 form-label form-label-required">
				Specify Details
			</label>
			<InputText id="other-text" required v-model="formFields.other_text"
				@invalid.capture.prevent="elementRequiredHandler" />
		</div>
	</ApplicationSection>

	<ApplicationSection title="">
		<b class="mt-4">If you have applied for a new homestead exemption and have a previous homestead
			in Florida, you may
			be entitled to transfer a homestead assessment difference from a previous homestead.</b>

		<div class="element-container">
			<label for="homestead-transfer" class="mt-2 form-label">
				I would like to transfer a homestead assessment
			</label>
			<CustomRadioGroup required v-model="formFields.homestead_transfer" groupId="homestead-transfer" :options="[
				{ id: 'homestead-transfer-yes', name: 'homestead-transfer', label: 'Yes', value: true },
				{ id: 'homestead-transfer-no', name: 'homestead-transfer', label: 'No', value: false }
			]" :elementRequiredHandler="elementRequiredHandler" />
		</div>
	</ApplicationSection>

	<ApplicationSection title="Previous Homestead Address" v-if="formFields.homestead_transfer === true">
		<div>
			<div class="element-container">
				<label for="homestead-street" class="mt-2 form-label form-label-required">
					Street
				</label>
				<InputText :required="formFields.homestead_transfer" id="homestead-street"
					v-model="formFields.previous_homestead_street" @invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="homestead-unit" class="mt-2 form-label form-label-required">
					Unit
				</label>
				<InputText id="homestead-unit" v-model="formFields.previous_homestead_unit"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="homestead-city" class="mt-2 form-label form-label-required">
					City
				</label>
				<InputText :required="formFields.homestead_transfer" id="homestead-city"
					v-model="formFields.previous_homestead_city" @invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="homestead-state" class="mt-2 form-label form-label-required">
					State
				</label>
				<InputText :required="formFields.homestead_transfer" id="homestead-state"
					v-model="formFields.previous_homestead_state" @invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="homestead-postal-code" class="mt-2 form-label form-label-required">
					Postal Code
				</label>
				<InputText :required="formFields.homestead_transfer" id="homestead-postal-code"
					v-model="formFields.previous_homestead_postal_code"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="homestead-parcel-id" class="mt-2 form-label form-label-required">
					Previous Homestead Parcel ID
				</label>
				<InputText :required="formFields.homestead_transfer" id="homestead-parcel-id"
					v-model="formFields.previous_homestead_parcel_id"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="homestead-county" class="mt-2 form-label form-label-required">
					Previous Homestead County
				</label>
				<Select id="homestead-county" :required="formFields.homestead_transfer" showClear
					v-model="formFields.homestead_county" :options="floridaCounties" placeholder="Select a county" />
			</div>

			<div class="element-container">
				<label for="date-sold" class="mt-2 form-label form-label-required">
					Date Sold or No Longer Used as Homestead
				</label>
				<DateComboBox id="date-sold" @invalid.capture.prevent="elementRequiredHandler"
					:required="formFields.homestead_transfer" v-model="formFields.date_sold" />
			</div>

			<div class="element-container">
				<label for="homestead-owned-lived" class="mt-2 form-label form-label-required">
					Co-applicants who owned and lived at the previous homestead
				</label>
				<InputText :required="formFields.homestead_transfer" id="homestead-owned-lived"
					v-model="formFields.co_applicants_owned_and_lived"
					@invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="homestead-not-moving" class="mt-2 form-label form-label-required">
					Owners of the previous homestead not moving to new homestead
				</label>
				<InputText :required="formFields.homestead_transfer" id="homestead-not-moving"
					v-model="formFields.previous_owners_not_moving" @invalid.capture.prevent="elementRequiredHandler" />
			</div>

			<div class="element-container">
				<label for="any-owners-stayed" class="mt-2 form-label"
					:class="{ 'form-label-required': isAnyOwnersStayedRequired }">
					Did any of the owners stay in the previous homestead?
				</label>
				<CustomRadioGroup groupId="any-owners-stayed" :required="isAnyOwnersStayedRequired"
					v-model="formFields.any_owners_stayed" :elementRequiredHandler="elementRequiredHandler" :options="[
						{ id: 'any-owners-stayed-yes', name: 'any-owners-stayed', label: 'Yes', value: true },
						{ id: 'any-owners-stayed-no', name: 'any-owners-stayed', label: 'No', value: false }
					]" />
			</div>

		</div>
	</ApplicationSection>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext"
import ApplicationSection from '@/components/ApplicationForm/ApplicationSection.vue'
import { useApplicationForm } from '@/stores/applicationForm'
import { computed, watch } from 'vue'
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import FileUploader from "@/components/Shared/FileUploader.vue"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import Select from "primevue/select"
import CustomRadioGroup from "@/components/ApplicationForm//CustomRadioGroup.vue"
import InputNumber from "primevue/inputnumber"
import { requiredInputAttribute } from "@/helpers/common"

const {
	elementRequiredHandler,
	acceptFileTypes,
	uploadFiles,
	floridaCounties
} = defineProps<{
	elementRequiredHandler: <T extends Event>(e: T) => void;
	acceptFileTypes: string;
	uploadFiles: (e: Event) => void;
	floridaCounties: string[]
}>()

const isAnyOwnersStayedRequired = computed((): boolean => (!formFields.value.co_applicants_owned_and_lived && !formFields.value.previous_owners_not_moving))

const storeApplicationForm = useApplicationForm()
const formFields = computed(() => storeApplicationForm.flFields)

watch(() => formFields.value.age_limited_income, (newVal) => {
	// If age-limited income is not selected, clear related fields
	if (!newVal) {
		formFields.value.household_income = null
		formFields.value.proof_of_age_uploaded = null
		formFields.value.dr_501sc_uploaded = null
	}
})

watch(() => formFields.value.age_limited_income_25, (newVal) => {
	// If age-limited income 25 is not selected, clear related fields
	if (!newVal) {
		formFields.value.household_income_25 = null
		formFields.value.dr_501sc_25_uploaded = null
	}
})

watch(() => formFields.value.widowed, (newVal) => {
	// If widowed exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.widowed = null
	}
})

watch(() => formFields.value.blind, (newVal) => {
	// If blind exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.blind = null
	}
})

watch(() => formFields.value.disabled, (newVal) => {
	// If disabled exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.disabled = null
	}
})

watch(() => formFields.value.quadriplegic, (newVal) => {
	// If quadriplegic exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.quadriplegic = null
	}
})

watch(() => formFields.value.limited_disability, (newVal) => {
	// If limited disability exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.household_income_disabled = null
	}
})

watch(() => formFields.value.first_responder, (newVal) => {
	// If first responder exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.first_responder = null
	}
})

watch(() => formFields.value.surviving_spouse_responder, (newVal) => {
	// If surviving spouse of first responder exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.surviving_spouse_responder = null
	}
})

watch(() => formFields.value.disabled_veteran, (newVal) => {
	// If disabled veteran exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.disabled_veteran = null
	}
})

watch(() => formFields.value.veteran_disabled_10, (newVal) => {
	// If 10% disabled veteran exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.veteran_disabled_10 = null
	}
})

watch(() => formFields.value.service_disabled_veteran, (newVal) => {
	// If service-disabled veteran exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.prorated_refund = null
	}
})

watch(() => formFields.value.prorated_refund, (newVal) => {
	// If prorated refund is not selected, clear related fields
	if (!newVal) {
		formFields.value.parcel_number = ""
		formFields.value.selected_county = ""
	}
})

watch(() => formFields.value.surviving_spouse_veteran, (newVal) => {
	// If surviving spouse of veteran exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.surviving_spouse_veteran = null
	}
})

watch(() => formFields.value.veteran_prorated_refund, (newVal) => {
	// If veteran prorated refund is not selected, clear related fields
	if (!newVal) {
		formFields.value.veteran_parcel_number = ""
		formFields.value.veteran_selected_county = ""
	}
})

watch(() => formFields.value.other_specify, (newVal) => {
	// If other exemption is not selected, clear related fields
	if (!newVal) {
		formFields.value.other_text = ""
	}
})

watch(() => formFields.value.homestead_transfer, (newVal) => {
	// If homestead transfer is not selected, clear related fields
	if (!newVal) {
		formFields.value.previous_homestead_street = ""
		formFields.value.previous_homestead_unit = ""
		formFields.value.previous_homestead_city = ""
		formFields.value.previous_homestead_state = ""
		formFields.value.previous_homestead_postal_code = ""
		formFields.value.previous_homestead_parcel_id = ""
		formFields.value.homestead_county = ""
		formFields.value.date_sold = ""
		formFields.value.co_applicants_owned_and_lived = ""
		formFields.value.previous_owners_not_moving = ""
		formFields.value.any_owners_stayed = null
	}
})

</script>