<template>
    <div class="position-relative">
        <label class="config-header">Configure Labels</label>
        <div class="actions-description">
            Create or modify labels that you assign to candidates. <br>
            You can search by labels by name in the Admin Section. <br>
            Tip: use labels for investigation status (field visit) and candidate status (snowbird).
        </div>
        <div class="admin-config-label mt-2">
            <div id="config-label-list"></div>
        </div>
        <div class="position-absolute" :style="{ top: 0, right: 0 }">
            <button type="button" class="searchButton" @click="showNewLabel = true" v-if="hasPermissionToCreateLabel">
                <span class="fa fa-plus-circle" aria-hidden="true"></span>
                New Label
            </button>
        </div>
        <teleport to="body">
            <ModalDialog v-if="showNewLabel" title="New Label" :close="() => showNewLabel = false">
                <AdminConfigAddLabel @add-new-label="() => fetchLabelsList()" />
            </ModalDialog>
        </teleport>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { ref, onMounted, watch, onUnmounted, computed } from "vue"
import AdminConfigAddLabel from "@/components/Admin/AdminConfigAddLabel.vue"
import type { Labels } from "@/helpers/interface/admin-page"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import type { TabulatorFull as Tabulator } from 'tabulator-tables'
import type { ColumnDefinition, CellComponent } from "tabulator-tables";
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import { useAdminDetails } from "@/stores/adminDetails";
import { getApiErrorMessage, validateUserPermission } from "@/helpers/common"
import type { AxiosError } from "axios";
import { toast } from "@/helpers/toast"
import { useAPI } from "@/helpers/services/api"

const api = useAPI()
const storeAdminDetails = useAdminDetails();
const fetchLoading = ref(false)
const showNewLabel = ref(false)
const labelsList = computed((): Labels[] => storeAdminDetails.getAllLabels)
const hasPermissionToCreateLabel = computed(() => validateUserPermission("create", "labels"))

let tabulator: Tabulator | null;
const selectedLabels = ref<null | Labels[]>([])
const findSelectedLabel = (labelID: number) => (selectedLabels.value?.find(item => item.id === labelID))

const clonedArray = ref<null | Labels[]>([])
const findCloneArray = (labelID: number) => (clonedArray.value?.find(item => item.id === labelID))

const hideActionButtons = (editButton: Element | null, saveButton: Element | null, cancelButton: Element | null, hide: string[] | string = ["save", "cancel"]) => {

	// Show all buttons by default
	[editButton, saveButton, cancelButton].forEach(button => {
		if (button) {
			button.classList.remove('d-none')
		}
	})

	// If hide is "all", hide all buttons
	if (hide === "all") {
		[editButton, saveButton, cancelButton].forEach(button => {
			if (button) {
				button.classList.add('d-none')
			}
		})
		return // Early return, no need to execute further logic
	}


	// Map button names to their corresponding elements
	const buttonMap: { [key: string]: Element | null } = {
		'edit': editButton,
		'save': saveButton,
		'cancel': cancelButton
	}

	// Otherwise, hide buttons based on the hide array
	if (Array.isArray(hide)) {
		hide.forEach(buttonName => {
			const button = buttonMap[buttonName]
			if (button) {
				button.classList.add('d-none')
			}
		})
	}
}

const editableFields = (actionContainer: Element | null, labelID: number, editable: boolean = true) => {
	const parentContainer = actionContainer?.parentElement?.parentElement
	const selectedData = findSelectedLabel(labelID)
	const selectedCloneData = findCloneArray(labelID)
	const labelName = parentContainer?.querySelector("div[tabulator-field='label_name']") as HTMLInputElement
	const candidates = parentContainer?.querySelector("div[tabulator-field='candidates']") as HTMLElement
	const applications = parentContainer?.querySelector("div[tabulator-field='applications']") as HTMLElement
	if (!selectedData || !selectedCloneData || !parentContainer || !labelName) return

	if (editable) {
		if (!labelName?.getAttribute("tabulator-default-style")) {
			labelName?.setAttribute("tabulator-default-style", labelName?.getAttribute("style") || "")
		}
		labelName?.setAttribute("style", labelName?.getAttribute("style") + "padding: 3px; background-color: white !important;border: 1px solid var(--main-input-border-color) !important")
		labelName?.focus()

		candidates.innerHTML = ""
		const candidatesCheckbox = document.createElement("input")
		candidatesCheckbox.type = "checkbox"
		candidatesCheckbox.id = `candidates-checkbox-${labelID}`
		candidatesCheckbox.checked = selectedData.candidates || false
		candidatesCheckbox.addEventListener("click", () => {
			selectedCloneData.candidates = candidatesCheckbox.checked
		})
		candidates.appendChild(candidatesCheckbox)

		applications.innerHTML = ""
		const applicationsCheckbox = document.createElement("input")
		applicationsCheckbox.type = "checkbox"
		applicationsCheckbox.id = `applications-checkbox-${labelID}`
		applicationsCheckbox.checked = selectedData.applications || false
		applicationsCheckbox.addEventListener("click", () => {
			selectedCloneData.applications = applicationsCheckbox.checked
		})
		applications.appendChild(applicationsCheckbox)

		const labelNameInput = labelName.querySelector("input")
		if (labelNameInput) {
			labelNameInput.value = selectedData.label_name || ""
			selectedCloneData.label_name = selectedData.label_name || ""
		}
	}
	else {
		labelName?.setAttribute("style", labelName?.getAttribute("tabulator-default-style") || "")
		labelName?.blur()
		labelName.innerHTML = selectedData.label_name

		candidates.innerHTML = selectedData.candidates ? "Yes" : "No"
		applications.innerHTML = selectedData.applications ? "Yes" : "No"
	}
}

const enableInlineEdit = (cell: CellComponent): boolean => {
	const data = cell.getData() as Labels
	if (!selectedLabels.value?.length) return false
	if (findSelectedLabel(data.id)) return true
	return false
}

const saveInlineEdit = async (labelID: number, actionContainer: HTMLDivElement, loadingIcon: HTMLDivElement) => {
	const dataSelected = findSelectedLabel(labelID)
	const selectedCloneData = findCloneArray(labelID)

	if (!dataSelected || !actionContainer || !loadingIcon || !selectedCloneData) return

	try {
		const data = {
			"label_name": selectedCloneData.label_name,
			"candidates": selectedCloneData.candidates,
			"applications": selectedCloneData.applications
		}
		await api.patch(`/labels/${labelID}`, data)
		loadingIcon?.classList.add("d-none")
		editableFields(actionContainer, labelID, false)
		toast.success(`Your update has been saved`)
		fetchLabelsList()
	} catch (error: unknown) {
		const err = error as AxiosError
		toast.error(getApiErrorMessage(err))
	}
}

const tableColumns = ref<ColumnDefinition[]>([
	{
		title: "&nbsp;",
		field: "updated_at",
		width: "10vh",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as Labels

			const parentDiv = document.createElement("div")
			const editButton = document.createElement("a")
			const cancelButton = document.createElement("a")
			const saveButton = document.createElement("a")
			const loadingIcon = document.createElement("div")

			parentDiv.id = `action-container-${data.id}`

			editButton.id = `edit-btn-${data.id}`
			editButton.title = `Edit`
			editButton.innerHTML = "<span class='fa fa-edit' aria-hidden='true'></span>"
			editButton.classList.add(`edit-btn`, `mx-1`)
			editButton.href = "javascript:;"
			editButton.addEventListener("click", () => {
				hideActionButtons(editButton, saveButton, cancelButton, ["edit"])
				selectedLabels.value?.push({
					id: data.id,
					label_name: data.label_name,
					candidates: data.candidates,
					applications: data.applications,
				})
				clonedArray.value = selectedLabels.value?.map(item => ({ ...item })) || null
				editableFields(parentDiv, data.id)
			})

			cancelButton.id = `cancel-btn-${data.id}`
			cancelButton.title = `Cancel`
			cancelButton.innerHTML = "<span class='fa fa-times-circle' aria-hidden='true'></span>"
			cancelButton.classList.add(`cancel-btn`, `d-none`, `mx-1`)
			cancelButton.href = "javascript:;"
			cancelButton.addEventListener("click", () => {
				const selectedData = findSelectedLabel(data.id)
				editableFields(parentDiv, data.id, false)
				hideActionButtons(editButton, saveButton, cancelButton, ["save", "cancel"])
				selectedLabels.value = selectedLabels.value?.filter(item => item.id !== data.id) || null
				clonedArray.value = selectedLabels.value?.map(item => ({ ...item })) || null
				data.label_name = selectedData?.label_name || ""
			})

			saveButton.id = `save-btn-${data.id}`
			saveButton.title = `Save`
			saveButton.innerHTML = "<span class='fa fa-save' aria-hidden='true'></span>"
			saveButton.classList.add(`save-btn`, `d-none`, `mx-1`)
			saveButton.href = "javascript:;"
			saveButton.addEventListener("click", async () => {
				if (data.label_name.length === 0) {
					toast.error("Label name is required")
					return
				}
				hideActionButtons(editButton, saveButton, cancelButton, "all")
				loadingIcon?.classList.remove("d-none")
				await saveInlineEdit(data.id, parentDiv, loadingIcon)
				hideActionButtons(editButton, saveButton, cancelButton, ["save", "cancel"])
				editableFields(parentDiv, data.id, false)
				selectedLabels.value = selectedLabels.value?.filter(item => item.id !== data.id) || null
				clonedArray.value = selectedLabels.value?.map(item => ({ ...item })) || null
			})

			loadingIcon.classList.add("d-inline")
			loadingIcon.classList.add("d-none")
			loadingIcon.setAttribute("id", `loading-icon-${data.id}`)
			loadingIcon.innerHTML = '<span class="fa fa-spinner fa-spin"></span>'

			parentDiv.appendChild(editButton)
			parentDiv.appendChild(cancelButton)
			parentDiv.appendChild(saveButton)
			parentDiv.appendChild(loadingIcon)

			return parentDiv;
		},
	},
	{
		title: "Label Name",
		field: "label_name",
		width: "30vh",
		headerSort: false,
		editor: "input",
		cellEdited: function (cell: CellComponent) {
			const data = cell.getData() as Labels
			const clonedArray = findCloneArray(data.id) || null
			if (!clonedArray) return
			clonedArray.label_name = data.label_name
		},
		editable: enableInlineEdit,
		editorParams: {
			elementAttributes: {
				required: true,
				style: "padding: 3px 0px; width: 100%; box-sizing: border-box",
				maxlength: "255",
			}
		}
	},
	{
		title: "Candidates",
		field: "candidates",
		width: "15vh",
		formatter: function (cell: CellComponent) {
			return cell.getValue() ? "Yes" : "No"
		}
	},
	{
		title: "Applications",
		field: "applications",
		width: "15vh",
		formatter: function (cell: CellComponent) {
			return cell.getValue() ? "Yes" : "No"
		}
	},
	{
		title: "Created On",
		field: "created_at",
		width: "15vh",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			const data = cell.getValue()
			return (data) ? dayjs(data).format("M/D/YYYY") : ""
		}
	},
	{
		title: "Updated On",
		field: "updated_at",
		width: "15vh",
		headerSort: false,
		formatter: function (cell: CellComponent) {
			const data = cell.getValue()
			return (data) ? dayjs(data).format("M/D/YYYY") : ""
		}
	}
])

const loadFailureHandler = (error: AxiosError) => {
	const message = getApiErrorMessage(error, { "featureName": "Customer List" })
	toast.error(message)
}

const fetchLabelsList = async () => {
	fetchLoading.value = true
	await storeAdminDetails.fetchLabelsList(loadFailureHandler)
	fetchLoading.value = false
}

onMounted(() => fetchLabelsList())
onUnmounted(() => destroyTabulator(tabulator))
watch(() => fetchLoading.value, async (isLoading) => {
	tabulator = await createTabulator("#config-label-list", {
		data: labelsList.value,
		columns: tableColumns.value,
		initialSort: [
			{ column: "label_name", dir: "asc" },
		]
	}, isLoading)
})
</script>

<style>
@import "@/assets/admin-page.css";
</style>
