import { computed, ref } from "vue"
import { defineStore } from "pinia"
import type { SupportingDocument } from "@/helpers/interface/trueapp"
import { useAPI } from "@/helpers/services/api"
import type { SummaryDetail } from "@/helpers/interface/appvet"
import type { HistoryAPIResponse, LabelSelected, AssignedUserSelected } from "@/helpers/interface/candidates"
import type { Auth0User } from "@/helpers/interface/admin-page"
import type {PageView} from "@/helpers/interface/general";

export const useTaxroll = defineStore("taxroll", () => {

	const api = useAPI()
	const quickSearchQuery = ref("")
	const setQuickSearchQuery = (qs: string) => quickSearchQuery.value = qs
	const getQuickSearchQuery = computed(() => quickSearchQuery.value)
	const documentList = ref<SupportingDocument[]>([])
	const fetchDocumentListLoading = ref(true)

	const appSummaryDetail = ref<SummaryDetail | null>(null)
	const fetchAppSummaryDetailLoading = ref(true)

	const appHistoryList = ref<HistoryAPIResponse[] | null>(null)
	const fetchAppHistoryListLoading = ref(true)

	const fetchUserInfoLoading = ref(true)
	const cacheUserInfo = ref<Auth0User[]>([])

	const lastView = ref<PageView[] | null>([])
	const fetchLastViewLoading = ref(true)
	const MOVE_TO_TODO_LIST_TIMER = 2000 // milliseconds

	const countAssignedLabel = ref<LabelSelected[]>([])
	const countAssignedLabelLoading = ref(false)

	const countAssignedUser = ref<AssignedUserSelected[]>([])
	const countAssignedUserLoading = ref(false)

	const fetchDocumentList = async (tru_id: string, errorHandler: Function) => {
		fetchDocumentListLoading.value = true

		try {
			const { data } = await api.get(`applications/supporting_documents/${tru_id}`)
			documentList.value = data
		}
		catch (error: unknown) {
			errorHandler(error)
		}
		fetchDocumentListLoading.value = false
	}

	const fetchAppSummaryDetail = async (tru_id: string, errorHandler: Function) => {
		fetchAppSummaryDetailLoading.value = true
		try {
			const { data } = await api.get(`applications/${tru_id}/detail`)
			appSummaryDetail.value = data
		}
		catch (error: unknown) {
			errorHandler(error)
		}
		fetchAppSummaryDetailLoading.value = false
	}

	const fetchAppHistoryList = async (tru_id: string, errorHandler: Function) => {
		fetchAppHistoryListLoading.value = true
		try {
			const { data } = await api.get(`applications/history?application_id=${tru_id}`)
			appHistoryList.value = data
		}
		catch (error: unknown) {
			errorHandler(error)
		}
		fetchAppHistoryListLoading.value = false
	}

	const fetchUserInfo = async (userId: string, errorHandler: Function) => {
		fetchUserInfoLoading.value = true

		const userInfo = cacheUserInfo.value.filter(user => user.user_id === userId)?.[0] || null
		if (userInfo) {
			fetchUserInfoLoading.value = false
			return
		}

		try {
			const { data } = await api.get(`users/auth0/${userId}/detail`)
			cacheUserInfo.value.push(data)
		}
		catch (error: unknown) {
			errorHandler(error)
		}
		fetchUserInfoLoading.value = false
	}

	/**
     * Fetches the latest view for a given TRU ID.
     *
     * @param {string} tru_id - The TRU ID.
     * @param {Function} errorHandler - The error handler.
     */
	const fetchLastView = async (tru_id: string, errorHandler: Function): Promise<void> => {
		fetchLastViewLoading.value = true
		try {
			const { data } = await api.get(`/insights/views/${tru_id}?latest=true`)
			lastView.value = data
		} catch (error: unknown) {
			errorHandler(error)
		}
		fetchLastViewLoading.value = false
	}


	// note: query = `candidates=true` and/or `applications=true`
	const getLabelSelected = async (tru_ids: string[], query: string, errorHandler: Function) => {
		countAssignedLabelLoading.value = true

		try {
			const payload = { tru_ids }
			const { data } = await api.post(`labels/selected?${query}`, payload)
			countAssignedLabel.value = data || []
		}
		catch (error: unknown) {
			errorHandler(error)
		}
		countAssignedLabelLoading.value = false
	}

	const getAssignedUserSelected = async (tru_ids: string[], errorHandler: Function) => {
		countAssignedUserLoading.value = true

		try {
			const payload = { tru_ids }
			const { data } = await api.post(`taxroll/count_assigned`, payload)
			countAssignedUser.value = data || []
		}
		catch (error: unknown) {
			errorHandler(error)
		}
		countAssignedUserLoading.value = false
	}


	const getDocumentList = computed(() => documentList.value)
	const getAppSummaryDetail = computed(() => appSummaryDetail.value)
	const getAppHistoryList = computed(() => appHistoryList.value)
	const getDocumentListLoadingState = computed(() => fetchDocumentListLoading.value)
	const getUserInfo = computed((): Auth0User[] => cacheUserInfo.value)

	const getLastView = computed(() => lastView.value)
	const getCountAssignedLabel = computed((): LabelSelected[] => countAssignedLabel.value)
	const getCountAssignedUser = computed((): (AssignedUserSelected & { id?: string })[] => countAssignedUser.value)

	return {
		MOVE_TO_TODO_LIST_TIMER,
		cacheUserInfo,
		appSummaryDetail,
		appHistoryList,
		lastView,
		countAssignedLabel,
		fetchAppHistoryListLoading,
		countAssignedLabelLoading,
		countAssignedUser,
		countAssignedUserLoading,
		fetchUserInfoLoading,
		fetchAppSummaryDetailLoading,
		fetchUserInfo,
		getAppSummaryDetail,
		getAppHistoryList,
		setQuickSearchQuery,
		getQuickSearchQuery,
		getLastView,
		getLabelSelected,
		getAssignedUserSelected,
		fetchDocumentList,
		fetchAppSummaryDetail,
		fetchAppHistoryList,
		fetchLastView,
		fetchLastViewLoading,
		getDocumentListLoadingState,
		getDocumentList,
		getUserInfo,
		getCountAssignedLabel,
		getCountAssignedUser
	}
})