<template>
  <div ref="CustomerConfigListDataView" id="CustomerConfigListDataView">
    <div class="row action-toolbar-container" id="page-header-action-section">
      <div class="col-6" v-if="!loading">
        <Button type="button" class="me-2" @click="SendToWindow($router.resolve({name: 'onboardingConfig', params: { id: 'new' }}).href)" severity="info">
          <span class="fa fa-edit" aria-hidden="true"></span> New
        </Button>
        <Button type="button" class="me-2" @click="toggleNotification(false)">
          <span class="fa fa-plus-circle" aria-hidden="true"></span> Assign Config
        </Button>
        <Button type="button" class="me-2" @click="toggleNotification(true)" severity="danger">
          <span class="fa fa-minus-circle" aria-hidden="true"></span> Unassign Config
        </Button>
      </div>
      <div class="col-4" v-else>
        <Skeleton width="400px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="col-2" v-if="!loading">
        <input type="text"  id="customerInput" class="form-control" placeholder="Customer Search" v-model="searchField" @keydown.enter="search = searchField">
      </div>
      <div class="col-2" v-if="!loading">
        <Button v-if="searchField" type="button" class="me-2" @click="search = searchField" severity="secondary">
          <span class="fa fa-solid fa-magnifying-glass" aria-hidden="true"></span>
        </Button>
        <Button v-if="search" type="button" class="me-2" @click="search = ''; searchField = ''" severity="secondary">
          <span class="fa fa-solid fa-circle-xmark" aria-hidden="true"></span>
        </Button>
      </div>
      <div class="col-2" v-else>
        <Skeleton width="225px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-if="!loading">
        <div>
          <Checkbox v-model="excludeQA" :input-id="'excludeQA'" binary/>
          <label for="excludeQA">Exclude QA</label>
        </div>
        <div>
          <Checkbox v-model="listed" :input-id="'listed'" binary/>
          <label for="listed">Listed-Only</label>
        </div>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-else>
        <Skeleton width="150px" height="16px" class="ms-2 mb-2"></Skeleton>
        <Skeleton width="150px" height="16px" class="ms-2 mb-2"></Skeleton>
      </div>
    </div>

    <div v-if="loading">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td colspan="6" style="text-align: center">
              <div class="spinner-border" style="width: 100px; height: 100px;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <BasicStrapCards :loading="loading" :parent-data-view="CustomerConfigListDataView" :total-records="totalRecords" :rows="rows" :first-index="first" :data="data" @onpage="(event) => onPage(event)" :key="reloader">
      <template #list="slotProps">
        <div v-for="(item, index) in (slotProps.data as CustomerConfigList[])" :key="index" class="row dataview-list-row-card" :id="`row_${index}`">
          <div class="col-1 align-content-center" id="selectionColumn">
            <div class="p-3">
              <Checkbox v-model="selectedItems" :inputId="item.customer_id" name="category" :value="item.customer_id" />
            </div>
          </div>
          <div class="col align-content-center clipboard-copy-wrapper">
            <div class="p-3">
              <div class="mb-2">
                {{ item.customer_name }}
              </div>
            </div>
          </div>
          <div class="col-8">
            <table class="table" style="border: 1px solid transparent;">
              <tbody>
                <tr v-for="(config, config_index) in getRow(item)" :key="config_index">
                  <td style="width: 200px;">
                    <a :href="'/onboarding/configs/' + config.config.id" target="_blank">
                      {{ config.config.name }}
                    </a>
                  </td>
                  <td style="width: 50px;">{{ config.type }}</td>
                  <td style="width: 100px;">{{ new Date(config.config.published_at).toDateString() }}</td>
                  <td style="width: 200px;">{{ getVersion(config.config.version_id) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </BasicStrapCards>
  </div>
  <teleport to="body">
		<div class="modal-overlay" v-if="notificationVisibility.showNotification" @click="toggleNotification(removal)"></div>
		<OnboardingConfigPopup @closePopup="toggleNotification(removal)" class="notification" :customer_id="selectedItems.pop()" :removal="removal"
			v-if="notificationVisibility.showNotification" />
	</teleport>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import {reactive, onMounted, watch, ref, onBeforeUnmount} from "vue";
import type {CustomerConfigList, LightConfig, LightConfigVersion} from "@/helpers/interface/onboarding";
import {getApiErrorMessage, hidePFGetHelpButton, scrollToView} from "@/helpers/common";
import OnboardingConfigPopup from "@/components/Admin/Onboarding/Onboarding-configPopup.vue";
import Checkbox from "primevue/checkbox";
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";
import type {DataTableEvent} from "@/helpers/interface/general";
import {toast} from "@/helpers/toast";

const state = reactive({
	configs: [] as Array<LightConfig>,
	config_versions: [] as Array<LightConfigVersion>
})

const CustomerConfigListDataView = ref<HTMLElement | null>(null);
const loading = ref(false);
const first = ref(0);
const rows = ref(25);
const data = ref<CustomerConfigList[]>([]);
const totalRecords = ref(0);
const currentPage = ref(1);
const dataParams = ref<DataTableEvent | null>(null);
const reloader = ref(0)
const searchField = ref<string>("")
const search = ref<string>("")
const removal = ref<boolean>(false)

const selectedItems = ref<string[]>([])

const excludeQA = ref<boolean>(true)
const listed = ref<boolean>(true)

const api = useAPI()

const notificationVisibility = ref({ showNotification: false })
const toggleBodyScrolling = (allowScrolling: boolean) => document.body.style.overflow = allowScrolling ? 'auto' : 'hidden';
const toggleNotification = (is_removal: boolean) => {
	removal.value = is_removal;
	notificationVisibility.value.showNotification = !notificationVisibility.value.showNotification;
	if (!notificationVisibility.value.showNotification) {
		getConfigs();
		getConfigVersions();
		loadData();
	}
	toggleBodyScrolling(!notificationVisibility.value.showNotification);
}
onBeforeUnmount(() => toggleBodyScrolling(true))

const SendToWindow = (url: string) => {
	window.open(url, '_blank')
}

watch([excludeQA, listed, search], () => {
	first.value = 0;
	onPage({page: 0, first: 0, rows: rows.value});
	reloader.value += 1;
})


const getConfigVersions = async () => {
	loading.value = true;
	const response = await api.get(`onboarding/configs/versions/list`);
	state.config_versions = response.data;
	loading.value = false;
}

const getConfigs = async () => {
	loading.value = true;
	const response = await api.get(`onboarding/configs/`);
	state.configs = response.data;
	loading.value = false;
}

const getRow = (customer: CustomerConfigList): any => {
	const configs = [
		{
			type: 'TaxRoll',
			config: getConfig(customer.taxroll_config)
		},
		{
			type: 'Apps',
			config: getConfig(customer.digital_config)
		},
		{
			type: 'OCR',
			config: getConfig(customer.ocr_config)
		},
	]
	return configs.filter((config) => config.config !== null)
}

const getConfig = (id: string | number) => {
	return state?.configs?.find(config => config.id == id) || null
}

const getVersion = (version_id: any) => {
	if (version_id){
		return state.config_versions.find(item => item.id === version_id)?.meta.created_by.comment || "Comment Not Found"
	}
	else {
		return "No Valid Version ID"
	}
}

const configParams = (event: any) => {
	rows.value = event?.rows
	return {
		"page": event?.page,
		"page_size": event?.rows,
		"include_total": false
	}
}

const loadData = async (event: any = null) => {
	loading.value = true
	dataParams.value = { ...dataParams.value, page: event?.page || currentPage.value, rows: event?.rows || 25 }
	let payload = configParams(dataParams.value)

	payload.page = currentPage.value

	const requests = []

	const customer_filter = {
		"limit": parseInt(payload.page_size),
		"offset": (parseInt(payload.page_size) * (parseInt(payload.page) - 1)),
		"listed": listed.value,
		"exclude_qa": excludeQA.value,
		"search": search.value,
	}
	const listRequest = api.post(`/onboarding/ingestions/configs`, customer_filter)
		?.then(response => {
			data.value = response.data || []
		})
		?.catch(error => {
			data.value = []
			toast.error(getApiErrorMessage(error))
		})
	requests.push(listRequest)

	const countRequest = api.post(`/onboarding/ingestions/configs/count`, customer_filter)
		?.then(response => {
			totalRecords.value = response.data || 0
		})
		?.catch(error => {
			totalRecords.value = 0
			toast.error(getApiErrorMessage(error))
		})
	requests.push(countRequest)

	await Promise.all(requests)
	loading.value = false
}

const onPage = async (event: DataTableEvent) => {
	event.page += 1
	dataParams.value = event
	currentPage.value = event.page
	await loadData(event)
	scrollToView("row_0")
}

onMounted(async () => {
	await getConfigs()
	await getConfigVersions()
	await loadData()
	hidePFGetHelpButton()
});

</script>

<style scoped>
</style>